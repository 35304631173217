import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  docsIcon,
  fadeDocsIcon,
  fileCircle,
  fileCircleIconFade,
  startComment,
  startCommentfade,
} from "./assets";
export const configJSON = require("./config");
const sideNavTab = [
  {
    id: 1,
    tab: "Overview",
    tabIcon: fileCircleIconFade,
    activeTabIcon: fileCircle,
    isActive: true,
  },
  {
    id: 2,
    tab: "Officail Docs",
    tabIcon: fadeDocsIcon,
    activeTabIcon: docsIcon,
    isActive: false,
  },
  {
    id: 3,
    tab: "Ratings",
    tabIcon: startComment,
    activeTabIcon: startCommentfade,
    isActive: false,
  },
];
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isPastShyft?: boolean;
  sideNavTab?: any;
  user:any
}

interface SS {
  id: any;
}

export default class ShyfterProfilePageContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserApiCallId: string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.state = {
      isPastShyft: false,
      sideNavTab: sideNavTab,
      user:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    const profileData = localStorage.getItem('userData')
    if (!!profileData) {
      const userdata = JSON.parse(profileData);
      this.getShyfterProfile(userdata.user.id);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponseData(message);
    }
  }
  handleApiResponseData(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserApiCallId) {
      this.getShyfterUserProfileRes(responseJson);
    }
  }


  getShyfterProfile(userId:any) {
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token:localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShyfteUserProfileEndPoint+`/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getShyfterUserProfileRes(responseJson: any) {
    this.setState({
        user:responseJson.user.data.attributes
    })
  }

  handleActiveTab = (tabId:any) => {
    const updatedTabs = this.state.sideNavTab.map((tab:any) => {
      if (tab.id === tabId) {
        return { ...tab, isActive: true };
      } else {
        return { ...tab, isActive: false };
      }
    });

    this.setState({ sideNavTab: updatedTabs });
  };

 
  navigateManageProfile(){
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EditShyfterProfilePage");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPastShyftMessage), "/Mange Your Account");
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }
}
