import React from "react";

import {
  Container,
  Box,
  Tabs,
  Tab,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StorefrontTwoTone from "@material-ui/icons/StorefrontTwoTone";
import WorkTwoTone from "@material-ui/icons/WorkTwoTone";
import './style.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TermsAndConditions3Controller, {
  Props,
  configJSON,
} from "./TermsAndConditions3Controller";
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";

export default class TermsAndConditions3 extends TermsAndConditions3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <GenericHeader navigation={this.props.navigation} />
        <Box className="commonheader">
          <Box className="firstsection-heading commonheader-heading">
            <Typography variant="h5" className="heading-main" data-test-id="test-heading">Terms & <span>Conditions</span></Typography>
            <label className="heading-mainsub">
              <span className="header-home" onClick={() => window.location.href = "/"}>Home</span> &nbsp;|&nbsp; <span className="header-home" onClick={() => window.location.href = "/TermsAndConditions"}>Terms & conditions</span>
            </label>
          </Box>
        </Box>
        <Box className="imageSection tabsection">
          <nav className="terms-nav">
            <Tabs aria-label="lab API tabs example" value={this.state.tabsValue} onChange={this.handleChange}>
              <Tab className="TabItem" label="Business T&C" value="Business" icon={<StorefrontTwoTone />} />
              <Tab className="TabItem" label="Shyfter T&C" value="Shyfter" icon={<WorkTwoTone />} />
            </Tabs>
          </nav>
          <div className="business-body" data-test-id="content">
            {this.state.tabsValue === "Business" ? (
              <>
                <h3>Business</h3>
                <h4>1) Ut enim ad minim veniam, quis nostrud exercitation</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>2) Duis aute irure dolor in reprehenderit in voluptate velit esse</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>3) Sunt in culpa qui officia deserunt mollit anim id est laborum</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>4) Excepteur sint occaecat cupidatat non</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>5) Consectetur adipisicing elit, sed do eiusmod</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>
              </>
            ) : (
              <>
                <h3>Shyfter</h3>
                <h4>1) Ut enim ad minim veniam, quis nostrud exercitation</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>2) Duis aute irure dolor in reprehenderit in voluptate velit esse</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>3) Sunt in culpa qui officia deserunt mollit anim id est laborum</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>4) Excepteur sint occaecat cupidatat non</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>

                <h4>5) Consectetur adipisicing elit, sed do eiusmod</h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </p>
              </>
            )}
          </div>
        </Box>
        <GenericFooter
          navigation={this.props.navigation}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  iconLabelWrapper: {
    flexDirection: "column"
  },
};
// Customizable Area End
