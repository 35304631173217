import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import dayjs from "dayjs";
import {
  marker,
  calendar,
  checkmark,
  dbaIcon,
  dlIcon,
  emailIcon,
  globleIcon,
  hastagIcon,
  phoneIcon,
  userIcon,
  hotelIcon,
  restaurantIcon,
} from "./assets";
interface Props {
  userData: any;
}
export class UserProfileBasicBlock extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      role_id,
      address,
      date_of_birth,
      ssn,
      dl_number,
      language_skill,
      restaurant_skills,
      hotel_skills,
      full_name,
      email,
      full_phone_number,
      age,
      dl_state,
    } = this.props.userData;

    const { dba_name, website } =
      this.props.userData.business_details != undefined &&
      this.props.userData.business_details;
    return role_id == 1 ? (
      <>
        <Box>
          <Box marginBottom="20px">
            <Typography style={webStyle.lable}>Basic Details</Typography>
          </Box>
          <Box style={webStyle.boxContainer} bgcolor="#fff" padding="0 10px">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box padding="10px">
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    padding="10px 5px"
                  >
                    <img src={dbaIcon} alt="" height="24px" />
                    <Box marginLeft="10px">
                      <Typography style={webStyle.fadeText}>
                        DBA Name
                      </Typography>
                      <Typography style={webStyle.contentText}>
                        {dba_name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box padding="10px">
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    padding="10px 5px"
                  >
                    <img src={marker} height="24px" />
                    <Box marginLeft="10px">
                      <Typography style={webStyle.fadeText}>
                        Hotel Address
                      </Typography>
                      <Typography style={webStyle.contentText}>
                        {this.props.userData.business_details?.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Divider variant="middle" />
            <Box display="flex" padding="10px">
              <img src={globleIcon} height="24px" />
              <Box marginLeft="10px">
                <Typography style={webStyle.fadeText}>
                  Business website
                </Typography>
                <Typography style={webStyle.contentText}>{website}</Typography>
              </Box>
            </Box>
          </Box>

          <Box marginY="10px">
            <Typography>Management Person Details</Typography>
          </Box>
          <Box style={webStyle.boxContainer} bgcolor="#fff" padding="0 10px">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box padding="10px">
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    padding="10px 5px"
                  >
                    <img src={userIcon} alt="" height="24px" />
                    <Box marginLeft="10px">
                      <Typography style={webStyle.fadeText}>
                        Contact Name
                      </Typography>
                      <Typography style={webStyle.contentText}>
                        {full_name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box padding="10px">
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    padding="10px 5px"
                  >
                    <img src={phoneIcon} height="24px" />
                    <Box marginLeft="10px">
                      <Typography style={webStyle.fadeText}>
                        Contact Number
                      </Typography>
                      <Typography style={webStyle.contentText}>
                        {full_phone_number}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Box display="flex" padding="10px">
              <img src={emailIcon} height="24px" />
              <Box marginLeft="10px">
                <Typography style={webStyle.fadeText}>Contact Email</Typography>
                <Typography style={webStyle.contentText}>{email}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    ) : (
      <>
        <Box marginBottom={"20px"}>
          <Typography style={webStyle.lable}>Basic Personal Details</Typography>
        </Box>
        <Box style={webStyle.boxContainer} bgcolor={"#fff"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box padding={"10px"}>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  padding={"10px 5px"}
                >
                  <img src={marker} alt="" height={"28px"} />
                  <Box marginLeft={"10px"}>
                    <Typography style={webStyle.fadeText}>Address</Typography>
                    <Typography style={webStyle.contentText}>
                      {address}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box padding={"10px"}>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  padding={"10px 5px"}
                >
                  <img src={calendar} alt="" height={"28px"} />
                  <Box marginLeft={"10px"}>
                    <Typography style={webStyle.fadeText}>
                      Date of Birth
                    </Typography>
                    <Typography style={webStyle.contentText}>
                      {dayjs(date_of_birth).format("MMM D, YYYY")} | {age}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box padding={"10px"}>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  padding={"10px 5px"}
                >
                  <img src={hastagIcon} alt="" height={"28px"} />
                  <Box marginLeft={"10px"}>
                    <Typography style={webStyle.fadeText}>SSN</Typography>
                    <Typography style={webStyle.contentText}>{ssn}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box padding={"10px"}>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  padding={"10px 5px"}
                >
                  <img src={dlIcon} alt="" height={"28px"} />
                  <Box marginLeft={"10px"}>
                    <Typography style={webStyle.fadeText}>DL</Typography>
                    <Typography style={webStyle.contentText}>
                      {dl_state} | {dl_number}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Box display={"flex"} padding={"10px"}>
            <img src={globleIcon} alt="" height={"28px"} />
            <Box marginLeft={"10px"}>
              <Typography style={webStyle.fadeText}>Language skills</Typography>
              <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                {language_skill?.map((skill: string) => {
                  return (
                    <Box
                      key={skill}
                      borderRadius={"50px"}
                      padding={"5px 10px"}
                      bgcolor={"#e8e1f0"}
                      margin={"5px"}
                    >
                      <Typography style={webStyle.fadeText}>{skill}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box margin={"10px"}>
          <Typography style={webStyle.lable}>Skill Inventory</Typography>
        </Box>
        <Box style={webStyle.boxContainer} padding={"10px"} bgcolor={"#fff"}>
          <Box margin={"10px"} padding={"10px"}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display={"flex"} alignItems={"center"}>
                  <img src={restaurantIcon} alt="" height={"28px"} />
                  <Box marginLeft={"10px"}>
                    <Typography style={webStyle.activeText}>
                    Restaurant Skill Set
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display={"flex"} alignItems={"center"}>
                  <img src={hotelIcon} alt="" height={"28px"} />
                  <Box marginLeft={"10px"}>
                    <Typography style={webStyle.activeText}>
                      Hotel Skill Set
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider variant="middle" />
          <Box padding={"10px"}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  {restaurant_skills?.map((skill: string) => {
                    return (
                      <Box
                        display={"flex"}
                        alignItems="center"
                        marginY={"10px"}
                      >
                        <img
                          src={checkmark}
                          height={"18px"}
                          style={{ marginRight: "4px" }}
                        />
                        <Typography style={webStyle.contentText}>
                          {skill}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  {hotel_skills?.map((skill: string) => {
                    return (
                      <Box
                        display={"flex"}
                        alignItems="center"
                        marginY={"10px"}
                      >
                        <img
                          src={checkmark}
                          height={"18px"}
                          style={{ marginRight: "4px" }}
                        />
                        <Typography style={webStyle.contentText}>
                          {skill}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}

const webStyle = {
  contentText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000",
    fontWieght: 500,
  },
  boxContainer: {
    marginY: "10px",
    bgcolor: "#fff",
    borderRadius: "20px",
    border: "1px solid #e1e6ec",
  },

  lable: {
    color: "#000",
    fontWieght: 700,
    fontSize: "16px",
    lineHeight: "18px",
  },
  fadeText: {
    color: "#6f7c8e",
    fontWieght: 500,
    fontSize: "16px",
    lineHeight: "18px",
  },

  mainWrapper: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  activeText: {
    fontSize: "16px",
    color: "#390879",
    fontWieght: 500,
    lineHeight: "18px",
  },
};
