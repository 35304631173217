import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getUserRole } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  userAddress?:string;
  userName?:string;
  isBreadcrumbs?:boolean;
  gotToProfile?:any;
  breadCrumsData?: any;
  currentPage?: string;
  pageTitle?:string
}

interface S {
  isOpenTabsMenu: boolean;
  isProfileMenu: boolean;
  isShowNotification: any;
  activeTab: string;
  activePage: string;
  isEmployer: boolean;
  parenRoute: string;
 
}

interface SS {
  id: any;
}

export default class LandingPageHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationShyfterDashboardMessage),
      getName(MessageEnum.NavigatoShyfterProfileMessage),
      getName(MessageEnum.NavigationPastShyftMessage),
      getName(MessageEnum.NavigationToDashboardStatsMessage),
      getName(MessageEnum.NavigationToEmployerLandingPageMessage),
    ];

    this.state = {
      isOpenTabsMenu: false,
      isProfileMenu: false,
      isShowNotification: null,
      activeTab: window.location.pathname,
      activePage: "",
      parenRoute: "",
      isEmployer: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    this.getActiveTab(message);
  }

  async componentDidMount() {
    const userRoleId = getUserRole();
    this.setState({ ...this.state, isEmployer: userRoleId === "1" });
  }

  getActiveTab(message: any) {
    if (getName(MessageEnum.NavigationShyfterDashboardMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigationShyfterDashboardMessage)
      );
      this.setState({ activeTab: currentTab });
    }
    if (getName(MessageEnum.NavigationPastShyftMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigationPastShyftMessage)
      );
      this.setState({ activeTab: currentTab });
    }
    if (getName(MessageEnum.NavigationSavedShyftMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigationSavedShyftMessage)
      );
      this.setState({ activeTab: currentTab });
    }
    if (getName(MessageEnum.NavigatoShyfterProfileMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigatoShyfterProfileMessage)
      );
      this.setState({ activeTab: currentTab });
      if (
        getName(MessageEnum.NavigationToDashboardStatsMessage) === message.id
      ) {
        const currentTab = message.getData(
          getName(MessageEnum.NavigationToDashboardStatsMessage)
        );
        this.setState({ activeTab: currentTab });
      }
      if (
        getName(MessageEnum.NavigationToEmployerLandingPageMessage) ===
        message.id
      ) {
        const currentTab = message.getData(
          getName(MessageEnum.NavigationToEmployerLandingPageMessage)
        );
        this.setState({ activeTab: currentTab });
      }
    }
  }
  goToHome() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      this.state.isEmployer ? "EmployerHomePage" : "ShyfterLandingPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (this.state.isEmployer) {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationToEmployerLandingPageMessage)
      );
      raiseMessage.addData(
        getName(MessageEnum.NavigationToEmployerLandingPageMessage),
        "/LandingPage"
      );
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
    } else {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationShyfterDashboardMessage)
      );
      raiseMessage.addData(
        getName(MessageEnum.NavigationShyfterDashboardMessage),
        "/ShyfterDashboard"
      );
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
    }
  }

  navigateToYourSubscription() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CurrentSubscription"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationYourSubsCriptionMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationYourSubsCriptionMessage),
      "Your Subscription"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  openProfileMenu() {
    this.setState({
      isShowNotification: null,
      isProfileMenu: !this.state.isProfileMenu,
    });
  }
  goToPastShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PastShyftPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/PastShyft"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }
  goToSavedShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SavedShyftPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/SavedShyft"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  goToMyShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyShyftsPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/MyShyfts"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  goToDashboardStats() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "DashboardStats");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationToDashboardStatsMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationToDashboardStatsMessage),
      "/DashboardStats"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  openTabsMenu() {
    this.setState({
      isOpenTabsMenu: !this.state.isOpenTabsMenu,
    });
  }
  showNotification(e: any) {
    this.setState({
      isProfileMenu: false,
      isShowNotification: e?.currentTarget,
    });
  }

  closeNotification = () => {
    this.setState({...this.state, isShowNotification: null});
  }

  goToPage = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData( getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  handleBreadCrumNavigation = (path: string) => {
    if(path === "Home") {
      this.goToHome();
    } else {
      this.goToPage(path)
    }
  }
}
