export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const marker = require("../assets/marker.png");
export const fadeDocsIcon = require("../assets/document-signed.png");
export const docsIcon = require("../assets/document-signed(1).png");
export const fileCircleIconFade = require("../assets/file-circle-info(1).png");
export const fileCircle = require("../assets/file-circle-info.png");
export const idBadge = require("../assets/id-badge copy.png");
export const startCommentfade = require("../assets/star-comment-alt(1).png");
export const startComment = require("../assets/star-comment-alt.png");
export const hourglass = require("../assets/hourglass.png");
export const phoneIcon = require("../assets/phone-flip.png");
export const globeIcon = require("../assets/globeIcon.png");
export const buildingIcon = require("../assets/building.png");
export const envelopeIcon = require("../assets/envelope.png");
export const userIcon = require("../assets/user.png");
