import React from "react";
import { Box, Checkbox, Typography, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const useStyle = makeStyles({
  chatCardWrapper: {
    border: "1px solid #E1E6EC",
    borderRadius: "14px",
    marginBottom: "15px",
    marginRight: "5px",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(57, 8, 121,0.1)",
    },
  },

  selected: {
    backgroundColor: "rgb(57, 8, 121,0.1)",
  },

  unSelected: {
    backgroundColor: "#FFFFFF",
  },

  headerDetails: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
  },

  personalInfo: {
    gap: "8px",
    display: "flex",
  },

  chatImgWrapper: {
    "& img": {
      width: "46px",
      borderRadius: "12px",
      height: "46px",
    },
  },

  messangerDetail: {
    "& > div": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    "& h3": {
      margin: "0px",
    },
    "& p": {
      margin: "0px",
    },
    "& svg": {
      fontSize: "15px",
    },
  },

  timeBox: {
    flexDirection: "column",
    gap: "5px",
    fontWeight: 800,
    display: "flex",
    justifyContent: "center",

    "& span": {
      fontSize: "11px",

      lineHeight: "16px",
      color: "#6F7C8E",
      letterSpacing: "0",
      textAlign: "right",
    },
    "& .MuiChip-root": {
      background: "#F7B500",
      borderRadius: "12px",
      color: "#FFFFFF",
      fontFamily: "Gotham-Bold",
      height: "27px",
      fontSize: "11px",

      textAlign: "right",
      letterSpacing: "0.2px",
      lineHeight: "18px",

      "& .MuiChip-avatar": {
        height: "12px",
        width: "11px",
        marginRight: "-8px",

        marginLeft: "8px",
      },
      "& span": {
        color: "#FFFFFF",
      },
    },
  },

  ratingTime: {
    fontSize: "11px",
    color: "#6f7c8e",
    fontFamily: "Gotham-Bold",
    fontWeight: 500,

    "@media(max-width:375px)": {
      fontSize: "9px",
    },
  },

  messangerName: {
    fontWeight: 500,
    fontFamily: "Gotham",
    fontSize: "16px",
    lineHeight: "25px",
    color: "#0a0412",

    "@media(max-width:375px)": {
      fontSize: "11px",
    },
  },
});

interface ChatCardProps {
  jobTitle: string;
  senderName: string;
  time: string;
  imgUrl: string;
  lastChat: string;
  isActionNeeded?: boolean;
  chatId: number;
  activeId: number;
  selectedItem: (e: any, chatId: string) => void;
  handleRenderChat: (chatId: number) => void;
}
const ChatCard = (props: ChatCardProps) => {
  const classes = useStyle();

  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box
        data-test-id="startChatBtn"
        sx={{ width: props.isActionNeeded ? "90%" : "100%" }}
        className={
          props.chatId === props.activeId
            ? `${classes.selected} ${classes.chatCardWrapper}`
            : `${classes.unSelected} ${classes.chatCardWrapper}`
        }
        onClick={() => props.handleRenderChat(props.chatId)}
      >
        <Box className={classes.headerDetails}>
          <Box className={classes.personalInfo}>
            <Box className={classes.chatImgWrapper}>
              <img src={props.imgUrl} alt="" />
            </Box>
            <Box className={classes.messangerDetail}>
              <Box>
                <Typography className={classes.messangerName} variant="h6">
                  {props.senderName} | {props.jobTitle}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.timeBox}>
            <Typography className={classes.ratingTime}>{props.time}</Typography>
          </Box>
        </Box>
      </Box>
      {props.isActionNeeded && (
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
          style={{ color: "#390879", marginBottom: "10px" }}
          key={props.chatId}
          value={props.chatId}
          onChange={(event) => props.selectedItem(event, String(props.chatId))}
        />
      )}
    </Box>
  );
};

export default ChatCard;
