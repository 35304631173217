import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { 
  convertDateIntoDateMonthYearFormat, 
  convertMealBreakIntoTimeFormat, 
  convertMealBreakStringIntoNumber, 
  covertHourMinIntoDateTimeString, 
  getTimeHoursFromDateString 
} from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  updateStatusData: any;
  mealBreakVal: string;
  mealBreakErr: string;
  isUpdateTime: boolean;
  punchInHourTime: string;
  punchInMinTime: string;
  punchOutHourTime: string;
  punchOutMinTime: string;
  punchInAmPm: string;
  punchOutAmPm: string;
  statusType: string;
  isAddMealCheck: boolean;
  changeStatusToValue: string;
  isStatusUpdated: boolean;
  currentStatusDate: string
  apiError: boolean;
  activityId: number;
  timeErr: string;
  changeStatusToValueErr: string;
}

interface SS {
  id: any;
}

export default class UpdateStatusPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateActivityStatusAPIId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      updateStatusData: {},
      mealBreakVal: "",
      mealBreakErr: "",
      isUpdateTime: false,
      punchInHourTime: "",
      punchInMinTime: "",
      punchInAmPm: "AM",
      punchOutHourTime: "",
      punchOutMinTime: "",
      punchOutAmPm: "AM",
      statusType: "",
      isAddMealCheck: false,
      changeStatusToValue: "",
      isStatusUpdated: false,
      currentStatusDate: "",
      apiError: false,
      activityId: 0,
      timeErr: "",
      changeStatusToValueErr: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getUpdateStatusSessionData = async() => {
    try {
        const data = await getStorageData("updateStatusData", true);
        this.setState({ 
          updateStatusData: data , 
          mealBreakVal: convertMealBreakIntoTimeFormat(data.attributes.meal_break),
          punchInHourTime: getTimeHoursFromDateString(data.attributes.punch_in).hours12h,
          punchInMinTime: getTimeHoursFromDateString(data.attributes.punch_in).minutes,
          punchInAmPm: getTimeHoursFromDateString(data.attributes.punch_in).amPm,
          punchOutHourTime: getTimeHoursFromDateString(data.attributes.punch_out).hours12h,
          punchOutMinTime: getTimeHoursFromDateString(data.attributes.punch_out).minutes,
          punchOutAmPm: getTimeHoursFromDateString(data.attributes.punch_out).amPm,
          statusType: data.attributes.status,
          currentStatusDate: convertDateIntoDateMonthYearFormat(data.attributes.created_at, false),
          activityId: data.attributes.id,
        });
    }
    catch (error) {
        console.log("err", error)
    }
  }

  async componentDidMount() {
    window.scrollTo(0,0);
    this.getUpdateStatusSessionData();
  }

  async componentWillUnmount() {
    await removeStorageData("updateStatusData");
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleApiResponse(apiRequestCallId, responseJson);
    }
  }

  handleApiResponse(apiRequestCallId: string, responseJson: any) {
  
    if(apiRequestCallId === this.updateActivityStatusAPIId) {
      if(responseJson.data) {
        this.setState({
          ...this.state,
          isStatusUpdated: true,
          apiError: false,
        })
      }
      if(responseJson.error || responseJson.errors) {
        this.setState({
          ...this.state,
          isStatusUpdated: false,
          apiError: true
        })
      }
    }
 
  }

  checkValidation = (timeErrorVal: string, mealErrVal: string) => {
    let isValid = false;

    if (timeErrorVal === "" && mealErrVal === "") {
      isValid = true;
    }

    if (isValid) {
      this.handleUpdateNow()
    } 
  }


  isCheckForSameTime = (
    pInHour: number, pInMinute: number, pInAMPM: string,
    pOutHour: number, pOutMinute: number, pOutAMPM: string
  ) => {
    const startDate = `${pInHour}${pInMinute}${pInAMPM}`;
    const endDate = `${pOutHour}${pOutMinute}${pOutAMPM}`;
    return startDate === endDate;
  };

  handleValdation = () => {
    const { punchInAmPm, punchInHourTime, punchInMinTime,
      punchOutAmPm, punchOutHourTime, punchOutMinTime, mealBreakVal
    } = this.state;

    const checkIsTimeSame = this.isCheckForSameTime(
      Number(punchInHourTime), Number(punchInMinTime), punchInAmPm, 
      Number(punchOutHourTime), Number(punchOutMinTime), punchOutAmPm
    )

    let sameTimeErr = "";
    let mealErr= "";

    if(mealBreakVal == ""){
      mealErr = "*Required, select meal break time."
    } 
  
    if(checkIsTimeSame) {
      sameTimeErr = "*Required, punch in and punch out can't be same"
    }

    this.setState({
      ...this.state,
      timeErr: sameTimeErr,
      mealBreakErr: mealErr
    })

    this.checkValidation(sameTimeErr, mealErr)
  }


  handleUpdateStartOrEndShyftApi = ()  => {
    this.handleValdation()
  }

  convertPunchInOutTiemToApiRequest = (punchHourTime: any, punchMinTime: any, punchAmPm: any) => {
    const dateTimePunchInOut = covertHourMinIntoDateTimeString(
      punchHourTime, punchMinTime, punchAmPm
    );

    return dateTimePunchInOut;
  }

  handleUpdateNow = () => {
    const { changeStatusToValue, activityId, mealBreakVal,
      punchInHourTime, punchInMinTime, punchInAmPm,
      punchOutHourTime, punchOutMinTime, punchOutAmPm,
    } = this.state;

    const punchedIn = this.convertPunchInOutTiemToApiRequest(punchInHourTime, punchInMinTime, punchInAmPm);

    const punchOut = this.convertPunchInOutTiemToApiRequest(punchOutHourTime, punchOutMinTime, punchOutAmPm);

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem('authToken'),
      };
  
      const body = {
        activity: {
          status: changeStatusToValue === "End Shyft" ? "completed" : "ongoing",
          meal_break: convertMealBreakStringIntoNumber(mealBreakVal),
          punch_in: punchedIn, 
          punch_out: punchOut             
        }
      };
  
      const updateActivityStatusApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      updateActivityStatusApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.postCreateActivityApiEndPoint}${activityId}`
      );
      
      updateActivityStatusApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      updateActivityStatusApiMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      updateActivityStatusApiMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postWithdrawFromShyftApiMethodType,
      );
  
      this.updateActivityStatusAPIId = updateActivityStatusApiMsg.messageId;
      runEngine.sendMessage(updateActivityStatusApiMsg.id, updateActivityStatusApiMsg);
  
  }

  handleMealBreakValue = (event: any) => {
    this.setState({ mealBreakVal: event.target.value, mealBreakErr: ""})
  }

  handleUpdateTime = () => {
    this.setState({ isUpdateTime: !this.state.isUpdateTime })
  }

  handleTimeValueChang = (event: any, name: string) => {
    const { value } = event.target;

    this.setState({
      ...this.state,
      [name]: value
    })
  }


  handleChangeStatusTo = (event: any) => {
    const { value } = event.target;
    this.setState({
      ...this.state,
      changeStatusToValue: value,
      changeStatusToValueErr: "",
    })
  }

  handleAddMealCheck = () => {
    this.setState({
      ...this.state,
      isAddMealCheck: !this.state.isAddMealCheck
    })
  }

  handleCloseUpdateSuccessModal = () => {
    this.setState({
      ...this.state,
      isStatusUpdated: false
    })
  }

}
