import {
  AppBar,
  Box,
  ClickAwayListener,
  Container,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";

function ChatHeader() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <AppBar
      style={{ width: "100%", background: "#fff", boxShadow: "none" }}
      position="static"
    >
      <Container style={{ padding: 0 }} maxWidth="xl">
        <Toolbar
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box color={"#ccc"}>user Details</Box>

          <Box>
            <Box>
              <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                style={{
                  backgroundColor: "#e1e6ec",
                  padding: "5px",
                  borderRadius: "50%",
                }}
                onClick={handleToggle}
              >
                <MoreVertIcon
                  style={{ color: "#000" }}
                  width={20}
                  height={20}
                />
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                      style={{
                        boxShadow: "0px 4px 22px rgba(10,4,18,0.04)",
                        border: "0.5px dashed #e1e6ec",
                        borderRadius: "10px",
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <Typography>View Profile</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Typography>Your Shift</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Typography>Report</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Typography>Delete Chat</Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ChatHeader;
