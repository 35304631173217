import React from 'react'
import { Box, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import StarIcon from '@material-ui/icons/Star';
import BlueButton from './BlueButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ArrowForwardIos, WorkOutline } from '@material-ui/icons';
import { blankStar } from './assets';
import CustomButton from './CustomButton.web';
import { getExperienceText } from './ShyftDetailCardWithoutTabs.web';


const useStyle = makeStyles({
    cardWrapper: {
        margin: "17px 20px 0px 20px",
        backgroundColor: '#FFFFFF',
        border: '1px solid #E1E6EC',
        borderRadius: '14px',
        padding: '10px',
    },
    cardHeaderDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    shyfterPersonalInfo: {
        display: 'flex',
        gap: '8px',
    },
    imgWrapper: {
        '& img': {
            width: "46px",
            height: "46px",
            borderRadius: "12px",
        }
    },
    shyfterDetail: {
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
        },
        '& h3': {
            margin: '0px'
        },
        '& p': {
            margin: '0px'
        },
        '& svg': {
            fontSize: '15px'
        }
    },
    ratingBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: "5px",
        fontWeight: 800,
        '& span': {
            fontSize: '11px',
            color: '#6F7C8E',
            letterSpacing: '0',
            textAlign: 'right',
            lineHeight: '16px',
        },
        '& .MuiChip-root': {
            background: '#F7B500',
            borderRadius: '12px',
            fontFamily: 'Gotham-Bold',
            fontSize: '11px',
            color: '#FFFFFF',
            letterSpacing: '0.2px',
            textAlign: 'right',
            lineHeight: '18px',
            height: '27px',
            "& .MuiChip-avatar": {
                height: "12px",
                width: "11px",
                marginRight: "-8px",
                marginLeft: "8px",
            },
            '& svg': {
                color: '#fff !important',
                height: '18px !important',
            },
            '& span': {
                color: '#FFFFFF',
            }
        },
    },
    ratingTime: {
        fontSize: "11px",
        fontWeight: 500,
        color: "#6f7c8e",
        fontFamily: 'Gotham-Bold',
        "@media(max-width:375px)": {
            fontSize: "9px",
        },
    },

    btnWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiGrid-item': {
            padding: '0px 7px',
        },
        "& button": {
            width: '100%',

        }
    },
    ignoreBtn: {
        '& button': {
            backgroundColor: '#fff !important',
            border: 'none !important',
            boxShadow: 'initial',
            fontSize: '13px',
            color: '#6F7C8E',
            textTransform: "capitalize"
        }
    },

    buttonAccept: {
        textTransform: "capitalize"
    },

    experenceText: {
        fontSize: "14px",
        color: "#7f848c",
        fontWeight: 500
    },

    userTittleText: {
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "20px"
    },

    userImageIcon: {
        width: "60px",
        height: "60px",
        marginTop: "15px"
    },

    userNameText: {
        textAlign: "center"
    },

    briefcaseIcon: {
        width: "15px",
        height: "15px",
    },

    doorManText: {
        fontWeight: 600,
        marginLeft: "5px",
        color: "#4f3472"
    },

    imgIconHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    ShyftContain: {
        textAlign: "center",
        color: "gray",
        marginTop: "10px",
        fontWeight: 500,
    },

    viewButton: {
        backgroundColor: "#3a0879",
        color: "#FFF",
        borderRadius: "20px",
        textTransform: "capitalize",
        width: "250px",
        height: "48px",
        marginTop: "10px",
        "&:hover": {
            backgroundColor: "#3a0879",
            color: "#FFF",
        }
    },

    iconWithCalender: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    modalContainer: {
        backgroundColor: "#FFF",
        width: "500px",
        height: "300px",
        borderRadius: 20,
        padding: "20px"
    },

    buttonHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    modalCloseButton: {
        position: "fixed",
        color: "#fff",
        top: "26px",
        right: "473px",
        backgroundColor: "#26202e",
        borderColor: "#fff",
        borderWidth: "thin",
        borderRadius: "50% ",
        zIndex: 9999,
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    closeButton: {
        position: "fixed",
        right: "400px",
        backgroundColor: "#26202e",
        borderColor: "#fff",
        borderWidth: "thin",
        color: "#fff",
        borderRadius: "50% ",
        zIndex: 9999,
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },

    shyfterName: {
        fontSize: "16px",
        lineHeight: "25px",
        color: "#0a0412",
        fontWeight: 500,
        fontFamily: "Gotham",
        "@media(max-width:375px)": {
            fontSize: "11px",
        },
    },

    backIcon: {
        width: "15px",
        height: "15px",
        backgroundColor: "rgba(0,0,0,0)",
        "@media(max-width:375px)": {
            width: "10px",
        height: "10px",
        },
    },

    shyfterExperence: {
        fontSize: "14px",
        lineHeight: "15px",
        color: "#6f7c8e",
        fontWeight: 500,
        "@media(max-width:375px)": {
            fontSize: "10px",
        },
    }
});

interface ShyfterRequestCardProps {
    shyfterName: string;
    shyfterExperence: string;
    time: string;
    imgUrl: string;
    rating:string;
    handleIgnoreBtn: any;
    handleAcceptBtn: any;
    navigateApplicantProfile?: (shyfter: any) => void;
    shyfter: any;
}
const ShyfterRequestCard = (props: ShyfterRequestCardProps) => {
    const classes = useStyle();

    const handleClick = ()=>{
        if(props.navigateApplicantProfile){
            props.navigateApplicantProfile(props.shyfter.request)
        }
    }

    return (
        <Box>
            <Box
                className={classes.cardWrapper}
            >
                <Box className={classes.cardHeaderDetails}>
                    <Box className={classes.shyfterPersonalInfo}>
                        <Box className={classes.imgWrapper}>
                            <img src={props.imgUrl} style={{cursor:"pointer"}} onClick={()=> handleClick()}  alt=""/>
                        </Box>
                        <Box className={classes.shyfterDetail}>
                            <Box  data-test-id={"navigateToProfile"} onClick={()=> handleClick()} style={{cursor:"pointer"}}>
                                <Typography className={classes.shyfterName} variant='h6'>{props.shyfterName}</Typography>
                                <ArrowForwardIos className={classes.backIcon} />
                            </Box>
                            <Box className={classes.experenceText}>
                                <WorkOutline />
                                <p className={classes.shyfterExperence}>Exp. {getExperienceText(props.shyfterExperence)}</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.ratingBox}>
                        <Typography className={classes.ratingTime}>{props.time}</Typography>
                        <Chip
                            avatar={<img src={blankStar} alt="star" />}
                             label={props.rating}
                        />
                    </Box>
                </Box>

                <Divider variant="middle" style={{ margin: '10px 0px' }} />

                <Box className={classes.btnWrapper}>
                    <Grid container>
                        <Grid item xs={6} sm={6}>
                            <Box className={classes.ignoreBtn}>
                                <BlueButton btnText='Ignore' handleClick={props.handleIgnoreBtn} />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <CustomButton label='Accept' handleClick={props.handleAcceptBtn} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default ShyfterRequestCard;