import React from "react";
import { Box, makeStyles, IconButton, Divider } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import { hotelIcon, restaurantIcon, verifiedIcon } from "./assets";
const useStyles = makeStyles((theme) => ({
  skillListWrapper: {
    marginBottom: "16px",
    borderRadius: "14px",
    padding: "6px 6px 6px 12px",
    border: "1px solid #e1e6ec",
    " & .header": {
      fontSize: 15,
      lineHeight: "16px",
      fontWeight: 600,
      color: "#0a0412",
    },
    " & .img": {
      paddingRight: "10px",
    },
    " & .iconRight": {
      backgroundColor: "#f1f5f8",
      padding: "8px",
      color: "#000",
    },
    " & .listWrapper": {
      padding: "5px 0px",
    },
    " & .verifyIcon": {
      paddingRight: "5px",
    },
    " & .skillItem": {
      fontSize: "14px",
      color: "#0a0412",
    },
    " & .skillItemWrapper": {
      padding: "5px 5px 3px 0px",
    },
    " & .closeIcon": {
      padding: "4px",
      backgroundColor: "#f1f5f8",
      " & .MuiSvgIcon-fontSizeSmall": {
        fontSize: "1rem",
      },
    },
  },
}));

interface ISkillListProps {
  type: string;
  title: string;
  items: string[];
  deleteItem: (item: string) => void;
  openSkillDialog: (type: string) => void;
}

const SkillList: React.FC<ISkillListProps> = ({
  type,
  title,
  items,
  deleteItem,
  openSkillDialog,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.skillListWrapper}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className="header" display="flex" alignItems="center">
          <img
            className="img"
            height={26}
            src={type === "hotel" ? hotelIcon : restaurantIcon}
            alt={type}
          />
          {`Set Your ${title} Skills`}
        </Box>
        <Box>
          <IconButton
            className="iconRight"
            onClick={() => openSkillDialog(type)}
            data-test-id="open-skill-dialog"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
      {items && items.length > 0 && (
        <Box className="listWrapper">
          <Divider />
          {items.map((skill: string) => (
            <Box
              key={skill + "skill" + type}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="skillItemWrapper"
            >
              <Box className="skillItem" display="flex" alignItems="center">
                <img
                  src={verifiedIcon}
                  alt="verify"
                  height={16}
                  className="verifyIcon"
                />
                {skill}
              </Box>
              <Box>
                <IconButton
                  onClick={() => deleteItem(skill)}
                  size="small"
                  className="closeIcon"
                  data-test-id="delete-item"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SkillList;
