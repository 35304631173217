export const userProfile = require("../assets/images/user-profile.png");
export const HomeIcon = require("../assets/images/logo.png");
export const Logo = require("../assets/images/logoName.png");
export const JobIcon = require("../assets/images/briefcase-blank.png");
export const JobIconDis = require("../assets/images/briefcase-blank1.png");
export const MessageIcon = require("../assets/images/comments.png");
export const BookmarkBlank = require("../assets/images/bookmark.png");
export const BookmarkBlankfilled = require("../assets/images/bookmarkfilled.png");
export const Notification = require("../assets/images/notification.png");
export const Arrow = require("../assets/images/arrow.png");
export const PastShyft = require("../assets/images/time-past.png");
export const ProfileImg = require("../assets/images/ProfileImg.png");
export const VerifiedIcon = require("../assets/images/verified.png");
export const PlaceIcon = require("../assets/images/placeIcon.png");
export const Edit = require("../assets/images/edit.png");
export const Help = require("../assets/images/headset.png");
export const SignoutIcon = require("../assets/images/signoutIcon.png");
export const RightArrowIcon = require("../assets/images/keyboard_arrow_down_black.png");
export const CrownIcon = require("../assets/images/crown.png");
export const settings = require("../assets/images/settings-sliders.svg");
export const document = require("../assets/images/document.png");
export const pastShyftActive = require("../assets/images/time-past-color.png");



export const LogoutIcon = require("../assets/images/signoutIcon.png");
export const dashboardSelectedIcon = require("../assets/images/dashboardSelected.png");
export const dashboardUnSelectedIcon = require("../assets/images/dashboardUnSelected.png");
export const commentsBlue = require("../assets/images/commentsBlue.png");


