import React, { Component } from 'react';
// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";

import './style.css';
// Customizable Area Start
// Customizable Area End

interface Props {
  navigation: any
  // Customizable Area Start
  dataTestId?: string;
  // Customizable Area End

}
export default class GenericFooter extends Component<Props, any>{
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Box className='generic-footer imageSection' >
        <div className='footersecsion-first'>
          <div className='footerfirst-item'>
            <div className='footerfirst-header'>
              <img className='footer-logo' src={require('./logo2.png')}></img>
              <Typography variant='h6'>
                About Snagashyft
              </Typography>
            </div>
            <div className='footerfirst-body'>
              <p>
                Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.
              </p>
              <span className='readmore' onClick={() => { window.location.href = "/About" }}>Read More</span>
            </div>
          </div>
          <div className='footerfirst-item'>
            <div className='footerfirst-header'>
              <Typography variant='h6'>
                More Links
              </Typography>
            </div>
            <div className='footerfirst-body'>
              <p className='footerfirst-links'>
                <span onClick={() => window.location.href = "/BusinessBenifits"}> &gt; Business </span>
                <span onClick={() => window.location.href = "/ShyfterBenifits"} data-test-id="Business-testing"> &gt; Shyfters </span>
                <span onClick={() => window.location.href = "/FAQ"} data-test-id="helpcentre-testing"> &gt; Faq </span>
                <span className={window.location.pathname === '/ContactUS' ? 'selected': ''} onClick={() => window.location.href = "/ContactUS"} data-test-id="ContactUs-testing"> &gt; Contact Us </span>
              </p>

            </div>
          </div>
          <div className='footerfirst-item'>
            <div className='footerfirst-header'>
              <Typography variant='h6'>
                Connect With us
              </Typography>
            </div>
            <div className='footerfirst-body'>
              <p className='footer-contact'>
                <img src={require('./assets/phone.png')}></img>
                <span>
                  <label>+1 - 010 4412 325</label>
                  <label>+1 - 010 2232 005</label>
                </span>
              </p>
              <p className='footer-contact'>
                <img src={require('./assets/mail.png')}></img>
                <span>
                  <label>info@snagashyft.com</label>
                  <label>support@snagashyft.com</label>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='footersection-second'>
          <label>Copyright © 2023 Snagashyft. All rights reserved.</label>
          <label><label onClick={() => window.location.href = "/TermsAndConditions3"} data-test-id="terms-testing">Terms & Conditions </label>| <label onClick={() => window.location.href = "/PrivacyAndPolicy"} data-test-id="Privacy-testing">Privacy Policy</label></label>
        </div>
      </Box>
    )
  }
}
  // Customizable Area End