// Customizable Area Start
import React from "react";
import { Box, Popover } from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import { radioCheckedDarkBlueIcon, radioUnCheckedIcon } from "./assets";
import SortFilterController, { Props } from "./SortFilterController";

const styles = {
  filterWrapper: {
    padding: "12px",
    width: "220px"
  },
  filterHeader: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#0a0412",
    fontWeight: "bold" as any,
    fontFamily: '"Gotham", sans-serif',
    padding: "10px 0px"
  },
  filterItemText: {
    fontSize: "12px",
    color: "#0a0412",
    fontWeight: 600,
    fontFamily: '"Gotham", sans-serif'
  },
  filterItem: {
    cursor: "pointer"
  },
  actionWrapper: {
    padding: "12px",
    borderTop: "1px solid rgba(225, 230, 236)"
  }
};

const setActiveSortFilterImage = (filter: string, selectedFilter: string) => {
  return filter === selectedFilter
    ? radioCheckedDarkBlueIcon
    : radioUnCheckedIcon;
};

export default class SortFilter extends SortFilterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isOpen } = this.props;
    return (
      <Popover
        open={Boolean(isOpen)}
        anchorEl={isOpen}
        onClose={this.handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{
          style: {
            borderRadius: "10px"
          }
        }}
      >
        <Box style={styles.filterWrapper}>
          <Box style={styles.filterHeader} textAlign="center">
            Sort By
          </Box>
          <Box
            paddingTop="5px"
            paddingBottom="5px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={styles.filterItem}
            onClick={() => this.onChangeFilter("default")}
            data-test-id="default-filter"
          >
            <Box style={styles.filterItemText}>Default: All</Box>
            <Box>
              <img
                src={setActiveSortFilterImage(
                  "default",
                  this.state.appliedFilter
                )}
                alt="checkbox"
                height={18}
              />
            </Box>
          </Box>
          <Box
            paddingTop="5px"
            paddingBottom="5px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={styles.filterItem}
            onClick={() => this.onChangeFilter("latest")}
            data-test-id="latest-filter"
          >
            <Box style={styles.filterItemText}>Date Completed: Latest</Box>
            <Box>
              <img
                src={setActiveSortFilterImage(
                  "latest",
                  this.state.appliedFilter
                )}
                alt="checkbox"
                height={18}
              />
            </Box>
          </Box>
          <Box
            paddingTop="5px"
            paddingBottom="5px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={styles.filterItem}
            onClick={() => this.onChangeFilter("earliest")}
            data-test-id="earliest-filter"
          >
            <Box style={styles.filterItemText}>Date Completed: Earliest</Box>
            <Box>
              <img
                src={setActiveSortFilterImage(
                  "earliest",
                  this.state.appliedFilter
                )}
                alt="checkbox"
                height={18}
              />
            </Box>
          </Box>
        </Box>
        <Box style={styles.actionWrapper}>
          <CustomButton handleClick={this.handleApplyFilter} label="Apply" />
        </Box>
      </Popover>
    );
  }
}

// Customizable Area End
