import React from "react";

import AppliedShyftController, { Props } from "./AppliedShyftsController.web";
import { Box, Container, Grid } from "@material-ui/core";

import {
  breadCrumSubsPath,
  checkIfUserAppliedeForShyft,
} from "../../../components/src/Utilities";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";

export default class AppliedShyftPage extends AppliedShyftController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { appliedShyftsList, selectedShyft, appliedShyftDetails, request } =
      this.state;

    return (
      <>
        <LandingPageHeader
          {...this.props}
          currentPage="Applied Shyfts"
          breadCrumsData={breadCrumSubsPath}
          isBreadcrumbs={true}
        />
        <Box marginTop={"8%"} width={"100%"}>
          <Container maxWidth="md">
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item xs={6}>
                <Box>
                  {appliedShyftsList.map((item) => {
                    return (
                      <ShyftCard
                        key={item.id}
                        {...item}
                        onCardClick={() => this.onSelectShift(item.id)}
                        isActiveShyft={item.id === selectedShyft}
                      />
                    );
                  })}
                  <div style={{ height: "20px" }} ref={this.eleRef}></div>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  style={{ position: "sticky", top: "24%" }}
                  margin={"15px 0px"}
                >
                  {appliedShyftDetails && (
                    <ShyfterDetailsCard
                      key={appliedShyftDetails.id}
                      {...appliedShyftDetails}
                      showOverviewSubtext={false}
                      isAppliedShyft={checkIfUserAppliedeForShyft(request)}
                      onWithdrawShift={() =>
                        this.withdrawFromTheShyft(appliedShyftDetails.id)
                      }
                      shyftStatus={request ? request.status : undefined}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}
