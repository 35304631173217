export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const Hotel = require("../assets/group_Hotel.png")
export const Croupier = require("../assets/group_Croupier.png")
export const Communication = require("../assets/communications.png")
export const Suitcase = require("../assets/suitcase.png")
export const Glass = require("../assets/glass.png")
export const Money= require("../assets/money.png")
export const Diamond = require("../assets/diamond.png")
export const Analysis = require("../assets/analysis.png")
export const Page = require("../assets/page.png")
export const Path= require("../assets/path.png")
export const Time= require("../assets/time.png")
export const GreenCheckMark = require('./greencheck.png');
export const iphone2 = require('./iphone2.png')
export const iphone3 = require('./iphone3.png')
export const shyfterBenifitIcon = require("./sectionicon1.png")

export const callCenterImg = require('../assets/call-center.png')
export const contactMailImg = require('../assets/contact_mail.png')
export const pinImg = require('../assets/pin.png')