export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const dashboardCodebar = require("../assets/images/dashboard-codebar.svg");
export const dashboardIcon = require("../assets/images/dashboard-icon.svg");
export const playstore = require("../assets/images/playstore.svg");
export const appstore = require("../assets/images/appstore.svg");
export const calendar = require("../assets/images/calendar.png");
export const dashboardImg = require("../assets/images/dashboard-img.svg");
export const crown = require("../assets/images/crown.svg");
export const headset = require("../assets/images/headset.png");
export const document = require("../assets/images/document.png");
export const shieldCheck = require("../assets/images/shield-check.svg");
export const employerShyftsLandingPage = require("../assets/images/employer-shyfts-landing-page.svg");
export const paymentIcon = require("../assets/images/payment-icon.svg");
export const creditCardBlue = require("../assets/images/credit-card-blue.svg");
export const checkCircleBlue = require("../assets/images/check_circle_dark_blue.png");
export const crownBlue = require("../assets/images/crown-blue.svg");
export const pointerImg = require("../assets/images/pointer-img.svg");
export const accept=require('../assets/images/image_Accept.svg')
export const arrowRightBlueIcon=require('../assets/images/arrow_circle_right_blue.png')
export const arrowRightYellowIcon=require('../assets/images/arrow_circle_right_yellow.png')
export const arrowRightPinkIcon=require('../assets/images/arrow_circle_right_pink.png')
export const radioCheckedIcon=require('../assets/images/check_circle_dark_blue.png')
export const radioUnCheckedIcon=require('../assets/images/radio_unchecked.svg')
export const timerIcon=require('../assets/images/clock3.png')

export const crownIcon = require("../assets/images/crown.png");