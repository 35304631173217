import React from "react";

import { Box, Container, Grid } from "@material-ui/core";
import SavedShyftController, { Props } from "./SavedShyftController.web";

import {
  breadCrumSubsPath,
  checkIfUserAppliedeForShyft,
} from "../../../components/src/Utilities";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import ShyfterFeedbackModal from "../../../components/src/ShyfterFeedbackModal.web";
import { SuccessIcon } from "./assets";

export default class SavedShyftPage extends SavedShyftController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      savedShyftsList,
      selectedShyft,
      savedShyftDetails,
      request,
      isShyftApplied,
      isRegisterationPending,
    } = this.state;

    return (
      <>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Saved Shyfts"
          breadCrumsData={breadCrumSubsPath}
        />
        <Box marginTop={"8%"} width={"100%"}>
          <Container maxWidth="md">
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item xs={6}>
                <Box>
                  {savedShyftsList.map((item) => {
                    return (
                      <ShyftCard
                        key={item.id}
                        {...item}
                        onCardClick={() => this.onSelectShift(item.id)}
                        isActiveShyft={item.id === selectedShyft}
                        isSavedShyft={true}
                        isShyfExpired={true}
                        onSaveShyft={() => this.unSaveShyft(item.id)}
                      />
                    );
                  })}
                  <div ref={this.elementRef} style={{ height: "20px" }}></div>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  margin={"15px 0px"}
                  style={{ position: "sticky", top: "24%" }}
                >
                  {savedShyftDetails && (
                    <ShyfterDetailsCard
                      {...savedShyftDetails}
                      key={savedShyftDetails.id}
                      shyftStatus={request ? request.status : undefined}
                      isAppliedShyft={checkIfUserAppliedeForShyft(request)}
                      onApplyShift={() =>
                        this.applyForTheShyft(savedShyftDetails.id)
                      }
                      onWithdrawShift={() =>
                        this.withdrawFromTheShyft(savedShyftDetails.id)
                      }
                      showOverviewSubtext={false}
                      // isShyfExpired={true}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            <ShyfterFeedbackModal
              isOpen={isShyftApplied}
              title="Applied Successfully"
              description="This shift has been applied successfully. Explore more shyfts or you can view status of this shyft"
              imageSrc={SuccessIcon}
              primaryButtonText="Explore More"
              onClose={this.closeApplyShiftModal}
              secondaryButtonText="View Status"
              primaryButtonOnClick={this.closeApplyShiftModal}
              secondaryButtonOnClick={this.closeApplyShiftModal}
            />

            <ShyfterFeedbackModal
              isOpen={isRegisterationPending}
              onClose={this.closeRegistrationModal}
              imageSrc={SuccessIcon}
              title="Registration Pending"
              description="Your registeration is pending. Please complete your registration to apply for a shyft in a hotel and restaurant."
              primaryButtonText="Complete Now"
              primaryButtonOnClick={this.closeRegistrationModal}
            />
          </Container>
        </Box>
      </>
    );
  }
}
