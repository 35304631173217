import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  isOpen: boolean;
  secondaryButtonText?: string;
  onClose: () => void;
  secondaryButtonOnClick?: () => void;
  imageSrc: string;
  modalTitle: string;
  modalDescription: string;
  classes: {
    [property: string]: string;
  };
}

interface S {}

interface SS {
  id: any;
}

export default class FeedbackModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {};
    this.subScribedMessages = [];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}
