import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import { Grid, Container, Box, Typography } from "@material-ui/core";
import ProfileCard from "../../../components/src/ProfileCard";
import ShyfterLandingPageController, {
  Props,
} from "./ShyfterLandingPageController.web";
import ProgressBar from "../../../components/src/ProgressBar";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import GetLocationModal from "../../../components/src/GetLocationModal.web";
import SearchShyftsModal from "../../../components/src/ShyftsSearchModal";
import SideNav from "../../../components/src/SideNav";
import Carousel from "react-material-ui-carousel";
import SubmitSuccessPopUp from "../../../components/src/SubmitSuccessPopUp";
import FeedbackModal from "./FeedbackModal.web";
import { successTickIcon, placeIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0a0412",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#390879",
    },
  },
});
const styles = {
  viewAll: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#390879",
    textDecoration: "underline",
    cursor: "pointer",
  },
  shyftText: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  sideText: {
    color: "#0a0412",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "19px",
  },
  locationBox: { marginTop: "15px", gap: "5px" },
  location: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#0a0412",
    cursor: "pointer",
    textDecoration: "underline",
  },
} as const;

export default class ShyfterLandingPage extends ShyfterLandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      shyftDetails,
      shyftsList,
      selectedShyft,
      recommendedShyftsList,
      shyftRequest,
      profileComplition,
      userData,
      isRegisterationPendingModalOpen,
      isAppliedModalOpen,
      recentSearches,
      isShowLocationModal,
      isShowSearchModal,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader
          {...this.props}
          gotToProfile={() => this.navigateToShyfterProfile()}
          userName={userData ? userData.full_name : ""}
          userAddress={userData ? userData.address : ""}
        />
        <Container>
          <Box marginTop={"8%"}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={3}>
                <ProfileCard
                  userName={"Test User"}
                  place={"Berin"}
                  city={"New Yark"}
                  contactMail={"example@gmail.com"}
                  contactNumber={"5241578523"}
                  goToProfile={() => this.navigateToShyfterProfile()}
                  userData={this.state.userData}
                />
                <Typography>
                  <Box style={styles.sideText} padding={"10px 0px"}>
                    More Options
                  </Box>
                </Typography>
                <SideNav
                  pastShyft={() => this.navigateToPastShyft()}
                  myShyft={() => this.navigateToMyShyft()}
                  otherPageNavigation={(path: string) =>
                    this.handleMoreOptionsNavigation(path)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box padding={"0 10px"}>
                  <ProgressBar progress={profileComplition} />
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={this.showLocationModal}
                    style={styles.locationBox}
                  >
                    <img src={placeIcon} alt="" height={"18px"} />
                    <Typography style={styles.location}>New York</Typography>
                  </Box>
                  <Box>
                    <SearchWithFilterandLocation
                      isDisabled={true}
                      handleSerchModal={() => this.showSearchModal()}
                      handleSearchFilter={this.navigateToExploreShift}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                    marginTop={"30px"}
                  >
                    <Typography style={styles.shyftText}>
                      Explore Shyfts
                    </Typography>
                    <Typography
                      style={styles.viewAll}
                      onClick={() => this.handleExploreShyft()}
                      data-test-id="exploreShyfts"
                    >
                      View All
                    </Typography>
                  </Box>
                  <Box>
                    <Carousel
                      animation="slide"
                      indicators={false}
                      navButtonsAlwaysVisible
                      swipe={true}
                      autoPlay={false}
                      navButtonsProps={{
                        style: {
                          color: "black",
                          padding: "14px",
                          backgroundColor: "#fff",
                          border: "1px solid #e1e6ec",
                          height: "18px",
                          width: "18px",
                          margin: "0px",
                        },
                      }}
                      next={(next: number) => this.setSelectedShyft(next)}
                      prev={(prev: number) => this.setSelectedShyft(prev)}
                    >
                      {shyftsList.map((shift, index) => (
                        <ShyftCard
                          key={shift.id}
                          {...shift}
                          isActiveShyft={index === selectedShyft}
                          onSaveShyft={() =>
                            this.handleSaveShyft(shift.id, shift.saved)
                          }
                          showBookMark={true}
                        />
                      ))}
                    </Carousel>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                  >
                    <Typography style={styles.shyftText} variant="subtitle1">
                      Recommended Shyfts
                    </Typography>
                    <Typography
                      style={styles.viewAll}
                      onClick={() => this.handleViewAllRecomendedShyfts()}
                      data-test-id="recomendedShyfts"
                    >
                      View All
                    </Typography>
                  </Box>
                  <Box>
                    {recommendedShyftsList.map((shift) => (
                      <ShyftCard
                        {...shift}
                        key={shift.id}
                        showBookMark={true}
                        onSaveShyft={() =>
                          this.handleSaveShyft(shift.id, shift.saved)
                        }
                      />
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box
                  padding={"0 10px"}
                  style={{ position: "sticky", top: "16%" }}
                >
                  {shyftDetails && (
                    <ShyfterDetailsCard
                      onApplyShift={() =>
                        this.applyForTheShyft(shyftDetails.id)
                      }
                      onWithdrawShift={() =>
                        this.withdrawFromTheShyft(shyftDetails.id)
                      }
                      key={shyftDetails.id}
                      {...shyftDetails}
                      onViewProfile={() =>
                        this.navigateToBusinessProfile(shyftDetails.businessId)
                      }
                      isAppliedShyft={this.checkIfAppliedeShyft(shyftRequest)}
                      shyftStatus={
                        shyftRequest ? shyftRequest.status : undefined
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <GetLocationModal
            open={isShowLocationModal}
            closeModal={() => this.closeLocationModal()}
          />
          <SearchShyftsModal
            open={isShowSearchModal}
            recentSearches={recentSearches}
            removeQueryFromSearch={this.removeQueryFromRecentSearches}
            closeModal={() => this.closeSearchModal()}
            onSearch={this.onSearchShifts}
          />
          <SubmitSuccessPopUp
            open={!!this.state.signUpMessage}
            isShyfter={true}
            isDoubleAction={true}
            completeRegistration={() => this.navigateToShyfterProfile()}
            exploreShyfts={() => this.handleExploreShyft()}
            backToLogin={() => this.setState({ signUpMessage: "" })}
            isShyfterRefg={true}
          />
          <FeedbackModal
            isOpen={isAppliedModalOpen}
            modalTitle="Applied Successfully"
            imageSrc={successTickIcon}
            primaryButtonText="Explore More"
            primaryButtonOnClick={this.closeAppliedShiftModal}
            modalDescription="This shift has been applied successfully. Explore more shyfts or you can view status of this shyft"
            secondaryButtonText="View Status"
            secondaryButtonOnClick={this.closeAppliedShiftModal}
            onClose={this.closeAppliedShiftModal}
          />
          <FeedbackModal
            isOpen={isRegisterationPendingModalOpen}
            onClose={this.closeRegistrationModal}
            modalTitle="Registration Pending"
            modalDescription="Your registeration is pending. Please complete your registration to apply for a shyft in a hotel and restaurant."
            imageSrc={successTickIcon}
            secondaryButtonText="Complete Now"
            secondaryButtonOnClick={this.closeRegistrationModal}
          />
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }
}
