import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Radio,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { radioCheckedDarkBlueIcon, radioUnCheckedIcon } from "./assets";
import CustomButton from "./CustomButton.web";

const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .skillSetTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "16px",
      color: "#0a0412",
    },
    " & .skillItem": {
      border: "1px solid #e1e6ec",
      borderRadius: "28px",
      padding: "15px",
      margin: "10px 0px",
      cursor: "pointer",
    },
    " & .selectedSkillItem": {
      border: "1px solid #390879",
      borderRadius: "28px",
      padding: "15px",
      margin: "10px 0px",
      cursor: "pointer",
    },
    " & .MuiRadio-colorPrimary": {
      padding: 0,
    },
    " & .skillText": {
      fontSize: 14,
      fontWeight: 500,
      color: "#0a0412",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
  },
}));

interface ISkillItem {
  item: string;
  isSelected: boolean;
}

interface ISkillSetDialogProps {
  open: boolean;
  type: string;
  items: string[];
  selectedItems: string[];
  onCloseDialog: (type: string) => void;
  saveSkillData: (type: string, list: string[]) => void;
}

const SkillSetDialog: React.FC<ISkillSetDialogProps> = ({
  open,
  selectedItems,
  onCloseDialog,
  saveSkillData,
  type,
  items,
}) => {
  const classes = useStyles();
  const [totalItems, setTotalItems] = useState<ISkillItem[]>([]);

  useEffect(() => {
    if (!totalItems.length) {
      const itemList = items.map((item) => ({
        item,
        isSelected: selectedItems.includes(item),
      }));
      setTotalItems(itemList);
    }
  }, []);

  const toggleItemSelection = (item: string) => {
    const itemList = [...totalItems];
    const index = itemList.findIndex((i) => i.item === item);
    if (index > -1) {
      let ob = itemList[index];
      ob = { ...ob, isSelected: !ob.isSelected };
      itemList.splice(index, 1, ob);
      setTotalItems(itemList);
    }
  };

  const saveSkillDataSet = () => {
    const checkedList = totalItems
      .filter((a) => a.isSelected)
      .map((item) => item.item);
    saveSkillData(type, checkedList);
  };

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" onClick={() => onCloseDialog(type)}>
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className="skillSetTitle">{`${type} Skill Set`}</Box>
      <Box>
        {totalItems.map((item) => (
          <Box
            className={item.isSelected ? "selectedSkillItem" : "skillItem"}
            onClick={() => toggleItemSelection(item.item)}
            key={item.item + "skill-item"}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            data-test-id="skill-item"
          >
            <Typography className="skillText">{item.item}</Typography>
            <Radio
              checked={item.isSelected}
              value=""
              color="primary"
              checkedIcon={
                <img src={radioCheckedDarkBlueIcon} height={20} alt="checked" />
              }
              icon={
                <img src={radioUnCheckedIcon} height={20} alt="unchecked" />
              }
            />
          </Box>
        ))}
      </Box>
      <Box className="actionWrapper">
        <CustomButton label="Save" handleClick={saveSkillDataSet} />
      </Box>
    </Dialog>
  );
};

export default SkillSetDialog;
