export const planIcon = require("../assets/addEvent/image-plane.png");
export const forwardIcon = require("../assets/addEvent/icons8-forward-52.png");
export const schooldropIcon = require("../assets/addEvent/schooldrop.png");
export const schoolpickIcon = require("../assets/addEvent/schoolpick.png");
export const GetTogetherIcon = require("../assets/addEvent/GetTogether.png");
export const MeetingIcon = require("../assets/addEvent/Meeting.png");
export const OutforCoffeeIcon = require("../assets/addEvent/OutforCoffee.png");
export const GymIcon = require("../assets/addEvent/Gym.png");
export const FlightIcon = require("../assets/addEvent/flight.png");
export const DoctorIcon = require("../assets/addEvent/doctor.png");
export const VetIcon = require("../assets/addEvent/vet.png");
export const RentIcon = require("../assets/addEvent/money.png");
export const FeesIcon = require("../assets/addEvent/fees2x.png");
export const ActivityIcon = require("../assets/addEvent/image-pulse2x.png");
export const CustomIcon = require("../assets/addEvent/image-custom2x.png");
export const cakeIcon = require("../assets/addEvent/image-cake.png");
export const AddIcon = require("../assets/addEvent/icons8-plus-48.png");
export const LocationIcon = require("../assets/addEvent/image-location.png");
export const DownArrowIcon = require("../assets/addEvent/image-right.png");
export const CricleIcon = require("../assets/addEvent/cicrleB.png");
export const CricleTickIcon = require("../assets/addEvent/tickCircle.png");
export const SquareTickIcon = require("../assets/addEvent/imageTick.png");
export const UpwardIcon = require("../assets/addEvent/upArroww.png");
export const downAIcon = require("../assets/addEvent/downArrow.png");
export const alarm = require("../assets/addEvent/alarm.png");
export const clear = require("../assets/clear.png");
export const locationTarget = require("../assets/target.png");

//
export const birthday = require("../assets/icon/birthday.png");
export const school_drop = require("../assets/icon/school_drop.png");
export const school_pick = require("../assets/icon/school_pick.png");
export const getTogether = require("../assets/icon/get_togther.png");
export const meeting = require("../assets/icon/meeting.png");
export const outForCoffee = require("../assets/icon/out_for_coffee.png");
export const gym = require("../assets/icon/gym.png");
export const flight = require("../assets/icon/flight.png");
export const vet = require("../assets/icon/vet.png");
export const rent = require("../assets/icon/rent.png");
export const fees = require("../assets/icon/fees.png");
export const activity = require("../assets/icon/activity.png");
export const custom = require("../assets/icon/custom.png");
export const doctor = require("../assets/icon/doctor.png");
export const selected = require("../assets/tick.png");

export const upcominglogo = require("../assets/menu.png");
export const addEvent = require("../assets/image-plus.png");
export const close = require("../assets/close.png");
export const edit = require("../assets/edit.png");
export const alllogo = require("../assets/all-logo.png");
export const ProfileImg = require("../assets/icon/ProfileImg.png");
export const RightArrowIcon = require("../assets/rightArrow.png");
export const timmerBlank = require("../assets/icon/timmerBlank.png");
export const FailedIcon = require("../assets/image_Failed.svg");
export const SuccessIcon = require("../assets/image_Accept.svg");

export const PhoneIcon = require("../assets/icon/phone.png");

export const fadeDocsIcon = require("../../CustomisableUserProfiles/assets/document-signed.png");
export const docsIcon = require("../../CustomisableUserProfiles/assets/document-signed(1).png");
export const fileCircleIconFade = require("../../CustomisableUserProfiles/assets/file-circle-info(1).png");
export const fileCircle = require("../../CustomisableUserProfiles/assets/file-circle-info.png");
export const startCommentfade = require("../../CustomisableUserProfiles/assets/star-comment-alt(1).png");
export const startComment = require("../../CustomisableUserProfiles/assets/star-comment-alt.png");
export const comment = require("../assets/comments.png");
export const user = require("../assets/userBlack.png");
