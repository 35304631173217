import React, { Component } from "react";
import { Box, withStyles } from "@material-ui/core";
import BlueButton from "./BlueButton";

export const useStyles: any = () => ({
  mainWrapperSubscriptionFlow: {
    width: "100%",
    height: "400px",
    border: "1px solid #ddd",
    borderRadius: "14px",
    backgroundColor: "#fff",
    color: "",
    display: "flex",
    alignItems: "center",
    padding: "20px 0px 70px 0px",
    justifyContent: "center",
  },
  contentWrapSubscriptionFlow: {
    width: "75%",
    margin: "auto",
    textAlign: "center",
  },
  heading: {
    color: "#0a0412",
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "center",
    margin: 0,
    fontWeight: 400,
    marginTop: "20px",
  },
  text: {
    color: "#6f7c8e",
    fontSize: "12px",
    lineHeight: "17px",
    textAlign: "center",
    margin: "10px auto 20px",
    width: "90%",
  },
  textCenterSubscriptionFlow: {
    textAlign: "center",
  },
});

interface MyProps {
  handleClick: any;
  imgUrl: string;
  heading: string;
  paragraph: string;
  btnText: string;
  btnIcon?: any;
}

type MyState = {
  // faq: any,
};

export class EmployerSubscriptionFlow extends Component<MyProps, MyState> {
  render() {
    const { classes }: any = this.props;

    const handleClick = () => {
      this.props.handleClick();
    };

    return (
      <div className={classes.mainWrapperSubscriptionFlow}>
        <Box>
          <Box className={classes.textCenterSubscriptionFlow}>
            <img src={this.props.imgUrl} alt="" width={60} />
          </Box>
          <Box className={classes.contentWrapSubscriptionFlow}>
            <h2 className={classes.heading}>{this.props.heading}</h2>
            <p className={classes.text}>{this.props.paragraph}</p>
            <Box>
              <BlueButton
                data-test-id="subscriptionBtn"
                btnText={this.props.btnText}
                btnIcon={this.props.btnIcon}
                handleClick={handleClick}
              />
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

export default withStyles(useStyles)(EmployerSubscriptionFlow);
