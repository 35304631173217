import React, { Component } from 'react'
import { Box, Button, withStyles } from '@material-ui/core'
import { AnyPtrRecord } from 'dns';


export const useStyles: any = (theme: any) => ({
    mainWrapper: {
        '& button': {
            backgroundColor: '#390879',
            borderRadius: '24px',
            fontWeight: 700,
            fontSize: '14px',
            color: '#FFFFFF',
            border: '0.4px solid #979797',
            textTransform: 'capitalize',
            padding: '10px 50px',
            '&:hover':{
                backgroundColor: '#390879',
                border: '0.4px solid #979797',
            }
        }
    },
    iconWrap: {
        marginRight: '5px',
        display: 'flex'
    },
    btnDisable: {
        backgroundColor: '#39087980 !important',
        color: '#fff !important',
    }
})

interface MyProps {
    btnText: string;
    btnIcon?: any;
    disabled?: boolean;
    handleClick: any,
}

type MyState = {
    // faq: any,
};
class BlueButton extends Component<MyProps, MyState> {

    static propTypes: {
        // classes: any;
        // formType: any, changeEdit: any, params: any
    };

    render() {
        const { classes }:any = this.props;
        return (
            <div className={classes.mainWrapper}>
                <Button data-test-id="blueBtn" disabled={this.props.disabled || false} variant="contained" className={`${this.props.disabled ? classes.btnDisable : ''}`} onClick={this.props.handleClick}>
                     {this.props.btnIcon && <Box className={classes.iconWrap}> {this.props.btnIcon}</Box>}
                      {this.props.btnText}</Button>
            </div>
        )
    }
}

export default withStyles(useStyles)(BlueButton)

