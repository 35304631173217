import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import Footer from "../../../components/src/Footer.web";
import PastShyftController, { Props } from "./PastShyftController.web";
import PastShyftActivity from "../../../components/src/PastShyftActivity.web";
import CommonLandingPage from "../../../components/src/CommonLandingPage.web";
import Spinner from "../../../components/src/Spinner";
import { FailedIcon, comment, user } from "./assets";

export default class PastShyftPage extends PastShyftController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      pastShyftsList,
      selectedPastShyft,
      activityRecords,
      summary,
      ratingSucess,
      request,
      isLoading,
    } = this.state;

    const currentShift = pastShyftsList.find(
      (shift) => shift.id === selectedPastShyft
    );
    let ratingsData;

    if (currentShift) {
      ratingsData = {
        shiftId: currentShift.id,
        title: currentShift.shyft,
        subtitle: currentShift.shyftPlace,
      };
    }

    const options = pastShyftsList.map((shift) => [
      {
        menuIcon: user,
        menuText: "View Profile",
        onClick: () => this.onViewBusinessProfile(shift.businessId),
      },
      {
        menuIcon: comment,
        menuText: "Message",
        onClick: () => {},
      },
    ]);

    return (
      <>
        <LandingPageHeader {...this.props} />
        <Box marginTop={"5%"} width={"100%"}>
          <Container maxWidth="md">
            {isLoading && <Spinner />}

            {pastShyftsList.length === 0 && !isLoading && (
              <Box marginTop="25px">
                <CommonLandingPage
                  heading="No Shyfters Found!"
                  paragraph={
                    "No Past Shyfts found, get jobs and complete the shyfts first"
                  }
                  imgUrl={FailedIcon}
                  btnText="Explore Jobs"
                  handleClick={this.viewExploreShyfts}
                  data-test-id="no-shyft-data-card"
                />
              </Box>
            )}

            {pastShyftsList.length !== 0 && (
              <>
                <SearchWithFilterandLocation
                  isSortIcon={true}
                  handleSerchModal={this.onSearchPastShyfts}
                />
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={6}>
                      <Box data-testid="shyfts-srcoll-area">
                        {pastShyftsList.map((shift, index) => {
                          return (
                            <ShyftCard
                              {...shift}
                              key={shift.id}
                              onCardClick={() =>
                                this.onSelectPastShift(shift.id)
                              }
                              isMenu
                              isActiveShyft={shift.id === selectedPastShyft}
                              isPasstShyft={true}
                              options={options[index]}
                            />
                          );
                        })}
                        <div ref={this.elementRef}></div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box margin={"15px 0px"}>
                        <PastShyftActivity
                          shyftEndDate={currentShift?.shyftEndDate}
                          ratingsData={ratingsData}
                          summaryData={summary}
                          activityData={activityRecords}
                          isPastActivity={true}
                          isRatingSuccess={ratingSucess}
                          onSubmitRating={this.onSubmitRatings}
                          request={request}
                          isShyftEndedSuccess={
                            request?.removal_reason ? false : true
                          }
                        />
                      </Box>
                    </Grid>
                  </>
                </Grid>
              </>
            )}
          </Container>
        </Box>
        <Footer />
      </>
    );
  }
}
