// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { convertTimeStringIntoDateObject } from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  activeStats: string;
  createdShyftList: any[];
  ongoingShyftList: any[];
  completedShyftList: any[];
  activeShyftData: any;
  activityRecordList: any[];
  shyftSummaryData: any;
  completedActivityRecordList: any[];
  completedShyftSummaryData: any;
  createdShyftFilter: string;
  ongoingShyftFilter: string;
  completedShyftFilter: string;
  activityEndRequest: any;
  isOpenSortFilter: any;
  isOpenActivityFilterDrawer: boolean;
  isRemoveModalOpen: boolean;
  isEndShyft: boolean;
  dashboardMenuAnchor: any;
  activeShyftId: string;
  isOpenEditShyftDialog: string;
  isOpenShyftEditSuccessDialog: boolean;
  successDialogDescription: string;
  successDialogTitle: string;
  removeReason: string;
  removeFieldErr: string;
  ongoingShyftId: any,
  ongoingWorkerId: any
  isOpenViewRequestDrawer: boolean;
  viewRequestData: any[];
  isOpenViewRequestSuccessModal: boolean;
  acceptedShyfterName: string;
  isEndOrRemoveSuccessModal: boolean;
  endOrRemoveSuccessModalTitle: string;
  endOrRemoveSuccessModalDesc: string;
  ongoingRecentActivityData: any;
  completeRequestActivityData: any;
  ongoingCurrentActivityData: any;
  isopenReviewAndRatingModal: boolean;
  isopenRatingSucessModal: boolean;
  ratingsModalData: any;
  isDashboardStatsMounted: boolean;
  activityRecordSingleItemData: any;
  openUpdateStatusModal: boolean;
  createdShyftPageNo: number;
  isCreatedShyftsLastPage: boolean;
  ongoingShyftPageNo: number;
  isOngoingShyftsLastPage: boolean;
  completedShyftPageNo: number;
  isCompletedShyftsLastPage: boolean;
  createdShyftsCount: number;
  ongoingShyftsCount: number;
  completedShyftsCount: number;
  activityRecordBoxVisible: boolean;
  completedActivityRecordBoxVisible: boolean;
  completedShyftId: any;
}

interface SS {
  id: any;
}

export default class DashboardStatsController extends BlockComponent<
  Props,
  S,
  SS
> {
  createdShyftListAPIId: string = "";
  ongoingShyftListAPIId: string = "";
  activeShyftDetailsAPIId: string = "";
  completedShyftListAPIId: string = "";
  activeShyftActivityRecordsAPIId: string = "";
  activeCompletedShyftActivityRecordsAPIId: string = "";
  deleteShyftAPIId: string = "";
  editShyftAPIId: string = "";
  ongoingRemovePersonAPIId: string = "";
  ongoingEndShyftAPIId: string = "";
  getViewRequestApiCallId: string = "";
  ignoreRequestApiCallId: string = "";
  acceptRequestApiCallId: string = "";
  isHotelUser: boolean = false;
  shyftOngoingRatingsAPIId: string = "";
  shyftsCountAPIId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleDashboardShyftListScroll = this.handleDashboardShyftListScroll.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeStats: "create",
      createdShyftList: [],
      ongoingShyftList: [],
      completedShyftList: [],
      activeShyftData: {},
      activityRecordList: [],
      shyftSummaryData: {},
      completedActivityRecordList: [],
      completedShyftSummaryData: {},
      createdShyftFilter: "default",
      ongoingShyftFilter: "default",
      completedShyftFilter: "default",
      activityEndRequest: {},
      isOpenSortFilter: null,
      isOpenActivityFilterDrawer: false,
      isRemoveModalOpen: false,
      isEndShyft: false,
      dashboardMenuAnchor: null,
      activeShyftId: "",
      isOpenEditShyftDialog: "",
      isOpenShyftEditSuccessDialog: false,
      successDialogDescription: "",
      successDialogTitle: "",
      removeReason: "",
      removeFieldErr: "",
      ongoingShyftId: 0,
      ongoingWorkerId: 0,
      isOpenViewRequestDrawer: false,
      acceptedShyfterName: "",
      isOpenViewRequestSuccessModal: false,
      viewRequestData: [],
      isEndOrRemoveSuccessModal: false,
      endOrRemoveSuccessModalTitle: "",
      endOrRemoveSuccessModalDesc: "",
      ongoingRecentActivityData: {},
      completeRequestActivityData: {},
      ongoingCurrentActivityData: {},
      isopenReviewAndRatingModal: false,
      isopenRatingSucessModal: false,
      ratingsModalData: {},
      isDashboardStatsMounted: false,
      activityRecordSingleItemData: {},
      openUpdateStatusModal: false,
      isCreatedShyftsLastPage: false,
      createdShyftPageNo: 1,
      isCompletedShyftsLastPage: false,
      completedShyftPageNo: 1,
      isOngoingShyftsLastPage: false,
      ongoingShyftPageNo: 1,
      createdShyftsCount: 0,
      ongoingShyftsCount: 0,
      completedShyftsCount: 0,
      activityRecordBoxVisible: false,
      completedActivityRecordBoxVisible: false,
      completedShyftId: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.handleDashboardStatsAPIResponse(responseJson, apiRequestCallId);
        this.handleActivitytReceiveApiResponse(responseJson, apiRequestCallId)
        if (this.shyftOngoingRatingsAPIId === apiRequestCallId) {
          if(responseJson.data) {
            this.setState({
              ...this.state,
              isopenRatingSucessModal: true,
            });
            this.handleCloseReviewAndRatingModal()
          }
        }
      }
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCreatedShyftList(1);
    this.getShyftsCount();
    this.getOngoingShyftList(1);
    this.getCompletedShyftList(1);
    const userDataObject = localStorage.getItem("userData");
    if (userDataObject && JSON.parse(userDataObject)?.hotel?.length > 0) {
      this.isHotelUser = true;
    }
    window.addEventListener('scroll', this.handleDashboardShyftListScroll);
  }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleDashboardShyftListScroll);
  }

  handleDashboardShyftListScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollHeight - scrollTop === clientHeight) {
      this.onScrollApiCalls();
    }
  };

  onScrollApiCalls = () => {
    if (this.state.activeStats === "create") {
      this.setState((prevState) => ({
        createdShyftPageNo: prevState.createdShyftPageNo + 1,
      }), () => {
        if (!this.state.isCreatedShyftsLastPage) {
          this.getCreatedShyftList(this.state.createdShyftPageNo, this.state.createdShyftFilter);
        }
      });
    } else if (this.state.activeStats === "ongoing") {
      this.setState((prevState) => ({
        ongoingShyftPageNo: prevState.ongoingShyftPageNo + 1,
      }), () => {
        if (!this.state.isOngoingShyftsLastPage) {
          this.getOngoingShyftList(this.state.ongoingShyftPageNo, this.state.ongoingShyftFilter);
        }
      });
    } else {
      this.setState((prevState) => ({
        completedShyftPageNo: prevState.completedShyftPageNo + 1,
      }), () => {
        if (!this.state.isCompletedShyftsLastPage) {
          this.getCompletedShyftList(this.state.completedShyftPageNo, this.state.completedShyftFilter);
        }
      });
    }
  }

  handleStatsChange = (type: string) => {
    this.setState({ ...this.state, activeStats: type, 
      isopenRatingSucessModal: false, isopenReviewAndRatingModal: false,
      isRemoveModalOpen: false, isEndOrRemoveSuccessModal: false, 
      openUpdateStatusModal: false 
    });
  };

  handleMenuItemClick = (type: string) => {
    this.setState({ dashboardMenuAnchor: null });
    if (type === "Delete Shyft" && this.state.activeShyftId) {
      this.deleteShyftFromDashboard(this.state.activeShyftId);
    }
    if ((type === "Edit Shyft" || type === "Republish Shyft") && this.state.activeShyftId) {
      this.getActiveShyftDetails(this.state.activeShyftId);
      this.setState({ isOpenEditShyftDialog: type === "Edit Shyft" ? "edit" : "republish" });
    }
  };

  openAddShyftDialog = () => {
    this.setState({
      ...this.state,
      isOpenEditShyftDialog: "create",
      isOpenShyftEditSuccessDialog: false,
    });
  };

  toggleEditShyftSuccessDialog = (isOpen: boolean) => {
    this.setState({
      ...this.state,
      isOpenEditShyftDialog: "",
      isOpenShyftEditSuccessDialog: !this.state.isOpenShyftEditSuccessDialog,
      successDialogTitle: !isOpen ? "" : this.state.successDialogTitle,
      successDialogDescription: !isOpen ? "" : this.state.successDialogDescription,
    });
  };

  closeEditShyftDialog = () => {
    this.setState({ ...this.state, isOpenEditShyftDialog: "" });
  };

  openShyftMenu = (e: any, id: string) => {
    this.setState({ ...this.state, dashboardMenuAnchor: e?.currentTarget, activeShyftId: id });
  };

  closeShyftMenu = () => {
    this.setState({ ...this.state, dashboardMenuAnchor: null, activeShyftId: "" });
  };

  getNoShyftsTitle = () => {
    let titleText = configJSON.emptyCreatedShyftTitle;
    if (this.state.activeStats === "complete") {
      titleText = configJSON.emptyCompletedShyftTitle;
    } else if (this.state.activeStats === "ongoing") {
      titleText = configJSON.emptyOngoingShyftTitle;
    }
    return titleText;
  };

  getNoShyftsDescription = () => {
    let descriptionText = configJSON.emptyCreatedShyftDescription;
    if (this.state.activeStats === "complete") {
      descriptionText = configJSON.emptyCompletedShyftDescription;
    } else if (this.state.activeStats === "ongoing") {
      descriptionText = configJSON.emptyOngoingShyftDescription;
    }
    return descriptionText;
  };

  setAPIUrl = (page: number, filter?: string) => {
    let url = `?per_page=10&page=${page}`;
    if (filter === "latest") {
      url = `?sort_column=updated_at&sort_order=desc&per_page=10&page=${page}`;
    } else if (filter === "earliest") {
      url = `?sort_column=updated_at&sort_order=asc&per_page=10&page=${page}`;
    }
    return url;
  }

  getCreatedShyftList(page: number, filter?: string) {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const createdShyftsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createdShyftListAPIId = createdShyftsAPIMsg.messageId;

    const createdShyftUrl = configJSON.createdShyftsAPIURL+ this.setAPIUrl(page, filter);

    createdShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      createdShyftUrl
    );

    createdShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createdShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(createdShyftsAPIMsg.id, createdShyftsAPIMsg);
  }

  getShyftsCount() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const shyftsCountMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shyftsCountAPIId = shyftsCountMsg.messageId;

    shyftsCountMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shyftsCountApiEndPoint
    );

    shyftsCountMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    shyftsCountMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(shyftsCountMsg.id, shyftsCountMsg);
  }

  getOngoingShyftList(page: number, filter?: string) {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const ongoingShyftsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ongoingShyftListAPIId = ongoingShyftsAPIMsg.messageId;

    ongoingShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ongoingShyftsAPIURL + this.setAPIUrl(page, filter)
    );

    ongoingShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    ongoingShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(ongoingShyftsAPIMsg.id, ongoingShyftsAPIMsg);
  }

  getCompletedShyftList(page: number, filter?: string) {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const completedShyftsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.completedShyftListAPIId = completedShyftsAPIMsg.messageId;

    completedShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.completedShyftsAPIURL + this.setAPIUrl(page, filter)
    );

    completedShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    completedShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(completedShyftsAPIMsg.id, completedShyftsAPIMsg);
  }

  getCompletedShyftActivityRecordList = (id: number, shyfter_id: number, activityItem: any) => {
    this.emptyActivityData(activityItem);
    this.setState({ 
      completedActivityRecordBoxVisible: false,
      completedShyftId: id,
      completedActivityRecordList: [],
      completedShyftSummaryData: {},
      ongoingCurrentActivityData: activityItem,
      activeShyftId: id.toString(),
    })

    const activeCompletedShyftActivityRecordsDetailsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    this.activeCompletedShyftActivityRecordsAPIId = activeCompletedShyftActivityRecordsDetailsMsg.messageId;

    activeCompletedShyftActivityRecordsDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    activeCompletedShyftActivityRecordsDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    activeCompletedShyftActivityRecordsDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.shyftActivityRecordAPIURL}&shift_id=${id}&user_id=${shyfter_id}`
    );

    runEngine.sendMessage(activeCompletedShyftActivityRecordsDetailsMsg.id, activeCompletedShyftActivityRecordsDetailsMsg);
  }

  getOngoingShyftActivityRecordList = (id: number, shyfter_id: number, activityItem: any) => {
    this.emptyActivityData(activityItem);
    this.setState({ 
      ongoingShyftId: id,
      activityRecordList: [],
      ongoingWorkerId: shyfter_id,
      openUpdateStatusModal: false, 
      activityRecordBoxVisible: false,
      ongoingCurrentActivityData: activityItem,
    })
    
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const activeShyftActivityRecordsDetailsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activeShyftActivityRecordsAPIId = activeShyftActivityRecordsDetailsMsg.messageId;

    activeShyftActivityRecordsDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.shyftActivityRecordAPIURL}&shift_id=${id}&user_id=${shyfter_id}`
    );

    activeShyftActivityRecordsDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    activeShyftActivityRecordsDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(activeShyftActivityRecordsDetailsMsg.id, activeShyftActivityRecordsDetailsMsg);
  }

  getActiveShyftDetails = (id: string) => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const activeShyftsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activeShyftDetailsAPIId = activeShyftsAPIMsg.messageId;

    activeShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shyftDetailsAPIURL + `/${id}`
    );

    activeShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activeShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(activeShyftsAPIMsg.id, activeShyftsAPIMsg);
  };

  emptyActivityData = (activityItemData: any) => {
    const ratingData = {
        shiftId: activityItemData?.shift.id,
        title: activityItemData?.worker.full_name,
        subtitle: activityItemData?.shift.shift_type,
        shyftProfile: activityItemData?.worker_profile_picture
    };
    
    if(this.state.activeStats === "ongoing") {
      this.setState({
        ...this.state,
        ongoingRecentActivityData: activityItemData?.recent_activity,
        shyftSummaryData: {},
        ratingsModalData: ratingData,
      })
    } 
    if(this.state.activeStats === "complete") {
      this.setState({
        ...this.state,
        completeRequestActivityData: activityItemData?.request,
        completedShyftSummaryData: {},
        ratingsModalData: {},
      })
    }

  }

  handleDashboardStatsAPIResponse = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (apiRequestCallId === this.createdShyftListAPIId) {
      this.setCreatedShyftListAPIResponse(responseJson);
    }
    if (apiRequestCallId === this.ongoingShyftListAPIId) {
      this.setOngoingShyftListAPIResponse(responseJson);
    }
    if (apiRequestCallId === this.deleteShyftAPIId) {
      if (responseJson?.message) {
        this.setState({createdShyftPageNo: 1, isCreatedShyftsLastPage: false});
        this.getCreatedShyftList(1, this.state.createdShyftFilter);
        this.getShyftsCount();
      }
    }
    if (apiRequestCallId === this.shyftsCountAPIId) {
      this.setShyftsCount(responseJson);
    }
    this.handleRestApiResponse(responseJson,apiRequestCallId);
    this.handleViewSuccessRequest(responseJson, apiRequestCallId);
  };
  
  setShyftsCount = (responseJson: any) => {
    this.setState({createdShyftsCount: responseJson?.created_shift || 0, 
      ongoingShyftsCount: responseJson?.ongoing_shifts || 0,
      completedShyftsCount: responseJson?.completed_shifts || 0
    });
  }

  setCreatedShyftListAPIResponse = (responseJson: any) => {
    if (this.state.createdShyftPageNo === 1) {
      this.setState({ ...this.state, createdShyftList: responseJson?.length > 0 ? responseJson : [] }, () => {
        if (!this.state.isDashboardStatsMounted && responseJson.length > 0) {
          this.getActiveShyftDetails(responseJson[0]?.shift?.id);
          this.setState({isDashboardStatsMounted: true});
        }
      });
    } else if (responseJson) {
      this.setState({...this.state, 
        createdShyftList: [...this.state.createdShyftList, ...responseJson],
        isCreatedShyftsLastPage: responseJson.length < 10
      });
    }
  }

  setOngoingShyftListAPIResponse = (responseJson: any) => {
    if (this.state.ongoingShyftPageNo === 1) {
      this.setState({ ...this.state, ongoingShyftList: responseJson?.ongoing_shifts?.length > 0 ? responseJson?.ongoing_shifts : [] });
      responseJson?.ongoing_shifts?.length > 0 && 
        this.getOngoingShyftActivityRecordList(
          responseJson.ongoing_shifts[0].shift.id,
          responseJson.ongoing_shifts[0].worker.id, 
          responseJson.ongoing_shifts[0]
      )
    } else if (responseJson?.ongoing_shifts) {
      this.setState({...this.state, 
        ongoingShyftList: [...this.state.ongoingShyftList, ...responseJson.ongoing_shifts],
        isOngoingShyftsLastPage: responseJson?.ongoing_shifts?.length < 10
      });
    }
  }

  setCompletedShyftListAPIResponse = (responseJson: any) => {
    if (this.state.completedShyftPageNo === 1) {
      this.setState({ ...this.state, completedShyftList: responseJson?.completed_shifts?.length > 0 ? responseJson?.completed_shifts : [] });
      responseJson?.completed_shifts?.length > 0 && 
        this.getCompletedShyftActivityRecordList(
          responseJson.completed_shifts[0].shift.id,
          responseJson.completed_shifts[0].worker.id, 
          responseJson.completed_shifts[0]
      )
    } else if (responseJson?.completed_shifts) {
      this.setState({...this.state, 
        completedShyftList: [...this.state.completedShyftList, ...responseJson.completed_shifts],
        isCompletedShyftsLastPage: responseJson?.completed_shifts?.length < 10
      });
    }
  }
  
  handleRestApiResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.completedShyftListAPIId) {
      this.setCompletedShyftListAPIResponse(responseJson);
    }
    if (apiRequestCallId === this.activeShyftDetailsAPIId) {
      if (responseJson?.shift) {
        this.setState({ ...this.state, activeShyftData: responseJson });
      }
    }
    if (apiRequestCallId === this.editShyftAPIId) {
      this.setUpdateShyftResponse(responseJson);
    }
    if (apiRequestCallId === this.acceptRequestApiCallId) {
      this.handleAcceptSuccessRequest(responseJson) 
    }
    if (apiRequestCallId === this.ignoreRequestApiCallId) {
      this.handleIgnoreSuccessRequest(responseJson) 
    }

  }

  handleOngoingActivityResponseHandle = (responseJson: any)  => {
    if(responseJson?.message === "No activity yet") {
      this.setState({ 
        ...this.state, 
        activityRecordList: [],  
        shyftSummaryData: {},
        activityEndRequest: {},
      });
    }
    if (responseJson?.activities?.data && responseJson?.summary) {
      this.setState({ 
        ...this.state, 
        activityRecordList: responseJson.activities.data,  
        shyftSummaryData: responseJson.summary,
        activityEndRequest: {},
      });
    }
    this.setState({
      ...this.state,
      activityRecordBoxVisible: true
    })
  }

  handleCompletedActivityResponseHandle = (responseJson: any)  => {
    if (responseJson?.activities?.data && responseJson?.summary && responseJson?.request) {
      this.setState({ 
        ...this.state, 
        completedActivityRecordList: responseJson.activities.data,  
        completedShyftSummaryData: responseJson.summary,
        activityEndRequest: responseJson.request
      });
    }
    if(responseJson?.message === "No activity yet") {
      this.setState({ 
        ...this.state, 
        completedActivityRecordList: [],  
        completedShyftSummaryData: {},
        activityEndRequest: {},
      });
    }
    this.setState({
      ...this.state,
      completedActivityRecordBoxVisible: true
    })
  }

  handleActivitytReceiveApiResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.activeShyftActivityRecordsAPIId) {
      this.handleOngoingActivityResponseHandle(responseJson)
    }

    if (apiRequestCallId === this.activeCompletedShyftActivityRecordsAPIId) {
      this.handleCompletedActivityResponseHandle(responseJson)
    }

    if (apiRequestCallId === this.ongoingEndShyftAPIId) {
      if(responseJson?.message) {
        this.handleRemoveOrEndShyftSuccessApiResponses("end");
      } else if(responseJson?.error) {
          console.log("end shyft person error response", responseJson)
      }
    }


    if (apiRequestCallId === this.ongoingRemovePersonAPIId) {
      if(responseJson?.message) {
        this.handleRemoveOrEndShyftSuccessApiResponses("remove");
      } else if(responseJson?.error) {
          console.log("remove person error response", responseJson)
      }
    }

  }

  closeEndOrRemoveSuccessModal = () => {
    this.setState(({
      ...this.state,
      isEndOrRemoveSuccessModal: false,
    }))
  }

  handleEndOrRemoveRateShyt = () => {
    this.closeEndOrRemoveSuccessModal();
    this.setState({
      ...this.state,
      isopenReviewAndRatingModal: true,
      isEndOrRemoveSuccessModal: false,
    })
  }

  handleCloseReviewAndRatingModal = () => {
    this.setState({
      ...this.state,
      isopenReviewAndRatingModal: false,
    })
  }

  handleCloseRateSuccessModal = () => {
    this.setState({
      ...this.state,
      isopenRatingSucessModal: false,
    })
  }

  openEndOrRemoveSuccessModal = (title: string, description: string) => {
    this.setState(({
      ...this.state,
      endOrRemoveSuccessModalTitle: title,
      isEndOrRemoveSuccessModal: true,
      endOrRemoveSuccessModalDesc: description
    }))
  }

  handleRemoveOrEndShyftSuccessApiResponses = (value: string) => {
    switch(value) {
      case "remove":
        this.closeOngoingActivityRemoveModal()
        this.openEndOrRemoveSuccessModal(configJSON.shyftActivityRemoveTitleLabel, configJSON.shyftActivityRemoveDescription)
        break;
      case "end":
        this.handleIsCloseEndShyft()
        this.openEndOrRemoveSuccessModal(configJSON.shyftActivityEndedTitleLabel, configJSON.shyftActivityEndedDescription)
        break;
    }
  }

  handleRepublishCompletedShyft = () => {
    this.handleMenuItemClick("Republish Shyft")
  }

  handleCompletedShyftMessage = () => {
    console.log('message button click..')
  }

  openShyftFilter = (e: any) => {
    this.setState({...this.state, isOpenSortFilter: e?.currentTarget});
  }

  closeShyftFilter = () => {
    this.setState({...this.state, isOpenSortFilter: null});
  }

  applyFilter = (filter: string) => {
    if (this.state.activeStats === "create") {
      this.setState({createdShyftFilter: filter, isOpenSortFilter: null, createdShyftPageNo: 1, isCreatedShyftsLastPage: false});
      this.getCreatedShyftList(1, filter);
    } else if (this.state.activeStats === "ongoing") {
      this.setState({ongoingShyftFilter: filter, isOpenSortFilter: null, ongoingShyftPageNo: 1, isOngoingShyftsLastPage: false});
      this.getOngoingShyftList(1, filter);
    } else if (this.state.activeStats === "complete") {
      this.setState({completedShyftFilter: filter, isOpenSortFilter: null, completedShyftPageNo: 1, isCompletedShyftsLastPage: false});
      this.getCompletedShyftList(1, filter);
    }
  }

  openActivityFilterDrawer = () => {
    this.setState({...this.state, isOpenActivityFilterDrawer: true});
  }

  closeActivityFilterDrawer = () => {
    this.setState({...this.state, isOpenActivityFilterDrawer: false});
  }

  applyActivityFilterDrawer = () => {
    this.closeActivityFilterDrawer();
  }

  openOngoingActivityRemoveModal = () => {
    this.setState({ isRemoveModalOpen: true });
  };

  closeOngoingActivityRemoveModal = () => {
    this.setState({ isRemoveModalOpen: false, removeReason: "", removeFieldErr: "" });
  };

  handleIsEndShyft = () => {
    this.setState({ isEndShyft: true});
  };

  handleIsCloseEndShyft = () => {
    this.setState({ isEndShyft: false });
  };

  deleteShyftFromDashboard(id: string) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const deleteShyftApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteShyftAPIId = deleteShyftApiMsg.messageId;

    deleteShyftApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shyftDetailsAPIURL + `/${id}`
    );

    deleteShyftApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteShyftApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );
    runEngine.sendMessage(deleteShyftApiMsg.id, deleteShyftApiMsg);
  }

  saveShyftDetails = (shyftData: any) => {
    const shyftObj = new FormData();
    shyftObj.append("[shift]shift_type", shyftData.selectedShyft);
    shyftObj.append(
      "[shift]requirements",
      shyftData.shyftRequirements.join(",")
    );
    shyftObj.append("[shift]description", shyftData.shyftDescription);
    shyftObj.append("[shift]expirence", shyftData.experience);
    shyftObj.append("[shift]amount", shyftData.amount);
    shyftObj.append("[shift]amount_per", shyftData.rateFrequency);
    shyftObj.append(
      "[shift]shift_from",
      moment(convertTimeStringIntoDateObject(shyftData.fromTimeHour, shyftData.fromTimeMinute, shyftData.fromTimeFormat)).format('hh:mm A')
    );
    shyftObj.append(
      "[shift]shift_to",
      moment(convertTimeStringIntoDateObject(shyftData.toTimeHour, shyftData.toTimeMinute, shyftData.toTimeFormat)).format('hh:mm A')
    );

    const headers = {
      token: localStorage.getItem("authToken"),
    };

    const updateShyftsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editShyftAPIId = updateShyftsMsg.messageId;

    let apiUrl = configJSON.shyftDetailsAPIURL;
    let methodType = configJSON.exampleAPiMethod;
    if (this.state.isOpenEditShyftDialog === "edit") {
      apiUrl = configJSON.shyftDetailsAPIURL+`/${shyftData?.id}`;
      methodType = configJSON.putAPIMethodType;
    }

    updateShyftsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    updateShyftsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateShyftsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    updateShyftsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      shyftObj
    );
    runEngine.sendMessage(updateShyftsMsg.id, updateShyftsMsg);
  };


  setUpdateShyftResponse = (responseJson: any) => {
    if (responseJson && responseJson.shift) {
      this.setState({
        successDialogDescription: responseJson?.message === "Shift updated successfully" ? 
        configJSON.shyftUpdatedDescription :
        configJSON.shyftAddedDescription,
        successDialogTitle: 
        responseJson?.message === "Shift updated successfully" ? 
        configJSON.shyftUpdatedTitleLabel : 
        configJSON.shyftAddedTitleLabel,
        createdShyftPageNo: 1,
        isCreatedShyftsLastPage: false,
        ongoingShyftPageNo: 1,
        isOngoingShyftsLastPage: false,
        completedShyftPageNo: 1,
        isCompletedShyftsLastPage: false
      })
      this.getCreatedShyftList(1, this.state.createdShyftFilter);
      this.getOngoingShyftList(1, this.state.ongoingShyftFilter);
      this.getCompletedShyftList(1, this.state.completedShyftFilter);
      this.toggleEditShyftSuccessDialog(true);
      this.getActiveShyftDetails(this.state.activeShyftId);
      this.getShyftsCount();
    }
  };

  handleViewRequestForDashboard = () => {
    this.getViewRequestForDashboard(this.state.activeShyftData?.shift?.id);
    this.setState({ isOpenViewRequestDrawer: true }); 
  };

  handleViewRequestDialogClose = () => {
    this.setState({ 
      isOpenViewRequestDrawer: false, 
      acceptedShyfterName: ""  
    });
  };

  closeAcceptSuccessModal = (moveToOngoing: boolean) => {
    this.setState({ 
      isOpenViewRequestSuccessModal: false, 
      acceptedShyfterName: "",
      isOpenViewRequestDrawer: false, 
      activeShyftData: {},
      activeStats: moveToOngoing ? "ongoing" : "create",
      createdShyftPageNo: 1,
      isCreatedShyftsLastPage: false,
      isOngoingShyftsLastPage: false,
      ongoingShyftPageNo: 1,
      isCompletedShyftsLastPage: false,
      completedShyftPageNo: 1
    });
    this.getCreatedShyftList(1, this.state.createdShyftFilter);
    if (moveToOngoing) {
      this.getOngoingShyftList(1, this.state.ongoingShyftFilter);
    }
  };

  handleRemoveShyfterReason = (reason: string) => {
    let newVal = reason;
    const removeReason = newVal.slice(0, 80);

    this.setState({
      ...this.state,
      removeReason: removeReason
    })
  }

  handleRemoveSubmit = () => {
    if(!configJSON.minMaxRemoveReasonLimitCheck.test(this.state.removeReason.trim())) {
      this.setState({
        ...this.state,
        removeFieldErr: "*Required, min-max[3-80] length."
      });
    } else {
      this.setState({ 
        ...this.state,
        removeFieldErr: "",
      })
      this.ongoingRemovePersonApi()
    }
  }

  handleEndShyftSubmit = () => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const ongoingEndShyftApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ongoingEndShyftAPIId = ongoingEndShyftApiMsg.messageId;

    ongoingEndShyftApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putEndShyftApiEndPoint}${this.state.ongoingShyftId}`
    );

    ongoingEndShyftApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    ongoingEndShyftApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethodType
    );

    runEngine.sendMessage(ongoingEndShyftApiMsg.id, ongoingEndShyftApiMsg);
  }

  ongoingRemovePersonApi = () => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const body = {
      shift_id: this.state.ongoingShyftId,
      worker_id: this.state.ongoingWorkerId,
      removal_reason: this.state.removeReason
    }

    const ongoingRemovePersonApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ongoingRemovePersonAPIId = ongoingRemovePersonApiMsg.messageId;

    ongoingRemovePersonApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postRemovePersonApiEndpoint
    );

    ongoingRemovePersonApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    ongoingRemovePersonApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    ongoingRemovePersonApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(ongoingRemovePersonApiMsg.id, ongoingRemovePersonApiMsg);
  }

  getViewRequestForDashboard(id: any) {
    const header = {
      token: localStorage.getItem('authToken'),
      "Content-Type": configJSON.getVieWRequestApiContentType,
    };
    const viewRequestAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getViewRequestApiCallId = viewRequestAPIMsg.messageId;
    viewRequestAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVieWRequestEndPoint}?shift_id=${id}`
    );
    viewRequestAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    viewRequestAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getVieWRequestApiMethodType
    );
    runEngine.sendMessage(viewRequestAPIMsg.id, viewRequestAPIMsg);
  }

  handleViewSuccessRequest = (responseJson: any, apiRequestCallId: string)=>{
    if (apiRequestCallId === this.getViewRequestApiCallId) {
      this.setState({viewRequestData:responseJson?.requests})
    }
  }

  handleIgnoreSuccessRequest = (responseJson: any)=>{
    if (responseJson?.message === "Request ignored successfully") {
      this.setState({
        acceptedShyfterName: "",
        createdShyftPageNo: 1,
        isOpenViewRequestDrawer: false,
        isCreatedShyftsLastPage: false
      })
      this.getCreatedShyftList(1, this.state.createdShyftFilter);
      this.getActiveShyftDetails(this.state.activeShyftData?.shift?.id);
    }
  }

  handleAcceptSuccessRequest = (responseJson: any)=>{
    if (responseJson?.message === "Request accepted successfully") {
      this.setState({ isOpenViewRequestDrawer: false }, () => {
        this.setState({ isOpenViewRequestSuccessModal: true });
      });
    }
  }

  dashboardStatsAPICall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessageAPIForDashboardStats = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageAPIForDashboardStats.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const dashboardStatsHeader = {
      "Content-Type": contentType,
      token: localStorage.getItem('authToken')
    };
    requestMessageAPIForDashboardStats.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(dashboardStatsHeader)
    );
    requestMessageAPIForDashboardStats.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageAPIForDashboardStats.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageAPIForDashboardStats.id, requestMessageAPIForDashboardStats);
    return requestMessageAPIForDashboardStats.messageId;
  };

  ignoreShyftRequest = async (ignoreRequestData: any) => {
    let apiData = {
      shift_id: ignoreRequestData.request.shift_id,
      request_id: ignoreRequestData.request.id
    }
    this.ignoreRequestApiCallId = await this.dashboardStatsAPICall({
      contentType: configJSON.postignoreRequestApiContentType,
      method: configJSON.postignoreRequestApiMethodType,
      endPoint: configJSON.postignoreRequestEndPoint,
      body:apiData
    });
  }

  acceptShyftRequest = async (acceptRequestData: any) => {
    let apiData = {
      shift_id: acceptRequestData.request.shift_id,
      request_id: acceptRequestData.request.id
    }
    this.acceptRequestApiCallId = await this.dashboardStatsAPICall({
      contentType: configJSON.postaceeptRequestApiContentType,
      method: configJSON.postaceeptRequestApiMethodType,
      endPoint: configJSON.postaceeptRequestEndPoint,
      body:apiData
    });
    const shifterName = this.state.viewRequestData.find((i) => i.request.id === Number(acceptRequestData.request.id))?.worker?.full_name;
    this.setState({acceptedShyfterName: shifterName ?? ""});
  }

  handleSubmitReviewAndRating = (ratingValue: number, comment: string) => {
    const { ongoingCurrentActivityData } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('authToken'),
    };

    const body = {
      rating: {
        score: ratingValue,
        comment,
        shift_id: ongoingCurrentActivityData?.shift.id,
        hotel_id: ongoingCurrentActivityData?.worker.id,
      }
    };

    const submitReviewAndRatingApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    submitReviewAndRatingApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postRateAndReviewApiEndPoint}`
    );
    
    submitReviewAndRatingApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    submitReviewAndRatingApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    submitReviewAndRatingApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftsApiMethodType,
    );

    this.shyftOngoingRatingsAPIId = submitReviewAndRatingApiMsg.messageId;
    runEngine.sendMessage(submitReviewAndRatingApiMsg.id, submitReviewAndRatingApiMsg);
  }

  handleUpdateActivityStatusCard = (item: any) => {
    this.setState({
      ...this.state,
      activityRecordSingleItemData: item,
      openUpdateStatusModal: true
    })
  }

  handleCloseUpdateModal = () => {
    this.setState({
      ...this.state,
      openUpdateStatusModal: false
    })
  }

  handleUpdateStatusModalSubmit = () => {
    this.handleCloseUpdateModal();

    setStorageData('updateStatusData',JSON.stringify(this.state.activityRecordSingleItemData))
    
    const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "UpdateStatusPage");
      msg.addData( getName(MessageEnum.NavigationPropsMessage), this.props);
  
    this.send(msg);
  }
}
// Customizable Area End
