import React from "react";

import ExploreShyftsController, { Props } from "./ExploreShyftsController.web";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterFeedbackModal from "../../../components/src/ShyfterFeedbackModal.web";
import GetLocationModal from "../../../components/src/GetLocationModal.web";
import { SuccessIcon } from "./assets";
import { breadCrumSubsPath } from "../../../components/src/Utilities";

export default class ExploreShyftsPage extends ExploreShyftsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      shyftsList,
      selectedShyft,
      shyftDetails,
      request,
      search,
      isShyftApplied,
      isRegisterationPending,
      isLocationModalOpen,
      totalRecords,
    } = this.state;

    return (
      <>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Explore Shyfts"
          breadCrumsData={breadCrumSubsPath}
        />
        <Box width="100%" marginTop={"8%"}>
          <Container maxWidth="md">
            <SearchWithFilterandLocation
              showFilterText={true}
              handleSerchModal={this.onSearchShifts}
              islocation={true}
              defaultValue={search}
              handleLoctionModal={this.openLocationModal}
              handleSearchFilter={this.handleExploreFilterShifts}
            />
            {totalRecords !== 0 && (
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >{`Total ${totalRecords} Shyfts`}</Typography>
            )}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  {shyftsList.map((item) => {
                    return (
                      <ShyftCard
                        key={item.id}
                        {...item}
                        onCardClick={() => this.onSelectShift(item.id)}
                        onSaveShyft={() =>
                          this.handleExploreSaveShyft(item.id, item.saved)
                        }
                        isActiveShyft={item.id === selectedShyft}
                        showBookMark={true}
                      />
                    );
                  })}
                  <div ref={this.elementRef}></div>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box
                  margin={"15px 0px"}
                  style={{ position: "sticky", top: "24%" }}
                >
                  {shyftDetails && (
                    <ShyfterDetailsCard
                      {...shyftDetails}
                      key={shyftDetails.id}
                      shyftStatus={request ? request.status : undefined}
                      isAppliedShyft={this.checkIfAppliedeForShyft(request)}
                      onApplyShift={() => this.applyForShyft(shyftDetails.id)}
                      onWithdrawShift={() =>
                        this.withdrawFromShyft(shyftDetails.id)
                      }
                      onViewProfile={() => {
                        this.onViewBusinessProfile(shyftDetails.businessId);
                      }}
                      showOverviewSubtext={false}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <ShyfterFeedbackModal
              isOpen={isShyftApplied}
              title="Applied Successfully"
              description="This shift has been applied successfully. Explore more shyfts or you can view status of this shyft"
              imageSrc={SuccessIcon}
              primaryButtonText="Explore More"
              onClose={this.closeApplyShiftModal}
              secondaryButtonText="View Status"
              primaryButtonOnClick={this.closeApplyShiftModal}
              secondaryButtonOnClick={this.closeApplyShiftModal}
            />
            <ShyfterFeedbackModal
              title="Registration Pending"
              isOpen={isRegisterationPending}
              description="Your registeration is pending. Please complete your registration to apply for a shyft in a hotel and restaurant."
              imageSrc={SuccessIcon}
              onClose={this.closePendingRegistrationModal}
              primaryButtonText="Complete Now"
              primaryButtonOnClick={this.closePendingRegistrationModal}
            />
            <GetLocationModal
              open={isLocationModalOpen}
              closeModal={this.closeLocationModal}
            />
          </Container>
        </Box>
        {/* <Footer /> */}
      </>
    );
  }
}
