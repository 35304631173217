import React, { Component } from 'react';
// Customizable Area Start 
import './style.css';
// Customizable Area End
// Customizable Area Start
interface Props {
  dataTestId?: string;
  navigation: any;
  onSignUp?: () => void;
  // Customizable Area End

  // Customizable Area Start
}
export default class GenericHeader extends Component<Props, any>{
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className='generic-header'>
        <ul className='header-listitems'>
          <li onClick={() => window.location.href = "/BusinessBenifits"}>Business</li>
          <li onClick={() => window.location.href = "/ShyfterBenifits"}>Shyfters</li>
          <li onClick={() => window.location.href = "/FAQ"}>Faq</li>
          <li className={window.location.pathname === '/ContactUS' ? 'selected': ''} onClick={() => window.location.href = "/ContactUS"}>Contact Us</li>
        </ul>
        <span className='header-logo' onClick={() => { window.location.href = "/" }}><img src={require("./logo.png")} onClick={() => { window.location.href = "/" }}></img><img src={require("./logotitle.png")}></img></span>
        <span className='header-buttongrp'>
          <span className='header-login' onClick={() => window.location.href = "/Login"}>Login Now</span>
          <span className='header-signup' onClick={this.props.onSignUp}>Sign Up</span>
        </span>
      </div>
    )
  }
}
  // Customizable Area End