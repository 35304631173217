import React from "react";
import { Box, Typography, Grid, Divider, Tabs, Tab } from "@material-ui/core";

import {
  ThemeProvider,
  createTheme,
  makeStyles,
  createStyles,
  StyleRules,
} from "@material-ui/core/styles";
import StaticRating from "./StaticRatings";
import ShyftCard from "./ShyftCard";
import CustomButton from "./CustomButton.web";
import StarIcon from "@material-ui/icons/Star";
import moment from "moment";
const Briefcase = require("./assets/images/briefcase-blank.png");
const PlaceIcon = require("./assets/images/placeIcon.png");
const Clock = require("./assets/images/clock3.png");
const billingIcon = require("./assets/images/money-bills-simple.png");
const checkmark = require("./assets/images/task-check.png");
const ProfileImg = require("./assets/images/ProfileImg.png");
const Verified = require("./assets/images/verified.png");
const gemsIcon = require("./gem.png");
const work = require("./assets/images/briefcase-2.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mr5: {
    margin: "5px",
  },
  dayText: {
    position: "absolute",
    right: "5px",
    top: "5px",
    color: "#6f7c8e",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19px",
  },
  placeText: {
    color: "#390879",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19px",
    textAlign: "center",
  },
  hotelText: {
    color: "#390879",
  },
  restaurantText: {
    color: "#b9df12",
  },
  flexContainer: {
    gap: "0px",
  },
  card: {
    border: "1px solid #e1e6ec",
    borderRadius: "10px",
    backgroundColor: "#fff",
    height: "82vh",
    overflowY: "scroll",
  },
  cardContent: {
    overflowX: "hidden",
    padding: "10px",
  },
  cardFooter: {
    borderTop: "1px solid #e1e6ec",
    position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "10px 0",
    backgroundColor: "#fff",
  },
  scrollbarTrack: {
    width: "2px",
  },
  scrollbarThumb: {
    background: "#ffff",
    borderRadius: "5px",
  },
  boxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "178px",
    height: "87px",
    margin: "10px",
    borderRadius: "10px",
  },
  tab: {
    minWidth: "25px",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "#6f7c8e",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "center",
  },
  cardHeading: {
    fontSize: "20px",
    lineHeight: "19px",
    color: "#0a0412",
    marginTop: "4px",
    marginBottom: "4px",
  },
  overviewHeading: {
    color: "#0a0412",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
  },
  detailTitle: {
    color: "#0a0412",
    fontSize: "15px",
    lineHeight: "19px",
  },
  cardSubHeading: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#0a0412",
  },
  cardActiveHeading: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#390879",
    textDecoration: "underline",
  },
  locationText: {
    color: "#6f7c8e",
    fontSize: "14px",
    lineHeight: "16px",
  },
  cardInactiveSubHeading: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#6f7c8e",
  },
  subHeadingText: {
    fontSize: "11px",
    lineHeight: "16px",
    color: "#6f7c8e",
  },
  experienceText: {
    color: "#390879",
    fontSize: "16px",
    lineHeight: "18px",
    textTransform: "capitalize",
  },
  mutedText: {
    color: "#0a0412",
    fontSize: "13px",
    lineHeight: "16px",
    textAlign: "center",
  },
  cardTag: {
    background: "rgba(98, 54, 255, 0.05)",
    boxShadow: "0px 3px 14px rgba(10,4,18,0.04)",
    borderRadius: "18px",
  },
  tagText: {
    fontSize: "11px",
    color: "#6236ff",
    textTransform: "capitalize",
  },
  cardbodyText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#000",
  },
  requirementText: {
    color: "#0a0412",
    fontSize: "14px",
    lineHeight: "18px",
  },
  descriptionText: {
    color: "#0a0412",
    fontSize: "12px",
    lineHeight: "18px",
  },
  commentText: {
    color: "#0a0412",
    fontSize: "12px",
    lineHeight: "16px",
  },
  cardEmpName: {
    fontSize: "18px",
    lineHeight: "20px",
    color: "#0a0412",
  },
}));

function getProperties(styles: StyleRules): any {
  const properties: any = {};

  for (const [key, value] of Object.entries(styles)) {
    properties[key] = value;
  }

  return properties;
}

const styles = createStyles({
  "::-webkit-scrollbar": {
    width: "0.4em",
  },
  "::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    outline: "1px solid slategrey",
  },
});
const properties = getProperties(styles);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

interface ShyftType {
  id: number;
  syftTimimg: string;
  billing: string;
  day: string;
  shyft: string;
  shyftPlace: string;
}

interface TReviewComment {
  id: number;
  score: number;
  comment: string;
  shift_type: string;
  worker_name: string;
  worker_profile_picture: string;
  created_at: string;
}

interface Props {
  id?: Number;
  employername?: String;
  county?: String;
  userImg?: any;
  isShyfExpired?: boolean;
  syftTimimg?: String;
  billing?: String;
  day?: String;
  shyft?: String;
  shyftPlace?: String;
  isSavedShyft?: boolean;
  isActiveShyft?: boolean;
  isAppliedShyft?: boolean;
  shyftStatus?: string;
  requirements?: string[];
  description?: string;
  experience?: string;
  businessName?: string;
  businessType?: string;
  overallRatings?: number;
  ratings?: TReviewComment[];
  ratingsCount?: number;
  reviewsCount?: number;
  onApplyShift?: () => void;
  onWithdrawShift?: () => void;
  onViewProfile?: () => void;
  showOverviewSubtext?: boolean;
  businessShifts?: ShyftType[];
}

const getPlaceActiveClass = (
  hotelClassname: string,
  restoClassname: string,
  place?: String,
) => {
  return place?.toLowerCase() === "hotel" ? hotelClassname : restoClassname;
};

const ShyfterDetailsCard = (props: Props) => {
  const {
    employername,
    county,
    userImg,
    shyft,
    shyftPlace,
    syftTimimg,
    day,
    billing,
    isShyfExpired,
    isSavedShyft,
    isActiveShyft,
    isAppliedShyft,
    shyftStatus,
    description,
    requirements = [],
    experience,
    businessName,
    businessType,
    overallRatings,
    ratings = [],
    ratingsCount,
    reviewsCount,
    onApplyShift,
    onWithdrawShift,
    businessShifts = [],
    showOverviewSubtext = true,
    onViewProfile,
  } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box className={`${classes.card}`} sx={properties}>
        <Box className={`${classes.cardContent}`}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            padding="10px"
            position="relative"
          >
            <Typography className={classes.dayText}>{day}</Typography>
            <Box
              height={"24px"}
              width={"24px"}
              bgcolor={"rgba(57, 8, 121, 0.05)"}
              borderRadius="10px"
              padding="10px"
              margin="10px"
            >
              <img src={Briefcase} alt="" height="24px" width="24px" />
            </Box>
            <Typography className={`${classes.placeText} ${getPlaceActiveClass(
              classes.hotelText,
              classes.restaurantText,
              shyftPlace,
            )}`}
            >
              {shyftPlace || ""}
            </Typography>
            <Typography className={classes.cardHeading}>
              {shyft || ""}
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box marginRight={"5px"}>
                <img src={PlaceIcon} alt="" height="18px" />
              </Box>
              <Typography className={classes.locationText}>
                {county || ""}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            padding="10px"
          >
            <Box className={classes.boxes} bgcolor={"#F4FAF2"} gridGap={"5px"}>
              <img src={billingIcon} alt="" height="20px" />
              <Typography className={classes.cardInactiveSubHeading}>
                Amount
              </Typography>
              <Typography className={classes.mutedText}>
                {billing || "$120 Per Hr"}
              </Typography>
            </Box>
            <Box className={classes.boxes} bgcolor={"#FFFBF2"} gridGap={"5px"}>
              <img src={Clock} alt="" height="20px" />
              <Typography className={classes.cardInactiveSubHeading}>
                Shyft Timing
              </Typography>
              <Typography className={classes.mutedText}>
                {syftTimimg || "12:00pm - 09:00pm"}
              </Typography>
            </Box>
          </Box>
          <Divider variant="middle" />
          <Box padding="0 10px">
            <div className={classes.root}>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                centered
                classes={{ flexContainer: classes.flexContainer }}
              >
                <Tab
                  className={classes.tab}
                  label="Shyft Overview"
                  {...a11yProps(0)}
                />
                <Tab
                  className={classes.tab}
                  label={`${shyftPlace} Details`}
                  {...a11yProps(1)}
                />
                <Tab
                  className={classes.tab}
                  label={`${shyftPlace} Ratings`}
                  {...a11yProps(2)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Box>
                  {showOverviewSubtext && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginTop="10px"
                    >
                      <Divider style={{ width: "31%" }} />
                      <Box margin={"3px 0"}>
                        <Typography className={classes.overviewHeading}>
                          Shyft Overview
                        </Typography>
                      </Box>
                      <Divider style={{ width: "31%" }} />
                    </Box>
                  )}

                  <Box margin={"15px 0px"}>
                    <Typography className={classes.detailTitle}>
                      Experience Required
                    </Typography>
                  </Box>
                  <Box
                    border="1px solid #e1e6ec"
                    borderRadius="10px"
                    padding="10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <img
                      src={work}
                      alt=""
                      height="24px"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      className={classes.experienceText}
                    >
                      {`${experience} Yrs` || ""}
                    </Typography>
                  </Box>
                  <Box margin={"15px 0px"}>
                    <Typography className={classes.detailTitle}>
                      Shyft Requirement
                    </Typography>
                  </Box>
                  <Box
                    border="1px solid #e1e6ec"
                    width="100%"
                    borderRadius="10px"
                  >
                    <Grid container style={{ padding: "10px" }}>
                      {requirements.map((requirement) => (
                        <Grid item xs={6}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <img
                              src={checkmark}
                              alt="tick"
                              height="20px"
                              style={{ marginRight: "5px" }}
                            />
                            <Typography className={classes.requirementText}>
                              {requirement}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box margin={"15px 0px"}>
                    <Typography className={classes.detailTitle}>
                      Shyft Description
                    </Typography>
                  </Box>
                  <Box
                    border="1px solid #e1e6ec"
                    borderRadius="10px"
                    padding="10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Typography className={classes.descriptionText}>
                      {description || ""}
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  margin="10px"
                  marginTop="18px"
                >
                  <Typography className={classes.detailTitle}>
                    Business Overview
                  </Typography>
                  <Typography
                    className={classes.cardActiveHeading}
                    style={{ textDecoration: "underline" }}
                    onClick={onViewProfile}
                    data-test-id="view-profile"
                  >
                    View Profile
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  border="1px solid #e1e6ec"
                  borderRadius="10px"
                  padding="10px"
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <img
                      src={userImg || ProfileImg}
                      style={{
                        width: "62px",
                        height: "auto",
                        borderRadius: "10px",
                        marginRight: "10px",
                      }}
                    />
                    <Box>
                      <Typography className={classes.cardEmpName}>
                        {businessName}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.cardTag}
                        margin={"5px 0px"}
                      >
                        <img
                          src={gemsIcon}
                          style={{
                            height: "14px",
                            marginRight: "5px",
                          }}
                        />
                        <Typography className={classes.tagText}>
                          {businessType}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box alignItems="center" textAlign="center">
                    <img src={Verified} style={{ height: "18px" }} />
                    <Typography
                      className={classes.cardbodyText}
                      style={{ color: "green" }}
                    >
                      Verified
                    </Typography>
                  </Box>
                </Box>
                {businessShifts.length !== 0 && (
                  <Box margin={"10px 0px"}>
                    <Typography className={classes.detailTitle}>
                      Shyft created by {businessName}
                    </Typography>
                  </Box>
                )}
                {businessShifts.map((item) => {
                  return <ShyftCard key={item.id} {...item} />;
                })}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  border="1px solid #e1e6ec"
                  padding="10px 20px"
                  borderRadius="10px"
                  bgcolor={"#e1e6ec"}
                  margin={"10px 0px"}
                  style={{
                    backgroundColor: "rgba(225, 230, 236, 0.3)"
                  }}
                >
                  <Typography style={{ marginRight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "30px" }}>
                      {overallRatings}
                      <span style={{ fontSize: "16px" }}>/5</span>
                    </span>
                  </Typography>
                  <Divider />
                  <StaticRating
                    ratingCount={ratingsCount}
                    reviewCount={reviewsCount || ratings.length}
                    rating={overallRatings}
                  />
                 
                </Box>
                {ratings.length !== 0 && (
                  <Box margin="10px" marginBottom="5px">
                    <Typography className={classes.detailTitle}>
                      All Reviews
                    </Typography>
                  </Box>
                )}
                <Box style={{ maxHeight: "200px", overflowY: "auto" }}>
                  {ratings.map((rating) => (
                    <Box
                      key={rating.id || ""}
                      border="1px solid #e1e6ec"
                      borderRadius="10px"
                      padding={"5px"}
                      marginTop="10px"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        padding="10px"
                      >
                        <Box display="flex" style={{ gap: "5px" }}>
                          <Box style={{ borderRadius: "10px" }}>
                            <img
                              src={rating.worker_profile_picture || ""}
                              height={"42px"}
                              width={"42px"}
                              style={{ borderRadius: "10px" }}
                            />
                          </Box>
                          <Box padding={"0px 10px"}>
                            <Typography className={classes.cardSubHeading}>
                              {rating.worker_name || ""}
                            </Typography>
                            <Box
                              display={"flex"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              padding={"5px 0px"}
                            >
                              <Box marginRight={"5px"}>
                                <img
                                  src={Briefcase}
                                  height={"18px"}
                                  width={"18px"}
                                />
                              </Box>
                              <Typography className={classes.subHeadingText}>
                                {rating.shift_type || ""}
                              </Typography>
                              <Box
                                width={"1px"}
                                borderLeft={"1px solid #e1e6ec"}
                              />
                              <Typography className={classes.subHeadingText}>
                                {rating.created_at
                                  ? `| ${moment(rating.created_at).format(
                                      "DD MMM, YYYY"
                                    )}`
                                  : ""}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          padding={"3px 7px"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          borderRadius="30px"
                          bgcolor={"#f5c42c"}
                          color={"#fff"}
                          fontSize="14px"
                        >
                          <StarIcon
                            style={{
                              color: "#fff",
                              height: "14px",
                              width: "14px",
                            }}
                          />
                          {rating.score || ""}
                        </Box>
                      </Box>
                      <Box padding={"0px 10px"}>
                        <Typography className={classes.commentText}>
                          {rating.comment || ""}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </TabPanel>
            </div>
          </Box>
        </Box>
        <Box className={classes.cardFooter}>
          <Box borderRadius="50px" textAlign="center" margin="10px">
            {isAppliedShyft ? (
              <Box
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <Box
                  padding={"14px"}
                  width={"30%"}
                  borderRadius={"50px"}
                  border="1px solid #FAE2D1"
                  bgcolor={"#FAF0EE"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {shyftStatus}
                </Box>
                <Box width={"50%"}>
                  <CustomButton
                    label={"Withdraw Request"}
                    handleClick={onWithdrawShift}
                  />
                </Box>
              </Box>
            ) : (
              <CustomButton
                disabled={isShyfExpired}
                label={"Apply"}
                handleClick={onApplyShift}
              />
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ShyfterDetailsCard;
