import React from "react";

// Customizable Area Start
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { createTheme } from "@material-ui/core/styles";
import "./style.css";

// Customizable Area End

export interface Props {
  // Customizable Area Start
  message: string;
  onClose: () => void;
  // Customizable Area End
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
});

// const popUptheme = createTheme({
//   typography: {
//     subtitle1: {
//       lineHeight: "1.6rem",
//       fontWeight: "bold",
//       fontSize: "1.4rem",
//     },
//     subtitle2: {
//       lineHeight: "20px",
//       fontSize: "14px",
//       maxWidth: "60%",
//     },
//     body1: {
//       lineHeight: "20px",
//       fontSize: "14px",
//       display: "flex",
//       flexDirection: "row",
//       justifyContent: "space-between",
//     },
//   },
// });

export default class Alert extends React.Component<Props> {
  message: string;
  onClose: () => void
  constructor(props: Props) {
    super(props);
    this.message = props.message;
    this.onClose = props.onClose;
  }

  render() {
    return (
      // Required for all blocks

      <div className={`alert error`}>
        <div style={{ marginTop: "23px" }}>
        <img
          style={{
            height: "35px",
            width: "35px",
          }}
          src={require("./assets/warning.png")}
        />
        </div>

        <div className={`alert-body`}>
          <strong style={{ lineHeight: "16px", fontSize: "15px", width: "300px" }}>
            Alert
          </strong>
          <span style={{ marginTop: "10px" , lineHeight: "15px", fontSize: "12px", fontWeight: 800}}>{this.message}</span>
        </div>
        <CloseRoundedIcon data-test-id="close-alert" onClick={this.onClose} style={{marginTop: "23px", backgroundColor: "#E1E6EC", cursor: "pointer", borderRadius: "15px"}} />
        
      </div>
    );
  }
}
