import React, { FunctionComponent } from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const customStyles = makeStyles((them) => ({
  input: {
    display: "none",
  },
  button: {
    width: "100%",
    backgroundColor: "rgba(57, 8, 121, .04)",
    color: them.palette.primary.main,
    border: "1px dashed",
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: 12,
    fontWeight: 500,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(57, 8, 121, .04)",
    },
  },
  backBtnText: {
    color: "black",
  },
  main: {
    marginBottom: 10,
  },
}));

type Props = {
  uploadLabel: string;
  id: string;
  inputName: string;
  onChange: (e: any) => void;
  error: string,
  selectedName: string
};

const UploadButton: FunctionComponent<Props> = ({ uploadLabel, id, inputName, onChange, error, selectedName }) => {
  const classes = customStyles();
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const fileType = file.type;
    const fileSize = file.size;
    if (file && fileType === "application/pdf" && fileSize < 2097152) {
      onChange(event);
    }
  };
  return (
    <div className={classes.main}>
      <Typography
        style={{
          fontWeight: 500,
          fontSize: 12,
          letterSpacing: "-0.21px",
          color: "#0A0412",
        }}
        classes={{
          root: classes.backBtnText,
        }}
        variant="body1"
        color={"primary"}
      >
        {uploadLabel}
      </Typography>
      <input
        accept=".pdf, application/pdf"
        className={classes.input}
        id={id}
        type="file"
        name={inputName}
        onChange={handleFileChange}
        data-test-id="file-upload"
      />
      <label htmlFor={id}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{color: error? "red": "#390879"}}
          classes={{ root: classes.button }}
          disableElevation={true}
        >
          {!selectedName && <img src={require("./assets/uploadButton.svg")}/>}
          <span>{selectedName || "Upload Document"}</span>
        </Button>
      </label>
    </div>
  );
};
export default UploadButton;
