import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Input,
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
  } from "@material-ui/core";
// Customizable Area End

import SplashscreenController, { Props } from "./SplashscreenController";

import { imgSplash } from "./assets";

// Customizable Area Start
import AccountTypeSelectionDialog from "../../../components/src/AccountTypeSelectionDialog.web";
import LandingScreen from "../../../components/src/LandingScreen.web";

  // Customizable Area End
export default class LandingPage extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <LandingScreen
          navigation={this.props.navigation}
          dataTestId="landing-screen"
          goToContactUs={this.goToContactUs}
          goToSignUp={this.toggleRegistrationDialog}
        />
        {this.state.isOpenRegistrationDialog && (
          <AccountTypeSelectionDialog
            isOpen={this.state.isOpenRegistrationDialog}
            handleClose={this.toggleRegistrationDialog}
            handleSave={this.redirectToSignUpPage}
          />
        )}
      </Box>

);
  }
   // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
