import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setSelectedSubsId } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  allSubscriptionList: any;
  subscribeNowDialog: boolean;
  userSubscription: any;
  upgradedPlan: any;
}

interface SS {
  id: any;
}

export default class CurrentSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCurrentUserSubsApiCallId: string = "";
  getAllSubscriptionApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      allSubscriptionList: "",
      subscribeNowDialog: false,
      userSubscription: "",
      upgradedPlan: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getUserSubscription();
    this.getAllSubscriptionList();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
  }

  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCurrentUserSubsApiCallId) {
      this.setState({
        userSubscription: responseJson.data.attributes.subscription.data,
      });
    }
    if (apiRequestCallId === this.getAllSubscriptionApiCallId) {
      this.setState({
        allSubscriptionList: responseJson.subscriptions,
        upgradedPlan: responseJson.subscriptions[0]
      });
    }
  }

  handleSubscribeNowBtn = () => {
    this.setState({ subscribeNowDialog: !this.state.subscribeNowDialog });
  };

  upgradeSubscription = (selectedPlan: any) => {
    setSelectedSubsId(selectedPlan)
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubsCheckout");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationSubsCheckoutMessage)
    );
    const prams = { path: "checkout", subNaveTab: "Checkout Plan", planId: selectedPlan };
    raiseMessage.addData(
      getName(MessageEnum.NavigationSubsCheckoutMessage),
      prams
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };
  getUserSubscription() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrentUserSubsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userSubscriptionEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllSubscriptionList() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubscriptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubscriptionType = (planType: string) => {
    if(planType?.includes("Basic")){
      return 'basic'
    }
    if(planType?.includes("Standard")){
      return "standard"
    }else{
      return "gold"
    }
  };
  handlePlanChecked = (plan: any) => {
    this.setState({upgradedPlan:plan})
  };
}
