Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "helpcentre";
exports.labelBodyText = "helpcentre Body";

exports.helpcentreAPIEndPoint = "/help_centre/question_type";
exports.httpGetType = "GET";

exports.btnExampleTitle = "CLICK ME";


exports.resolveYourissueText = "Complete the fields below. We will resolve your issue"
exports.within24hrsText = "in within 24 hours."

exports.fullNameTitle = "Full Name";
exports.reasonTitle = "Select Reason";
exports.messageTitle = "Enter a message...";

exports.reasonList = [
  { name: "I need help", value: "I need help" },
  { name: "I have a question", value: "I have a question" },
  { name: 'I have a problem',value: 'I have a problem' },
  { name: 'i have a suggestion',value: 'i have a suggestion' },
  { name: 'other',value: 'other' }
];

exports.minMaxFullNameLimitCheck = /^.{4,40}$/;
exports.minMaxMessageLimitCheck = /^.{4,200}$/;

exports.helpusApiEndPoint = "bx_block_settings/help_us";

// Customizable Area End
