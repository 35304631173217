import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  roundedTextField: {
    paddingBottom: "16px",
    "& input": {
      padding: "14px 18px",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "-internal-light-dark(rgb(255, 255, 255), rgba(70, 90, 126, 0.4)) !important",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    }
  },
}));

interface ITextFieldProps {
  value: string | number | undefined;
  label: string;
  name: string;
  error: boolean;
  maxLength?: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: React.FC<ITextFieldProps> = ({
  value,
  label,
  error,
  name,
  maxLength,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      className={classes.roundedTextField}
      inputProps={{ maxLength: maxLength }}
      InputProps={{ style: { borderRadius: '20px'}}}
      variant="outlined"
      value={value}
      error={error}
      onChange={handleChange}
      data-test-id={name}
    />
  );
};

export default CustomInput;
