import React from "react";

// Customizable Area Start
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Box,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./style.css";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomInput from "./CustomInputField.web";

const emaillIcon = require("./assets/images/outgoing_mail.png");
const phoneIcon = require("./assets/images/ring.png");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  title: string;
  type: string;
  value: string;
  name: string;
  isOpen: boolean;
  handleClose?: (e: any) => void;
  disabled?: boolean;
  handleChange?: (e: any) => void;
  handleSubmit?: (e: any) => void;
  // Customizable Area End
}

const popUptheme = createTheme({
  typography: {
    subtitle1: {
      lineHeight: "1.6rem",
      fontWeight: "bold",
      fontSize: "1.4rem",
    },
    subtitle2: {
      lineHeight: "20px",
      fontSize: "14px",
      maxWidth: "60%",
    },
    body1: {
      lineHeight: "20px",
      fontSize: "14px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  palette: {
    primary: {
      main: "#390879",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      contrastText: "#47008F",
    },
  },
});

export default class FormDialogBox extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isOpen,
      title,
      type,
      value,
      name,
      handleChange,
      handleSubmit,
      handleClose,
      disabled,
    } = this.props;

    return (
      <ThemeProvider theme={popUptheme}>
        <Dialog
          open={isOpen}
          PaperProps={{
            style: {
              width: "530px",
              height: "auto",
              borderRadius: "20px",
              overflowY: "visible",
            },
          }}
        >
          <IconButton
            data-test-id="close-btn"
            onClick={handleClose}
            style={{
              border: "1px rgb(255, 255, 255, 0.3) solid",
              padding: "5px",
              position: "absolute",
              top: "-40px",
              right: 0,
              borderRadius: "50%",
              backgroundColor: "rgb(0 0 0 / 55%)",
            }}
          >
            <CloseIcon style={{ color: "#fff" }} height={20} width={20} />
          </IconButton>
          <DialogTitle>
            <Box textAlign={"center"}>
              <Box>
                <img
                  src={type == "email" ? emaillIcon : phoneIcon}
                  height={"40px"}
                />
              </Box>
              <strong style={{ fontSize: "22px", fontWeight: 800 }}>
                {title}
              </strong>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomInput
              value={value}
              label={type}
              name={name}
              error={false}
              handleChange={(e: any) =>
                handleChange && handleChange(e.target.value)
              }
            />
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              id="resendBtn"
              style={{
                marginTop: "0px",
                width: "95%",
                height: "50px",
                borderRadius: "20px",
                marginBottom: "15px",
                textTransform: "none",
              }}
              variant="contained"
              color="primary"
              data-test-id="submit-otp"
              onClick={handleSubmit}
              disabled={disabled}
            >
              <strong style={{ fontSize: "14px", fontWeight: 800 }}>
                Get OTP
              </strong>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}
