import React from "react";
import {
  Button,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";
const signouticon = require("./assets/images/signoutIcon.png");
const deleteIcon = require("./assets/images/trash.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#0a0412",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "none",
  },
  dangerBtn: {
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "none",
    backgroundColor: "#f5d9de",
    color: "#ff375b",
    "& :hover": {
      backgroundColor: "#f5d9de",
      color: "#ff375b",
      borderRadius: "50px",
      border: "1px solid #390879",
    },
  },
  btnIcon: {
    height: "18px",
    margin: "0px 10px",
  },
}));

interface IButtonProps {
  label?: string;
  disabled?: boolean;
  isOutlined?: boolean;
  handleClick?: (e: any) => void;
  isDanger?: boolean;
  islogOutBtn?: boolean;
  isDeleteBtn?: boolean;
  notFullWidth?: boolean;
}

const CustomButton: React.FC<IButtonProps> = ({
  label,
  disabled,
  isOutlined,
  handleClick,
  isDanger,
  isDeleteBtn,
  islogOutBtn,
  notFullWidth,
}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Button
        fullWidth={notFullWidth ? false : true}
        variant={isOutlined ? "outlined" : "contained"}
        color="primary"
        className={!isDanger ? classes.button : classes.dangerBtn}
        disabled={disabled}
        onClick={handleClick}
        data-test-id="customBtn"
      >
        {isDeleteBtn && (
          <img src={deleteIcon} alt="" className={classes.btnIcon} />
        )}
        {label}
        {islogOutBtn && (
          <img src={signouticon} alt="" className={classes.btnIcon} />
        )}
      </Button>
    </ThemeProvider>
  );
};

export default CustomButton;
