import React from "react";

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import SavedShyftController, { Props } from "./SavedShyftController.web";
import { Box, Container, Grid } from "@material-ui/core";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import ShyftCard from "../../../components/src/ShyftCard";
import { ProfileImg } from "./assets";
import Footer from "../../../components/src/Footer.web";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";

const ShyfList2 = [
  {
    id: 4,
    employername: "Martin",
    county: "America",
    userImg: ProfileImg,
    syftTimimg: "12:00 PM- 09:00 PM",
    billing: "$120 Per Hr",
    day: "Yesterday",
    shyft: "Cook",
    shyftPlace: "Hotel",
  },
  {
    id: 2,
    employername: "Martin",
    county: "America3",
    userImg: ProfileImg,
    syftTimimg: "12:00 PM- 09:00 PM",
    billing: "$150 Per Hr",
    day: "Yesterday",
    shyft: "Cook",
    shyftPlace: "Hotel3",
  },
];
export default class ShyftDetailsPage extends SavedShyftController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <LandingPageHeader {...this.props} />
        <Box width="100%" marginTop="5%">
          <Container maxWidth="md">
            <SearchWithFilterandLocation showFilterText={true} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  {ShyfList2.map((item) => {
                    return <ShyftCard {...item} key={item.id} />;
                  })}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box margin="15px 0px">
                  <ShyfterDetailsCard />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer/>
      </>
    );
  }
}
