import React from "react";
import { DownloadDocsCard } from "../../../components/src/DownloadDocCard";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
interface Props {
  officialDocs: any;
  formLabel?: string;
  certificateLabel?: string;
  isBusinessProfile?: boolean;
}

export class UserOfficialDocs extends React.Component<Props> {
  render() {
    return (
      <Box>
        <Grid container spacing={2}>
          {!this.props.isBusinessProfile && (
            <>
             <Grid item xs={12} md={6}>
            <DownloadDocsCard
              lable={"Certificate"}
              docs={this.props?.officialDocs?.certificate}
            />
          </Grid>
              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Form | I-9"}
                  docs={this.props?.officialDocs?.form_i_9_url}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Form | W-4"}
                  docs={this.props?.officialDocs?.form_w_4_url}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Form | IT-2104"}
                  docs={this.props?.officialDocs?.form_it_2104_url}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Servsafe Tips"}
                  docs={this.props?.officialDocs?.servsafe_tips_url}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable="Background Check Authorization"
                  docs={
                    this.props?.officialDocs?.background_check_authorization_url
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"First Aid/CPR"}
                  docs={this.props?.officialDocs?.first_aid_cpr_url}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6}>
            <DownloadDocsCard
              lable={"Form"}
              docs={this.props?.officialDocs?.form}
            />
          </Grid>

          {!this.props.isBusinessProfile && (
            <>
              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Motot Vehicle"}
                  docs={this.props?.officialDocs?.motor_vehicle_check_url}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Sexual Harassment Training"}
                  docs={this.props?.officialDocs?.sexual_harassment_training_url}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  }
}
