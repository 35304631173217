import React from "react";
import {
  Box,
  Container,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CloseIcon from "@material-ui/icons/Close";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import { accept, checkCircleBlue, creditCardBlue, paymentIcon } from "./assets";
import SubsCheckoutController from "./SubsCheckoutController.web";
import moment from "moment";
import CustomButton from "../../../components/src/CustomButton.web";
import {
  breadCrumSubsPath,
  handleCondition,
} from "../../../components/src/Utilities";
import SubscribeNowModal from "./SubscribeNowModal.web";
import { SubscriptionCard } from "./SubscriptionCard.web";

export class SubsCheckout extends SubsCheckoutController {
  render() {
    const { classes }: any = this.props;
    const { selectedSubs, upgradedPlan, selectedSubId, cardsData } = this.state;
    const d1 = this.handleDatavalidation();
    const d2 = this.handlevalidation();
    return (
      <div>
        <LandingPageHeader
          {...this.props}
          pageTitle="Proceed to Pay"
          breadCrumsData={breadCrumSubsPath}
          isBreadcrumbs={true}
          currentPage={!upgradedPlan ? "Checkout Plan" : "Subscription Plans"}
        />
        <Box
          style={{ height:"auto", backgroundColor: "#f7faff", minHeight: "90vh" }}
        >
          <Box className={classes?.mainContentWrap} marginTop={"8%"}>
            <Container>
              <Box className={classes?.planWrapper}>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <Box className={classes?.plansContetn}>
                      <Box className={classes?.cardLabel}>
                        
                        {!upgradedPlan ? (
                          <>
                            <p>Selected Plan</p>
                            <div>
                              <span
                                onClick={this.handleSubscribeNowBtn}
                                data-test-id="editplan"
                              >
                                Edit plan <EditIcon />
                              </span>
                            </div>
                          </>
                          ) : (
                            <p>Current Plan</p>
                          )
                        }
                      </Box>

                      <SubscriptionCard
                        title={selectedSubs?.name}
                        month={selectedSubs?.duration}
                        price={selectedSubs?.price}
                        type={this.getSubsType(
                          !!selectedSubId ? selectedSubId : selectedSubs?.name
                        )}
                        benefits={selectedSubs?.benefits} isChecked={true} id={""}                      
                          // validtill={selectedSubs?.valid_up_to}
                        isCrownLogo={true}
                        isFromCheckout={true}
                      />
                    </Box>

                    <Divider variant="middle" />
                    {upgradedPlan && (
                      <Box>
                        <Box className={classes?.cardLabel}>
                          <p>Upgrade Plan</p>

                          <div>
                            <span
                              onClick={this.handleEditUpgradeModal}
                              data-test-id="editUpgradplan"
                            >
                              Edit plan <EditIcon />
                            </span>
                          </div>
                        </Box>

                        <Box>
                          <SubscriptionCard
                            title={upgradedPlan?.name}
                            month={upgradedPlan?.duration}
                            price={upgradedPlan?.price}
                            type={this.getSubsType(upgradedPlan?.name)}
                            benefits={upgradedPlan?.benefits} isChecked={true} id={""}
                            isCrownLogo={true}
                            isFromCheckout={true}
                          />
                        </Box>
                      </Box>
                    )}

                    <Box className={classes?.paymentMethodCard}>
                      <Box className={classes?.cardLabel}>
                        <p>Payment Method</p>
                      </Box>
                      <Box
                        className={`${classes?.selectedPlanCard} ${classes?.cardWrapper}`}
                      >
                        <Box className={classes?.planInfoWrapper}>
                          <Grid container className={classes?.cardsWrapper}>
                            {cardsData.length > 0 ? (
                              cardsData.slice(0,1).map((card: any) => (
                                <Grid item xs={12} sm={6} key={card.cardNumber}>
                                  <Box className={classes?.creditCardInfo}>
                                    <Box
                                      className={`${classes?.creditCardHeader} ${classes?.flexBox}`}
                                    >
                                      <Box
                                        className={
                                          classes?.creditCardHeaderData
                                        }
                                      >
                                        <img src={creditCardBlue} alt="" />
                                        <h5>Credit Card</h5>
                                      </Box>
                                      <Box>
                                        <img
                                          style={{ width: "20px" }}
                                          src={checkCircleBlue}
                                          alt=""
                                        />
                                      </Box>
                                    </Box>
                                    <Divider
                                      variant="middle"
                                      style={{ margin: "10px 0px" }}
                                    />
                                    <Box
                                      className={`${classes?.creditCardDetailWrapper} ${classes?.flexBox}`}
                                    >
                                      <Box
                                        className={classes?.creditCardDetail}
                                      >
                                        <img src={paymentIcon} alt="" />
                                        <Box>
                                          <p>{card.name}</p>
                                          <h5>
                                            xxxx xxxx xxxx {card.cardNumber
                                              .toString()
                                              .slice(12)
                                              .replace(/(\d{4})/g, "$1 ")}
                                          </h5>
                                        </Box>
                                      </Box>
                                      <Box onClick={this.handleAddCardDialog} data-test-id="manageCard">
                                        <a>Manage Card</a>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              ))
                            ) : (
                              <Grid item xs={12} sm={6}>
                                <Box
                                  data-test-id="addToCartButton"
                                  className={classes?.card}
                                  onClick={this.handleAddCardDialog}
                                >
                                  <Box className={classes?.cardInfo}>
                                    <img src={creditCardBlue} alt="" />
                                    <span>Credit Card</span>
                                  </Box>
                                  <Box className={classes?.cardCircle} />
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box className={classes?.priceSummaryContetn}>
                      <Box className={classes?.paymentMethodCard}>
                        <Box className={classes?.cardLabel}>
                          <p>Price Summary</p>
                        </Box>
                        <Box
                          className={`${classes?.selectedPlanCard} ${classes?.cardWrapper}`}
                        >
                          <Box
                            className={`${classes?.flexBox} ${classes?.priceCardLabel} ${this.getSubsType(upgradedPlan?.name)}`}
                          >
                            <p>Plan Type</p>
                            <span>
                              {handleCondition(
                                !!upgradedPlan,
                                upgradedPlan.name,
                                selectedSubs.name
                              )?.split(" ")[0]}
                            </span>
                          </Box>
                          <Box
                            className={`${classes?.flexBox} ${classes?.priceCardLabel}`}
                          >
                            <p>
                              Plan Duration <ArrowDropDownIcon />
                            </p>
                            <h5>
                              {handleCondition(
                                !!upgradedPlan,
                                upgradedPlan.duration,
                                selectedSubs.duration
                              )}{" "}
                              Days
                            </h5>
                          </Box>
                          <Box className={classes?.durationWrapper}>
                            <Box className={classes?.startData}>
                              <p>Start Date</p>
                              <h5>{moment().format("D MMM, YYYY")}</h5>
                            </Box>
                            <Box
                              className={`${classes?.benefitsPlanLabel} ${classes?.durationWatch}`}
                            >
                              <span>
                                <AccessTimeIcon />
                              </span>
                            </Box>
                            <Box className={classes?.endData}>
                              <p>End Date</p>
                              <h5>
                                {moment().add(1, "month").format("D MMM, YYYY")}
                              </h5>
                            </Box>
                          </Box>
                          <Box
                            className={`${classes?.flexBox} ${classes?.priceCardLabel}`}
                            style={{ marginBottom: "10px" }}
                          >
                            <p>Plan Price</p>
                            <h5>
                              $
                              {handleCondition(
                                !!upgradedPlan,
                                upgradedPlan.price,
                                selectedSubs.price
                              )}
                            </h5>
                          </Box>
                          <Divider variant="middle" style={{ margin: "0px" }} />

                          <Box className={classes?.finalAmount}>
                            <h3>
                              Final Amount | $
                              {handleCondition(
                                !!upgradedPlan,
                                upgradedPlan.price,
                                selectedSubs.price
                              )}
                            </h3>
                          </Box>
                        </Box>
                      </Box>
                      <p>Payment by Credit Card</p>
                      <Box className={classes?.payBtn}>
                        <CustomButton
                          data-test-id="proceedToPay"
                          disabled={cardsData?.length <= 0}
                          label={`Pay $ ${handleCondition(
                            !!upgradedPlan,
                            upgradedPlan.price,
                            selectedSubs.price
                          )}`}
                          handleClick={
                            !!upgradedPlan
                              ? () => this.upgradePlan(upgradedPlan)
                              : () => this.buyPlan(selectedSubs)
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>

        {/* add credit card dialog */}
        {this.state.addCardDialog && (
          <Box className={classes?.addCreditCardWrapper}>
            <Dialog
              data-test-id="addCardModal"
              open={this.state.addCardDialog}
              maxWidth="xs"
              scroll="body"
              fullWidth
              className={classes?.subscriptionDialogWrapper}
            >
              <IconButton className="close-icon">
                <CloseIcon
                  height={20}
                  width={20}
                  onClick={this.handleAddCardDialog}
                />
              </IconButton>
              <div className="subscribe-now-dialog-content">
                <div className="subscribe-now-title">Add Card</div>

                <div className={classes?.addCardForm}>
                  <div className={classes?.inputFieldWrapper}>
                    <TextField
                      data-test-id="cardNumber"
                      error={this.state.cardNumbererror}
                      id="outlined-error-helper-text"
                      label="Card Number"
                      helperText={this.state.errorMsg}
                      variant="outlined"
                      value={this.state.cardNumber}
                      inputProps={{
                        maxLength: 16,
                      }}
                      onChange={(e) => this.handleCardNumber(e)}
                    />
                  </div>
                  <div className={classes?.inputFieldWrapper}>
                    <TextField
                      data-test-id="cardName"
                      error={this.state.cardNamererror}
                      id="outlined-error-helper-text"
                      label="Name on Card"
                      helperText={this.state.nameErrorMsg}
                      variant="outlined"
                      value={this.state.cardHolder}
                      inputProps={{
                        maxLength: 30,
                      }}
                      onChange={(e) => this.handleCardName(e)}
                    />
                  </div>

                  <Grid container className={classes?.durationGrid}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={classes?.addCardDurationAlignment}
                    >
                      <Grid
                        container
                        className={`${classes?.flexBox} ${classes?.durationWrap}`}
                      >
                        <Grid item xs={12} sm={6}>
                          <div className={classes?.inputWrapper}>
                            <FormControl
                              className={classes?.formControl}
                              size="small"
                            >
                              <InputLabel id="demo-controlled-open-select-label">
                                MM
                              </InputLabel>
                              <Select
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={this.state.monthSelect}
                                value={this.state.month}
                                onClose={this.handleMonthOpen}
                                onOpen={this.handleMonthOpen}
                                onChange={(e) => this.handleChangeMonth(e)}
                                className={classes?.monthSelectCss}
                                data-test-id="selectMoth"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {this.state.months?.map((month: any) => {
                                  return (
                                    <MenuItem value={month} key={month}>
                                      {month}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </Grid>
                        <Divider
                          variant="middle"
                          orientation="vertical"
                          style={{ height: "25px", margin: "0px" }}
                        />
                        <Grid item xs={12} sm={5}>
                          <div className={classes?.inputWrapper}>
                            <FormControl
                              className={classes?.formControl}
                              size="small"
                            >
                              <InputLabel id="demo-controlled-open-select-label">
                                YY
                              </InputLabel>
                              <Select
                                data-test-id="selectYear"
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                onClose={this.handleYearClose}
                                onOpen={this.handleYearOpen}
                                open={this.state.yearSelect}
                                value={this.state.year}
                                onChange={(e) => this.handleChangeYear(e)}
                                className={classes?.monthSelectCss}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {this.state.years?.map((year: any) => {
                                  return (
                                    <MenuItem value={year} key={year}>
                                      {year}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <div className={classes?.inputFieldWrapper}>
                        <TextField
                          data-test-id="cardCvv"
                          id="outlined-basic"
                          label="CVV"
                          variant="outlined"
                          value={this.state.cvv}
                          size="small"
                          error={this.state.cardCvverror}
                          inputProps={{ maxLength: 3 }}
                          onChange={(e) => this.handleChangeCVV(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="subscribe-now-action-container">
                  <div className={classes?.proceedBtn}>
                    <CustomButton
                      disabled={d1 || d2}
                      data-test-id="addCardProceedBtn"
                      label={"Proceed"}
                      handleClick={() => this.addCardProceed()}
                    />
                  </div>
                </div>
              </div>
            </Dialog>
          </Box>
        )}

        {/* Transition success dialog */}
        {this.state.transitionSuccessDialog && (
          <Box className={classes?.transitionSuccessWrapper}>
            <Dialog
              data-test-id="transactionPopUp"
              open={this.state.transitionSuccessDialog}
              maxWidth="xs"
              scroll="body"
              fullWidth
              className={classes?.subscriptionDialogWrapper}
            >
              <IconButton
                className="close-icon"
                data-test-id="closeTransactionPopUp"
                onClick={this.handleTransitionSuccessDialog}
              >
                <CloseIcon height={20} width={20} />
              </IconButton>
              <div className="subscribe-now-dialog-content">
                <div className={classes?.textCenter}>
                  <img src={accept} alt="" />
                </div>

                <div className="subscribe-now-title transition-title">
                  Transaction Success
                </div>

                <div
                  className={`${classes?.textCenter} ${classes?.tansitionPara}`}
                >
                  <p>
                    {`Your subscription ${selectedSubs.duration} Days is successfully activated in your
                  account. Now you can explore the services of Snagashyft.`}
                  </p>
                </div>
                <div
                  className="subscribe-now-action-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Box width={"45%"}>
                    <CustomButton
                      label={"Upgrade Plan"}
                      isOutlined={true}
                      data-test-id="upgradePlanBTN"
                      handleClick={() => this.upgradeSubscription()}
                    />
                  </Box>
                  <Box width={"45%"}>
                    <CustomButton
                      label={"Back to Home"}
                      data-test-id="backToHome"
                      handleClick={() => this.backToHome()}
                    />
                  </Box>
                </div>
              </div>
            </Dialog>
          </Box>
        )}
        {this.state.upgradeModal && (
          <Box>
            <SubscribeNowModal
              title="Upgrade To"
              data-test-id="upgradePlanModal"
              isOpen={this.state.upgradeModal}
              handleClose={this.handleEditUpgradeModal}
              handleSave={this.handleChangeSubscription}
              upgradedPlanId={
                !!selectedSubId ? selectedSubId : selectedSubs?.name
              }
              gridColumnWidth={6}
              lable={"Upgrade To"}
              allSubscriptionsList={this.state.allSubscriptions}
            />
          </Box>
        )}
        {this.state.subscribeNowDialog && (
          <Box>
            <SubscribeNowModal
              isOpen={this.state.subscribeNowDialog}
              handleClose={this.handleSubscribeNowBtn}
              handleSave={this.handleChangeSubscription}
              selectedplanId={selectedSubs.name}
              allSubscriptionsList={this.state.allSubscriptions}
            />
          </Box>
        )}
        <Footer />
      </div>
    );
  }
}

const useStyles: any = () => ({
  mainWrapper: {},
  textCenter: {
    textAlign: "center",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bar: {
    backgroundColor: "#fff",
    boxShadow: "0 4px 17px 0 rgba(10,4,18,0.04)",
    marginTop: "76px",
  },
  barContentWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  barLeftContent: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "& h2": {
      display: "inline-block",
    },
  },
  iconWrap: {
    width: "35px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E1E6EC",
    borderRadius: "23px",
    color: "#000000",
    cursor: "pointer",
    "& svg": {
      color: "#000000",
    },
  },
  barRightContent: {
    "& ul": {
      padding: "0px",
      margin: "0px",
      display: "flex",
      alignItems: "center",
      "& li": {
        listStyleType: "none",
        margin: "0px 6px",
        "& a": {
          fontSize: "13px",
          color: "#6F7C8E",
          lineHeight: "16px",
          fontWeigth: 600,
          textDecoration: "none",
        },
      },
      "& svg": {
        fontSize: "13px",
        color: "#6F7C8E",
        fontWeigth: 600,
      },
    },
  },

  mainContentWrap: {},
  planWrapper: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
    "&>div": {
      "&>div": {
        padding: "0px 8px",
      },
    },
  },
  plansContetn: {
    "& p": {
      fontSize: "15px",
      color: "#0A0412",
      lineHeight: "19px",
    },
  },
  cardLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& span": {
      fontSize: "14px",
      color: "#390879",
      letterSpacing: "-0.25px",
      lineHeight: "18px",
      fontWeight: 600,
      display: "inline-flex",
      textDecoration: "underline",
      cursor: "pointer",
      "& svg": {
        marginLeft: "4px",
        fontSize: "18px",
      },
    },
  },
  selectedPlanCard: {
    background: "#FFFFFF",
    border: "1px solid #2675FF",
    boxShadow: "0 4px 14px 0 rgba(57,8,121,0.00)",
    borderRadius: "14px",
    padding: "15px",
  },
  planInfo: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "12px",
      color: "#0A0412",
      letterSpacing: "-0.21px",
      fontWeight: 600,
    },
    "& h2": {
      fontSize: "20px",
      color: "#2675FF",
      letterSpacing: "-0.36px",
      lineHeight: "18px",
      margin: "0px",
    },
  },
  benefitsPlanWrapper: {
    "& ul": {
      padding: "0px",
      paddingTop: "15px",
      margin: "0px",
      "& li": {
        listStyleType: "none",
        margin: "0px 6px",
        fontSize: "12px",
        color: "#0A0412",
        letterSpacing: "-0.21px",
        textAlign: "left",
        paddingTop: "5px",
      },
      "& svg": {
        fontSize: "13px",
        color: "#6F7C8E",
        fontWeigth: 600,
      },
    },
  },
  planInfoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& button": {
      backgroundImage:
        "linear-gradient(250deg, #2289FF 0%, rgba(39,93,255,0.99) 100%)",
      borderRadius: "15px",
      fontSize: "12px",
      color: "#FFFFFF",
      letterSpacing: "-0.21px",
      lineHeight: "18px",
      textTransform: "capitalize",
    },
  },
  benefitsPlanLabel: {
    display: "block",
    position: "relative",
    textAlign: "center",
    "&::before": {
      content: '""',
      border: "0.5px solid #E1E6EC",
      display: "block",
      top: "50%",
      position: "absolute",
      left: "0",
      right: "0",
    },
    "& span": {
      backgroundColor: "#fff",
      position: "relative",
      zIndex: "11",
      padding: "0px 15px",
      fontSize: "12px",
      color: "#0A0412",
      letterSpacing: "-0.21px",
      lineHeight: "18px",
      fontWeigth: 600,
    },
  },

  cardWrapper: {
    border: "1px solid #E1E6EC",
  },
  card: {
    border: "1px solid #E1E6EC",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "29px",
    padding: "15px",
    width: "340px",
    maxWidth: "340px",
    cursor: "pointer",
    "@media(max-width: 1300px)": {
      width: "auto",
    },
    "& span": {
      padding: "0px",
    },
  },
  cardInfo: {
    display: "flex",
    alignItems: "center",
    "& span": {
      paddingLeft: "6px",
    },
  },
  cardCircle: {
    width: "20px",
    height: "20px",
    border: "1px solid #E1E6EC",
    borderRadius: "50%",
  },
  priceCardLabel: {
    "& p": {
      fontSize: "14px",
      color: "#6F7C8E",
      lineHeight: "14px",
      margin: "4px 0px",
      "& svg": {
        verticalAlign: "middle",
      },
    },
    "& span": {
      fontSize: "14px",
      color: "#2C85FF",
      lineHeight: "14px",
    },
    "& h5": {
      fontSize: "14px",
      color: "#0A0412",
      lineHeight: "14px",
      margin: "0px",
    },
    "&.basic > span": {
      color: "#2675ff",
    },
    "&.standard > span": {
        color: "#cd22ff",
    },
    "&.gold > span": {
        color: "#FFCC00",
    },
  },
  durationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#e1e6ec4d",
    padding: "10px",
    marginBottom: "8px",
    "& p": {
      fontSize: "10px",
      color: "#6F7C8E",
      lineHeight: "14px",
      margin: "0px",
      textAlign: "center",
    },
    "& h5": {
      fontSize: "12px",
      color: "#0A0412",
      lineHeight: "14px",
      margin: "0px",
    },
  },
  durationWatch: {
    flexGrow: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 18px",
    "&::before": {
      content: '""',
      border: "1px dashed #6F7C8E",
      transform: "translate(0px, -50%)",
    },
    "& span": {
      backgroundColor: "#f6f7f9",
      padding: "0px 4px",
      color: "#6F7C8E",
    },
  },
  finalAmount: {
    "& h3": {
      fontSize: "16px",
      color: "#390879",
      textAlign: "center",
      marginBottom: "0px",
    },
  },
  priceSummaryContetn: {
    "&>p": {
      fontSize: "12px",
      color: "#6F7C8E",
      textAlign: "center",
      fontWeight: 600,
    },
  },
  payBtn: {
    "& button": {
      width: "100%",
    },
  },

  subscriptionDialogWrapper: {
    " & .MuiDialog-paperWidthMd": {
      maxWidth: 900,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 20,
    },
    " & .close-icon": {
      border: "1px solid rgba(255, 255, 255, 0.3)",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0",
      borderRadius: "50%",
      backgroundColor: "rgba(0, 0, 0, 0.55)",
      color: "#fff",
    },
    " & .subscribe-now-dialog-content": {
      padding: "10px",
    },
    " & .subscribe-now-title": {
      fontSize: "22px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "5px 0px 30px",
      lineHeight: "26px",
      color: "#0a0412",
      fontWeight: "bold",
      textAlign: "center",
    },
    "& .subscribe-now-action-container": {
      textAlign: "center",
    },
    "& .transition-title": {
      paddingBottom: "0px",
    },
  },
  inputWrapper: {
    textAlign: "center",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      border: "1px solid #E1E6EC",
      borderRadius: "25.5px",
      margin: "8px 0px",
    },

    "& label": {
      marginTop: "8px",
      fontSize: "13px",
      color: "#6F7C8E",
      letterSpacing: "-0.23px",
      lineHeight: "18px",
    },
  },
  inputFieldWrapper: {
    textAlign: "center",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      // border: "1px solid #E1E6EC",
      borderRadius: "25.5px",
      margin: "8px 0px",
    },
    "& label": {
      marginTop: "8px",
      fontSize: "13px",
      color: "#6F7C8E",
      letterSpacing: "-0.23px",
      lineHeight: "18px",
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    '& .MuiOutlinedInput-multiline': {
      padding: "5px 14px",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    }
  },
  tansitionPara: {
    fontSize: "12px",
    color: "#6F7C8E",
    letterSpacing: "-0.23px",
    textAlign: "center",
    lineHeight: "18px",
    margin: "0px",
  },
  transitionSuccessWrapper: {
    "& .subscribe-now-title": {
      paddingBottom: "0px",
    },
  },
  upgradePlanBtn: {
    "& button": {
      backgroundColor: "#ffff !important",
      border: "1px solid #390879 !important",
      color: "#390879 !important",
    },
  },
  selectWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  monthSelectCss: {
    "& li": {
      display: "block",
      textAlign: "center",
    },
  },
  formControl: {
    height: "40px",
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiInput-formControl:hover": {
      border: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& label": {
      marginTop: "0px",
      top: "-8px",
      left: "10px",
    },
    "& label + .MuiInput-formControl": {
      marginTop: "6px",
      marginRight: "10px",
    },
  },
  durationWrap: {
    border: "1px solid #E1E6EC",
    borderRadius: "25.5px",
  },
  addCardDurationAlignment: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  durationGrid: {
    gap: "10px",
    justifyContent: "space-around",
  },
  proceedBtn: {
    "& button": {
      width: "200px",
      marginTop: "15px",
    },
  },
  creditCardInfo: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #390879",
    boxShadow: "0 4px 14px 0 rgba(57,8,121,0.09)",
    borderRadius: "14px",
    padding: "15px",
  },
  creditCardHeaderData: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    "& h5": {
      fontSize: "14px",
      color: "#0A0412",
      letterSpacing: "-0.25px",
      lineHeight: "18px",
      margin: "0px",
    },
  },
  cardsWrapper: {
    "&>div": {
      padding: "0px 8px",
    },
  },
  creditCardDetailWrapper: {
    "& a": {
      fontSize: "12px",
      color: "#390879",
      letterSpacing: "-0.21px",
      textAlign: "right",
      lineHeight: "18px",
    },
  },
  creditCardDetail: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    "& p": {
      margin: "0px",
      textAlign: "left",
      fontSize: "12px",
      color: "#6F7C8E",
      letterSpacing: "-0.21px",
      lineHeight: "18px",
    },
    "& h5": {
      margin: "0px",
      textAlign: "left",
      fontSize: "14px",
      color: "#0A0412",
      letterSpacing: "-0.25px",
      lineHeight: "18px",
    },
  },
  pointerImgStyle: {
    marginRight: "5px",
    marginBottom: "-2px",
  },
});

export default withStyles(useStyles)(SubsCheckout);
