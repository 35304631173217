import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isActionNeeded: boolean;
  selectedItems: string[];
  deleteModalOpen: boolean;
  renderChatId: number
}

interface SS {}

export default class ActiveMessagesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getActiveMessagesApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isActionNeeded: false,
      selectedItems: [],
      deleteModalOpen: false,
      renderChatId: -1
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleAction = () => {
    this.setState({
      isActionNeeded: !this.state.isActionNeeded,
      selectedItems: []
    });
  };

  selectedItem = (event: any, chatId: string) => {
    const isEventActive = event.target.checked;
    
    isEventActive &&
      this.setState({
        selectedItems: [...this.state.selectedItems, chatId],
      });

    !isEventActive &&
      this.setState((prevState) => ({
        selectedItems: prevState?.selectedItems?.filter(
          (item) => item !== chatId
        ),
      }));
  };

  handleCloseAction = () => {
    this.setState({
      isActionNeeded: false,
      selectedItems: []
    });
  };

  handleDeleteModal = () => {
    this.setState({
      deleteModalOpen: true,
    });
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteModalOpen: false,
    });
  }

  handleRenderChat = (chatId: number) => {
    this.setState({
      renderChatId: Number(chatId)
    })
  }
}

