Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.noShyftersFound = "No Shyfters Found!";
exports.noShyftsFound = "No Shyfts Found!";
exports.noShyftersFoundDescriptionForEmployer = "You've not assgined any jobs to shyfters yet, Add new shyfts or accepts shyfter's request to start messaging";
exports.noShyftersFoundDescriptionForShyfter = "You've not assgined to shyfts yet, Explore new shyfts to chat someone";
exports.startMessaging = "Start Messaging";
exports.startMessagingDescriptionForEmployer = "Please add chat to start messaging with the shyfter.";
exports.startMessagingDescriptionForShyfter = "Please add chat to start messaging with the business.";
exports.addShyfts = "+ Add Shyfts";
exports.exploreJobs = "Explore Jobs";
exports.addChat = "+ Add Chat";
exports.addChat = "+ Add Chat";
exports.searchPlaceholderForEmployer = "Search for person...";
exports.searchPlaceholderForShyfter = "Search for business...";
exports.shyftsCountApiEndPoint = "bx_block_events/shift_count";
exports.myShyftsApiEndPoint = "bx_block_events/shyfter_shifts?page=1&per_page=10&sort_column=created_at&sort_order=desc";
exports.addShyftApiEndPoint = "bx_block_events/shifts";
exports.shyftAddedTitle = "Shyft Added Successfully";
exports.shyftAddedDescription =
  "This shyft has been added successfully. Please review and either accept or deny active requests.";  
// Customizable Area End