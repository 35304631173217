import React from "react";
import { Box, Button, Grid} from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import SkillList from "../../../components/src/SkillList.web";
import MultiValueInput from "../../../components/src/MultiValueInput.web";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import SkillSetDialog from "../../../components/src/SkillSetDialog.web";
interface Props {
  user: any;
  userFormError: any;
  configJSON: any;
  handleShyfterInputChange: any;
  setMaxDate: any;
  deleteSkillItem: any;
  handleLanguageChange: any;
  toggleSkillSetDialog: any;
  moveToNext: any;
  moveBack: any;
  setSkillSetList:any;
  setSelectedSkills:any;
  openSkillSetDialog:any;
  saveSkillData:any;
}
export const UpdateProfessionalDeatils = (props: Props) => {
  const {
    moveToNext,
    moveBack,
    user,
    userFormError,
    configJSON,
    handleShyfterInputChange,
    deleteSkillItem,
    handleLanguageChange,
    toggleSkillSetDialog,
    setSelectedSkills,
    setSkillSetList,
    openSkillSetDialog,
    saveSkillData
  } = props;

  return (
    <Box
      padding={"10px"}
      marginY={"10px"}
      bgcolor={"#fff"}
      borderRadius={"10px"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomSelect
            value={3}
            handleChange={(e) => handleShyfterInputChange(e, "experience")}
            label={configJSON.yourExperience}
            name="experience"
            error={!!userFormError.experience}
            options={configJSON.experienceList}
          />

          <SkillList
            type="restaurant"
            title="Restaurant"
            items={user.restaurant_skills}
            deleteItem={(skill) => deleteSkillItem("restaurant", skill)}
            openSkillDialog={(type) => toggleSkillSetDialog(type)}
          />
            {openSkillSetDialog && (
                <SkillSetDialog
                  open={openSkillSetDialog}
                  onCloseDialog={() => toggleSkillSetDialog("")}
                  saveSkillData={saveSkillData}
                  type={openSkillSetDialog}
                  items={setSkillSetList(openSkillSetDialog)}
                  selectedItems={setSelectedSkills()}
                />
              )}
        </Grid>
        <Grid item xs={12} md={6}>
          <MultiValueInput
            values={user.language_skill}
            handleChange={handleLanguageChange}
            name="languageSkills"
            label={configJSON.languageSkills}
            error={!!userFormError.languageSkills}
          />
          <SkillList
            type="hotel"
            title="Hotel"
            items={user.hotel_skills}
            deleteItem={(skill) => deleteSkillItem("hotel", skill)}
            openSkillDialog={(type) => toggleSkillSetDialog(type)}
          />
        </Grid>
      </Grid>
      <Box
        marginY={"10"}
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Button
          variant="contained"
          style={{ borderRadius: "50px",padding:"11px 20px" }}
          onClick={moveBack}
          data-test-id="moveBack"
        >
          <ArrowBackIos style={{height:"14px"}}/> Back
        </Button>
        
        <Box padding={"10px"} width={"15%"}>
          <CustomButton
            label={"Next"}
            isOutlined={false}
            handleClick={moveToNext}
          />
        </Box>
      </Box>
    </Box>
  );
};
