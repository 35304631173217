import React from "react";
import {
  Box,
  IconButton,
  Dialog,
  Typography,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import FeedbackModalController, { Props } from "./FeedbackModalController.web";
import CustomButton from "../../../components/src/CustomButton.web";

const styles = {
  closeIcon: {
    position: "absolute",
    border: "1px rgb(255, 255, 255, 0.3) solid",
    padding: "5px",
    top: "-40px",
    right: "0px",
    borderRadius: "50%",
    backgroundColor: "rgb(0 0 0 / 55%)",
    color: "#fff"
  },
  modalTitle: {
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: "12px",
    paddingTop: "12px",
    color: "#0a0412"
  },
  modalDescription: {
    fontSize: "14px",
    color: "#6f7c8e",
    textAlign: "center",
    width: "65%"
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    marginTop: "12px"
  }
} as const;

export class FeedbackModal extends FeedbackModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isOpen,
      modalTitle,
      onClose,
      imageSrc,
      secondaryButtonText,
      primaryButtonText,
      primaryButtonOnClick,
      secondaryButtonOnClick,
      modalDescription,
      classes
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        classes={{ paper: classes.root }}
      >
        <IconButton style={styles.closeIcon} onClick={onClose}>
          <CloseIcon width={20} height={20} />
        </IconButton>

        <Box display="flex" justifyContent="center">
          <img src={imageSrc} alt="" width={64} />
        </Box>
        <Box
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          display="flex"
        >
          <Typography style={styles.modalTitle}>{modalTitle}</Typography>
          <Typography style={styles.modalDescription}>
            {modalDescription}
          </Typography>
        </Box>
        <Box style={styles.buttons}>
          {primaryButtonText && (
            <CustomButton
              isOutlined
              label={primaryButtonText}
              handleClick={primaryButtonOnClick}
            />
          )}
          {secondaryButtonText && (
            <CustomButton
              label={secondaryButtonText}
              handleClick={secondaryButtonOnClick}
            />
          )}
        </Box>
      </Dialog>
    );
  }
}

export default withStyles({
  root: {
    overflowY: "visible",
    padding: 30,
    borderRadius: 20
  }
})(FeedbackModal);
