import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import Footer from "../../../components/src/Footer.web";
import PastShyftActivity from "../../../components/src/PastShyftActivity.web";
import { RightArrowIcon, timmerBlank, FailedIcon } from "./assets";
import ShyftSummary from "../../../components/src/ShyftSummary";
import TimerWithCircularProgressBar from "../../../components/src/TimerWithCircularProgressBar";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import MyShyftsController, {
  Props,
  TActivityRequest,
} from "./MyShyftsController.web";
import {
  convertDateIntoDayDateMonthYearFormat,
  convertSecondsToHoursMinutesFormat,
} from "../../../components/src/Utilities";
import CommonLandingPage from "../../../components/src/CommonLandingPage.web";
import moment from "moment";

const isShyftEnded = (request?: TActivityRequest) => {
  if (request?.removal_reason) {
    return false;
  }

  return true;
};

export default class MyShyftsPage extends MyShyftsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      myShyftsList,
      selectedMyShyft,
      summary,
      activityRecords,
      request,
      currentHoursWorked,
      totalShiftTime,
      shyftPunchInTime,
      shyftPunchOutTime,
      nextShyftHoursLeft,
      isShyftActivity,
    } = this.state;

    const currentShyft = myShyftsList.find(
      (shyft) => shyft.id === selectedMyShyft
    );

    const isOngoingShyft = currentShyft?.isOngoing === true;

    const renderPunchingUI = () => {
      if (isOngoingShyft) {
        return (
          <Box
            style={{
              borderTop: "1px solid #e1e6ec",
              width: "100%",
              padding: "10px 0",
              backgroundColor: "#fff",
            }}
            data-test-id="punch-out"
            onClick={this.punchOutFromTheShyft}
          >
            <Box
              borderRadius="50px"
              margin="10px auto"
              maxWidth={"90%"}
              border="1px solid #390879"
              display={"flex"}
              padding={"5px"}
            >
              <Box
                height={"40px"}
                width={"40px"}
                bgcolor={"#390879"}
                borderRadius={"50px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <DoubleArrowIcon />
              </Box>

              <Box>
                <Typography
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Punch Out
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    paddingLeft: "5px",
                  }}
                >
                  Swipe left to punch out from the shyft...
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      }

      if (currentShyft && nextShyftHoursLeft[currentShyft.id] < 600) {
        return (
          <Box
            style={{
              borderTop: "1px solid #e1e6ec",
              width: "100%",
              padding: "10px 0",
              backgroundColor: "#fff",
            }}
            data-test-id="punch-in"
            onClick={this.punchInToTheShyft}
          >
            <Box
              borderRadius="50px"
              margin="10px auto"
              maxWidth={"90%"}
              border="1px solid #390879"
              display={"flex"}
              padding={"5px"}
            >
              <Box
                height={"40px"}
                width={"40px"}
                bgcolor={"#390879"}
                borderRadius={"50px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <DoubleArrowIcon />
              </Box>

              <Box>
                <Typography
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Punch In
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    paddingLeft: "5px",
                  }}
                >
                  Swipe right to punch in this shyft...
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      }

      return (
        <div>
          Punch in button will be active 10 minutes before your shyft timming.
        </div>
      );
    };

    const renderActivityAndRecords = () => {
      if (isShyftActivity) {
        return (
          <PastShyftActivity
            summaryData={summary}
            activityData={activityRecords}
            isPastActivity={false}
            request={request}
            isShyftEndedSuccess={isShyftEnded(request)}
            isShyftActivity={true}
            backToMyShyfts={() => this.bactToMyshyfts()}
          />
        );
      }

      return (
        <Box borderRadius={"20px"} border={"1px solid #e1e6ec"}>
          <Box
            style={{
              overflow: "hidden",
              overflowY: "scroll",
              height: "fit-contain",
              padding: "15px",
            }}
          >
            <Box margin={"0px"}>
              {currentShyft && (
                <ShyftCard {...currentShyft} isMyShyft={true} employername="" />
              )}
            </Box>
            <Box>
              <ShyftSummary
                viewRecordActivity={() => this.viewRecordActivity()}
                hoursWorked={summary?.hours_worked}
                missedShifts={summary?.missed_shifts}
                completedDays={summary?.completed_days}
              />
            </Box>
            <Box marginY={"10px"}>Recent Status</Box>
            <Box
              borderRadius={"20px"}
              padding={"20px"}
              border={"1px solid #e1e6ec"}
              textAlign={"center"}
            >
              <Box>
                <Box
                  textAlign={"center"}
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {convertDateIntoDayDateMonthYearFormat(
                    moment().toISOString()
                  )}
                </Box>
                <Box paddingY={"10px"}>
                  <TimerWithCircularProgressBar
                    percent={this.getCurrentHoursPercentage(
                      currentHoursWorked,
                      totalShiftTime
                    )}
                    timer={convertSecondsToHoursMinutesFormat(
                      currentHoursWorked
                    )}
                    isShyftPage={true}
                    size={200}
                  />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#6f7c8e",
                      marginTop: "-25px",
                    }}
                  >
                    <Box>Punch in</Box>
                    <Box>{this.formatPunchTime(shyftPunchInTime)}</Box>
                  </Box>
                  <Box
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#6f7c8e",
                    }}
                  >
                    <Box>Punch out</Box>
                    <Box>{this.formatPunchTime(shyftPunchOutTime)}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {currentShyft && currentShyft.isOngoing !== true && (
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
                borderRadius={"10px"}
                border={"1px solid #f7b500"}
                bgcolor={"#f9f6ef"}
                marginY={"10px"}
                padding={"10px"}
              >
                <Box display={"flex"}>
                  <img src={timmerBlank} alt="" height={"24px"} />
                  <Typography style={{ color: "#f7b500", paddingLeft: "5px" }}>
                    Shyft start in:
                  </Typography>
                </Box>
                <Typography
                  style={{
                    fontSize: "18px",
                    color: "#390879",
                  }}
                >
                  {convertSecondsToHoursMinutesFormat(
                    nextShyftHoursLeft[currentShyft.id]
                  )}
                </Typography>
              </Box>
            )}
          </Box>
          {renderPunchingUI()}
        </Box>
      );
    };

    return (
      <>
        <LandingPageHeader {...this.props} />
        <Box marginTop={"5%"} width={"100%"}>
          {myShyftsList.length !== 0 ? (
            <Container maxWidth="md">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginTop={"15px"}
                  >
                    <Box
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                    >
                      My Shyfts
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      onClick={() => this.navigateToPage("AppliedShyft")}
                      data-test-id="appliedShyft"
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"20px"}
                        width={"20px"}
                        color="#fff"
                        bgcolor={"#390879"}
                        borderRadius={"50px"}
                        marginRight={"10px"}
                      >
                        {myShyftsList.length}
                      </Box>
                      <Box
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#390879",
                          marginRight: "5px",
                        }}
                      >
                        Applied Shyft
                      </Box>
                      <img src={RightArrowIcon} alt="" height={"14px"} />
                    </Box>
                  </Box>
                  <Box>
                    {myShyftsList.map((item) => {
                      return (
                        <ShyftCard
                          {...item}
                          key={item.id}
                          isMenu={true}
                          isMyShyft={true}
                          isMyShyftList={true}
                          isActiveShyft={item.id === selectedMyShyft}
                          onCardClick={() => this.onSelectMyShift(item.id)}
                          isOngoing={item.isOngoing}
                          nextShyftTime={this.getNextShyftTime(
                            item.isOngoing,
                            item.id
                          )}
                        />
                      );
                    })}
                    <div ref={this.elementRef}></div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderActivityAndRecords()}
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Box>
              <CommonLandingPage
                heading="No Shyfts Found!"
                paragraph="You've not assigned to any shyfts yet, Explore new shyfts to get the job"
                imgUrl={FailedIcon}
                btnText="explore shifts"
                handleClick={() => this.navigateToPage("ExploreShyftsPage")}
                data-test-id="no-shyft-data-card"
              />
            </Box>
          )}
        </Box>
        <Footer />
      </>
    );
  }
}
