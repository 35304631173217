import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import {
  constructURL,
  TRatings,
  formatShiftsListResponse,
  formatShyftDetails,
  TAPIShyftDetails,
  constructSearchFilter,
} from "../../../components/src/Utilities";
import { createRef, RefObject } from "react";

import {
  TShyftItem,
  TPagination,
  TShyftDetails,
  TQueryParams,
  TShyftRequest,
} from "./ExploreShyftsController.web";
import { setStorageData } from "../../../framework/src/Utilities";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  recommendedList: TShyftItem[];
  selectedShyft: number | null;
  shyftDetails: TShyftDetails | null;
  totalPages: number;
  totalShyfts: number;
  perPage: number;
  page: number;
  profileComplition: number;
  isLocationModalOpen: boolean;
  request?: TShyftRequest;
  isShyftApplied: boolean;
  isRegisterationPending: boolean;
  search: string;
  saveShyftId?: number;
}

interface SS {
  id: any;
}

export default class RecommendedShyftsController extends BlockComponent<
  Props,
  S,
  SS
> {
  elementObserver: IntersectionObserver | null = null;
  skipRender = true;
  elementRef: RefObject<HTMLDivElement>;
  getRecommendedShyftsAPICallId: string = "";
  getRecommendedShyftDetailsAPICallId: string = "";
  getNextRecommendedShyfts: string = "";
  getCurrentUserApiCallId: string = "";
  postWithdrawFromShyftCallId: string = "";
  postApplyForShyftAPICallId: string = "";
  postRecommShyftSaveAPICallId: string = "";
  deleteShyftSaveAPICallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.elementRef = createRef<HTMLDivElement>();

    this.subScribedMessages = [
      getName(MessageEnum.NavigationSearchShyftsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      search: "",
      recommendedList: [],
      page: 1,
      perPage: 15,
      totalPages: 1,
      totalShyfts: 0,
      shyftDetails: null,
      selectedShyft: null,
      isLocationModalOpen: false,
      profileComplition: 0,
      isShyftApplied: false,
      isRegisterationPending: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.handleRecommendedRestAPIResponses(response, apiRequestCallId);
    }

    if (getName(MessageEnum.NavigationSearchShyftsMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationSearchShyftsMessage)
      );
      this.setState({ search: data.search });
    }
  }

  handleRecommendedRestAPIResponses = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (
      [
        this.getRecommendedShyftsAPICallId,
        this.getNextRecommendedShyfts,
      ].includes(apiRequestCallId)
    ) {
      this.handleRecommendedShyftsListingResponse(
        responseJson,
        apiRequestCallId
      );
    }

    if (
      [
        this.postApplyForShyftAPICallId,
        this.postWithdrawFromShyftCallId,
      ].includes(apiRequestCallId)
    ) {
      this.handleRecommendedShyftsApplyResponse(responseJson, apiRequestCallId);
    }

    if (
      [
        this.postRecommShyftSaveAPICallId,
        this.deleteShyftSaveAPICallId,
      ].includes(apiRequestCallId)
    ) {
      this.handleRecommendedSaveShyftsResponse(apiRequestCallId);
    }

    if (this.getRecommendedShyftDetailsAPICallId === apiRequestCallId) {
      this.handleShyftDetailsData(
        responseJson.shyft_details.data,
        responseJson.shyft_details.meta.ratings_data,
        responseJson.shyft_details.meta.request
      );
    }

    if (apiRequestCallId === this.getCurrentUserApiCallId) {
      this.setCurrentProfileCompletion(
        responseJson.data.attributes.profile_completion
      );
    }
  };

  handleRecommendedShyftsListingResponse = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (this.getRecommendedShyftsAPICallId === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.recommended_shifts.data,
        responseJson.pagination_details,
        "initial"
      );
    }

    if (this.getNextRecommendedShyfts === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.recommended_shifts.data,
        responseJson.pagination_details,
        "next"
      );
    }
  };

  handleRecommendedShyftsApplyResponse = (
    response: any,
    apiRequestCallId: string
  ) => {
    if (this.postApplyForShyftAPICallId === apiRequestCallId) {
      this.setShyftRequestData(response);
    }

    if (this.postWithdrawFromShyftCallId === apiRequestCallId) {
      this.setState({
        request: undefined,
      });
    }
  };

  handleRecommendedSaveShyftsResponse = (apiRequestCallId: string) => {
    if (this.postRecommShyftSaveAPICallId === apiRequestCallId) {
      this.setRecommendedSaveShyftsData("save");
    }

    if (this.deleteShyftSaveAPICallId === apiRequestCallId) {
      this.setRecommendedSaveShyftsData("unsave");
    }
  };

  getCurrentUserProfile() {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCurrentUserApiCallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  setCurrentProfileCompletion(profileComplition: number) {
    this.setState({
      profileComplition,
    });
  }

  getRecommendedShifts(
    { search, page, filter }: TQueryParams,
    type: "initial" | "next"
  ) {
    const header = {
      "Content-Type": configJSON.getRecommendedShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = constructURL({
      endpoint: configJSON.getRecommendedShiftsApiEndPoint,
      search,
      page,
    });

    const requestBody = { filter: constructSearchFilter({ filter }) };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRecommendedShiftsApiMethodType
    );

    if (type === "initial") {
      this.getRecommendedShyftsAPICallId = requestMessage.messageId;
    } else {
      this.getNextRecommendedShyfts = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShiftsData(
    shifts: TAPIShyftDetails[],
    pagination: TPagination,
    type: "initial" | "next"
  ) {
    const recommendedList = formatShiftsListResponse(shifts);
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );

    if (type === "initial") {
      this.setState({
        totalPages,
        recommendedList,
        selectedShyft: recommendedList[0]?.id,
        totalShyfts: pagination.total_records,
      });
    } else {
      this.setState((prevState) => ({
        totalPages,
        recommendedList: [...prevState.recommendedList, ...recommendedList],
      }));
    }
  }

  handleRecomendedFilterShifts = (filter: string[], reset?: string) => {
    const { search, page, perPage } = this.state;
    this.getRecommendedShifts({ search, page, perPage, filter }, "initial");

    if (reset) {
      this.getRecommendedShifts({ search, page, perPage }, "initial");
    }
  };

  onSearchShifts = (value: string) => {
    this.setState({ search: value });
  };

  onSelectShift = (shyftId: number) => {
    this.setState({ selectedShyft: shyftId });
  };

  listenForScrollIntersection() {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (this.skipRender) {
          this.skipRender = false;
          return;
        }

        if (this.state.page < this.state.totalPages) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };
    this.elementObserver = new IntersectionObserver(callback);
    if (this.elementRef.current)
      this.elementObserver.observe(this.elementRef.current);
  }

  async componentDidMount() {
    const { search, page, perPage } = this.state;
    this.getCurrentUserProfile();
    this.getRecommendedShifts({ search, page, perPage }, "initial");
    this.listenForScrollIntersection();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.page !== prevState.page) {
      const { search, page, perPage } = this.state;
      this.getRecommendedShifts({ search, page, perPage }, "next");
    }

    if (this.state.search !== prevState.search) {
      const { search, page, perPage } = this.state;
      this.getRecommendedShifts({ search, page, perPage }, "initial");
    }

    if (
      this.state.selectedShyft &&
      this.state.selectedShyft !== prevState.selectedShyft
    ) {
      this.getRecommendShyftDetails(this.state.selectedShyft);
    }
  }

  async componentWillUnmount() {
    if (this.elementObserver) this.elementObserver.disconnect();
  }

  getRecommendShyftDetails(shyftId: number) {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRecommendedShyftDetailsAPICallId = reqMessage.messageId;

    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleShyftDetailsData(
    shift: TAPIShyftDetails,
    ratings: TRatings,
    request?: TShyftRequest
  ) {
    const shyftDetails = formatShyftDetails(shift, ratings);
    this.setState({ shyftDetails, request });
  }

  checkIfAppliedeForShyft = (request?: TShyftRequest) => {
    if (!request) return false;
    return request.status === "pending";
  };

  applyForRecommendedShyft = (shiftId: number) => {
    // Temporarily Disabling for testing
    // if (this.state.profileComplition < 100) {
    //   this.setState({ isRegisterationPending: true });
    //   return;
    // }

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postApplyForShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postApplyShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
          status: "pending",
        },
      })
    );

    const header = {
      "Content-Type": configJSON.postApplyShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApplyShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  setShyftRequestData = (responseJson: any) => {
    this.setState({
      request: {
        updated_at: responseJson.data.attributes.updated_at,
        created_at: responseJson.data.attributes.created_at,
        status: responseJson.data.attributes.status,
        removal_reason: responseJson.data.attributes.removal_reason,
        shift_id: responseJson.data.attributes.id,
        worker_id: responseJson.data.attributes.worker_id,
        id: responseJson.data.attributes.id,
      },
      isShyftApplied: true,
    });
  };

  withdrawFromRecommendedShyft = (shiftId: number) => {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postWithdrawFromShyftCallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
        },
      })
    );

    const header = {
      "Content-Type": configJSON.postWithdrawShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleSaveShyft = (shiftId: number, isSaved: boolean) => {
    if (isSaved) {
      this.unsaveRecommendedShyft(shiftId);
    } else {
      this.saveRecommendedShyft(shiftId);
    }
  };

  saveRecommendedShyft = (id: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSaveShyftApiEndPoint
    );

    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        saved_shift: {
          shift_id: id,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.postRecommShyftSaveAPICallId = requestMessage.messageId;
    this.setState({ saveShyftId: id });

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSaveShyftApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsaveRecommendedShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteShyftSaveAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaveShyftApiEndPoint}/${id}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  setRecommendedSaveShyftsData = (type: "save" | "unsave") => {
    const { recommendedList, saveShyftId } = this.state;
    const updatedList = [...recommendedList];

    const shyftIndex = recommendedList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );

    if (shyftIndex !== -1) {
      updatedList[shyftIndex].saved = type === "save";
    }

    this.setState({
      recommendedList: updatedList,
    });
  };

  openLocationModal = () => {
    this.setState({ isLocationModalOpen: true });
  };

  closeLocationModal = () => {
    this.setState({ isLocationModalOpen: false });
  };

  closeApplyShiftModal = () => {
    this.setState({ isShyftApplied: false });
  };

  closePendingRegistrationModal = () => {
    this.setState({ isRegisterationPending: false });
  };

  viewBusinessProfile = (businessId: number) => {
    const navMsg = new Message(getName(MessageEnum.NavigationMessage));
    setStorageData("businessId", businessId);

    navMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    this.send(navMsg);
  };
}
