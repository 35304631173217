import { Box, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { ReviewCard } from "./ReviewCard";
import Rating from "./StaticRatings";
import { TRatingsData } from "../../blocks/CustomisableUserProfiles/src/ShyfterBusinessProfileController";

const useStyle = makeStyles({
  card: {
    border: "1px solid #e3eaf4",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: "rgba(225,230,236,0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    margin: "10px 0px",
  },
  cardText: {
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
  },
  noReviews: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    height: "100px",
    marginTop: "10px",
  },
});

interface Props {
  ratingsData?: TRatingsData;
}

export const AllReview = (props: Readonly<Props>) => {
  const classes = useStyle();
  const {
    ratingsData = {
      reviews: [],
      ratingsCount: 0,
      reviewsCount: 0,
      overallRating: 0,
    },
  } = props;

  const { reviews, ratingsCount, reviewsCount, overallRating } = ratingsData;

  return (
    <Box>
      <Box>
        <Typography>All Reviews</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {reviews.length === 0 && (
            <Box className={classes.noReviews}>
              <Typography>No reviews exists!</Typography>
            </Box>
          )}
          {reviews.map((review) => (
            <ReviewCard key={review.id} {...review} />
          ))}
        </Grid>

        <Grid item xs={12} md={4}>
          <Box className={classes.card} marginY={"10px"}>
            <Box textAlign={"center"} marginTop={"15px"}>
              <Box paddingY={"10px"}>
                <Typography>
                  <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                    {overallRating}/<span style={{ fontSize: "14px" }}>5</span>
                  </span>
                </Typography>
              </Box>
              <Divider variant="middle" />
              <Rating rating={overallRating} />
            </Box>
            <Box padding={"10px"}>
              <Typography className={classes.cardText}>
                Based on {ratingsCount} Ratings & {reviewsCount} Reviews
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
