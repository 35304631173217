Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Events";
exports.labelBodyText = "Events Body";
exports.acceptRequestEndPoint = "/events/requests/accepted";
exports.createEventEndPoint = "/events/events";
exports.GOOGLE_API_KEY = "AIzaSyAQu429K52drmir9450TvUYcy82ZL3QQ9I";
exports.GOOGLE_PACES_API_BASE_URL =
  "https://maps.googleapis.com/maps/api/place";

exports.getShiftsApiContentType = "application/json";
exports.getShiftsApiMethodType = "POST";
exports.getShiftsApiEndPoint = "/bx_block_events/get_shyfts_to_explore";

exports.getRecommendedShiftsApiContentType = "application/json";
exports.getRecommendedShiftsApiMethodType = "POST";
exports.getRecommendedShiftsApiEndPoint =
  "/bx_block_events/get_shyfter_recommended_shyfts";

exports.getShiftDetailsApiContentType = "application/json";
exports.getShiftDetailsApiMethodType = "GET";
exports.getShiftDetailsApiEndPoint = "/bx_block_events/get_shyft_details";

exports.getPastShyftsApiContentType = "application/json";
exports.getPastShyftsApiMethodType = "GET";
exports.getPastShyftsApiEndPoint = "bx_block_events/get_shyfter_past_shyfts";

exports.getActivityRecordsApiContentType = "application/json";
exports.getActivityRecordsApiMethodType = "GET";
exports.getActivityRecordsApiEndPoint = "bx_block_events/activity_records";

exports.postShyftsRatingApiContentType = "application/json";
exports.postShyftsRatingApiMethodType = "POST";
exports.postShyftsRatingApiEndPoint = "/bx_block_ratings/ratings";

exports.getMyShyftsApiContentType = "application/json";
exports.getMyShyftsApiMethodType = "GET";
exports.getMyShyftsApiEndPoint = "bx_block_events/shyfter_shifts";

exports.getCurrentUserProfileEndPoint = "account_block/current_user";

exports.postApplyShyftApiContentType = "application/json";
exports.postApplyShyftApiMethodType = "POST";
exports.postApplyShyftApiEndPoint = "/bx_block_events/apply_shift";

exports.postWithdrawShyftApiContentType = "application/json";
exports.postWithdrawShyftApiMethodType = "PATCH";
exports.postWithdrawShyftApiEndPoint =
  "/bx_block_request_management/withdraw_request";

exports.getSavedShyftApiContentType = "application/json";
exports.getSavedShyftApiMethodType = "GET";
exports.getSavedShyftApiEndPoint = "bx_block_events/saved_shifts";

exports.postSaveShyftApiMethodType = "POST";
exports.postSaveShyftApiContentType = "application/json";
exports.postSaveShyftApiEndPoint = "bx_block_events/saved_shifts";

exports.deleteSaveShyftApiMethodType = "DELETE";
exports.deleteSaveShyftApiContentType = "application/json";
exports.deleteSaveShyftApiEndPoint = "bx_block_events/saved_shifts";

exports.getAppliedShyftApiContentType = "application/json";
exports.getAppliedShyftApiMethodType = "GET";
exports.getAppliedShyftApiEndPoint = "bx_block_events/shyfter_pending_shifts";

exports.postPunchInShyftApiContentType = "application/json";
exports.postPunchInShyftApiMethodType = "POST";
exports.postPunchInShyftApiEndPoint = "bx_block_events/activities";

exports.getViewActivityApiContentType = "application/json";
exports.getViewActivityApiMethodType = "GET";
exports.getViewActivityApiEndPoint = "bx_block_events/view_activity";

exports.postPunchOutShyftApiContentType = "application/json";
exports.postPunchOutShyftApiMethodType = "PATCH";
exports.postPunchOutShyftApiEndPoint = "bx_block_events/activities";
exports.getShyfteUserProfileEndPoint = "account_block/users";

exports.postaceeptRequestApiContentType = "application/json";
exports.postaceeptRequestApiMethodType = "POST";
exports.postaceeptRequestEndPoint = "bx_block_events/accept_request";

exports.postignoreRequestApiContentType = "application/json";
exports.postignoreRequestApiMethodType = "POST";
exports.postignoreRequestEndPoint = "bx_block_events/ignore_request";

// Customizable Area End
