import React from "react";
import { Box, Button,Typography } from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import { ArrowBackIos } from "@material-ui/icons";

interface Props {
  user: any;
  userFormError: any;
  configJSON: any;
  handleShyfterInputChange: any;
  moveBack:any;
  submitBtn:any;
}
export const UpdateOtherDeatils = (props: Props) => {
  const {
    user,
    userFormError,
    configJSON,
    handleShyfterInputChange,
    moveBack,
    submitBtn
  } = props;
  return (
    <>
      <Box
        border="1px solid #e1e6ec"
        marginY={"10px"}
        padding={"30px 20px 10px 20px"}
        borderRadius={"10px"}
        bgcolor={"#fff"}
      >
        <Box>
          <Box marginY={"10px"}>
            <Typography>Industry Computer Application</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
          <Box  width={"95%"}>
            <CustomSelect
              value={user.industry_computer_application_pos}
              handleChange={(e: any) => handleShyfterInputChange(e, "industry_computer_application_pos")}
              label={configJSON.pos}
              name="pos"
              error={!!userFormError.dlState}
              options={configJSON.posList}
            />
            </Box>
          <Box padding={"10px"} width={"95%"}>
          <CustomSelect
              value={user.industry_computer_application_pms}
              handleChange={(e: any) => handleShyfterInputChange(e,"industry_computer_application_pms")}
              label={configJSON.pms}
              name="pms"
              error={!!userFormError.dlState}
              options={configJSON.pmsList}
            />
          </Box>
          </Box>
        </Box>
        <Box>
          <Box marginY={"10px"}>
            <Typography>Industry Computer Application</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
          <Box width={"95%"}>
            <CustomSelect
              value={"true"}
              handleChange={(e: any) =>{}}
              label={configJSON.selectDLState}
              name="ques1"
              error={!!userFormError.dlState}
              options={configJSON.ansList}
            />
            </Box>
            <Box width={"95%"}>
            <CustomSelect
              value={"false"}
              handleChange={(e: any) => {}}
              label={configJSON.selectDLState}
              name="ques2"
              error={!!userFormError.dlState}
              options={configJSON.ansList}
            />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        marginY={"10"}
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Box padding={"10px"} width={"15%"}>
        <Button
          variant="contained"
          style={{ borderRadius: "50px",padding:"11px 20px" }}
          onClick={moveBack}
        >
          <ArrowBackIos style={{height:"14px"}}/> Back
        </Button>
        </Box>
        <Box padding={"10px"} width={"15%"} onClick={submitBtn}>
          <CustomButton label={"Submit"} isOutlined={false} />
        </Box>
      </Box>
    </>
  );
};
