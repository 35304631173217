Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiPatchMethodType = "PATCH";
exports.validationApiPutMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.validationPostMethod='POST';
exports.exampleAPiMethod = "POST";
exports.exampleDeleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.phoneNumberOptVarifyUrl = "account_block/verify_now?phone_number=";
exports.verifyOTP = "account_block/verify_otp";
exports.btnExampleTitle = "CLICK ME";
exports.getShyfteUserProfileEndPoint="account_block/users";
exports.patchUserEndPoint="account_block/users"
exports.updateprofilepic="account_block/users"
exports.postEmailOTPEndpoint = "account_block/accounts/send_otps/email_otp";
exports.getPhoneOTPEndpoint = "account_block/verify_now";
exports.putUpdatePhoneEndpoint = "account_block/update_phone";
exports.deleteAccountApiEndPoint="account_block/delete"
exports.genralSettig="Genral Settings"
exports.dlStateList = [
  { name: "Florida", value: "florida"},
  { name: "Texas", value: "texas"},
  { name: "Alaska", value: "alaska"},
];

exports.restaurantSkills = [
  "server",
  "cook",
  "host",
  "busser/runner",
  "dishwasher",
  "bartender",
];
exports.experienceList = [
  { name: "1 Year", value: "1"},
  { name: "2 Years", value: "2"},
  { name: "4 Years", value: "4"},
  { name: "3 Years", value: "3"},
  { name: "5 Years", value: "5"},
];
exports.hotelSkills = [
  "front desk",
  "room attendant",
  "doncierge",
  "doorman",
  "bellperson",
  "laundry attendant",
  "shuttle driver",
  "house person",
  "engineer (maintenance)"
]
exports.posList=[
  { name: "Aloha", value: "Aloha"},
]
exports.pmsList=[
  { name: "Opera", value: "AloOperaha"},
]
exports.ansList=[
  { name: "True", value: "true"},
  { name: "False", value: "false"},
]
exports.pms="PMS";
exports.mos="POS"
exports.minMaxCharLimitRegex = /^.{4,30}$/;
exports.minMaxCharLimitForAddressRegex = /^.{4,100}$/;
exports.einNumberRegex = /^\d{9,11}$/;
exports.backToLogin = "Back to Login";
exports.phone_numberRegex = /^\d{10}$/;
exports.enterPersonalDetails = "Enter Your Personal Details";
exports.enterProfessionalDetails = "Enter Your Professional Details";
exports.fullName = "CONTACT NAME";
exports.address = "ADDRESS";
exports.dateOfBirth = "DATE OF BIRTH";
exports.mobileNumber = "MOBILE NUMBER";
exports.yourExperience = "YOUR EXPERIENCE";
exports.prev = "Prev";
exports.signUpNow = "Sign Up Now";
exports.languageSkills = "LANGUAGE SKILLS";
exports.full_nameRegex = /^[A-Za-z ]+$/;
exports.verifyNow = "Verify Now";
exports.emailAddress = "EMAIL ADDRESS";
exports.enterSSN = "ENTER SSN";
exports.selectDLState = "SELECT DL STATE";
exports.dlNumber = "DL-NUMBER";
exports.next = "Next";
// Customizable Area End