import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import CallMadeIcon from '@material-ui/icons/CallMade';
const KeyArrowRight = require("./assets/images/keyboard_arrow_down_black.png");

interface Props {
  linkData: any;
  getActiveTab?: any;
  isMobileView?: boolean;
  isEdit?:boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: "1px solid #e1e6ec",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      padding: "10px",
    },

    cursor: {
      cursor: "pointer",
    },
    tabText: {
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: "15px",
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
    },
    tabActiveText: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "15px",
      color: "#390879",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
    },
    bgColor: {
      backgroundColor: "#f7faff",
      borderRadius: "10px",
      padding: "8px",
    },
    activeTab: {
      backgroundColor: "rgba(225, 195, 247, 0.3)",
      color: "#390879",
    },
    hover: {
      "&:hover": {
        backgroundColor: "rgba(225, 195, 247, 0.3)",
        borderRadius: "10px",
        color: "#390879",
      },
    },
  })
);

export const ProfileQuickLink = (props: Props) => {
  const classes = useStyle();
  const { linkData, getActiveTab,isEdit } = props;

  return (
    <Box padding={"5px"} bgcolor={"#fff"} borderRadius={"10px"} border={"1px solid #e1e6ec"}>
    {linkData.map((tab: any) => {
        return (
          <Box
          key={tab.id}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className={`${classes.cursor} ${classes.hover} ${
              classes.bgColor
            } ${tab.isActive && classes.activeTab}`}
            margin={"5px"}
            onClick={() => getActiveTab(tab.id)}
            data-test-id="activeTab"
          >
            <Box
              display={"flex"}
              justifyContent={"space-start"}
              alignItems={"center"}
            >
              {tab?.tabIcon&&(
                <Box marginRight={"10px"}>
                <img
                  src={tab.isActive ? tab.activeTabIcon : tab.tabIcon}
                  alt=""
                  height={props.isMobileView ? "11px" : "20px"}
                />
              </Box>
              )}
              
              <Typography className={tab.isActive ? classes.tabActiveText : classes.tabText} variant="h3">{tab.tab}</Typography>
            </Box>
            <Box
              width={"40px"}
              height={"40px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >{isEdit?(
              <CallMadeIcon/>
            ):(
              <img
              src={KeyArrowRight}
              alt=""
              height={props.isMobileView ? "8px" : "14px"}
            />
            )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
