import React from "react";
import { Button, Radio, Dialog, Grid } from "@material-ui/core";
import "./AccountTypeSelectionDialog.css";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  radioUnCheckedIcon,
  radioCheckedDarkBlueIcon,
  workIcon,
  storeIcon,
} from "./assets";

interface Props {
  isOpen: boolean;
  handleClose: (e: any) => void;
  handleSave: (type: any) => void;
}

interface States {
  selectedRole: string;
}

export default class AccountTypeSelectionDialog extends React.Component<
  Props,
  States
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRole: "EmployerSignUp",
    };
  }

  handlePlanChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    this.setState({ ...this.state, selectedRole: type });
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        maxWidth="sm"
        scroll="body"
        fullWidth
        className="create-account-dialog"
      >
        <IconButton className="close-icon" onClick={this.props.handleClose}>
          <CloseIcon height={20} width={20} />
        </IconButton>
        <div className="create-account-dialog-content">
          <div className="create-account-title">Create an Account</div>
          <div className="create-account-subtitle">
            Select your role and continue to register your account
            <div>
              in <span style={{ fontWeight: 600 }}>SNAGASHYFT</span>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className={`selection-item ${
                  this.state.selectedRole === "EmployerSignUp" ? "active-role" : ""
                }`}
              >
                <div className="selection-text-wrapper">
                  <img src={storeIcon} width={20} />
                  <span
                    className={`selection-text ${
                      this.state.selectedRole === "EmployerSignUp"
                        ? "active-role-text"
                        : ""
                    }`}
                  >
                    Continue as a Businesses
                  </span>
                </div>
                <div className="selection-action">
                  <Radio
                    checked={this.state.selectedRole === "EmployerSignUp"}
                    onChange={(e) => this.handlePlanChecked(e, "EmployerSignUp")}
                    value="plan"
                    color="primary"
                    checkedIcon={
                      <img
                        src={radioCheckedDarkBlueIcon}
                        height={20}
                        alt="checked"
                      />
                    }
                    icon={
                      <img
                        src={radioUnCheckedIcon}
                        height={20}
                        alt="unchecked"
                      />
                    }
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                className={`selection-item ${
                  this.state.selectedRole === "ShyfterSignUp" ? "active-role" : ""
                }`}
              >
                <div className="selection-text-wrapper">
                  <img src={workIcon} width={20} />
                  <span
                    className={`selection-text ${
                      this.state.selectedRole === "ShyfterSignUp"
                        ? "active-role-text"
                        : ""
                    }`}
                  >
                    Continue as a Worker
                  </span>
                </div>
                <div className="selection-action">
                  <Radio
                    checked={this.state.selectedRole === "ShyfterSignUp"}
                    onChange={(e) => this.handlePlanChecked(e, "ShyfterSignUp")}
                    value="plan"
                    color="primary"
                    checkedIcon={
                      <img
                        src={radioCheckedDarkBlueIcon}
                        height={20}
                        alt="checked"
                      />
                    }
                    icon={
                      <img
                        src={radioUnCheckedIcon}
                        height={20}
                        alt="unchecked"
                      />
                    }
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="proceed-action-container">
            <Button
              fullWidth
              className="proceed-btn"
              data-test-id="proceed-btn"
              onClick={() => this.props.handleSave(this.state.selectedRole)}
            >
              Proceed
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
