import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import moment from "moment";
export const configJSON = require("./config");
const sideNavTab = [
  {
    id: 1,
    tab: "Edit & Update Profile",
    isActive: true,
  },
  {
    id: 2,
    tab: "Notification",
    isActive: false,
  },
];

const sideNavTabLink = [
  {
    id: 1,
    tab: "Help Center",
    isActive: false,
  },
  {
    id: 2,
    tab: "Term & Condition",
    isActive: false,
  },
  {
    id: 3,
    tab: "Privacy Policy",
    isActive: false,
  },
];
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isPastShyft?: boolean;
  sideNavTab?: any;
  token: any;
  userData: any;
  sideNavTabLink: any;
  progress: number;
  user: {
    id: number;
    full_name: string;
    phone_number: string;
    full_phone_number: string;
    email: string;
    address: string;
    date_of_birth: string;
    ssn: string;
    dl_state: string;
    dl_number: string;
    industry_computer_application_pos: string;
    industry_computer_application_pms: string;
    experience: string;
    language_skill: any[];
    restaurant_skills: any[];
    hotel_skills: any[];
    work_authorization_knockout_question: any[];
    role_id: 2;
    ein: null;
    created_at: string;
    updated_at: string;
    recent_login: null;
    recent_logout: null;
    age: string;
    profile_picture_url: null;
  };
  userFormError: {
    fullName: string;
    mobileNumber: string;
    email: string;
    address: string;
    dateOfBirth: string;
    ssn: string;
    dlNumber: string;
    dlState: string;
    experience: string;
    languageSkills: string;
    skills: string;
  };
  isShyfterFirstStepSaved: boolean;
  shyfterStep: number;
  profileComplition: number;
  openSkillSetDialog: string;
  isCompleteReg: boolean;
  isNumberVerified: boolean;
  verifiedNumber: string;
  snackbarMessage: string;
  resendDisabled: boolean;
  vafiyOpen: boolean;
  otp: string;
  seconds: number;
  otpSentCount: number;
  showAlert: boolean;
  error: string;
  isDeleteAccount: boolean;
}

interface SS {
  id: any;
}

export default class EditShyfterProfilePageContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getShyfterProfileApiCallId: string = "";
  patchShyfterProfileApiCallId: string = "";
  verifyOTPApiCallId: string = "";
  phoneOtpVarifyApiCallId: string = "";
  deleteAccountApiCallId: string = "";
  updateProfilePicApiCallId: string = "";
  countInterval: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.state = {
      isShyfterFirstStepSaved: false,
      isPastShyft: false,
      sideNavTab: sideNavTab,
      token: "",
      userData: "",
      sideNavTabLink: sideNavTabLink,
      progress: 10,
      shyfterStep: 1,
      profileComplition: 0,
      user: {
        id: 20,
        full_name: "",
        phone_number: "",
        full_phone_number: "",
        email: "",
        address: "",
        date_of_birth: "",
        ssn: "",
        dl_state: "",
        dl_number: "",
        industry_computer_application_pos: "",
        industry_computer_application_pms: "",
        experience: "",
        language_skill: [],
        restaurant_skills: [],
        hotel_skills: [],
        work_authorization_knockout_question: [],
        role_id: 2,
        ein: null,
        created_at: "",
        updated_at: "",
        recent_login: null,
        recent_logout: null,
        age: "",
        profile_picture_url: null,
      },
      userFormError: {
        fullName: "",
        mobileNumber: "",
        email: "",
        address: "",
        dateOfBirth: "",
        ssn: "",
        dlNumber: "",
        dlState: "",
        experience: "",
        languageSkills: "",
        skills: "",
      },
      openSkillSetDialog: "",
      isCompleteReg: false,
      isNumberVerified: false,

      resendDisabled: false,
      verifiedNumber: "",
      vafiyOpen: false,
      otp: "",
      seconds: 120,
      snackbarMessage: "",
      otpSentCount: 0,
      showAlert: false,
      isDeleteAccount: false,
      error: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    const shyfterData = localStorage.getItem("userData");
    if (!!shyfterData) {
      const user1 = JSON.parse(shyfterData);
      this.getShyfterProfile(user1.user.id);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
  }

  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.phoneOtpVarifyApiCallId) {
      this.handleOtpVarifyResponse(responseJson);
    } else if (apiRequestCallId === this.deleteAccountApiCallId) {
      this.moveToLoginPage();
    } else if (apiRequestCallId === this.updateProfilePicApiCallId) {
      console.log(responseJson);
    } else if (apiRequestCallId === this.verifyOTPApiCallId) {
      this.handleVerifyOTPResponse(responseJson);
    }
    if (apiRequestCallId === this.getShyfterProfileApiCallId) {
      this.setState({
        ...this.state,
        profileComplition: responseJson.profile_completion,
        verifiedNumber: responseJson.user.data.attributes.full_phone_number,
        user: responseJson.user.data.attributes,
      });
    }

    if (apiRequestCallId === this.patchShyfterProfileApiCallId) {
      let userShyfterId = responseJson.user.id;
      this.getShyfterProfile(userShyfterId);
    }
  }

  moveToLoginPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), "");
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  getShyfterProfile(userId: any) {
    const headerContent = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShyfterProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShyfteUserProfileEndPoint + `/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerContent)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getIsNumberVerified = () => {
    const enteredNumber = this.state.user.phone_number;
    return (
      this.state.isNumberVerified && this.state.verifiedNumber === enteredNumber
    );
  };

  handleVerifyOTPResponse = (responseJson: any) => {
    let isVerified = false;
    if (
      responseJson &&
      responseJson.message === "Phone number verified successfully"
    ) {
      isVerified = true;
    }
    let userNumber = this.state.user.phone_number;
    this.setState({
      isNumberVerified: isVerified,
      resendDisabled: false,
      vafiyOpen: false,
      verifiedNumber: isVerified ? userNumber : "",
      otp: "",
      seconds: 120,
      snackbarMessage: isVerified
        ? ""
        : "Verification failed. Please enter the correct pin!",
    });
    clearInterval(this.countInterval);
  };

  handlePhoneVerification = () => {
    const enteredNumber = this.state.user.phone_number;
    if (this.state.otpSentCount < 5) {
      if (!this.state.resendDisabled) {
        this.setState({ resendDisabled: true });
        this.sendPhoneSignUpNumberOtpVarify(enteredNumber);
      }
    } else {
      this.setState({
        ...this.state,
        snackbarMessage:
          "Maximum OTP generation attempts reached. For a fresh start, consider refreshing the page.",
      });
    }
  };

  setOTP = (otp: string) => {
    this.setState({ ...this.state, otp: otp });
  };

  handleOTPDialogClose = () => {
    this.setState({
      vafiyOpen: false,
      otp: "",
      resendDisabled: false,
      seconds: 120,
    });
    clearInterval(this.countInterval);
  };

  handleOtpVarifyResponse(responseJson: any) {
    if (
      responseJson.message &&
      responseJson.message === "OTP sent successfully"
    ) {
      this.setOTPTimer();
    } else {
      this.setState({
        error: responseJson.message || responseJson.error,
        showAlert: true,
        resendDisabled: false,
      });
    }
  }
  setOTPTimer = () => {
    this.setState(
      {
        seconds: 120,
        otpSentCount: this.state.otpSentCount + 1,
        otp: "",
        vafiyOpen: true,
      },
      () => {
        this.countInterval = setInterval(() => {
          const { seconds } = this.state;
          if (seconds > 0) {
            this.setState((prevState) => ({
              seconds: prevState.seconds - 1,
            }));
          } else {
            clearInterval(this.countInterval);
            this.setState({ resendDisabled: false });
          }
        }, 1000);
      }
    );
  };

  sendPhoneSignUpNumberOtpVarify(phone: string) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getPhoneOtpMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.phoneOtpVarifyApiCallId = getPhoneOtpMsg.messageId;

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneNumberOptVarifyUrl + `%2B91${phone}`
    );

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPhoneOtpMsg.id, getPhoneOtpMsg);
  }

  verifyOTPRequest = (phone: string, pin: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const validateOTPRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOTPApiCallId = validateOTPRequest.messageId;

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOTP
    );

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationPostMethod
    );

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ phone_number: `+91${phone}`, pin: pin })
    );
    runEngine.sendMessage(validateOTPRequest.id, validateOTPRequest);
  };

  updateUserProfile() {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchShyfterProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );
    const formData = new FormData();
    formData.append("role_id", "2");
    formData.append("shyfter[full_name]", this.state.user.full_name);
    formData.append("shyfter[email]", this.state.user.email);
    formData.append(
      "shyfter[phone_number]",
      `+91${this.state.user.full_phone_number}`
    );
    formData.append("shyfter[address]", this.state.user.address);
    formData.append("shyfter[ssn]", this.state.user.ssn);
    formData.append("shyfter[dl_state]", this.state.user.dl_state);
    formData.append("shyfter[dl_number]", this.state.user.dl_number);
    formData.append("shyfter[experience][]", this.state.user.experience);
    formData.append(
      "shyfter[language_skill][]",
      this.state.user.language_skill.join(",")
    );
    formData.append(
      "shyfter[restaurant_skills][]",
      this.state.user.restaurant_skills.join(",")
    );
    formData.append(
      "shyfter[hotel_skills][]",
      this.state.user.hotel_skills.join(",")
    );
    formData.append(
      "shyfter[date_of_birth]",
      moment(this.state.user.date_of_birth).format("DD/MM/YYYY")
    );
    formData.append(
      "shyfter[work_authorization_knockout_question]",
      "Are you authorized to work in USA?: true"
    );
    formData.append(
      "shyfter[work_authorization_knockout_question]",
      "Are you 18 years of age or above: false"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  moveShyfterNextStep = () => {
    this.updateUserProfile();
    if (this.state.shyfterStep <= 2) {
      this.setState({ ...this.state, shyfterStep: this.state.shyfterStep + 1 });
    } else {
      this.setState({ ...this.state, isCompleteReg: true, shyfterStep: 1 });
    }
  };
  moveShyfterBackStep = () => {
    this.setState({ ...this.state, shyfterStep: this.state.shyfterStep - 1 });
  };

  handleExploreShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handleShyfterInputChange = (event: any, name: any) => {
    const { value } = event.target;
    let newValue = value;
    switch (name) {
      case "full_name":
        newValue = newValue.replace(/[^a-zA-Z\s]/g, "");
        break;
      case "phone_number":
        const numberValue = newValue.toString();
        const sanitizedValue = numberValue
          .replace(/[+e-]/g, "")
          .replace(/\D/g, "");
        newValue = sanitizedValue.slice(0, 10);
        break;
      case "email":
        newValue = newValue.replace(/[^a-zA-Z0-9@.]/g, "");
        break;
    }

    this.setState(
      (prevState) => ({
        user: {
          ...prevState.user,
          [name]: newValue,
        },
      }),
      () => {}
    );
  };
  deleteSkillItem = (type: string, skillItem: string) => {
    if (type === "hotel") {
      this.setState({
        ...this.state,
        openSkillSetDialog: "",
        user: {
          ...this.state.user,
          hotel_skills: this.state.user.hotel_skills.filter(
            (i) => i !== skillItem
          ),
        },
      });
    } else {
      this.setState({
        ...this.state,
        openSkillSetDialog: "",
        user: {
          ...this.state.user,
          restaurant_skills: this.state.user.restaurant_skills.filter(
            (i) => i !== skillItem
          ),
        },
      });
    }
  };

  toggleSkillSetDialog = (type: string) => {
    this.setState({ ...this.state, openSkillSetDialog: type });
  };

  setSelectedSkills = () => {
    return this.state.openSkillSetDialog === "hotel"
      ? this.state.user.hotel_skills
      : this.state.user.restaurant_skills;
  };

  saveSkillData = (type: string, list: string[]) => {
    this.setState(
      {
        ...this.state,
        openSkillSetDialog: "",
        user: {
          ...this.state.user,
          [`${type}_skills`]: list,
        },
      },
      () => {}
    );
  };

  handleLanguageChange = (data: any) => {
    this.setState(
      {
        ...this.state,
        user: {
          ...this.state.user,
          language_skill: data,
        },
      },
      () => {}
    );
  };

  openDeleteAccontPopup = () => {
    this.setState({ isDeleteAccount: !this.state.isDeleteAccount });
  };

  confirmDeleteAccount = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteAccountApiCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountApiEndPoint
    );
    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleDeleteAPiMethod
    );
    runEngine.sendMessage(deletedMsg.id, deletedMsg);
    return true;
  };

  handleFileChange = (event: any) => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfilePicApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("user[profile_picture]", selectedFile, "[PROXY]");

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
