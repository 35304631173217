import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
import {
  docsIcon,
  fadeDocsIcon,
  fileCircle,
  fileCircleIconFade,
  startComment,
  startCommentfade,
  globeIcon,
  phoneIcon,
  marker,
  envelopeIcon,
  userIcon,
  buildingIcon,
} from "./assets";
import { formatReviewDate } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";

const tabs = [
  {
    id: 1,
    tab: "Overview",
    tabIcon: fileCircleIconFade,
    activeTabIcon: fileCircle,
    isActive: true,
  },
  {
    id: 2,
    tab: "Officail Docs",
    tabIcon: fadeDocsIcon,
    activeTabIcon: docsIcon,
    isActive: false,
  },
  {
    id: 3,
    tab: "Ratings",
    tabIcon: startComment,
    activeTabIcon: startCommentfade,
    isActive: false,
  },
];

export interface TProfileBlock {
  title: string;
  value: string;
  icon: string;
}

export interface TSideTab {
  id: number;
  tab: string;
  tabIcon: string;
  activeTabIcon: string;
  isActive: boolean;
}

export interface Props {
  navigation: any;
  id: string;
}

export type TBusinessProfile = {
  id: number;
  name: string;
  address: string;
  imageURL: string;
  tag: string;
  phoneNumber: string;
  websiteURL: string;
};

export type TReviewItem = {
  id: number;
  score: number;
  comment: string;
  userName: string;
  userImg: string;
  shiftType: string;
  date: string;
};

export type TRatingsData = {
  ratingsCount: number;
  reviewsCount: number;
  overallRating: number;
  reviews: TReviewItem[];
};

export type TOfficialDocs = {
  form: string;
  certificate: string;
};

interface S {
  businessProfile: TBusinessProfile | null;
  sideTabs: TSideTab[];
  basicBlock: TProfileBlock[];
  managementBlock: TProfileBlock[];
  ratingsData: TRatingsData | null;
  officalDocs: TOfficialDocs | null;
  businessId: number | null;
}

interface SS {
  id: any;
}

export default class ShyfterBusinessProfileContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getBusinessProfileId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      businessProfile: null,
      sideTabs: tabs,
      basicBlock: [],
      managementBlock: [],
      ratingsData: null,
      officalDocs: null,
      businessId: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getBusinessId = async () => {
    const businessId = await getStorageData("businessId");
    this.setState({ businessId });
  };

  getBusinessProfileHandler(businessId: number) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBusinessProfileId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShyfteUserProfileEndPoint}/${businessId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBusinessProfileData = (response: any) => {
    const isHotel = (response.type as string).toLowerCase() === "hotel";

    const businessProfile: TBusinessProfile = {
      id: response.id,
      name: response.full_name,
      address: response.business_details.address,
      imageURL: response.profile_picture,
      tag: response.business_details[
        isHotel ? "hotel_type" : "restaurant_type"
      ],
      phoneNumber: response.phone_number,
      websiteURL: response.business_details.website,
    };

    const basicBlock = [
      {
        icon: buildingIcon,
        title: "DBA Name",
        value: response.business_details.dba_name,
      },
      {
        icon: marker,
        title: `${isHotel ? "Hotel" : "Restaurant"} Address`,
        value: response.business_details.address,
      },
      {
        icon: globeIcon,
        title: "Business Website ",
        value: response.business_details.website,
      },
    ];

    const managementBlock = [
      {
        icon: userIcon,
        title: "Contact Name",
        value: `${response.full_name} | EIN-${response.ein}`,
      },
      {
        icon: phoneIcon,
        title: "Contact Number",
        value: response.business_details.telephone,
      },
      {
        icon: envelopeIcon,
        title: "Contact Email ID",
        value: response.email,
      },
    ];

    const ratingsData: TRatingsData = {
      ratingsCount: response.ratings.ratings_count,
      reviewsCount: response.ratings.reviews_count,
      overallRating: response.ratings.overall_rating,
      reviews: response.ratings.ratings_data.map((review: any) => ({
        id: review.id,
        score: review.score,
        comment: review.comment,
        userName: review.worker_name,
        userImg: review.worker_profile_picture,
        shiftType: review.shift_type,
        date: formatReviewDate(review.created_at),
      })),
    };

    const officalDocs = {
      form: response.official_docs.form,
      certificate: response.official_docs.certificate,
    };

    this.setState({
      businessProfile,
      basicBlock,
      managementBlock,
      ratingsData,
      officalDocs,
    });
  };

  changeTabHandler = (tabId: any) => {
    const updatedTabs = this.state.sideTabs.map((tab: any) => {
      if (tab.id === tabId) {
        return { ...tab, isActive: true };
      }

      return { ...tab, isActive: false };
    });

    this.setState({ sideTabs: updatedTabs });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getBusinessProfileId) {
        this.handleBusinessProfileData(responseJson.user.data.attributes);
      }
    }
  }

  async componentDidMount() {
    this.getBusinessId();
  }

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.businessId && this.state.businessId !== prevState.businessId)
      this.getBusinessProfileHandler(this.state.businessId);
  }
}
