import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Accordion,
  AccordionSummary,
  AccordionDetails
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import './style.css';
import HelpCentreController, { Props,configJSON } from "./HelpCentreController";
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";
import { Croupier, Hotel } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End




export default class FAQ extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleAccordionToggle = (id: number) => {
    this.setState((prevState) => ({
      expandAccordian: {...prevState.expandAccordian, [id]: !prevState.expandAccordian[id]},
    }));
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <ThemeProvider theme={theme}>
           <GenericHeader navigation={this.props.navigation} />

         <Box className="commonheader">
          <Box className="firstsection-heading commonheader-heading">
          <Typography variant="h5" className="heading-main" data-test-id="test-heading">Snagashyft <span>FAQ's</span></Typography>
          <label className="heading-mainsub">
            <span className="header-home" onClick={() => window.location.href = "/"}>Home</span> &nbsp;|&nbsp; <span className="header-home" onClick={() => window.location.href = `/FAQ`}>FAQ</span>
          </label>
          </Box>            
         </Box>
         <Box className="imageSection borderbottom">
      
          <div className="faqbody-left">
            <img src={Hotel}></img>
            <label>Frequently Asked Questions for <span>BUSINESSES</span></label>
           </div>
             <div className="faqbody-right">
             <Accordion className="bodyright-accordian" 
            key={1}
            expanded={this.state.expandAccordian[1]}
            onChange={() => this.handleAccordionToggle(1)}>
        <AccordionSummary
          expandIcon={this.state.expandAccordian[1]? <RemoveCircleOutlineIcon />:<AddCircleOutline/>}
          
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
         
          </div>
         
         
         </Box>
         <Box className="imageSection">
       
           <div className="faqbody-right">
           <Accordion className="bodyright-accordian" 
            key={2}
            expanded={this.state.expandAccordian[2]}
            onChange={() => this.handleAccordionToggle(2)}>
        <AccordionSummary
          expandIcon={this.state.expandAccordian[2]? <RemoveCircleOutlineIcon />:<AddCircleOutline/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
           </div>
           <div className="faqbody-left secondsection-right">
            <img src={Croupier}></img>
            <label >Frequently Asked Questions for <span>SHYFTERS</span></label>
           </div>
         </Box>
         <GenericFooter navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
