import React from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TermsAndConditions3Controller, {
  Props
} from "./TermsAndConditions3Controller";
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";

export default class PrivacyAndPolicy extends TermsAndConditions3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <GenericHeader navigation={this.props.navigation} />

         <Box className="commonheader">
          <Box className="firstsection-heading commonheader-heading">
          <Typography variant="h5" className="heading-main" data-test-id="test-heading">Privacy <span>Policy</span></Typography>
          <label className="heading-mainsub">
            <span className="header-home" onClick={() => window.location.href = "/"}>Home</span> &nbsp;|&nbsp; <span className="header-home" onClick={() => window.location.href = "/PrivacyPolicy"}>Privacy Policy</span>
          </label>
          </Box>            
         </Box>
         <Box className="imageSection">
   
         </Box>
         <GenericFooter   navigation={this.props.navigation} />

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
