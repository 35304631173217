import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef, RefObject } from "react";
export const configJSON = require("./config");
import {
  TCreatedShyft,
  TRatings,
  TReview,
  constructURL,
  formatShyftDetails,
  formatShiftsListResponse,
  TAPIShyftDetails,
  constructSearchFilter,
} from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";

export interface TShyftItem {
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  saved: boolean;
  id: number;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
}

export interface TShyftRequest {
  id: number;
  removal_reason: string;
  status: string;
  created_at: string;
  updated_at: string;
  worker_id: number;
  shift_id: number;
}

export interface TShyftDetails {
  id: number;
  shyftPlace: string;
  syftTimimg: string;
  saved: boolean;
  requirements: string[];
  description: string;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  shyft: string;
  experience: string;
  businessName: string;
  businessId: number;
  businessType: string;
  businessShifts: TCreatedShyft[];
  ratings: TReview[];
  overallRatings: number;
  ratingsCount: number;
  reviewsCount: number;
}

export interface TQueryParams {
  search?: string;
  page?: number;
  perPage?: number;
  filter?: string[];
}

export interface TPagination {
  page_number: number;
  total_records: number;
  records_in_this_page: number;
  records_per_page: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  search: string;
  shyftsList: TShyftItem[];
  page: number;
  perPage: number;
  totalRecords: number;
  selectedShyft: number | null;
  totalPages: number;
  shyftDetails: TShyftDetails | null;
  request?: TShyftRequest;
  isShyftApplied: boolean;
  saveShyftId?: number;
  isRegisterationPending: boolean;
  profileComplition: number;
  isLocationModalOpen: boolean;
  filter: string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyShyftsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  scrollObserver: IntersectionObserver | null = null;
  elementRef: RefObject<HTMLDivElement>;
  skipFirstCall = true;
  getAllShyftsAPICallId: string = "";
  getNextShyftsAPICallId: string = "";
  getShyftDetailsAPICallId: string = "";
  getCurrentUserApiCallId: string = "";
  postBookmarkShyftAPICallId: string = "";
  deleteBookmarkShyftAPICallId: string = "";
  postApplyShyftAPICallId: string = "";
  postWithdrawShyftAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.elementRef = createRef<HTMLDivElement>();

    this.subScribedMessages = [
      getName(MessageEnum.NavigationSearchShyftsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      search: "",
      shyftsList: [],
      page: 1,
      perPage: 15,
      totalPages: 1,
      totalRecords: 0,
      shyftDetails: null,
      selectedShyft: null,
      profileComplition: 0,
      isShyftApplied: false,
      isRegisterationPending: false,
      isLocationModalOpen: false,
      filter: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationSearchShyftsMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationSearchShyftsMessage)
      );
      this.setState({ filter: data.filter });
      this.setState({ search: data.search });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleRestAPIResponses(responseJson, apiRequestCallId);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleRestAPIResponses = (responseJson: any, apiRequestCallId: string) => {
    if (
      [this.getAllShyftsAPICallId, this.getNextShyftsAPICallId].includes(
        apiRequestCallId
      )
    ) {
      this.handleShyftsListingResponse(responseJson, apiRequestCallId);
    }

    if (
      [this.postApplyShyftAPICallId, this.postWithdrawShyftAPICallId].includes(
        apiRequestCallId
      )
    ) {
      this.handleShyftsApplyResponse(responseJson, apiRequestCallId);
    }

    if (
      [
        this.postBookmarkShyftAPICallId,
        this.deleteBookmarkShyftAPICallId,
      ].includes(apiRequestCallId)
    ) {
      this.handleSaveShyftsResponse(apiRequestCallId);
    }

    if (this.getShyftDetailsAPICallId === apiRequestCallId) {
      this.handleShyftDetailsData(
        responseJson.shyft_details.data,
        responseJson.shyft_details.meta.ratings_data,
        responseJson.shyft_details.meta.request
      );
    }

    if (this.getCurrentUserApiCallId === apiRequestCallId) {
      this.setCurrentProfileCompletion(
        responseJson.data.attributes.profile_completion
      );
    }
  };

  handleShyftsListingResponse = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (this.getAllShyftsAPICallId === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.explore_shifts.data,
        responseJson.pagination_details,
        "initial"
      );
    }

    if (this.getNextShyftsAPICallId === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.explore_shifts.data,
        responseJson.pagination_details,
        "next"
      );
    }
  };

  handleShyftsApplyResponse = (response: any, apiRequestCallId: string) => {
    if (this.postApplyShyftAPICallId === apiRequestCallId) {
      this.setShyftRequestData(response);
    }

    if (this.postWithdrawShyftAPICallId === apiRequestCallId) {
      this.setState({
        request: undefined,
      });
    }
  };

  handleSaveShyftsResponse = (apiRequestCallId: string) => {
    if (this.postBookmarkShyftAPICallId === apiRequestCallId) {
      this.setExploreSaveShiftData("save");
    }

    if (this.deleteBookmarkShyftAPICallId === apiRequestCallId) {
      this.setExploreSaveShiftData("unsave");
    }
  };

  getCurrentUserProfile() {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCurrentUserApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setCurrentProfileCompletion(profileComplition: number) {
    this.setState({
      profileComplition,
    });
  }

  getAllShifts(
    { search, page, filter }: TQueryParams,
    type: "initial" | "next"
  ) {
    const header = {
      "Content-Type": configJSON.getShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = constructURL({
      endpoint: configJSON.getShiftsApiEndPoint,
      search,
      page,
    });

    const requestBody = { filter: constructSearchFilter({ filter }) };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftsApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    if (type === "initial") {
      this.getAllShyftsAPICallId = requestMessage.messageId;
    } else {
      this.getNextShyftsAPICallId = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShiftsData(
    shifts: TAPIShyftDetails[],
    pagination: TPagination,
    type: "initial" | "next"
  ) {
    const shyftsList = formatShiftsListResponse(shifts);
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );

    if (type === "initial") {
      this.setState({
        totalPages,
        shyftsList,
        selectedShyft: shyftsList[0]?.id,
        totalRecords: pagination.total_records,
      });
    } else {
      this.setState((prevState) => ({
        totalPages,
        shyftsList: [...prevState.shyftsList, ...shyftsList],
      }));
    }
  }

  onSearchShifts = (value: string) => {
    this.setState({ search: value });
  };

  onSelectShift = (shyftId: number) => {
    this.setState({ selectedShyft: shyftId });
  };

  handleExploreFilterShifts = (filter: string[], reset?: string) => {
    const { search, page, perPage } = this.state;
    this.getAllShifts({ search, page, perPage, filter }, "initial");
    if (reset) {
      this.getAllShifts({ search, page, perPage }, "initial");
    }
  };

  listenForScrollIntersection() {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (this.skipFirstCall) {
          this.skipFirstCall = false;
          return;
        }

        if (this.state.page < this.state.totalPages) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };
    this.scrollObserver = new IntersectionObserver(callback);
    if (this.elementRef.current)
      this.scrollObserver.observe(this.elementRef.current);
  }

  async componentDidMount() {
    const { filter, search, page, perPage } = this.state;
    this.getCurrentUserProfile();
    this.getAllShifts({ search, page, perPage, filter }, "initial");
    this.listenForScrollIntersection();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.search !== prevState.search) {
      const { search, page, perPage, filter } = this.state;
      this.getAllShifts({ search, page, perPage, filter }, "initial");
    }

    if (this.state.page !== prevState.page) {
      const { search, page, perPage } = this.state;
      this.getAllShifts({ search, page, perPage }, "next");
    }

    if (
      this.state.selectedShyft &&
      this.state.selectedShyft !== prevState.selectedShyft
    ) {
      this.getShiftsDetails(this.state.selectedShyft);
    }
  }

  async componentWillUnmount() {
    if (this.scrollObserver) this.scrollObserver.disconnect();
  }

  getShiftsDetails(shyftId: number) {
    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    this.getShyftDetailsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShyftDetailsData(
    shift: TAPIShyftDetails,
    ratings: TRatings,
    request?: TShyftRequest
  ) {
    const shyftDetails = formatShyftDetails(shift, ratings);
    this.setState({ shyftDetails, request });
  }

  checkIfAppliedeForShyft = (request?: TShyftRequest) => {
    if (!request) return false;
    return request.status === "pending";
  };

  applyForShyft = (shiftId: number) => {
    // Temporarily Disabling for testing
    // if (this.state.profileComplition < 100) {
    //   this.setState({ isRegisterationPending: true });
    //   return;
    // }

    const header = {
      "Content-Type": configJSON.postApplyShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postApplyShyftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
          status: "pending",
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApplyShyftApiMethodType
    );

    this.postApplyShyftAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setShyftRequestData = (responseJson: any) => {
    this.setState({
      isShyftApplied: true,
      request: {
        id: responseJson.data.attributes.id,
        status: responseJson.data.attributes.status,
        removal_reason: responseJson.data.attributes.removal_reason,
        updated_at: responseJson.data.attributes.updated_at,
        created_at: responseJson.data.attributes.created_at,
        shift_id: responseJson.data.attributes.id,
        worker_id: responseJson.data.attributes.worker_id,
      },
    });
  };

  withdrawFromShyft = (shiftId: number) => {
    const header = {
      "Content-Type": configJSON.postWithdrawShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawShyftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    this.postWithdrawShyftAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleExploreSaveShyft = (shiftId: number, isSaved: boolean) => {
    if (isSaved) {
      this.unSaveExploreShyft(shiftId);
    } else {
      this.saveExploreShyft(shiftId);
    }
  };

  saveExploreShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postBookmarkShyftAPICallId = requestMessage.messageId;
    this.setState({ saveShyftId: id });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSaveShyftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        saved_shift: {
          shift_id: id,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSaveShyftApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unSaveExploreShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteBookmarkShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaveShyftApiEndPoint}/${id}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  setExploreSaveShiftData = (type: "save" | "unsave") => {
    const { shyftsList, saveShyftId } = this.state;
    const updatedList = [...shyftsList];

    const shyftIndex = shyftsList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );

    if (shyftIndex !== -1) {
      updatedList[shyftIndex].saved = type === "save";
    }

    this.setState({
      shyftsList: updatedList,
    });
  };

  closeApplyShiftModal = () => {
    this.setState({ isShyftApplied: false });
  };

  closePendingRegistrationModal = () => {
    this.setState({ isRegisterationPending: false });
  };

  openLocationModal = () => {
    this.setState({ isLocationModalOpen: true });
  };

  closeLocationModal = () => {
    this.setState({ isLocationModalOpen: false });
  };

  onViewBusinessProfile = (businessId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData("businessId", businessId);
    this.send(msg);
  };
  // Customizable Area End
}
