import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export const configJSON = require("./config");
import moment from "moment";
import { ProfileImg } from "./assets";
import {
  convertDateIntoTwelveHourFormat,
  convertDateToCalenderFormat,
  setKeyInLocalStorage,
  getKeyFromLocalStorage,
  filterValueFromArray,
} from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";

interface ShyftType {
  id: number;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  saved: boolean;
}

interface ShyftDetailsType {
  id: number;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  isSavedShyft: boolean;
  requirements: string[];
  description: string;
  experience: string;
  businessName: string;
  businessType: string;
  overallRatings: number;
  ratings: TComment[];
  businessId: number;
  ratingsCount: number;
  reviewsCount: number;
}

interface TComment {
  id: number;
  comment: string;
  score: number;
  shift_type: string;
  worker_name: string;
  created_at: string;
  worker_profile_picture: string;
}

interface APIShyftDetailsType {
  attributes: {
    activated: boolean;
    amount: number;
    amount_per: string;
    business_details: {
      business: {
        address: string;
        created_at: string;
        dba_name: string;
        hotel_name: string;
        hotel_type: string;
        restuarant_name: string;
        restaurant_type: string;
        id: number;
        telephone: string;
        updated_at: string;
        user_id: number;
        website: string;
      };
      employer_profile_picture: string | null;
    };
    business_type: string;
    created_at: string;
    description: string;
    employer_id: number;
    expirence: string;
    id: number;
    isAvailable: boolean;
    requirements: string[];
    saved: boolean;
    shift_from: string;
    shift_to: string;
    shift_type: string;
  };
}

interface RatingsType {
  overall_rating: number;
  ratings: TComment[];
  ratings_count: number;
  reviews_count: number;
}

interface TShyftRequest {
  id: number;
  status: string;
  removal_reason: string;
  updated_at: string;
  created_at: string;
  shift_id: number;
  worker_id: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  isShowLocationModal: boolean;
  isShowSearchModal: boolean;
  signUpMessage: string;
  userData: any;
  profileComplition: number;
  shyftsList: ShyftType[];
  recommendedShyftsList: ShyftType[];
  selectedShyft: number;
  saveShyftId?: number;
  shyftDetails: ShyftDetailsType | null;
  shyftRequest?: TShyftRequest;
  isRegisterationPendingModalOpen: boolean;
  recentSearches: string[];
  isAppliedModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShyfterLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getShyfterApiCallId: string = "";
  getAllShiftsAPICallId: string = "";
  getRecommnededShiftsAPICallId: string = "";
  getShiftDetailsAPICallId: string = "";
  postApplyShyftAPICallId: string = "";
  postWithdrawShyftAPICallId: string = "";
  postSaveShyftAPICallId: string = "";
  deleteSaveShyftAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.ShyfterRegistrationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      profileComplition: 0,
      isShowLocationModal: false,
      isShowSearchModal: false,
      signUpMessage: "",
      userData: "",
      shyftsList: [],
      recommendedShyftsList: [],
      selectedShyft: -1,
      shyftDetails: null,
      isRegisterationPendingModalOpen: false,
      isAppliedModalOpen: false,
      recentSearches: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    this.getShyfterProfile();
    this.getAllShifts();
    this.getRecommendedShifts();
    this.getRecentSearches();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.selectedShyft !== prevState.selectedShyft) {
      const shift = this.state.shyftsList[this.state.selectedShyft];
      this.getExploreShiftsDetails(shift.id);
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ShyfterRegistrationMessage) === message.id) {
      const signUpMessage = message.getData(
        getName(MessageEnum.ShyfterRegistrationMessage)
      );
      this.setState({ signUpMessage: signUpMessage });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
  }

  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getShyfterApiCallId) {
      this.getShyfterProfileRes(responseJson);
    }

    if (this.getAllShiftsAPICallId === apiRequestCallId) {
      this.handleShyfts(responseJson.explore_shifts.data, "explore");
    }

    if (this.getRecommnededShiftsAPICallId === apiRequestCallId) {
      this.handleShyfts(responseJson.recommended_shifts.data, "recommended");
    }

    if (this.getShiftDetailsAPICallId === apiRequestCallId) {
      this.handleShyftDetails(
        responseJson.shyft_details.data,
        responseJson.shyft_details.meta.ratings_data,
        responseJson.shyft_details.meta.request
      );
    }

    if (this.postApplyShyftAPICallId === apiRequestCallId) {
      this.setRequestData(responseJson.data);
    }

    if (this.postWithdrawShyftAPICallId === apiRequestCallId) {
      this.setState({
        shyftRequest: undefined,
      });
    }

    if (this.postSaveShyftAPICallId === apiRequestCallId) {
      this.setSaveShiftData("save");
    }

    if (this.deleteSaveShyftAPICallId === apiRequestCallId) {
      this.setSaveShiftData("unsave");
    }
  }

  // Customizable Area Start
  getRecentSearches = () => {
    const recentSearches = getKeyFromLocalStorage("recentSearches") as string[];
    this.setState({ recentSearches });
  };

  addQueryToRecentSearches = (query: string) => {
    const updatedSearches = [
      query,
      ...filterValueFromArray(this.state.recentSearches, query),
    ].slice(0, 5);

    setKeyInLocalStorage("recentSearches", updatedSearches);
  };

  removeQueryFromRecentSearches = (query: string) => {
    const updatedSearches = filterValueFromArray(
      this.state.recentSearches,
      query
    );
    this.setState({ recentSearches: updatedSearches });
    setKeyInLocalStorage("recentSearches", updatedSearches);
  };

  setSaveShiftData = (type: "save" | "unsave") => {
    const { recommendedShyftsList, shyftsList, saveShyftId } = this.state;

    const exploreList = [...shyftsList];
    const recommendedList = [...recommendedShyftsList];

    const exploreShyftIndex = shyftsList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );
    const recommendedShyftIndex = recommendedList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );

    if (exploreShyftIndex !== -1) {
      exploreList[exploreShyftIndex].saved = type === "save";
    }

    if (recommendedShyftIndex !== -1) {
      recommendedList[recommendedShyftIndex].saved = type === "save";
    }

    this.setState({
      recommendedShyftsList: recommendedList,
      shyftsList: exploreList,
    });
  };

  navigateToMyShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyShyftsPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/MyShyfts"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  setRequestData = (data: any) => {
    this.setState({
      isAppliedModalOpen: true,
      shyftRequest: {
        id: data.attributes.id,
        status: data.attributes.status,
        removal_reason: data.attributes.removal_reason,
        updated_at: data.attributes.updated_at,
        created_at: data.attributes.created_at,
        shift_id: data.attributes.id,
        worker_id: data.attributes.worker_id,
      },
    });
  };

  navigateToShyfterProfile() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ShyfterProfilePage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigatoShyfterProfileMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigatoShyfterProfileMessage),
      "Your Account"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  navigateToPastShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PastShyftPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "PastShyft"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handleViewAllRecomendedShyfts() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RecomendedShyfts"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPastShyftMessage), "");
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handleExploreShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  getAllShifts() {
    const header = {
      "Content-Type": configJSON.getShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = `${configJSON.getShiftsApiEndPoint}?sort_order=desc&sort_column=created_at`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftsApiMethodType
    );

    this.getAllShiftsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecommendedShifts() {
    const header = {
      "Content-Type": configJSON.getRecommnededShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = `${configJSON.getRecommnededShiftsApiEndPoint}?sort_order=desc&sort_column=created_at&page=1&per_page=3`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRecommnededShiftsApiMethodType
    );

    this.getRecommnededShiftsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getExploreShiftsDetails(shyftId: number) {
    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getShiftDetailsAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  setSelectedShyft(shyftIndex: number) {
    this.setState({ selectedShyft: shyftIndex });
  }

  showLocationModal = () => {
    this.setState({
      isShowLocationModal: true,
    });
  };
  closeLocationModal() {
    this.setState({
      isShowLocationModal: false,
    });
  }
  showSearchModal() {
    this.setState({
      isShowSearchModal: true,
    });
  }
  closeSearchModal() {
    this.setState({
      isShowSearchModal: false,
    });
  }

  closeAppliedShiftModal = () => {
    this.setState({
      isAppliedModalOpen: false,
    });
  };

  closeRegistrationModal = () => {
    this.setState({
      isRegisterationPendingModalOpen: false,
    });
  };

  getShyfterProfile() {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShyfterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getShyfterProfileRes(responseJson: any) {
    this.setState({
      userData: responseJson.data.attributes,
      profileComplition: responseJson.data.attributes.profile_completion,
    });
  }

  handleMoreOptionsNavigation = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  };

  handleShyfts(shifts: APIShyftDetailsType[], type: "recommended" | "explore") {
    const shyftsList: ShyftType[] = shifts.map((shift) => {
      return {
        id: shift.attributes.id,
        employername: shift.attributes.business_details.business.dba_name,
        userImg: shift.attributes.business_details.employer_profile_picture
          ? ProfileImg
          : "",
        syftTimimg: `${moment(shift.attributes.shift_from).format(
          "hh:mm A"
        )} - ${moment(shift.attributes.shift_to).format("hh:mm A")}`,
        billing: `$${shift.attributes.amount} Per ${shift.attributes.amount_per}`,
        day: convertDateToCalenderFormat(shift.attributes.created_at),
        shyft: shift.attributes.shift_type,
        shyftPlace: shift.attributes.business_type,
        county: shift.attributes.business_details.business.address,
        saved: shift.attributes.saved,
      };
    });

    if (type === "explore") {
      this.setState({ shyftsList, selectedShyft: 0 });
    } else {
      this.setState({ recommendedShyftsList: shyftsList });
    }
  }

  onSearchShifts = (value: string) => {
    if (!value) return;
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationSearchShyftsMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationSearchShyftsMessage), {
      search: value,
    });
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.addQueryToRecentSearches(value);
    this.send(msg);
  };

  navigateToExploreShift = (filter: string[]) => {
    if (!filter.length) return;
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationSearchShyftsMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationSearchShyftsMessage), {
      filter,
    });
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  handleShyftDetails(
    shift: APIShyftDetailsType,
    ratings: RatingsType,
    shyftRequest?: TShyftRequest
  ) {
    const shyftDetails = {
      id: shift.attributes.id,
      employername: shift.attributes.business_details.business.dba_name,
      userImg: shift.attributes.business_details.employer_profile_picture
        ? ProfileImg
        : "",
      syftTimimg: `${convertDateIntoTwelveHourFormat(
        shift.attributes.shift_from
      )} - ${convertDateIntoTwelveHourFormat(shift.attributes.shift_to)}`,
      billing: `$${shift.attributes.amount} per ${shift.attributes.amount_per}`,
      day: convertDateToCalenderFormat(shift.attributes.created_at),
      shyft: shift.attributes.shift_type,
      shyftPlace: shift.attributes.business_type,
      county: shift.attributes.business_details.business.address,
      isSavedShyft: shift.attributes.saved,
      requirements: shift.attributes.requirements,
      description: shift.attributes.description,
      experience: shift.attributes.expirence,
      businessId: shift.attributes.business_details.business.user_id,
      businessName: ["hotel", "Hotel"].includes(shift.attributes.business_type)
        ? shift.attributes.business_details.business.hotel_name
        : shift.attributes.business_details.business.restuarant_name,
      businessType: ["hotel", "Hotel"].includes(shift.attributes.business_type)
        ? shift.attributes.business_details.business.hotel_type
        : shift.attributes.business_details.business.restaurant_type,
      overallRatings: ratings.overall_rating,
      ratings: ratings.ratings.filter(Boolean),
      ratingsCount: ratings.ratings_count,
      reviewsCount: ratings.reviews_count,
    };
    this.setState({ shyftDetails, shyftRequest });
  }

  handleSaveShyft = (shiftId: number, isSaved: boolean) => {
    if (isSaved) {
      this.unSaveTheShyft(shiftId);
    } else {
      this.saveTheShyft(shiftId);
    }
  };

  saveTheShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postSaveShyftAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSaveShyftApiEndPoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        saved_shift: {
          shift_id: id,
        },
      })
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(request.id, request);
  };

  unSaveTheShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteSaveShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaveShyftApiEndPoint}/${id}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  checkIfAppliedeShyft = (request?: TShyftRequest) => {
    if (!request) return false;
    return request.status === "pending";
  };

  applyForTheShyft = (shiftId: number) => {
    // Temporarily Disabling for testing
    // if (this.state.profileComplition < 100) {
    //   this.setState({ isRegisterationPendingModalOpen: true });
    //   return;
    // }

    const header = {
      "Content-Type": configJSON.postApplyForShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postApplyShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postApplyForShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
          status: "pending",
        },
      })
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApplyForShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  withdrawFromTheShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.postWithdrawFromShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postWithdrawShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawFromShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: id,
        },
      })
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawFromShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  navigateToBusinessProfile = (businessId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );

    setStorageData("businessId", businessId);
    this.send(message);
  };
}
