import React from "react";
import {
  Paper,
  Divider,
  makeStyles,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  briefcaseIcon,
  clockIcon,
  greenArrow,
  moneyIcon,
  redArrow,
} from "./assets";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";

const useStyles = makeStyles((theme) => ({
  shyftCardContainer: (props: IShyftCardProps) => ({
    borderRadius: 12,
    border: "1px solid",
    cursor: "pointer",
    borderColor: props.isActive ? "#390879" : "#e1e6ec",
    padding: 14,
    marginBottom: 16,
    " & .shyftHeadWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: 14,
    },
    " & .userRoleWrapper": {
      display: "flex",
      alignItems: "center",
    },
    " & .userRoleName": {
      fontSize: 16,
      fontWeight: 500,
      color: "#0a0412",
      paddingLeft: 10,
    },
    " & .workIcon": {
      color: "#390879",
    },
    " & .userRoleSmall": {
      color: "#390879",
      fontSize: 14,
      fontWeight: 500,
    },
    " & .workIconSmall": {
      color: "#390879",
      height: 14,
      width: 14,
      marginRight: 3,
    },
    " & .workIconWrapper": {
      backgroundColor: "rgba(57,8,121,0.050980392156862744)",
      height: 42,
      width: 42,
      borderRadius: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    " & .flexItem": {
      display: "flex",
      alignItems: "center",
    },
    " & .activityWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 0px 0px",
    },
    " & .amountValue": {
      fontSize: 14,
      color: "#6f7c8e",
      fontWeight: 500,
      paddingLeft: 6,
    },
    " & .activityLabel": {
      color: "#6f7c8e",
      fontSize: 11,
      fontWeight: 500,
    },
    " & .activityValue": {
      color: "#0a0412",
      fontSize: 12,
      fontWeight: 500,
      paddingTop: 6,
    },
    " & .rightIconButton": {
      backgroundColor: "#e1e6ec",
      width: 34,
      height: 34,
      padding: 6,
    },
    " & .userImage": {
      borderRadius: 12,
      marginRight: 6,
    },
    " & .userName": {
      fontSize: 16,
      fontWeight: 500,
      color: "#0a0412",
      marginBottom: 3,
    },
    " & .amountLabel": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#6f7c8e",
      fontWeight: 500,
      fontFamily: "'Gotham', sens-serif",
      display: "flex",
      alignItems: "center",
      paddingLeft: 5,
      paddingBottom: 5,
    },
    " & .amountContainer": {
      borderRadius: "10px",
      backgroundColor: "#e1e6ec4d",
      padding: 6,
    },
    " & .amountLabelSpan": {
      paddingLeft: 5,
    },
    " & .amountValueDark": {
      color: "#0a0412",
    },
    " & .loginActivity": {
      color: "#2da406",
      fontWeight: 500,
      fontFamily: "'Gotham', sens-serif",
      fontSize: 13,
      display: "flex",
      alignItems: "center",
    },
    " & .logoutActivity": {
      color: "#ff375b",
      fontWeight: 500,
      fontFamily: "'Gotham', sens-serif",
      fontSize: 13,
      display: "flex",
      alignItems: "center",
    },
    " & .recentActivity": {
      fontSize: "11px",
      lineHeight: "16px",
      color: "#0a0412",
      fontWeight: 500,
      fontFamily: "'Gotham', sens-serif",
    },
    "& .activityStatus": {
      color: props.recentActivityType === "Login" ? "#2da406" : "#ff375b",
    },
    " & .completedTimeWrapper": {
      paddingRight: 10,
      borderRight: "1px solid rgb(225, 230, 236)"
    }
  }),
}));

interface IShyftCardProps {
  userRole: string;
  userName?: string;
  userImage?: string;
  amount: string;
  fromTime: string;
  toTime: string;
  showTimingAndAmountLabel: boolean;
  recentActivityType?: "Login" | "Logout";
  shyftCreationTime?: string;
  shyftEndTime?: string;
  shyftActivityTime?: string;
  showUsername?: boolean;
  isActive: boolean;
  type: "create" | "ongoing" | "complete";
  amountType?: string;
  handleClick: (e: any) => void;
  handleMenuClick: (e: any) => void;
}

const EmployerShyftCard: React.FC<IShyftCardProps> = ({
  userRole,
  userName,
  amount,
  userImage,
  shyftActivityTime,
  showUsername,
  shyftCreationTime,
  shyftEndTime,
  recentActivityType,
  fromTime,
  toTime,
  showTimingAndAmountLabel,
  isActive,
  type,
  amountType,
  handleClick,
  handleMenuClick,
}) => {
  const classes = useStyles({
    userRole,
    userName,
    amount,
    userImage,
    shyftActivityTime,
    shyftCreationTime,
    shyftEndTime,
    recentActivityType,
    showUsername,
    fromTime,
    toTime,
    showTimingAndAmountLabel,
    isActive,
    type,
    amountType,
    handleClick,
    handleMenuClick,
  });

  return (
    <Paper
      elevation={0}
      className={classes.shyftCardContainer}
    >
      <div className="shyftHeadWrapper">
        {!showUsername ? (
          <div className="userRoleWrapper">
            <div className="workIconWrapper">
              <img src={briefcaseIcon} height={20} className="workIcon" />
            </div>
            <div className="userRoleName">{userRole}</div>
          </div>
        ) : (
          <div className="flexItem">
            <img
              className="userImage"
              src={userImage}
              height={42}
              width={42}
              alt="user"
            />
            <div>
              <div className="userName">{userName}</div>
              <div className="flexItem">
                <img src={briefcaseIcon} className="workIconSmall" />
                <div className="userRoleSmall">{userRole}</div>
              </div>
            </div>
          </div>
        )}
        <IconButton
          size="small"
          onClick={handleMenuClick}
          data-test-id="menu-icon"
        >
          <MoreHorizIcon />
        </IconButton>
      </div>
      {!showTimingAndAmountLabel ? (
        <Grid container spacing={2}>
          <Grid item xs={6} className="flexItem">
            <img src={clockIcon} height={16} alt="clock" />
            <div className="amountValue">
              {fromTime} - {toTime}
            </div>
          </Grid>
          <Grid item xs={6} className="flexItem">
            <img src={moneyIcon} height={12} width={16} alt="clock" />
            <div className="amountValue">
              ${amount} Per {amountType}
            </div>
          </Grid>
          <Grid item xs={12} />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="amountContainer">
              <div className="amountLabel">
                <img src={clockIcon} height={16} alt="clock" />
                <span className="amountLabelSpan">Shyft Timing</span>
              </div>

              <div className="amountValue amountValueDark">
                {fromTime} - {toTime}
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="amountContainer">
              <div className="amountLabel">
                <img src={moneyIcon} height={12} width={16} alt="clock" />
                <span className="amountLabelSpan">Amount of Shyft</span>
              </div>
              <div className="amountValue amountValueDark">
                ${amount} Per {amountType}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} />
        </Grid>
      )}
      <Divider />
      <div className="activityWrapper">
        <div>
          {type === "create" && (
            <div className="activityLabel">Shyft Created on:</div>
          )}
          {type === "ongoing" && (
            <div className="recentActivity">
              Recent Activity{" "}
              <span className="activityStatus">| {recentActivityType}</span>
            </div>
          )}
          {type === "complete" && (
            <div className="completedTimeWrapper">
              <div className="activityLabel">Shyft Ended Successfully</div>
              <div className="loginActivity">{shyftActivityTime}</div>
            </div>
          )}
          {type === "create" && (
            <div className="activityValue">{shyftCreationTime}</div>
          )}
          {type === "ongoing" && recentActivityType === "Logout" && (
            <div className="logoutActivity">
              {shyftActivityTime}{" "}
              <img src={redArrow} width={16} style={{ paddingLeft: 5 }} />
            </div>
          )}
          {type === "ongoing" && recentActivityType === "Login" && (
            <div className="loginActivity">
              {shyftActivityTime}{" "}
              <img src={greenArrow} style={{ paddingLeft: 5 }} width={16} />
            </div>
          )}
        </div>
        <IconButton className="rightIconButton" data-test-id="shyft-card" onClick={handleClick}>
          <KeyboardArrowRightRoundedIcon />
        </IconButton>
      </div>
    </Paper>
  );
};

export default EmployerShyftCard;
