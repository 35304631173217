// Customizable Area Start
import React from "react";

import { Box, Container, Grid, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import { callCenterImg, contactMailImg, pinImg } from "./assets";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";

import HelpCenterWebController, { Props, configJSON } from "./HelpCenterWebController.web";
import CustomInputTextBox from "../../../components/src/CustomInputTextBoxField.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import CustomInput from "../../../components/src/CustomInputField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import ContactUsPopup from "../../../components/src/ContactUsPopUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      contrastText: "#fff",
    },
  },
});

const CustomFormWrapper = styled(Box)({
  width: "90%",
  padding: '10px', 
  margin: 'auto',
  '&.box_wrapper > .MuiOutlinedInput-multiline':{
    padding: '0px !important'
  }
})

export default class HelpCenterWeb extends HelpCenterWebController {
  constructor(props: Props) {
    super(props);
  }

  renderContactDetails = () => {
    return (
      <Box style={webStyle.contactDetailsWrapper}>
        <div style={webStyle.contactDetailSubWrapper} >
          <img src={callCenterImg} alt="phone_img" style={webStyle.leftImgStyle} />
          
          <div style={webStyle.detailTextWrapper}>
            <Typography  variant="h6" style={webStyle.detailMainText}> Call Us </Typography>
            
            <div style={webStyle.detailSubTextWrapper}> 
              <span>
                <label style={webStyle.subTextstyle}>+1-5563 00321</label>
                <label style={webStyle.subTextstyle}> | </label>
                <label style={webStyle.subTextstyle}>+1-5563 00322</label>
              </span>
            </div>

          </div>
        </div>

        <div style={webStyle.contactDetailSubWrapper} >
          <img src={contactMailImg} alt="email_img" style={webStyle.leftImgStyle} />

          <div style={webStyle.detailTextWrapper}>
            <Typography  variant="h6" style={webStyle.detailMainText}> Email Us </Typography>

            <div style={webStyle.detailSubTextWrapper}> 
              <span>
                <label style={webStyle.subTextstyle}>support@snagashyft.com</label>
                <br/>
                <label style={webStyle.subTextstyle}>info@snagashyft.com</label>
              </span>
            </div>

          </div>
        </div>


        <div style={webStyle.contactDetailSubWrapper} >
          <img src={pinImg} alt="location_img" style={webStyle.leftImgStyle} />
          
          <div style={webStyle.detailTextWrapper}>
            <Typography  variant="h6" style={webStyle.detailMainText}> Headquarter Address </Typography>
            
            <div style={webStyle.detailSubTextWrapper}> 
              <span>
                <label style={webStyle.subTextstyle}>733 Hickory Lane, Washinton, 20009</label>
                <br/>
                <label style={webStyle.subTextstyle}>United States</label>
              </span>
            </div>

          </div>
        </div>
      </Box>
    )
  }   

  renderContactFormHeading = () => {
    return (
      <Box sx={webStyle.formHeadingWrapper}>
            <Box sx={webStyle.headingSubWrapper}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 'bold', marginBottom: '5px'}}>
                Contact <span style={{color: '#BFDC46' }}> Form </span>
              </Typography>
              <Typography variant="h6" component="h6" style={webStyle.formSubHeadingStyle}>
                {configJSON.resolveYourissueText}
              </Typography>
              <Typography variant="h6" component="h6" style={webStyle.formSubHeadingStyle}>
                {configJSON.within24hrsText}
              </Typography>
            </Box>
      </Box>
    )
  }

  renderContactForm = (formData: any, errors: any) => {
    return(
        <>
            <CustomInput
                value={formData.fullName}
                handleChange={(event) => this.handleFormInputChange(event, "fullName")}
                label={configJSON.fullNameTitle}
                name="fullName"
                maxLength={30}
                error={!!errors.fullName}
            />
            <CustomSelect
                value={formData.reason}
                handleChange={(event) => this.handleFormInputChange(event, "reason")}
                label={configJSON.reasonTitle}
                name="reason"
                error={ !!errors.reason }
                options={configJSON.reasonList}
            />

            <CustomInputTextBox
                value={formData.message}
                handleChange={(event:any) => this.handleFormInputChange(event, "message")}
                label={configJSON.messageTitle}
                name="message"
                maxLength={200}
                error={ !!errors.message }
            />
        </>
    )
  }

  render() {
    const { formData, formDataErrors } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader {...this.props} isBreadcrumbs={true} breadCrumsData={this.state.helpCenterBreadCrumPath} currentPage={this.state.currentPage}/>
        <Box  width="100%" marginTop="8%">
          <Container style={{ width: '70%', marginTop: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                  {this.renderContactDetails()}
              </Grid>
              <Grid item xs={6}>
                <Box margin={"0px"}>
                    {this.renderContactFormHeading()}
                    <Box margin={"5px"} style={{ border: '1px solid lightgrey', borderRadius: '10px', padding: '10px', backgroundColor: '#fff', height: '295px' }}>
                      <CustomFormWrapper data-test-id="boxWrapperId" className="box_wrapper" >
                        <form className="form_wrapper">
                          {this.renderContactForm(formData, formDataErrors)}
                            <div style={webStyle.actionWrapper}>
                                <CustomButton
                                    label={'Submit'}
                                    handleClick={() => this.onFormSubmitHandler()}
                                />
                            </div>
                        </form>
                      </CustomFormWrapper>
                      
                    </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer />

        <ContactUsPopup 
          open={this.state.openPopup} 
          handleCancel={this.handleModalClose} 
          isSuccess={this.state.isSuccess}
          isApiError={this.state.helpApiError}
        />
      </ThemeProvider>
    );
  }
}


const webStyle = {
  contactDetailsWrapper: { 
    width: '90%', 
    height: '760px',  
    display: 'flex', 
    flexDirection: 'column' as 'column', 
    gap: '10px' 
  },
  contactDetailSubWrapper:{
    color: '#390879', 
    fontWeight: 'bold' as 'bold', 
    display: 'flex', 
    flexDirection: 'row' as 'row', 
    justifyContent: 'start', 
    alignItems: 'center',
    border: '1px solid lightgrey', 
    borderRadius: '10px',
    padding: '25px',
    backgroundColor: '#fff'
  },
  leftImgStyle: {
    width: '60px', 
    height: '60px',
    marginRight: '25px' 
  },
  detailTextWrapper: {
    flexDirection: 'column' as 'column' 
  },
  detailMainText: {
    color: 'black', 
    fontSize: '24px',
    fontWeight: 'bold' as 'bold', 
    textAlign: 'left' as 'left',
  },
  subTextstyle: {
    fontSize: '16px', 
    color: '#a9b1bb',
    fontWeight: 'bold' as 'bold'
  },
  detailSubTextWrapper: {
    marginTop: '5px',
  },
  formSubHeadingStyle: { 
    fontWeight: 'bold' as 'bold', 
    fontSize: '12px', 
    color: '#a9b1bb',
    textAlign: 'left' as 'left'
  },
  formHeadingWrapper: {
    display: "flex",
    flexDirection: "column" as 'column',
    alignItems: "center"
  },
  headingSubWrapper: { 
    flexDirection: 'row' as 'row', 
    justifyContent: 'flex-start', 
    alignItems: 'flex-start', 
    width: '100%', 
    marginBottom: '10px',
    marginLeft: '10px'
  },
  actionWrapper: {
    width: "50%",
    marginTop: "24px",
    paddingBottom: '30px',
    margin: 'auto'
  },
}

// Customizable Area End