import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import "./style.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    // inputBorders: {
    //   main: "#E1E6EC",
    // },
    // inputText: {
    //   main: "#6F7C8E",
    // },
  },
});

const LoginWrapper: FunctionComponent = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="page-layout">
        <div>
          <div className="login-image-wrapper">
            <img
              style={{
                height: "100%",
                maxWidth: 500,
              }}
              src={require("./assets/login_background.svg")}
            />
            <img
              style={{
                height: "100%",
                maxWidth: 200,
                position: "absolute",
              }}
              alt="The house from the offer."
              src={require("./assets/company_logo.svg")}
            />
          </div>
        </div>
        {children}
      </div>
    </ThemeProvider>
  );
};
export default LoginWrapper;
