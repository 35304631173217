import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
const KeyArrowRight = require("./assets/images/keyboard_arrow_down_black.png");
const HomeIcon = require("./logo.png");
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
const Briefcase = require("./assets/images/briefcase-blank.png");
const Comments = require("./assets/images/comments.png");
const PastShyft = require("./assets/images/time-past.png");
const Headset = require("./assets/images/headset.png");
const Document = require("./assets/images/document.png");
const Shieldcheck = require("./assets/images/shield-check.png");
const DashboardIcon = require("./assets/images/dashboard-icon.svg");

interface TabItemCardProps {
  tabName?: string;
  tabIcon?: string;
  isMobileView?: boolean;
  navTab?: boolean;
  isActive?: boolean;
  id?: number;
  getActiveTab?: any;
  activeTabIcon?: any;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    h1: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "26px",
      color: "#0a0412",
    },
    h3: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "15px",
      color: "#000",
    },
  },
});

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: "1px solid #e1e6ec",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      padding: "10px",
    },
    mobileCard: {
      backgroundColor: "#ffffff",
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        border: "none",
        borderRadius: "0px",
      },
    },
    iconWithBg: {
      backgroundColor: "rgba(225, 195, 247, 0.3)",
      height: "40px",
      width: "40px",
      margin: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "10px",
    },
    iconWithBgMobileView: {
      backgroundColor: "rgba(225, 195, 247, 0.3)",
      height: "24px",
      width: "24px",
      margin: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "10px",
    },
    cursor: {
      cursor: "pointer",
    },
    tabText: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "15px",
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
    },
    bgColor: {
      backgroundColor: "#e1e6ec",
      borderRadius: "10px",
      padding: "8px",
    },
    activeTab: {
      backgroundColor: "rgba(225, 195, 247, 0.3)",
    },
    hover: {
      "&:hover": {
        backgroundColor: "rgba(225, 195, 247, 0.3)",
        borderRadius: "10px",
        color: "rgba(225, 195, 247, 0.3)",
      },
    },
    navTitle: {
      color: "#0a0412",
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "18px",
      textTransform: "capitalize",
    },
  })
);

export const TabItemCard = (props: TabItemCardProps) => {
  const classes = useStyle();
  const {
    tabName,
    tabIcon,
    navTab,
    isActive,
    id,
    getActiveTab,
    activeTabIcon,
  } = props;
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      className={`${classes.cursor} ${classes.hover} ${navTab &&
        classes.bgColor} ${isActive && classes.activeTab}`}
      margin={"5px"}
      onClick={() => getActiveTab(id)}
      data-test-id="activeTab"
    >
      <Box
        display={"flex"}
        justifyContent={"space-start"}
        alignItems={"center"}
      >
        {navTab ? (
          <Box marginRight={"10px"}>
            <img
              src={isActive ? activeTabIcon : tabIcon}
              alt=""
              height={props.isMobileView ? "11px" : "20px"}
            />
          </Box>
        ) : (
          <Box
            className={
              props.isMobileView
                ? classes.iconWithBgMobileView
                : classes.iconWithBg
            }
          >
            <img
              src={!!tabIcon ? tabIcon : HomeIcon}
              alt=""
              height={props.isMobileView ? "11px" : "18px"}
            />
          </Box>
        )}

        <Typography variant="h3" className={classes.navTitle}>
          {tabName}
        </Typography>
      </Box>
      <Box
        width={"20px"}
        height={"20px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          src={KeyArrowRight}
          alt=""
          height={props.isMobileView ? "8px" : "14px"}
        />
      </Box>
    </Box>
  );
};

interface Props {
  navTab?: any;
  isMobileView?: boolean;
  pastShyft?: any;
  myShyft?: any;
  getActiveTab?: any;
  isEmployer?: boolean;
  otherPageNavigation?: any;
}
export default function SideNav(props: Props) {
  const classes = useStyle();
  const {
    pastShyft,
    myShyft,
    navTab,
    getActiveTab,
    isEmployer,
    otherPageNavigation,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      {!!navTab ? (
        <Box className={props.isMobileView ? classes.mobileCard : classes.card}>
          <Box>
            {navTab.map((tab: any) => {
              return (
                <TabItemCard
                  tabIcon={tab.tabIcon}
                  tabName={tab.tab}
                  key={tab.id}
                  navTab={!!navTab}
                  isActive={tab.isActive}
                  id={tab.id}
                  getActiveTab={getActiveTab}
                  activeTabIcon={tab.activeTabIcon}
                />
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box className={props.isMobileView ? classes.mobileCard : classes.card}>
          <Box>
            <TabItemCard
              tabIcon={HomeIcon}
              tabName={"Home"}
              isMobileView={props.isMobileView}
            />
          </Box>
          {!isEmployer && (
            <Box data-test-id="myShyftTab" onClick={myShyft}>
              <TabItemCard
                tabIcon={Briefcase}
                tabName={"My Shyfts"}
                isMobileView={props.isMobileView}
              />
            </Box>
          )}
          <Box>
            <TabItemCard
              tabIcon={Comments}
              tabName={"Messages"}
              isMobileView={props.isMobileView}
            />
          </Box>
          {isEmployer && (
            <Box
              data-test-id="dashboardId"
              onClick={() => otherPageNavigation("DashboardStats")}
            >
              <TabItemCard
                tabIcon={DashboardIcon}
                tabName={"Dashboard"}
                isMobileView={props.isMobileView}
              />
            </Box>
          )}
          {!isEmployer && (
            <Box data-test-id="pastShyftTab" onClick={pastShyft}>
              <TabItemCard
                tabIcon={PastShyft}
                tabName={"Past Shyfts"}
                isMobileView={props.isMobileView}
              />
            </Box>
          )}
          <Divider variant="middle" />
          <Box
            data-test-id="helpCenterId"
            onClick={() => otherPageNavigation("HelpCentre")}
          >
            <TabItemCard
              tabIcon={Headset}
              tabName={"Help Center"}
              isMobileView={props.isMobileView}
            />
          </Box>
          <Box
            data-test-id="termsAndConditionId"
            onClick={() => otherPageNavigation("TermsAndConditions")}
          >
            <TabItemCard
              tabIcon={Document}
              tabName={"Terms & Conditions"}
              isMobileView={props.isMobileView}
            />
          </Box>
          <Box
            data-test-id="privacyPolicyId"
            onClick={() => otherPageNavigation("PrivacyPolicy")}
          >
            <TabItemCard
              tabIcon={Shieldcheck}
              tabName={"Privacy Policy"}
              isMobileView={props.isMobileView}
            />
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}
