Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getShyfteUserProfileEndPoint = "account_block/users";
exports.getAllSubsEndPoint = "bx_block_custom_user_subs/subscriptions";
exports.emptyCreatedShyftTitle = "No Shyfts Added Yet!";
exports.emptyCreatedShyftDescription =
  "Please add your first job shyft and manage find the best candidate for the job role";
exports.emptyCompletedShyftTitle = "No Completed Shyfts Found!";
exports.emptyCompletedShyftDescription =
  "There is no completed shyfts found, please add shyft or end the ongoing shyft to see the completed status";
exports.emptyOngoingShyftTitle = "No Ongoing Shyfts Found!";
exports.emptyOngoingShyftDescription =
  "There is no ongoing shyfts found, please add shyft to view ongoing status";
exports.getCurrentUserProfileEndPoint = "account_block/current_user";
exports.addFirstShyftBtnLabel = "Add Shyft";
exports.createdShyftLabel = "Created Shyfts";
exports.ongoingShyftLabel = "Ongoing Shyfts";
exports.shyftAddedTitleLabel = "Shyft Added Successfully";
exports.shyftAddedDescription =
  "This shyft has been added successfully. Please review and either accept or deny active requests.";
exports.shyftUpdatedTitleLabel = "Shyft Updated Successfully";
exports.shyftUpdatedDescription =
  "This shyft has been updated successfully. Please review and either accept or deny active requests.";
exports.updateEmail = "Update Your Email";
exports.updatePhone = "Update Your Phone Number";
exports.createdShyftsAPIURL = "bx_block_events/created_shifts";
exports.ongoingShyftsAPIURL = "bx_block_events/ongoing_shifts";
exports.shyftDetailsAPIURL = "bx_block_events/shifts";
exports.republishShyftsAPIURL = "bx_block_events/republish_shift";
exports.completedShyftsAPIURL = "bx_block_events/completed_shifts";
exports.shyftActivityRecordAPIURL =
  "bx_block_events/activity_records?page=1&per_page=15";
exports.deleteAPIMethod = "DELETE";

exports.getShiftsApiContentType = "application/json";
exports.getShiftsApiMethodType = "POST";
exports.getShiftsApiEndPoint = "/bx_block_events/get_shyfts_to_explore";

exports.getRecommnededShiftsApiContentType = "application/json";
exports.getRecommnededShiftsApiMethodType = "POST";
exports.getRecommnededShiftsApiEndPoint =
  "/bx_block_events/get_shyfter_recommended_shyfts";

exports.getShiftDetailsApiContentType = "application/json";
exports.getShiftDetailsApiMethodType = "GET";
exports.getShiftDetailsApiEndPoint = "/bx_block_events/get_shyft_details";
exports.putAPIMethodType = "PUT";

exports.getVieWRequestApiContentType = "application/json";
exports.getVieWRequestApiMethodType = "GET";
exports.getVieWRequestEndPoint = "/bx_block_events/view_requests";

exports.postignoreRequestApiContentType = "application/json";
exports.postignoreRequestApiMethodType = "POST";
exports.postignoreRequestEndPoint = "bx_block_events/ignore_request";

exports.postaceeptRequestApiContentType = "application/json";
exports.postaceeptRequestApiMethodType = "POST";
exports.postaceeptRequestEndPoint = "bx_block_events/accept_request";

exports.postApplyForShyftApiContentType = "application/json";
exports.postApplyForShyftApiMethodType = "POST";
exports.postApplyForShyftApiEndPoint = "/bx_block_events/apply_shift";

exports.postWithdrawFromShyftApiContentType = "application/json";
exports.postWithdrawFromShyftApiMethodType = "PATCH";
exports.postWithdrawFromShyftApiEndPoint =
  "bx_block_request_management/withdraw_request";

exports.postSaveShyftApiContentType = "application/json";
exports.postSaveShyftApiMethodType = "POST";
exports.postSaveShyftApiEndPoint = "bx_block_events/saved_shifts";

exports.deleteSaveShyftApiContentType = "application/json";
exports.deleteSaveShyftApiMethodType = "DELETE";
exports.deleteSaveShyftApiEndPoint = "bx_block_events/saved_shifts";
exports.shyftsCountApiEndPoint = "bx_block_events/shift_count";

exports.minMaxRemoveReasonLimitCheck = /^.{4,80}$/;
exports.putAPIMethodType = "PUT"

exports.postRemovePersonApiEndpoint = "bx_block_events/remove_from_shift";
exports.putEndShyftApiEndPoint = "bx_block_events/end_shift?shift_id=";

exports.shyftActivityEndedTitleLabel = "End Successfully";
exports.shyftActivityEndedDescription =
  "The shyft has been ended successfully.Please rate the Shyfter on the work they have done";

exports.shyftActivityRemoveTitleLabel = "Removed Successfully";
exports.shyftActivityRemoveDescription =
    "Person has been removed successfully.Please rate the Shyfter on the work they have done";

exports.postRateAndReviewApiEndPoint = "bx_block_ratings/ratings";

exports.postCreateActivityApiEndPoint = "bx_block_events/activities/";
// Customizable Area End
