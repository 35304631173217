import React from "react";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { ArrowBack, ArrowBackIos } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import LoginWrapper from "../../../components/src/LoginWrapper";
import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationController";
import { verifiedIcon } from "./assets";
import CustomInput from "../../../components/src/CustomInputField.web";
import CustomDateInput from "../../../components/src/CustomDateInput.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import MultiValueInput from "../../../components/src/MultiValueInput.web";
import SkillList from "../../../components/src/SkillList.web";
import SkillSetDialog from "../../../components/src/SkillSetDialog.web";
import OtpPopUp from "../../../components/src/OtpPopUp";
import SubmittedFailedPopUp from "../../../components/src/SubmittedFailedPopUp";

const setMaxDate = () => {
  const maxSelectionDate = new Date();
  maxSelectionDate.setFullYear(new Date().getFullYear() - 18);
  return maxSelectionDate;
};

export const setDisabledVerifyOTP = (otp: string, seconds: number) => {
  return otp.length < 5 || seconds === 0;
};

const setSkillSetList = (type: string) => {
  let list: string[] = [];
  list =
    type === "hotel" ? configJSON.hotelSkills : configJSON.restaurantSkills;
  return list;
};

const webStyle = {
  stepWrapper: {
    maxWidth: 510,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column" as any,
    backgroundColor: "white",
    padding: "50px 75px",
  },
  backArrow: {
    backgroundColor: "#f2f2f9",
  },
  loginText: {
    fontWeight: 600 as any,
    fontSize: "16px",
    letterSpacing: "-0.31px",
    marginLeft: "8px",
  },
  header: {
    marginTop: "10%",
    fontSize: 36,
    fontWeight: "bold" as any,
    marginBottom: 14,
    marginLeft: 5,
  },
  shyfterText: { color: "#B9DF12" },
  subHeader: { marginBottom: "22px" },
  subHeaderText: {
    width: "100%",
    fontSize: 16,
    fontWeight: 600 as any,
    paddingLeft: 5,
  },
  subHeaderLine: { width: "75%" },
  verifiedIcon: {
    lineHeight: "18px",
    color: "#390879",
    cursor: "pointer",
    fontWeight: 600 as any,
    position: "absolute" as any,
    top: "12px",
    right: "14px",
  },
  verifyNowText: {
    lineHeight: "18px",
    fontSize: "12px",
    color: "#390879",
    cursor: "pointer",
    fontWeight: 600 as any,
    position: "absolute" as any,
    top: "14px",
    right: "14px",
  },
  mobileInputWrapper: {
    position: "relative" as any,
  },
  actionWrapper: {
    marginTop: "24px",
  },
  prevArrow: {
    backgroundColor: "#f1f5f8",
  },
  errorText: {
    color: "#ff0000",
    fontSize: "12px",
    marginTop: "-10px",
  },
};

export class ShyfterAccountRegistrationSteps extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      shyfterFormData,
      shyfterFormDataErrors,
      shyfterStep,
      openSkillSetDialog,
      snackbarMessage,
    } = this.state;

    return (
      <LoginWrapper>
        {!!snackbarMessage && (
          <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.handleSnackbarClose}
          >
            <Alert severity="warning" onClose={this.handleSnackbarClose}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        )}
        <Box style={webStyle.stepWrapper} id={"step1"}>
          <Box display="flex" alignItems="center">
            <IconButton
              color="primary"
              style={webStyle.backArrow}
              onClick={this.backToLogin}
              data-test-id="back-to-login"
            >
              <ArrowBack />
            </IconButton>
            <Typography
              style={webStyle.loginText}
              variant="body1"
              color={"primary"}
            >
              {configJSON.backToLogin}
            </Typography>
          </Box>
          <div style={webStyle.header}>
            <span>Become a</span>
            <span style={webStyle.shyfterText}> Shyfter</span>
          </div>
          <Box display="flex" alignItems="center" style={webStyle.subHeader}>
            <div style={webStyle.subHeaderText}>{this.getSubHeaderText()}</div>
            <div style={webStyle.subHeaderLine}>
              <Divider />
            </div>
          </Box>
          {shyfterStep === 1 && (
            <form>
              <CustomInput
                value={shyfterFormData.fullName}
                handleChange={(e) =>
                  this.handleShyfterInputChange(e, "fullName")
                }
                label={configJSON.fullName}
                name="fullName"
                maxLength={30}
                error={!!shyfterFormDataErrors.fullName}
              />
              <div style={webStyle.mobileInputWrapper}>
                <CustomInput
                  value={shyfterFormData.mobileNumber}
                  handleChange={(e) =>
                    this.handleShyfterInputChange(e, "mobileNumber")
                  }
                  label={configJSON.mobileNumber}
                  name="mobileNumber"
                  maxLength={10}
                  error={!!shyfterFormDataErrors.mobileNumber}
                />
                {this.getIsNumberVerified() ? (
                  <img
                    width={22}
                    src={verifiedIcon}
                    style={webStyle.verifiedIcon}
                  />
                ) : (
                  <span
                    style={webStyle.verifyNowText}
                    data-test-id="verify-btn"
                    onClick={this.handlePhoneVerification}
                  >
                    {configJSON.verifyNow}
                  </span>
                )}
              </div>
              <CustomInput
                value={shyfterFormData.email}
                handleChange={(e) => this.handleShyfterInputChange(e, "email")}
                label={configJSON.emailAddress}
                name="email"
                maxLength={30}
                error={!!shyfterFormDataErrors.email}
              />
              <CustomInput
                value={shyfterFormData.address}
                handleChange={(e) =>
                  this.handleShyfterInputChange(e, "address")
                }
                label={configJSON.address}
                name="address"
                maxLength={100}
                error={!!shyfterFormDataErrors.address}
              />
              <CustomDateInput
                handleChange={(e) =>
                  this.handleShyfterInputChange(e, "dateOfBirth")
                }
                value={shyfterFormData.dateOfBirth}
                label={configJSON.dateOfBirth}
                name="dateOfBirth"
                maxDate={setMaxDate()}
                error={!!shyfterFormDataErrors.dateOfBirth}
              />
              <CustomInput
                value={shyfterFormData.ssn}
                handleChange={(e) => this.handleShyfterInputChange(e, "ssn")}
                label={configJSON.enterSSN}
                name="ssn"
                maxLength={11}
                error={!!shyfterFormDataErrors.ssn}
              />
              <CustomSelect
                value={shyfterFormData.dlState}
                handleChange={(e) =>
                  this.handleShyfterInputChange(e, "dlState")
                }
                label={configJSON.selectDLState}
                name="dlState"
                error={!!shyfterFormDataErrors.dlState}
                options={configJSON.dlStateList}
              />
              <CustomInput
                value={shyfterFormData.dlNumber}
                handleChange={(e) =>
                  this.handleShyfterInputChange(e, "dlNumber")
                }
                label={configJSON.dlNumber}
                name="dlNumber"
                maxLength={11}
                error={!!shyfterFormDataErrors.dlNumber}
              />
              <div style={webStyle.actionWrapper}>
                <CustomButton
                  label={configJSON.next}
                  handleClick={this.moveShyfterNextStep}
                />
              </div>
              {this.state.vafiyOpen && (
                <OtpPopUp
                  isOpen={this.state.vafiyOpen}
                  otp={this.state.otp}
                  resend={this.handlePhoneVerification}
                  handleClose={this.handleOTPDialogClose}
                  phone={this.state.shyfterFormData.mobileNumber}
                  setOtp={this.setOTP}
                  seconds={this.state.seconds}
                  handleChange={this.handleOTPDialogClose}
                  handleSubmit={() =>
                    this.verifyOTPRequest(
                      shyfterFormData.mobileNumber,
                      this.state.otp
                    )
                  }
                  disabled={setDisabledVerifyOTP(
                    this.state.otp,
                    this.state.seconds
                  )}
                />
              )}
            </form>
          )}
          {shyfterStep === 2 && (
            <form>
              <CustomSelect
                value={shyfterFormData.experience}
                handleChange={(e) =>
                  this.handleShyfterInputChange(e, "experience")
                }
                label={configJSON.yourExperience}
                name="experience"
                error={!!shyfterFormDataErrors.experience}
                options={configJSON.experienceList}
              />
              <MultiValueInput
                values={shyfterFormData.languageSkills}
                handleChange={this.handleLanguageChange}
                name="languageSkills"
                label={configJSON.languageSkills}
                error={!!shyfterFormDataErrors.languageSkills}
              />
              <SkillList
                type="restaurant"
                title="Restaurant"
                items={shyfterFormData.restaurantSkills}
                deleteItem={(skill) =>
                  this.deleteSkillItem("restaurant", skill)
                }
                openSkillDialog={(type) => this.toggleSkillSetDialog(type)}
              />
              <SkillList
                type="hotel"
                title="Hotel"
                items={shyfterFormData.hotelSkills}
                deleteItem={(skill) => this.deleteSkillItem("hotel", skill)}
                openSkillDialog={(type) => this.toggleSkillSetDialog(type)}
              />
              {!!openSkillSetDialog && (
                <SkillSetDialog
                  open={!!openSkillSetDialog}
                  onCloseDialog={() => this.toggleSkillSetDialog("")}
                  saveSkillData={this.saveSkillData}
                  type={openSkillSetDialog}
                  items={setSkillSetList(openSkillSetDialog)}
                  selectedItems={this.setSelectedSkills()}
                />
              )}
              {!!shyfterFormDataErrors.skills && (
                <div style={webStyle.errorText}>
                  {shyfterFormDataErrors.skills}*
                </div>
              )}
              <Grid container style={webStyle.actionWrapper}>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      style={webStyle.prevArrow}
                      onClick={this.moveShyfterPrevStep}
                      data-test-id="prev"
                    >
                      <ArrowBackIos />
                    </IconButton>
                    <Typography style={webStyle.loginText} variant="body1">
                      {configJSON.prev}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <CustomButton
                    label={configJSON.signUpNow}
                    handleClick={this.shyfterSignUp}
                  />
                </Grid>
              </Grid>
            </form>
          )}
          <SubmittedFailedPopUp
            handleChangeNumber={this.onCloseAlert}
            handleToLogin={this.backToLogin}
            isOpen={this.state.showAlert}
            phone={this.state.shyfterFormData.mobileNumber}
          />
        </Box>
      </LoginWrapper>
    );
  }
}

export default ShyfterAccountRegistrationSteps;
