Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";
exports.contactUsApiEndPoint = "bx_block_settings/help_us";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Full Name";
exports.emailTitle = "Email Address";
exports.numberTitle = "Mobile number";
exports.commentsTitle = "Enter Your Comments...";

exports.namePlaceHolder = "Full Name";
exports.emailPlaceHolder = "Email Address";
exports.numberPlaceHolder = "Mobile number";
exports.commentsPlaceHolder = "Enter Your Message...";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

exports.willBeInTouchText = "Complete the fields below. We will be in touch within 24 hours."
exports.tabBusinessTitle = "Businesses"
exports.tabShyfterTitle = "Shyfters"
exports.businessTitle = "Business Name";
exports.firstNameTitle = "First Name";
exports.lastNameTitle = "Last Name";
exports.busiessPhoneTitle = "Business Phone Number";
exports.stateTitle = "State";
exports.cityTitle = "City";
exports.zipCodeTitle = "Zip Code";
exports.interestInHiringTitle = "I'm interested in hiring";
exports.previousExperienceTitle = "Previous Experience with";

exports.stateList = [
  { name: "Texas", value: "texas"},
  { name: "Florida", value: "florida"},
  { name: "Alaska", value: "alaska"},
];

exports.cityList = [
  {name: 'Alabama', value: 'AL'}, 
  {name: 'Alaska', value: 'AK'}, 
  {name: 'American Samoa', value: 'AS'}
]


exports.hiringInterestList = [
  {name: 'Servers', value: 'Servers'}, 
  {name: 'Bartenders', value: 'Bartenders'}, 
  {name: 'Food Runners/ Bussers', value: 'Food Runners/ Bussers'},
  {name: 'Hosts', value: 'Hosts'},
  {name: 'Cooks', value: 'Cooks'}, 
  {name: 'Dishwashers', value: 'Dishwashers'}, 
  {name: 'Hotel Front Desk', value: 'Hotel Front Desk'},
  {name: 'Hotel Housekeeping', value: 'Hotel Housekeeping'},
  {name: 'Hotel Bellstaff', value: 'Hotel Bellstaff'},
]

exports.prevExperienceList = [
  {name: 'Cafe & bar', value: 'Cafe & bar'}, 
  {name: 'Restaurants', value: 'Restaurants'}, 
  {name: 'Hotels', value: 'Hotels'},
  {name: 'Beverages', value: 'Beverages'}
]

exports.minMaxBusinessNameCharLimitRegex = /^.{4,30}$/;
exports.minMaxNameCharLimitRegex = /^.{3,15}$/;
exports.minMaxCommentsCharLimitRegex = /^.{10,200}$/;
exports.phoneNumberRegex = /^\d{10}$/;
exports.zipCodeRegex = /^\d{5}$/;
exports.emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;

exports.businessErrorMsg = "business name should be between 4 & 30 chars";
exports.nameErrorMsg = "name should be between 3 & 15 chars";
exports.emailErrormsg = "email address should be valid";
exports.phoneNumberErrorMsg = "should be valid, must be 10 digits";
exports.stateListErrorMsg = "select state";
exports.cityListErrorMsg = "select city";
exports.zipCodeErrorMsg = "must be 5 digits";
exports.skillErrorMsg = "select your interest/experience";
exports.commentsErrorMsg = "comments should be between 10 & 180 characters";

// Customizable Area End