import React from "react";
import { CircularProgress, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "400px",
    borderRadius: "14px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
  },
}));

export default function Spinner() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress />
    </Box>
  );
}
