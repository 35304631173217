import React from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
  InputBase,
  Box,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import ActiveMessagesController, { Props } from "./ActiveMessagesController";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import {
  actionIcon,
  actionTakenIcon,
  addIcon,
  closeIcon,
  emptyChatPhoto,
  searchIcon,
} from "./assets";
import ChatCard from "../../../components/src/ChatCard.web";
import ChatHeader from "../../../components/src/ChatHeader.web";

interface IChatModule {
  renderChatId: number;
}

interface IDeleteMessageProps {
  isEmployer: boolean;
  open: boolean;
  handleClose: () => void;
  deleteId: any[];
}

export const EmptyChatModule = () => {
  return (
    <Box
      padding={"0px 35px 0px 30px"}
      textAlign={"center"}
      marginTop={"25%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box>
        <img src={emptyChatPhoto} width={"85px"} alt="chat placeholder" />
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#0a0412",
            marginTop: "20px",
          }}
          variant="h5"
        >
          Your Snagashyft Messages
        </Typography>
        <Typography
          style={{
            color: "#6f7c8e",
            fontSize: "13px",
            fontWeight: "bold",
            paddingTop: "10px",
            width: "250px",
            margin: "auto",
          }}
          variant="subtitle2"
          paragraph={true}
        >
          Select chat to see messages from the shyfters
        </Typography>
      </Box>
    </Box>
  );
};

export const ChatModule = (props: IChatModule) => {
  return (
    <Box marginRight={"10px"} paddingLeft={"20px"} paddingRight={"20px"}>
      <ChatHeader />
      <Box>chat id passing correctly {props.renderChatId}</Box>
    </Box>
  );
};

export const DeleteMessage = (props: IDeleteMessageProps) => {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          overflowY: "visible",
          borderRadius: "20px",
          width: "530px",
        },
      }}
    >
      <IconButton
        style={{
          border: "1px rgb(255, 255, 255, 0.3) solid",
          backgroundColor: "rgb(0 0 0 / 55%)",
          position: "absolute",
          padding: "5px",
          right: 0,
          top: "-40px",
          borderRadius: "50%",
        }}
        onClick={props.handleClose}
      >
        <CloseIcon style={{ color: "#fff" }} width={20} height={20} />
      </IconButton>
      <Box>
        <Box textAlign={"center"} padding={"35px"}>
          <img src={closeIcon} width={"50px"} />
          <Box
            paddingBottom={"10px"}
            fontWeight={600}
            paddingTop={"10px"}
            textAlign="center"
            fontSize="22px"
            color="#0A0412"
          >
            Delete Chat!
          </Box>

          <Typography
            style={{
              color: "#6f7c8e",
              fontSize: "13px",
              textAlign: "center",
              fontWeight: "bold",
              margin: "auto",
              paddingBottom: "20px",
              width: "370px",
            }}
            variant="subtitle2"
            paragraph={true}
          >
            Are you sure to want to delete this chat from the list your account,
            please confirm?
          </Typography>

          <Box display={"flex"} justifyContent={"space-around"}>
            <Button
              style={{
                backgroundColor: "#fff",
                borderRadius: "24px",
                color: "#390879",
                textTransform: "capitalize",
                border: "1px solid #390879",
                width: "45%",
                minWidth: "170px",
                height: "48px",
                fontWeight: "bold",
              }}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#ff375b",
                borderRadius: "24px",
                color: "#fff",
                textTransform: "capitalize",
                border: "1px solid #ff375b",
                width: "45%",
                minWidth: "170px",
                height: "48px",
                fontWeight: "bold",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ActiveMessages extends ActiveMessagesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      deleteModalOpen,
      isActionNeeded,
      selectedItems,
      renderChatId,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader {...this.props} />
        {deleteModalOpen && (
          <DeleteMessage
            open={deleteModalOpen}
            isEmployer={false}
            deleteId={[]}
            handleClose={this.handleDeleteModalClose}
          />
        )}
        <Container>
          <Box
            marginTop={"8%"}
            minHeight={"75vh"}
            marginBottom={"3%"}
            border={"1px solid #e1e6ec"}
            borderRadius={"14px"}
            bgcolor={"#fff"}
            position={"relative"}
          >
            <Grid
              container
              spacing={2}
              style={{
                height: "calc(100% - 20px)",
                position: "absolute",
                top: "20px",
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  borderRight: "1px solid #e1e6ec",
                  padding: "0",
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box padding={"0px 3px 0px 35px"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginRight={"15px"}
                  >
                    <Typography
                      style={{
                        color: "#0a0412",
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: "0",
                      }}
                      variant="subtitle2"
                      paragraph={true}
                    >
                      Messages (2)
                    </Typography>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <img
                          src={isActionNeeded ? actionTakenIcon : actionIcon}
                          width={"22px"}
                          height={"22px"}
                          alt="action icon"
                          onClick={() => this.handleAction()}
                          data-test-id="takeActionBtn"
                          style={{
                            cursor: "pointer",
                            marginRight: isActionNeeded ? "5px" : "15px",
                          }}
                        />
                        {isActionNeeded && (
                          <Typography
                            style={{
                              marginRight: "10px",
                              fontWeight: "bold",
                              color: "#390879",
                              fontSize: "20px",
                            }}
                          >
                            {selectedItems.length}
                          </Typography>
                        )}
                      </Box>
                      <Button
                        data-test-id="btnChatAdd"
                        variant="contained"
                        disabled={isActionNeeded}
                        style={{
                          backgroundColor: isActionNeeded
                            ? "rgba(57, 8, 121, 0.5)"
                            : "#390879",
                          borderRadius: "18px",
                          color: "#fff",
                          textTransform: "capitalize",
                          width: "90px",
                        }}
                      >
                        Add
                        <img
                          src={addIcon}
                          width={"22px"}
                          height={"22px"}
                          alt="action button"
                          style={{ marginLeft: "7px" }}
                        />
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    width={"calc(100% - 20px)"}
                    border={"1px solid #e1e6ec"}
                    borderRadius={"23px"}
                    bgcolor={"rgb(225, 230, 236, 0.6)"}
                    height={"40px"}
                    marginTop={"15px"}
                    marginBottom={"15px"}
                  >
                    <Box margin="0 10px">
                      <img src={searchIcon} alt="search icon" height={"18px"} />
                    </Box>
                    <InputBase
                      placeholder={"Search for chat…"}
                      data-test-id="search-input"
                      inputProps={{
                        "aria-label": "search",
                      }}
                      fullWidth
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "14px",
                      }}
                    />
                  </Box>
                  <Box height={"80%"} style={{ overflowY: "auto" }}>
                    <Box>
                      {[1, 2, 3, 4, 5].map((id) => {
                        return (
                          <ChatCard
                            jobTitle="Doorman"
                            imgUrl="https://fastly.picsum.photos/id/64/4326/2884.jpg?hmac=9_SzX666YRpR_fOyYStXpfSiJ_edO3ghlSRnH2w09Kg"
                            senderName="Abdullah"
                            time="2 min"
                            lastChat="congratulations"
                            chatId={id}
                            isActionNeeded={isActionNeeded}
                            selectedItem={this.selectedItem}
                            handleRenderChat={this.handleRenderChat}
                            activeId={renderChatId}
                            key={id}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                  {isActionNeeded && (
                    <Box
                      position={"absolute"}
                      bottom={"35px"}
                      left={"30px"}
                      right={"30px"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Button
                        onClick={() => this.handleCloseAction()}
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "24px",
                          color: "#390879",
                          textTransform: "capitalize",
                          border: "1px solid #390879",
                          width: "45%",
                          minWidth: "170px",
                          height: "48px",
                          fontWeight: "bold",
                        }}
                        data-test-id="actionClose"
                      >
                        Cancel
                      </Button>
                      <Button
                        data-test-id="actionDelete"
                        style={{
                          backgroundColor: "#ff375b",
                          borderRadius: "24px",
                          color: "#fff",
                          textTransform: "capitalize",
                          border: "1px solid #ff375b",
                          width: "45%",
                          minWidth: "170px",
                          height: "48px",
                          fontWeight: "bold",
                        }}
                        disabled={!selectedItems.length}
                        onClick={() =>
                          !!selectedItems.length && this.handleDeleteModal()
                        }
                      >
                        Delete {`(${selectedItems.length})`}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={8}
                style={{
                  padding: "0",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {renderChatId === -1 ? (
                  <EmptyChatModule />
                ) : (
                  <ChatModule renderChatId={renderChatId} />
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }
}
