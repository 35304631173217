import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { convertTimeStringIntoDateObject, setSelectedSubsId } from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

  // Customizable Area Start
export interface ViewResponseType{
  total_requests: number;
  requests: [
      {
          request: {
              id: number;
              updated_at: string;
              status: string;
              shift_id: number;
              worker_id: number;
              created_at: string;
              removal_reason: null;
          },
          worker: {
              id: number;
              full_name: string;
              phone_number: number;
              email: string;
              ein: null;
              dl_number: number;
              language_skill: Array<string>;
              restaurant_skills: Array<string>;
              hotel_skills: Array<string>;
              recent_login: string;
              recent_logout: null;
              age: string;
              industry_computer_application_pos: null;
              industry_computer_application_pms: null;
              work_authorization_knockout_question: Array<string>;
              deleted: null;
              deleted_at: null;
              full_phone_number: number;
              country_code: number;
              experience: number;
              created_at: string;
              updated_at: string;
              role_id: number;
              address: string;
              date_of_birth: number;
              ssn: number;
              dl_state: string;
          },
          worker_profile_picture: null,
          worker_rating: null
      }
  ]
}

export interface IgnoreRequest{
  shift_id: number;
  request_id: number;
  message: string
}

export interface AcceptRequest{
  shift_id: number;
  request_id: number
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}

export interface IShyftDrawerData {
    request: {
        id: number;
        created_at: string;
        updated_at: string;
        status: string;
        shift_id: number;
        worker_id: number;
        removal_reason: null;
    },
    worker: {
        id: number;
        full_name: string;
        phone_number: number;
        email: string;
        ein: null;
        created_at: string;
        updated_at: string;
        role_id: number;
        address: string;
        date_of_birth: number;
        ssn: number;
        dl_state: string;
        dl_number: number;
        language_skill: Array<string>;
        restaurant_skills: Array<string>;
        hotel_skills: Array<string>;
        recent_login: string;
        recent_logout: null;
        age: string;
        industry_computer_application_pos: null;
        industry_computer_application_pms: null;
        work_authorization_knockout_question: Array<string>;
        deleted: null;
        deleted_at: null;
        full_phone_number: number;
        country_code: number;
        experience: number;
    },
    worker_profile_picture: null,
    worker_rating: null
}
  // Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}



interface S {
  // Customizable Area Start
  selectedTab: number;
  isOpenAddShyftDialog: string;
  isOpenSuccessDialog: boolean;
  createdShyftList: any[];
  ongoingShyftList: any[];
  userData: any;
  isUserHaveSubscriptionn: boolean;
  updateEmailPopUp: boolean;
  updatePhonePopUp: boolean;
  newEmail: string;
  newPhone: string;
  subscribeNowDialog: boolean;
  allSubscriptionList: any;
  activeShyftDetails: any;
  viewRequestDialog: boolean;
  shyftDrawerData: Array<IShyftDrawerData>;
  drawer: boolean;
  isModalOpen: boolean;
  menuAnchor: any;
  successDialogTitle: string;
  successDialogDescription: string;
  isOpenFilterDrawer: boolean;
  isRemoveModalOpen:boolean;
  isEndShyft:boolean;
  ongoingActivityRecordList: any[];
  ongoingShyftSummaryData: any;
  ongoingActivityRequestData: any;
  isActivityRecordVisible: boolean;
  activeShyftId: string;
  ongoingRemoveReason: string;
  removeInputErr: string;
  ongoingActivityShyftId: number;
  ongoingActivityWorkerId: number;
  acceptedShyfterName: string;
  createdShyftsPage: number;
  isCreatedShyftsLastPage: boolean;
  isEndRemoveSuccessModal: boolean;
  endRemoveSuccessModalTitle: string;
  endRemoveSuccessModalDesc: string;
  recentActivityStatus: any;
  rateAndReviewModalData: any;
  openOngoingReviewAndRatingModal: boolean;
  openRatingSucessModal: boolean;
  recentOngoingActivityData: any;
  isMounted: boolean;
  ongoingShyftsPage: number;
  isOngoingShyftsLastPage: boolean;
  isOpenUpdateStatusModal: boolean;
  singleItemActivityRecordData: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployerHomeAfterSubscriptionController extends BlockComponent<Props, S,SS> {
  // Customizable Area Start
  getUserProfileApiCallId: string = "";
  createdShyftsAPIId: string = "";
  ongoingShyftsAPIId: string = "";
  shyftDetailsAPIId: string = "";
  createNewShyftAPIId: string = "";
  getAllSubsApiCallId:string = "";
  getVieWRequestApiCallId:string = "";
  ignoreRequestShyftApiCallId:string = "";
  acceptRequestShyftApiCallId:string = "";
  deleteShyftAPIId: string = "";
  shyftActivityRecordAPIId: string = "";
  shyftRemovePersonAPIId: string = "";
  homeEndShyftAPIId: string = "";
  isHotelUser: boolean = false;
  ongoingReviewAndRatingsAPIId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleShyftListScroll = this.handleShyftListScroll.bind(this);
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
       // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedTab: 0,
      isOpenAddShyftDialog: "",
      isOpenSuccessDialog: false,
      createdShyftList: [],
      ongoingShyftList: [],
      userData: "",
      isUserHaveSubscriptionn: true,
      newEmail: "",
      newPhone: "",
      updateEmailPopUp: false,
      updatePhonePopUp: false,
      subscribeNowDialog: false,
      allSubscriptionList: "",
      activeShyftDetails: {},
      viewRequestDialog: false,
      drawer: false,
      isModalOpen: false,
      isRemoveModalOpen:false,
      isEndShyft:false,
      shyftDrawerData: [],
      menuAnchor: null,
      successDialogDescription: "",
      successDialogTitle: "",
      isOpenFilterDrawer: false,
      ongoingActivityRecordList: [],  
      ongoingShyftSummaryData: {},
      ongoingActivityRequestData: {},
      isActivityRecordVisible: false,
      ongoingRemoveReason: "",
      removeInputErr: "",
      ongoingActivityShyftId: 0,
      ongoingActivityWorkerId: 0,
      activeShyftId: "",
      acceptedShyfterName: "",
      createdShyftsPage: 1,
      isCreatedShyftsLastPage: false,
      isMounted: false,
      ongoingShyftsPage: 1,
      isOngoingShyftsLastPage: false,
      isEndRemoveSuccessModal: false,
      endRemoveSuccessModalTitle: "",
      endRemoveSuccessModalDesc: "",
      recentActivityStatus: {},
      rateAndReviewModalData: {},
      openOngoingReviewAndRatingModal: false,
      openRatingSucessModal: false,
      recentOngoingActivityData: {},
      isOpenUpdateStatusModal: false,
      singleItemActivityRecordData: {},

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.handleAPIResponse(responseJson, apiRequestCallId);
        this.handleAllSubsRes(responseJson, apiRequestCallId)
        this.handleVieWSucessRequest(responseJson, apiRequestCallId)
        this.handleignoreRequestShyftSucessRequest(responseJson, apiRequestCallId) 
        this.handleacceptRequestShyftSucessRequest(responseJson, apiRequestCallId) 
        this.handleRateAndReviewSuccessApiRequest(responseJson, apiRequestCallId)       
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    this.getCreatedShyfts(1);
    this.getOngoingShyfts(1);
    this.getShyfterProfile();
    this.getAllSubscription();
    const userData = localStorage.getItem("userData");
    if (userData && JSON.parse(userData)?.hotel?.length > 0) {
      this.isHotelUser = true;
    }
    window.addEventListener('scroll', this.handleShyftListScroll);
    // Customizable Area End
  }

// Customizable Area Start

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleShyftListScroll);
  }

  handleAPIResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.createdShyftsAPIId) {
      this.createdShyftsAPIResponse(responseJson);
    }
    if (apiRequestCallId === this.ongoingShyftsAPIId) {
      this.setOngoingShyftsAPIResponse(responseJson);
    }
    if (apiRequestCallId === this.shyftDetailsAPIId) {
      this.setShyftDetailsAPIResponse(responseJson);
    }
    if (apiRequestCallId === this.getUserProfileApiCallId) {
        this.setState({ ...this.state, userData: responseJson.data.attributes });
    }

    if (apiRequestCallId === this.createNewShyftAPIId) {
      this.setCreateNewShyftResponse(responseJson);
    }

    if (apiRequestCallId === this.deleteShyftAPIId) {
      if (responseJson?.message) {
        this.getCreatedShyfts(this.state.createdShyftsPage);
      }
    }
  };

  setShyftDetailsAPIResponse = (responseJson: any) => {
    if (responseJson && responseJson.shift) {
      this.setState({ ...this.state, activeShyftDetails: responseJson });
    }
  }

  setOngoingShyftsAPIResponse = (responseJson: any) => {
    if (this.state.ongoingShyftsPage === 1) {
      this.setState({ ...this.state, 
        ongoingShyftList: responseJson?.ongoing_shifts.length > 0 ? responseJson.ongoing_shifts : [] 
      });

      responseJson && responseJson?.ongoing_shifts.length > 0 && 
        this.handleOngoingActivityRecord(
          responseJson.ongoing_shifts[0].shift.id,
          responseJson.ongoing_shifts[0].worker.id, 
          responseJson.ongoing_shifts[0]
      )

    } else if (responseJson && responseJson?.ongoing_shifts) {
      this.setState({...this.state, 
        ongoingShyftList: [...this.state.ongoingShyftList, ...responseJson.ongoing_shifts],
        isOngoingShyftsLastPage: responseJson.ongoing_shifts.length < 10
      });
    }
  }

  createdShyftsAPIResponse = (responseJson: any) => {
    if (this.state.createdShyftsPage === 1) {
      this.setState({ ...this.state, createdShyftList: responseJson?.length > 0 ? responseJson : [] }, () => {
        if (!this.state.isMounted && responseJson.length > 0) {
          this.getShyftDetails(responseJson[0]?.shift?.id);
          this.setState({isMounted: true});
        }
      });
    } else if (responseJson) {
      this.setState({...this.state, 
        createdShyftList: [...this.state.createdShyftList, ...responseJson],
        isCreatedShyftsLastPage: responseJson.length < 10
      });
    }
  }

  setCreateNewShyftResponse = (responseJson: any) => {
    if (responseJson && responseJson.shift) {
      this.setState({
        successDialogTitle: 
        responseJson?.message === "Shift updated successfully" ? 
        configJSON.shyftUpdatedTitleLabel : 
        configJSON.shyftAddedTitleLabel,
        successDialogDescription: responseJson?.message === "Shift updated successfully" ? 
        configJSON.shyftUpdatedDescription :
        configJSON.shyftAddedDescription
      })
      this.getCreatedShyfts(this.state.createdShyftsPage);
      this.getShyftDetails(responseJson.shift?.id);
      this.getOngoingShyfts(this.state.ongoingShyftsPage);
      this.toggleSuccessDialog(true);
    }
  };

  handleAllSubsRes=(responseJson: any, apiRequestCallId: string)=>{
    if (apiRequestCallId === this.getAllSubsApiCallId) {
      this.setState({allSubscriptionList:responseJson.subscriptions})
    }

    if (apiRequestCallId === this.shyftActivityRecordAPIId) {
      if (responseJson?.activities?.data && responseJson?.summary) {
        this.setState({ 
          ...this.state,
          ongoingActivityRecordList: responseJson.activities.data,  
          ongoingShyftSummaryData: responseJson.summary,
          ongoingActivityRequestData: responseJson?.request,
          isActivityRecordVisible: true
        });
      }
      if(responseJson?.message === "No activity yet") {
        this.setState({ 
          ...this.state,
          ongoingActivityRecordList: [],  
          ongoingShyftSummaryData: {},
          ongoingActivityRequestData: {},
          isActivityRecordVisible: true
        });
      }
    }

    if(apiRequestCallId === this.shyftRemovePersonAPIId) {
      if(responseJson?.message) {
        this.handleHomeRemoveOrEndShyftSuccessApiResponses("removeHome")
      } else if(responseJson?.error ) {
          console.log("Home remove person error response", responseJson)
      }
    }

    if(apiRequestCallId === this.homeEndShyftAPIId) {
      if(responseJson?.message) {
        this.handleHomeRemoveOrEndShyftSuccessApiResponses("endHome")
      } else if(responseJson?.error) {
          console.log("Home end shyft person error response", responseJson)
      }
    }
  }

  handleRateAndReviewSuccessApiRequest = (responseJson: any, apiRequestCallId: string) => {
    if (this.ongoingReviewAndRatingsAPIId === apiRequestCallId) {
      if(responseJson && responseJson.data) {
        this.setState({
          ...this.state,
          openRatingSucessModal: true,
        });
        this.closeReviewAndRatingModal()
      }
    }
  }

  handleEndOrRemoveSuccessModalClose = () => {
    this.setState(({
      ...this.state,
      isEndRemoveSuccessModal: false,
    }))
  }

  handleRateShyftEndOrRemoveSuccessModal = () => {
    this.handleEndOrRemoveSuccessModalClose()
    this.setState(({
      ...this.state,
      openOngoingReviewAndRatingModal: true,
      isEndRemoveSuccessModal: false,
    }))
  }

  closeReviewAndRatingModal = () => {
    this.setState(({
      ...this.state,
      openOngoingReviewAndRatingModal: false,
    }))
  }

  closeRateSuccessModal = () => {
    this.setState(({
      ...this.state,
      openRatingSucessModal: false,
    }))
  }

  handleEndOrRemoveSuccessModalOpen = (title: string, description: string) => {
    this.setState(({
      ...this.state,
      isEndRemoveSuccessModal: true,
      endRemoveSuccessModalTitle: title,
      endRemoveSuccessModalDesc: description
    }))
  }

  handleHomeRemoveOrEndShyftSuccessApiResponses = (value: string) => {
    switch(value) {
      case "endHome":
        this.handleisCloseEndShyft()
        this.handleEndOrRemoveSuccessModalOpen(configJSON.shyftActivityEndedTitleLabel, configJSON.shyftActivityEndedDescription)
        break;
      case "removeHome":
        this.handleCloseRemoveModal()
        this.handleEndOrRemoveSuccessModalOpen(configJSON.shyftActivityRemoveTitleLabel, configJSON.shyftActivityRemoveDescription)
        break;
    }
  }

  handleVieWSucessRequest=(responseJson: ViewResponseType, apiRequestCallId: string)=>{
    if (apiRequestCallId === this.getVieWRequestApiCallId) {
      this.setState({shyftDrawerData:responseJson?.requests})
    }
  }

  handleignoreRequestShyftSucessRequest=(responseJson: IgnoreRequest, apiRequestCallId: string)=>{
    if (apiRequestCallId === this.ignoreRequestShyftApiCallId) {
      this.setState({
        viewRequestDialog: false, 
        acceptedShyfterName: "",
      })
      this.getCreatedShyfts(this.state.createdShyftsPage);
      this.getShyftDetails(this.state.activeShyftDetails?.shift?.id);
    }
  }

  handleacceptRequestShyftSucessRequest=(responseJson: AcceptRequest, apiRequestCallId: string)=>{
    if (apiRequestCallId === this.acceptRequestShyftApiCallId) {
      this.setState({ viewRequestDialog: false }, () => {
        this.setState({ isModalOpen: true });
      });
    }
  }

  handleTabChange = (newValue: number) => {
    this.setState({ ...this.state, selectedTab: newValue,
      ongoingActivityRequestData: {},
      openRatingSucessModal: false, openOngoingReviewAndRatingModal: false,
      isRemoveModalOpen: false, isEndShyft: false, isOpenUpdateStatusModal: false
    });
  };

  openShyftMenu = (e: any, id: string) => {
    this.setState({ ...this.state, menuAnchor: e?.currentTarget, activeShyftId: id });
  };

  closeShyftMenu = () => {
    this.setState({ ...this.state, menuAnchor: null, activeShyftId: "" });
  };

  openAddShyftDialog = (isEdit: boolean) => {
    this.setState({
      ...this.state,
      isOpenAddShyftDialog: isEdit ? "edit" : "create",
      isOpenSuccessDialog: false,
    });
  };

  closeAddShyftDialog = () => {
    this.setState({ ...this.state, isOpenAddShyftDialog: "" });
  };

  toggleSuccessDialog = (isOpen: boolean) => {
    this.setState({
      ...this.state,
      isOpenAddShyftDialog: "",
      isOpenSuccessDialog: !this.state.isOpenSuccessDialog,
      successDialogDescription: !isOpen ? "" : this.state.successDialogDescription,
      successDialogTitle: !isOpen ? "" : this.state.successDialogTitle,
    });
  };

  showUpdateEmailPopUp=()=>{
    this.setState({updateEmailPopUp:!this.state.updateEmailPopUp})
  }

  showUpdatePhonePopUp=()=>{
    this.setState({updatePhonePopUp:!this.state.updatePhonePopUp})
  }

  handleSubscribeNowBtn = () => {
    this.setState({ subscribeNowDialog: !this.state.subscribeNowDialog });
  };

  closeFilter = () => {
    this.setState({...this.state, isOpenFilterDrawer: false});
  }

  openFilterDrawer = () => {
    this.setState({...this.state, isOpenFilterDrawer: true});
  }

  applyFilter = () => {
    this.closeFilter();
  }

  getShyfterProfile() {
    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessageForShyfterProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessageForShyfterProfile.messageId;
    requestMessageForShyfterProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );
    requestMessageForShyfterProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageForShyfterProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessageForShyfterProfile.id, requestMessageForShyfterProfile);
  }

  navigateToCheckout = (planId:any) => {
    setSelectedSubsId(planId)
    const checkOutMsg = new Message(getName(MessageEnum.NavigationMessage));
    checkOutMsg.addData(getName(MessageEnum.NavigationTargetMessage), "SubsCheckout");
    checkOutMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationSubsCheckoutMessage)
    );
    const params={path:"checkout",subNaveTab:"Checkout Plan",planId:planId}
    raiseMessage.addData(
      getName(MessageEnum.NavigationSubsCheckoutMessage),
      params
    );
    checkOutMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(checkOutMsg);
  }

  handleViewRequest = () => {
    this.getVieWRequest(this.state.activeShyftDetails?.shift?.id);
    this.setState({ viewRequestDialog: true }); 
  };

  handleViewRequestCloseDialog = () => {
    this.setState({ 
      acceptedShyfterName: "",
      viewRequestDialog: false,
    });
  };

  closeShyftModal = (moveToOngoing: boolean) => {
    this.setState({ 
      isModalOpen: false, 
      viewRequestDialog: false, 
      acceptedShyfterName: "",
      selectedTab: moveToOngoing ? 1 : 0,
      activeShyftDetails: {}
    });
    this.getCreatedShyfts(this.state.createdShyftsPage);
    if (moveToOngoing) {
      this.getOngoingShyfts(this.state.ongoingShyftsPage);
    }
  };

  handleMenuItemClick = (type: string) => {
    this.setState({ menuAnchor: null });
    if (type === "Delete Shyft" && this.state.activeShyftId) {
      this.deleteShyft(this.state.activeShyftId);
    }
    if (type === "Edit Shyft" && this.state.activeShyftId) {
      this.getShyftDetails(this.state.activeShyftId);
      this.setState({ isOpenAddShyftDialog: "edit" });
    }
  };

  handleShyftListScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollHeight - scrollTop === clientHeight) {
      if (this.state.selectedTab === 0) {
        this.setState((prevState) => ({
          createdShyftsPage: prevState.createdShyftsPage + 1,
        }), () => {
          if (!this.state.isCreatedShyftsLastPage) {
            this.getCreatedShyfts(this.state.createdShyftsPage);
          }
        });
      } else {
        this.setState((prevState) => ({
          ongoingShyftsPage: prevState.ongoingShyftsPage + 1,
        }), () => {
          if (!this.state.isOngoingShyftsLastPage) {
            this.getOngoingShyfts(this.state.ongoingShyftsPage);
          }
        });
      }
    }
  };

  getCreatedShyfts(page: number) {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const createdShyftsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createdShyftsAPIId = createdShyftsAPIMsg.messageId;

    createdShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createdShyftsAPIURL+`?sort_column=updated_at&sort_order=desc&per_page=10&page=${page}`
    );

    createdShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createdShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(createdShyftsAPIMsg.id, createdShyftsAPIMsg);
  }

  getOngoingShyfts(page: number) {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const ongoingShyftsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ongoingShyftsAPIId = ongoingShyftsAPIMsg.messageId;

    ongoingShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ongoingShyftsAPIURL+`?sort_column=updated_at&sort_order=desc&per_page=10&page=${page}`
    );

    ongoingShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    ongoingShyftsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(ongoingShyftsAPIMsg.id, ongoingShyftsAPIMsg);
  }

  getShyftDetails(id: string) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const activeShyftDetailsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shyftDetailsAPIId = activeShyftDetailsMsg.messageId;

    activeShyftDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shyftDetailsAPIURL + `/${id}`
    );

    activeShyftDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activeShyftDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(activeShyftDetailsMsg.id, activeShyftDetailsMsg);
  }

  saveShyft = (shyftData: any) => {
    const shyftFormDataOb = new FormData();
    shyftFormDataOb.append("[shift]shift_type", shyftData.selectedShyft);
    shyftFormDataOb.append(
      "[shift]requirements",
      shyftData.shyftRequirements.join(",")
    );
    shyftFormDataOb.append("[shift]description", shyftData.shyftDescription);
    shyftFormDataOb.append("[shift]expirence", shyftData.experience);
    shyftFormDataOb.append("[shift]amount", shyftData.amount);
    shyftFormDataOb.append("[shift]amount_per", shyftData.rateFrequency);
    shyftFormDataOb.append(
      "[shift]shift_from",
      moment(convertTimeStringIntoDateObject(shyftData.fromTimeHour, shyftData.fromTimeMinute, shyftData.fromTimeFormat)).format('hh:mm A')
    );
    shyftFormDataOb.append(
      "[shift]shift_to",
      moment(convertTimeStringIntoDateObject(shyftData.toTimeHour, shyftData.toTimeMinute, shyftData.toTimeFormat)).format('hh:mm A')
    );

    const headers = {
      token: localStorage.getItem("authToken"),
    };

    const addEditShyftsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createNewShyftAPIId = addEditShyftsMsg.messageId;

    const isEdit = !!shyftData.id;
    const apiUrl = isEdit ? 
      configJSON.shyftDetailsAPIURL+`/${shyftData?.id}`: 
      configJSON.shyftDetailsAPIURL;
    const methodType = isEdit ? 
      configJSON.putAPIMethodType: 
      configJSON.exampleAPiMethod;

    addEditShyftsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    addEditShyftsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addEditShyftsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    addEditShyftsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      shyftFormDataOb
    );
    runEngine.sendMessage(addEditShyftsMsg.id, addEditShyftsMsg);
  };

  deleteShyft(id: string) {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const deleteShyftAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteShyftAPIId = deleteShyftAPIMsg.messageId;

    deleteShyftAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shyftDetailsAPIURL + `/${id}`
    );

    deleteShyftAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteShyftAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );
    runEngine.sendMessage(deleteShyftAPIMsg.id, deleteShyftAPIMsg);
  }

  getAllSubscription() {
    const header = {
      token: localStorage.getItem('authToken'),
      "Content-Type": configJSON.validationApiContentType,
    };
    const getSubscriptionRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubsApiCallId = getSubscriptionRequestMsg.messageId;
    getSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubsEndPoint
    );
    getSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getSubscriptionRequestMsg.id, getSubscriptionRequestMsg);
  }

  getVieWRequest(id: any) {
    const header = {
      token: localStorage.getItem('authToken'),
      "Content-Type": configJSON.getVieWRequestApiContentType,
    };
    const viewRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVieWRequestApiCallId = viewRequestMsg.messageId;
    viewRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVieWRequestEndPoint}?shift_id=${id}`
    );
    viewRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    viewRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getVieWRequestApiMethodType
    );
    runEngine.sendMessage(viewRequestMsg.id, viewRequestMsg);
  }

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessageForEmployerHome = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageForEmployerHome.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
      token: localStorage.getItem('authToken')
    };
    requestMessageForEmployerHome.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageForEmployerHome.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageForEmployerHome.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageForEmployerHome.id, requestMessageForEmployerHome);
    return requestMessageForEmployerHome.messageId;
  };

  ignoreRequestShyft = async (dataObject: any) => {
  let apiData = {
    shift_id: dataObject.request.shift_id,
    request_id: dataObject.request.id
                }
      this.ignoreRequestShyftApiCallId = await this.apiCall({
        contentType: configJSON.postignoreRequestApiContentType,
        method: configJSON.postignoreRequestApiMethodType,
        endPoint: configJSON.postignoreRequestEndPoint,
        body:apiData
      });
  }

  acceptRequestShyft = async (data: any) => {
    let apiDataObject = {
      shift_id: data.request.shift_id,
      request_id: data.request.id
    }
    this.acceptRequestShyftApiCallId = await this.apiCall({
        contentType: configJSON.postaceeptRequestApiContentType,
        method: configJSON.postaceeptRequestApiMethodType,
        endPoint: configJSON.postaceeptRequestEndPoint,
        body:apiDataObject
      });
      const shyfterName = this.state.shyftDrawerData.find((i) => i.request.id === Number(data.request.id))?.worker?.full_name;
      this.setState({acceptedShyfterName: shyfterName ?? ""});
    }

  employeerMoreOptionsNavigation = (pathName: string) => {
    const moreOptionMsg = new Message(getName(MessageEnum.NavigationMessage));
    moreOptionMsg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    moreOptionMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(moreOptionMsg);
  }

  handleOpenRemoveModal = () => {
    this.setState({ isRemoveModalOpen: true });
  };

  handleCloseRemoveModal = () => {
    this.setState({ isRemoveModalOpen: false, ongoingRemoveReason: "", removeInputErr: "" });
  };

  handleisEndShyft = () => {
    this.setState({ isEndShyft: true});
  };

  handleisCloseEndShyft = () => {
    this.setState({ isEndShyft: false });
  };

  handleIntialActivityState = (shyftId:number, workerId: number, activityItemData: any) => {
    const ratingModalData = {
      shiftId: activityItemData?.shift.id,
      title: activityItemData?.worker.full_name,
      subtitle: activityItemData?.shift.shift_type,
      shyftProfile: activityItemData?.worker_profile_picture
    };

    this.setState({ 
      ...this.state,
      ongoingActivityRecordList: [],  
      ongoingShyftSummaryData: {},
      ongoingActivityRequestData: {},
      isActivityRecordVisible: false,
      ongoingActivityShyftId: shyftId,
      ongoingActivityWorkerId: workerId,
      recentOngoingActivityData: activityItemData,
      recentActivityStatus: activityItemData.recent_activity,
      rateAndReviewModalData: ratingModalData, 
      isOpenUpdateStatusModal: false,
    });

  }

  handleOngoingActivityRecord = (id: number, userId: number, activityItem: any) => {
    this.handleIntialActivityState(id, userId, activityItem)

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const ongoingShyftActivityRecordsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shyftActivityRecordAPIId = ongoingShyftActivityRecordsMsg.messageId;

    ongoingShyftActivityRecordsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.shyftActivityRecordAPIURL}&shift_id=${id}&user_id=${userId}`
    );

    ongoingShyftActivityRecordsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    ongoingShyftActivityRecordsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(ongoingShyftActivityRecordsMsg.id, ongoingShyftActivityRecordsMsg);
  }

  handleChangeRemoveReason = (value: string) => {
      let newValue = value;
      const tempRemoveReason = newValue.slice(0, 80);
  
      this.setState({
        ...this.state,
        ongoingRemoveReason: tempRemoveReason
      })
  }

  handleRemovePerson =() => {
    if(!configJSON.minMaxRemoveReasonLimitCheck.test(this.state.ongoingRemoveReason.trim())) {
      this.setState({
        ...this.state,
        removeInputErr: "*Required, min-max[3-40] length."
      });
    } else {
      this.setState({ 
        ...this.state,
        removeInputErr: "",
      })
      this.removePersonApi()
    }
  }

  removePersonApi = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const removePersonRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      shift_id: this.state.ongoingActivityShyftId,
      worker_id: this.state.ongoingActivityWorkerId,
      removal_reason: this.state.ongoingRemoveReason
    }

    this.shyftRemovePersonAPIId = removePersonRequestMsg.messageId;

    removePersonRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    removePersonRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    removePersonRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    removePersonRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postRemovePersonApiEndpoint
    );

    runEngine.sendMessage(removePersonRequestMsg.id, removePersonRequestMsg);
  }

  handleEndShyftApiCall = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const homeEndShyftDetailsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.homeEndShyftAPIId = homeEndShyftDetailsMsg.messageId;

    homeEndShyftDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putEndShyftApiEndPoint}${this.state.ongoingActivityShyftId}`
    );

    homeEndShyftDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    homeEndShyftDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethodType
    );

    runEngine.sendMessage(homeEndShyftDetailsMsg.id, homeEndShyftDetailsMsg);
  }

  handleReviewAndRatingSubmit = (ratingVal: number, comment: string) => {
    const { recentOngoingActivityData } = this.state;

    const reviewAndRateRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('authToken'),
    };

    reviewAndRateRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postRateAndReviewApiEndPoint}`
    );

    const body = {
      rating: {
        score: ratingVal,
        comment,
        shift_id: recentOngoingActivityData?.shift.id,
        hotel_id: recentOngoingActivityData?.worker.id,
      }
    };

    this.ongoingReviewAndRatingsAPIId = reviewAndRateRequestMessage.messageId;

    reviewAndRateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reviewAndRateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reviewAndRateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftsApiMethodType,
    );

    runEngine.sendMessage(reviewAndRateRequestMessage.id, reviewAndRateRequestMessage);
  }

  handleCloseUpdateModalHome = () => {
    this.setState({
      ...this.state,
      isOpenUpdateStatusModal: false
    })
  }

  handleHomeUpdateActivityStatusCard = (item: any) => {
    this.setState({
      ...this.state,
      singleItemActivityRecordData: item,
      isOpenUpdateStatusModal: true
    })
  }

  handleHomeUpdateStatusModalSubmit = () => {
    this.handleCloseUpdateModalHome();
    
    setStorageData('updateStatusData',JSON.stringify(this.state.singleItemActivityRecordData))
    this.employeerMoreOptionsNavigation("UpdateStatusPage");
  }

   navigateApplicantProfile = (shyfter: any) => {
    const applicantProfileMsg = new Message(getName(MessageEnum.NavigationMessage));
    applicantProfileMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ApplicantProfile");
    applicantProfileMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationApplicantProfileMessage),
    );
    localStorage.setItem("applicantId", JSON.stringify(shyfter?.worker_id));
    localStorage.setItem("requestId", JSON.stringify(shyfter?.id));
    localStorage.setItem("shiftId", JSON.stringify(shyfter?.shift_id));
    applicantProfileMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(applicantProfileMsg);
   }

  viewUserProfile = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmployerBusinessProfile"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/EmployerBusinessProfile"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }
  // Customizable Area End
}
