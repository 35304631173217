// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BreadCrumType, breadCrumPathData } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
    userRoleType: string;
    subTitle: string;
    termsAndConditionApiResponseData: any;
    tAndCBreadCrumPath: BreadCrumType[];
    currentPage: string;
    loading: boolean;
    tAndCApiError: string;
}

interface SS {
  id: any;
}

export default class TermsAndConditionWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  termsAndConditionApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        termsAndConditionApiResponseData: {},
        userRoleType: configJSON.businessRole,
        subTitle: configJSON.businessTileText,
        tAndCBreadCrumPath: breadCrumPathData,
        currentPage: 'Terms & Conditions',
        loading: false,
        tAndCApiError: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    const getRole = localStorage.getItem("userRole");
    this.setUserRole(getRole);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
         
        if (!apiRequestCallId || !responseJson) {
            console.log("Error in API Message Recived- HelpCenter");
            return;
        }

        if (apiRequestCallId === this.termsAndConditionApiCallId) {
            if(responseJson && responseJson.data) {
              this.setState({ termsAndConditionApiResponseData: responseJson.data, loading: true })
            } 
            if(responseJson.error) {
              this.setState({  tAndCApiError: responseJson.error })
            }
        }
    }
  } 

  setUserRole = (getRole: string | null ) => {
    if(getRole !== "1") {
      this.setState({ userRoleType: configJSON.shyfterRole, subTitle: configJSON.snagShyftTitleText });
    }

    this.getTermsAndConditionApiCall();
  }

  getTermsAndConditionApiCall = () => {
    const tAndCRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('authToken'),
    };

    this.termsAndConditionApiCallId = tAndCRequestMessage.messageId;

    tAndCRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.termsAndConditionEndPoint}${this.state.userRoleType}`
    );

    tAndCRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    
    tAndCRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    
    runEngine.sendMessage(tAndCRequestMessage.id, tAndCRequestMessage);
    return true;
  }

  // Customizable Area End
}
