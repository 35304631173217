import React from "react";
import {
  Box,
  Container,
  Dialog,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#0a0412",
    },
  },
});

import MessagesController, { Props, configJSON } from "./MessagesController";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import {
  briefcaseGrey,
  chatIcon,
  hotel,
  noShyftIcon,
  searchIcon,
} from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddShyftDialog from "../../../components/src/AddShyftDialog.web";
import CustomSuccessDialog from "../../../components/src/CustomSuccessDialog";
const messageListForShyfter = [
  {
    name: "Skyline Luxurious",
    shyftName: "Doorman",
    type: "Hotel",
    image: "",
    id: 1,
  },
  { name: "Cafe Lonely", shyftName: "Chef", type: "Hotel", image: "", id: 2 },
  {
    name: "The Royals",
    shyftName: "Front Desk",
    type: "Restaurant",
    image: "",
    id: 3,
  },
];

const messageListForEmployer = [
  { name: "John Doe", shyftName: "Doorman", image: "", id: 1 },
  { name: "Richard King", shyftName: "Chef", image: "", id: 2 },
  { name: "Aston Agar", shyftName: "Front Desk", image: "", id: 3 },
];
interface INewMessageProps {
  isEmployer: boolean;
  open: boolean;
  chatList: any[];
  handleClose: () => void;
}

interface IMessageCardProps {
  name: string;
  shyftName: string;
  type?: string;
  image?: string;
  onClick: () => void;
}

const setEmptyDataIcon = (isNoShyftIcon: boolean) => {
  return isNoShyftIcon ? noShyftIcon : chatIcon;
};

const setEmptyDataTitle = (isEmployer: boolean, isNoShyft: boolean) => {
  let title = configJSON.startMessaging;
  if (isEmployer && isNoShyft) {
    title = configJSON.noShyftersFound;
  } else if (!isEmployer && isNoShyft) {
    title = configJSON.noShyftsFound;
  }
  return title;
};

const setEmptyDataDescription = (isEmployer: boolean, isNoShyft: boolean) => {
  let description = configJSON.startMessagingDescriptionForShyfter;
  if (isEmployer) {
    if (isNoShyft) {
      description = configJSON.noShyftersFoundDescriptionForEmployer;
    } else {
      description = configJSON.startMessagingDescriptionForEmployer;
    }
  } else if (isNoShyft) {
    description = configJSON.noShyftersFoundDescriptionForShyfter;
  }
  return description;
};

const setEmptyDataBtnText = (isEmployer: boolean, isNoShyft: boolean) => {
  let btnText = configJSON.addChat;
  if (isNoShyft) {
    if (isEmployer) {
      btnText = configJSON.addShyfts;
    } else {
      btnText = configJSON.exploreJobs;
    }
  }
  return btnText;
};

const setSearchPlaceholder = (isEmployer: boolean) => {
  return isEmployer
    ? configJSON.searchPlaceholderForEmployer
    : configJSON.searchPlaceholderForShyfter;
};

const setMessageList = (isEmployer: boolean) => {
  return isEmployer ? messageListForEmployer : messageListForShyfter;
};

const MessageCard = (props: IMessageCardProps) => {
  return (
    <Box
      border="1px solid #E1E6EC"
      borderRadius={12}
      display="flex"
      alignItems="center"
      padding="10px"
      margin="10px 0px"
      onClick={props.onClick}
    >
      <Box marginRight="8px">
        <img
          style={webStyle.messageImage}
          src={props.image}
          height={42}
          width={42}
          alt=""
        />
      </Box>
      <Box>
        <Box
          color="#0A0412"
          fontSize="14px"
          fontWeight={600}
          paddingBottom="3px"
        >
          {props.name}
        </Box>
        <Box
          color="#6F7C8E"
          fontSize="12px"
          fontWeight={500}
          display="flex"
          alignItems="center"
        >
          {props.type && (
            <Box padding="0px 10px 0px 2px" display="flex" alignItems="center">
              <Box style={webStyle.dot} />
              {props.type}
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <Box paddingRight="3px">
              <img src={briefcaseGrey} height={11} width={11} alt="shift" />
            </Box>
            {props.shyftName}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const NewMessage = (props: INewMessageProps) => {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          width: "530px",
          borderRadius: "20px",
          overflowY: "visible",
        },
      }}
    >
      <IconButton
        onClick={props.handleClose}
        style={{
          border: "1px rgb(255, 255, 255, 0.3) solid",
          padding: "5px",
          position: "absolute",
          top: "-40px",
          right: 0,
          borderRadius: "50%",
          backgroundColor: "rgb(0 0 0 / 55%)",
        }}
      >
        <CloseIcon style={{ color: "#fff" }} height={20} width={20} />
      </IconButton>
      <Box
        fontSize="22px"
        color="#0A0412"
        textAlign="center"
        padding="20px 0px"
        fontWeight={600}
      >
        New Message
      </Box>
      <Box padding="0px 20px 20px">
        <TextField
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} height={18} />
              </InputAdornment>
            ),
            style: { borderRadius: "20px" },
          }}
          placeholder={setSearchPlaceholder(props.isEmployer)}
          inputProps={{
            style: {
              paddingTop: "14px",
              paddingBottom: "14px",
            },
          }}
        />
      </Box>
      <Box style={webStyle.newMessagesWrapper}>
        {props.chatList.map((item) => (
          <MessageCard
            key={item.id}
            name={item.name}
            shyftName={item.shyftName}
            type={item.type}
            image={item?.image || hotel}
            onClick={props.handleClose}
          />
        ))}
      </Box>
    </Dialog>
  );
};

export default class Messages extends MessagesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isShowNoChatsPage,
      isShowNoShyfterPage,
      isEmployer,
      isOpenNewMessageDialog,
      isOpenAddShyftDialog,
      isOpenSuccessDialog,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader {...this.props} />
        {isOpenNewMessageDialog && (
          <NewMessage
            open={isOpenNewMessageDialog}
            chatList={setMessageList(isEmployer)}
            isEmployer={isEmployer}
            handleClose={this.handleCloseNewMessageDialog}
          />
        )}
        {isOpenAddShyftDialog && (
          <AddShyftDialog
            open={isOpenAddShyftDialog}
            saveShyft={this.saveNewShyftData}
            title={"Add Shyft"}
            isEdit={false}
            onCloseDialog={this.closeAddShyftDialog}
            editShyftData={{}}
            isHotelType={this.isHotelUser}
          />
        )}
        {isOpenSuccessDialog && (
          <CustomSuccessDialog
            open={isOpenSuccessDialog}
            title={configJSON.shyftAddedTitle}
            description={configJSON.shyftAddedDescription}
            positiveAction={this.closeSuccessDialogDialog}
            positiveActionText="Okay"
            negativeAction={this.handleActionBtnClick}
            closeDialog={this.closeSuccessDialogDialog}
            negativeActionText="+ Add More"
          />
        )}
        <Container>
          <Box marginTop={"10%"} minHeight={"95vh"}>
            {(isShowNoShyfterPage || isShowNoChatsPage) && (
              <Box
                border="1px solid #E1E6EC"
                borderRadius="0.875rem"
                height="678px"
                width="100%"
                bgcolor="#fff"
                marginBottom="30px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Box>
                    <img
                      height={60}
                      width={60}
                      src={setEmptyDataIcon(isShowNoShyfterPage)}
                      alt="chat"
                    />
                  </Box>
                  <Box
                    color="#0A0412"
                    fontSize="20px"
                    padding="16px 0px"
                    fontFamily='"Gotham", sans-serif'
                    fontWeight={600}
                    textAlign="center"
                    data-test-id="no-data-title"
                  >
                    {setEmptyDataTitle(isEmployer, isShowNoShyfterPage)}
                  </Box>
                  <Box
                    color="#6F7C8E"
                    fontFamily='"Gotham", sans-serif'
                    fontWeight={500}
                    fontSize="12px"
                    width="245px"
                    textAlign="center"
                  >
                    {setEmptyDataDescription(isEmployer, isShowNoShyfterPage)}
                  </Box>
                  <Box width="200px" paddingTop="30px">
                    <CustomButton
                      label={setEmptyDataBtnText(
                        isEmployer,
                        isShowNoShyfterPage
                      )}
                      handleClick={this.handleActionBtnClick}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  newMessagesWrapper: {
    overflowY: "auto" as any,
    padding: "0px 20px 20px",
  },
  messageImage: {
    borderRadius: "12px",
  },
  dot: {
    width: "5px",
    height: "5px",
    backgroundColor: "#6F7C8E",
    borderRadius: "50%",
    marginRight: "4px",
  },
};
