import React from "react";

import {
    Box,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End


import HelpCentreController, { Props } from "./HelpCentreController";
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";
import { GreenCheckMark, iphone3, iphone2 } from "./assets";
import { Benefits} from "./constant";

export default class Benifits extends HelpCentreController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        let pathName = this.getRedirectUrl();
        const pageBenifitsItems = pathName==="/ShyfterBenifits"?Benefits.ShyfterBenifits:Benefits.BusinessBenefits
        const pageTitle = pathName==="/ShyfterBenifits"?"Shyfter":"Business";
        const image = pathName==="/ShyfterBenifits"?iphone3:iphone2;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <GenericHeader navigation={this.props.navigation} />

                <Box className="commonheader">
                    <Box className="firstsection-heading commonheader-heading">
                        <Typography variant="h5" className="heading-main" data-test-id="test-heading">For <span>{pageTitle}</span></Typography>
                        <label className="heading-mainsub">
                            <span className="header-home" onClick={() => window.location.href = "/"}>Home</span> &nbsp;|&nbsp; <span className="header-home" onClick={() => window.location.href = `/${pageTitle}Benifits`}>{pageTitle}</span>
                        </label>
                    </Box>
                </Box>
                <Box className="imageSection benifits-bodyheader">
                    <div className='secondsection-heading'>
                        <Typography variant='h4' className='heading-main'>What <span>Benifits</span> you Get as a {pageTitle}?</Typography>
                        <span className='heading-mainsub'>Top features of the app which help you to get your job easy and more efficient with app managing features</span>
                    </div>

                    <div className="benifitssection-body">
                        {
                          pageBenifitsItems.map(item=>{
                            return    <span className='right-card benifit-card'>
                            <img src={require(`../assets${item.imgUrl}`)}></img>
                            <label className='card-heading'>{item.title}</label>
                            <label className='card-details'>Copy
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                            </label>
                        </span>
                           }) 
                        }
                    </div>
                </Box>
                <Box className='body-forthsection imagesection' style={{background:"white"}}>
                    <div className='forthsection-left'>
                        <div className='forthsection-heading common-heading'>
                            <Typography variant='h5' className='heading-main'> Join As a <span>{pageTitle}</span></Typography>
                            <span className='heading-mainsub'>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                        </div>
                        <div className='forthleft-items'>
                            <div className='forthsection-listitem'>
                                <img src={GreenCheckMark}></img>
                                <label className='contentinfo-details'>
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={GreenCheckMark}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={GreenCheckMark}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>

                            <div className='forthsection-listitem'>
                                <img src={GreenCheckMark}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>
                        </div>
                        <span className='header-buttongrp btgrp-other'>
                            <span  className='header-signup' onClick={() => { window.location.href = "/SignUp" }} data-testid="join2-test">Join Now</span>
                            <span  className='header-login' onClick={() => { window.location.href = "/ContactUs" }} data-testid="contact2-test">Contact Us</span>
                        </span>
                    </div>
                    <div className='forthsection-right benifitsection-image'>
                        <img src={image}></img>
                    </div>
                </Box>
                <GenericFooter navigation={this.props.navigation} />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
