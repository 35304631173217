import React, { Component } from 'react';
// Customizable Area Start
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import { Box, Typography } from '@material-ui/core';
import GenericFooter from './GenericFooter.web';
import './style.css';
// Customizable Area End
// Customizable Area Start
interface Props {
    
    navigation: any,
    goToSignUp:()=>void;
    goToContactUs:()=>void;
    dataTestId:any;
}
// Customizable Area End
// Customizable Area Start
export default class LandingScreen extends Component<Props, any>{
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id={''} onSignUp={this.props.goToSignUp}/>
                <Box className='body-firstsection'>
                    <div className='body-section1'>
                        <span className='body-logo'><img src={require('./logo2.png')} /></span>
                        <span className='body-title'>Explore Snagashyft to Get <span>Hospitality</span> Services Easy</span>
                        <span className='body-subtitle'>Manage your Hotels, Restaurants & Explore Jobs in Hospitality with Snagashyft</span>
                        <span className='header-buttongrp'>
                            <span className='header-login body-join' onClick={this.props.goToSignUp} data-testid="join1-test">Join Now</span>
                            <span className='header-signup body-reach' onClick={() => this.props.goToContactUs()} data-testid="contact1-test">Reach Us</span>
                        </span>
                    </div>
                    <div className='body-section2'>
                        <img src={require('./body-iphone.png')} alt="" />
                    </div>


                </Box>
                <Box className='body-secondsection'>
                    <div className='secondsection-heading'>
                        <Typography variant='h4' className='heading-main'> About <span>Snagashyft</span></Typography>
                        <span className='heading-mainsub'>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                    </div>
                    <div className='secondsection-content'>
                        <img className='content-image' src={require('./bodysecondsection.png')}></img>
                        <div className='content-info'>
                            <span className='content-item'>
                                <span className='contentinfo-circle'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                            </span>
                            <span className='content-item'>
                                <span className='contentinfo-circle'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                            </span>
                            <span className='content-item'>
                                <span className='contentinfo-circle'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                            </span>
                            <span className='content-item'>
                                <span className='contentinfo-circle lastitem'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                            </span>
                        </div>
                    </div>

                </Box>
                <Box className='body-thirdsection'>
                    <div className='thirdsection-heading'>
                        <Typography variant='h4' className='heading-main'>What <span>Benefits</span> you Get?</Typography>
                        <span className='heading-mainsub'>Top features of the app which helps you to get your jobs easy and more efficient with app the managing features</span>
                    </div>
                    <div className='thirdsection-content'>
                        <div className='content-left'>
                            <span className='contentLeft-item'>
                                Benefits for Job Seekers
                            </span>
                            <span className='contentLeft-item'>
                                Benefits for Employers
                            </span>
                        </div>
                        <div className='content-right'>
                            <div className='contentright-first'>
                                <span className='right-card'>
                                    <img src={require('./sectionicon1.png')}></img>
                                    <label className='card-heading'>Explore Jobs</label>
                                    <label className='card-details'>Copy
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    </label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon2.png')}></img>
                                    <label className='card-heading'>Get the Best Payee</label>
                                    <label className='card-details'>Copy
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    </label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon3.png')}></img>
                                    <label className='card-heading'>Verified Businesses</label>
                                    <label className='card-details'>Copy
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    </label>
                                </span>
                                <span className='rightcontent-icon'><img src={require('./righticon.png')} onClick={()=>{window.location.href="/ShyfterBenifits"}}></img></span>
                            </div>
                            <div className='contentright-second'>
                                <span className='right-card'>
                                    <img src={require('./sectionicon4.png')}></img>
                                    <label className='card-heading'>Get Premium</label>
                                    <label className='card-details'>Copy
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    </label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon5.png')}></img>
                                    <label className='card-heading'>Add Jobs</label>
                                    <label className='card-details'>Copy
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    </label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon6.png')}></img>
                                    <label className='card-heading'>Manage Multiple Shifts</label>
                                    <label className='card-details'>Copy
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    </label>
                                </span>
                                <span className='rightcontent-icon'><img src={require('./righticon.png')} onClick={()=>window.location.href="/BusinessBenifits"}></img></span>
                            </div>
                        </div>
                    </div>
                </Box>
                <Box className='body-forthsection imagesection'>
                    <div className='forthsection-left'>
                        <div className='forthsection-heading common-heading'>
                            <Typography variant='h5' className='heading-main'> Join As an <span>Business</span></Typography>
                            <span className='heading-mainsub'>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                        </div>
                        <div className='forthleft-items'>
                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>

                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>
                        </div>
                        <span className='header-buttongrp btgrp-other'>
                            <span className='header-signup' onClick={this.props.goToSignUp} data-testid="join2-test">Join Now</span>
                            <span className='header-login' onClick={() => this.props.goToContactUs()} data-testid="contact2-test">Contact Us</span>
                        </span>
                    </div>
                    <div className='forthsection-right'>
                        <img src={require('./iphone2.png')}></img>
                    </div>
                </Box>
                <Box className='body-fifthsection colorSection'>
                    <div className='fifthsection-left'>
                        <img src={require('./iphone3.png')}></img>
                    </div>
                    <div className='fifthsection-right'>

                        <div className='fifthsection-heading common-heading'>
                            <Typography variant='h5' className='heading-main'> Join As a <span>Job Seeker</span></Typography>
                            <span className='heading-mainsub'>Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                        </div>
                        <div className='forthleft-items'>
                            <div className='forthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>

                            <div className='forthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='contentinfo-details'>
                                    Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </label>
                            </div>
                        </div>
                        <span className='header-buttongrp btgrp-other'>
                            <span className='header-signup body-reach' onClick={this.props.goToSignUp}>Join Now</span>
                            <span className='header-login body-join' onClick={() => this.props.goToContactUs()}>Contact Us</span>
                        </span>

                    </div>
                </Box>
                <Box className='body-sixthsection imageSection'>

                    <div className='sixthsection-heading common-heading'>
               
                        <Typography variant='h5' className='heading-main'><span>Download </span>the Application</Typography>
                   
                        <span className='heading-mainsub'>
                            Get the free Mobile App of <strong>Snagashyft</strong> from Play Store or
                            The App Store to get the jobs or manage the services of your Hotels
                            & Restaurants easy.
                        </span>
                    </div>
                    <div className='downloadsection-content'>
                 
                        <div className='download-playstore download-section'>
                        <span className='heading-image left-image'>
                                <img src={require('./assets/arrowleft.png')} alt="" />
                                </span>
                           <label className='dowloadforandroid'>
                      Download for Android
                           </label>
                           <span className='download-icon download' onClick={()=>{window.location.replace("https://play.google.com/store/apps?utm_source=apac_med&hl=en-IN&utm_medium=hasem&utm_content=Dec1721&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-ap-Evergreen-Dec1721-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700058906730295_creativeid_480915691141_device_c&gclid=CjwKCAjwt52mBhB5EiwA05YKo1erl8AwBa9aWdXWaW39_x4ATuF-WnOtjSXEXC_80-d7VE-bw21M7xoCaY8QAvD_BwE&gclsrc=aw.ds&pli=1")}}>
                            <img src={require('./assets/download.png')}></img>
                           </span>
                           <span className='download-playstorebutton storebutton'>
                            <img src={require('./assets/playstore.png')} onClick={()=>{window.location.replace("https://play.google.com/store/apps?utm_source=apac_med&hl=en-IN&utm_medium=hasem&utm_content=Dec1721&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-ap-Evergreen-Dec1721-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700058906730295_creativeid_480915691141_device_c&gclid=CjwKCAjwt52mBhB5EiwA05YKo1erl8AwBa9aWdXWaW39_x4ATuF-WnOtjSXEXC_80-d7VE-bw21M7xoCaY8QAvD_BwE&gclsrc=aw.ds&pli=1")}}></img>
                           </span>
                        </div>
                        <div className='download-iphone '>
                          <img src={require('./assets/iphonehalf.png')} onClick={()=>window.location.replace("https://www.apple.com/in/app-store/")}></img>
                        </div>
                        <div className='download-apple download-section'>
                        <span className='heading-image right-image'>
                        <img src={require('./assets/arrowright.png')} alt="" />
                            </span>
                        <label className='dowloadforandroid'>
                            Download for IOS
                           </label>
                           <span className='download-icon download' onClick={()=>window.location.replace("https://www.apple.com/in/app-store/")}>
                            <img src={require('./assets/download.png')}></img>
                           </span>
                           <span className='download-playstorebutton storebutton'>
                            <img src={require('./assets/applestore.png')}></img>
                           </span>
                        </div>
                    </div>
                </Box>
                <GenericFooter dataTestId="footer-testing" navigation={this.props.navigation} />
            </>
        )
    }
}
// Customizable Area End