import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
const KeyArrowRight = require("./assets/images/keyboard_arrow_down_black.png");

interface Props {
  progress: number;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#b9df12",
    },
  })
)(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "15px",
    border: "1px solid #e1e6ec",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  progress: {
    color: "#390879",
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  registration: {
    color: "#0a0412",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    textDecoration: "underline",
    marginBottom: "6px",
  },
});

const ProgressBar = (props: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Typography variant="h4">
        <Box>{props.progress}%</Box>
      </Typography>
      <Box padding={"10px"} width={"65%"}>
        <Typography variant="subtitle1">
          <Box className={classes.registration}>Complete Your Registration</Box>
        </Typography>
        <div className={classes.root}>
          <BorderLinearProgress variant="determinate" value={50} />
        </div>
      </Box>
      <Box display="flex" justifyContent="center">
        <img src={KeyArrowRight} style={{ height: "14px" }} />
      </Box>
    </Box>
  );
};

export default ProgressBar;
