import React, { useState, useEffect } from "react";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Typography,
  InputBase,
  Dialog,
  DialogTitle,
  Tabs,
  Tab,
  ListItem,
  ListItemSecondaryAction,
  Checkbox,
  ListItemText,
  List,
  Button,
  Divider,
  IconButton,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';

const SettingSliderIcon = require("./assets/images/settings-sliders.png");
const placeIcon = require("./assets/images/placeIcon.png");
const SearchIcon = require("./assets/images/search.png");

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "70%" }}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface Props {
  showFilterText?: boolean;
  modal?: boolean;
  handleLoctionModal?: any;
  islocation?: boolean;
  handleSerchModal?: (value: string) => void;
  isDisabled?: boolean;
  isSortIcon?: boolean;
  defaultValue?: string;
  placeholder?: string;
  locations?: Array<string>;
  positions?: Array<string>;
  shyftTimes?: Array<string>;
  shyftAmount?: Array<string>;
  shyftAmountDay?: Array<string>;
  handleSearchFilter?: (filters: string[],reset?: string) => void;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(57, 8, 121)",
      contrastText: "rgb(111, 124, 142)",
    },
  },
  typography: {
    h6: {
      fontSize: "24px",
      fontFamily: "Gotham",
      fontWeight: 700,
    },
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  listroot: {
    width: "100%",
    maxWith: "360px",
    overflowY: "auto",
    height: "95%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    textTransform: "capitalize",
    border: "1px solid #e1e6ec",
    alignItems: "flex-start",
    fontFamily: "Gotham",

    "& .Mui-selected": {
      color: "rgb(57, 8, 121)",
      fontWeight: "bold",
      backgroundColor: "rgb(57, 8, 121,0.1)",
     }
  },
  dialog: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: "0px",
    width: "405px",
    height: "100%",
    minHeight: "100%",
    borderRadius: "20px 0px 0px 20px",
    overflowY: "visible",
  },
  flexContainer: {
    gap: "0px",
  },
  customDialogTitle: {
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "center",
  },
  indicator: {
    left: 0,
  },
  Button: {
    margin: "10px",
    padding: "10px 30px",
    borderRadius: "50px",
    textTransform: "capitalize",
    width: "45%",
    fontWeight: "bold"
  },
  ApplyButton: {
    color: "#fff",
    padding: "10px 40px",
  },  
  location: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "15px",
    color: "#000",
    cursor: "pointer",
    textDecoration: "underline",
  },
  subTitle: {
    fontSize: "11px", 
    color: "rgba(0, 0, 0, 0.44)", 
    textAlign:"center",
    fontWeight:"bold",
    position: "relative",
    overflow: "hidden",
    display: "block",
    margin: "3px 17px",

    "&::before": {
      content: '""',
      display: "block",
      width: "23%",
      height: "1px",
      background: "rgba(0, 0, 0, 0.24)",
      left: 0,
      top: "50%",
      position: "absolute",
    },
   "&::after":{
    content: '""',
    display: "block",
    width: "23%",
    height: "1px",
    background: "rgba(0, 0, 0, 0.24)",
    right: 0,
    top: "50%",
    position: "absolute",
   }
  },
 timeSubTitle: {
  "&::before": {
    width: "35%"
  },
  "&::after":{
    width: "35%"
   }
 },
 closeIcon: {
  border: "1px rgb(255, 255, 255, 0.3) solid",
  padding: "5px",
  position: "absolute",
  top: "17px",
  left: "-50px",
  borderRadius: "50%",
  backgroundColor: "rgb(0 0 0 / 55%)",
  color: "#fff",
},
});

const SearchWithFilterandLocation = (props: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [defaultValues] = React.useState(['Default: All location','Default: All position','Default: Any times','Default: All amount']);
  const [searchFilter, setSearchFilter] = React.useState(defaultValues);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    showFilterText,
    isDisabled,
    modal,
    handleLoctionModal,
    handleSerchModal,
    defaultValue,
    islocation,
    placeholder = "Find any hotel or restaurant shyfts..",
    locations = ["Default: All", "hotels", "restaurants"],
    positions = ["Default: All", 
    "Server",
    "Host",
    "Bartender",
    "Busser/Runner",
    "Cook",
    "Dishwasher",
    "Front Desk",
    "Concierge"
  ],
    shyftTimes = [
      "Default: Any",
      "12:00AM - 03:00AM",
      "03:00AM - 06:00AM",
      "06:00AM - 09:00AM",
      "09:00AM - 12:00PM",
      "12:00PM - 03:00PM",
      "03:00PM - 06:00PM",
      "06:00PM - 09:00PM",
      "09:00PM - 12:00AM",
    ],
    shyftAmount = [
      "Default: All",
      "15-25",
      "25-35",
      "35-45",
      "45-60",
      "60-80",
      "80-100",
      "100-150",
      "150-200",
      "200-250",
    ],
    handleSearchFilter,
  } = props;


  const handleToggle = (value: string, group: string) => () => {

    let newValue = `${value} ${group}`;

    const currentIndex = searchFilter.indexOf(newValue);

    let newChecked = [...searchFilter]as string[];
    const isDefault = defaultValues.includes(newValue);
    const isAlreadyStored = defaultValues.filter(isStored=> isStored.endsWith(group)).join(', ')
  
    if (isDefault) {
      newChecked = newChecked.filter((item) => item === newValue || !item.includes(group));
    } else if (newChecked.includes(isAlreadyStored)) {
      const index = newChecked.indexOf(isAlreadyStored);
      newChecked.splice(index, 1);
    }

    if (currentIndex === -1) {
      newChecked.push(newValue);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const isGroupMissed = newChecked.filter(isStored=> isStored.endsWith(group)).length

    const updateDefault = group === 'times' ? `Default: Any ${group}` : `Default: All ${group}`

    if(!isGroupMissed){
      newChecked.push(updateDefault)
    }

    setSearchFilter(newChecked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setSearchTerm(defaultValue || "");
  }, [defaultValue]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="20px"
      >
        <Box
          width={modal ? "100%" : "80%"}
          padding="10px"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          border="1px solid #e1e6ec"
          borderRadius="23px"
          bgcolor="rgba(225, 230, 236, 0.25)"
        >
          {" "}
          <Box
            display={islocation ? "flex" : "none"}
            alignItems={"center"}
            onClick={handleLoctionModal}
            marginRight={"5px"}
            data-test-id="getLocation"
            style={{ gap: "5px", width: "18%" }}
          >
            <img src={placeIcon} alt="" height={"18px"} />{" "}
            <Typography className={classes.location}>New York</Typography>
          </Box>
          {islocation && <Divider orientation="vertical" flexItem />}
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            data-test-id="openSearchShyftModal"
            onClick={() => {
              if (handleSerchModal) {
                handleSerchModal(searchTerm);
              }
            }}
          >
            <Box margin="0 10px">
              <img src={SearchIcon} alt="" height={"18px"} />
            </Box>
            <InputBase
              placeholder={placeholder}
              data-test-id="search-input"
              inputProps={{
                "aria-label": "search",
              }}
              readOnly={isDisabled}
              fullWidth
              value={searchTerm}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontSize: "14px",
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && handleSerchModal) {
                  handleSerchModal(searchTerm);
                }
              }}
            />
          </Box>
        </Box>
        <Box
          display={modal ? "none" : "flex"}
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: "#390879",
            padding: "10px",
            borderRadius: "50px",
          }}
          width={showFilterText && "14%"}
          onClick={() => setOpen(true)}
        >
          {!props.isSortIcon && !showFilterText && (
            <img
              src={SettingSliderIcon}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                marginRight: `${showFilterText ? "10px" : "0"}`,
              }}
            />
          )}
          {showFilterText && (
            <Box display={"flex"} alignItems={"center"}>
              <img
                src={SettingSliderIcon}
                alt=""
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: `${showFilterText ? "10px" : "0"}`,
                }}
              />
              <Typography style={{ fontSize: "18px", color: "#fff" }}>
                Fillter
              </Typography>
            </Box>
          )}
          {props.isSortIcon && (
            <Box display={"flex"} alignItems={"center"} paddingX={"2px 20px"}>
              <Typography style={{ fontSize: "18px", color: "#fff" }}>
                Sort
              </Typography>
              <img
                src={SettingSliderIcon}
                alt=""
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: `${props.isSortIcon ? "10px" : "0"}`,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        style={{ borderRadius: "20px 0px" }}
      >
         <IconButton
        className={classes.closeIcon}
        onClick={handleClose}
        data-test-id="closeModal"
      >
        <CloseIcon height={20} width={20} />
      </IconButton>
        <DialogTitle
          className={classes.customDialogTitle}
          id="simple-dialog-title"
        >
          <span style={{fontWeight: "bold", color: "rgba(0, 0, 0, 0.87)"}}>Filter</span>
        </DialogTitle>
        <Box borderTop={"1px solid #E1E6EC"} borderBottom={"1px solid #E1E6EC"}  style={{height:"50vh"}}>
          <div className={classes.root} style={{ height: "100%" }}>
            <Tabs
              orientation="vertical"
              value={value}
              indicatorColor="primary"
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              classes={{
                flexContainer: classes.flexContainer,
                indicator: classes.indicator,
              }}
            >
              <Tab
                className={classes.tabs}
                label="Location"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabs}
                label="Position"
                {...a11yProps(1)}
              />
              <Tab
                className={classes.tabs}
                label="Shyft Times"
                {...a11yProps(2)}
              />
              <Tab
                className={classes.tabs}
                label="Shyft Amount"
                {...a11yProps(3)}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <List dense disablePadding className={classes.listroot} key={value}>
                {locations?.map((value) => {
                  const labelId = `checkbox-list-locations-label-${value}`;
                 
                  return (
                    <ListItem key={value} button  onClick={handleToggle(value,'location')}>
                    <ListItemText
                      id={labelId}
                      primary={<Typography variant="body2" style={{ fontWeight: "bold", fontSize: "14px" }}>{value.includes("All") ? value : `Shyfts in ${value.charAt(0).toUpperCase() + value.slice(1)}`}</Typography>}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(value,'location')}
                        checked={searchFilter.includes(`${value} location`)}
                        inputProps={{ "aria-labelledby": labelId }}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        color="primary"
                        key={value}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  );
                })}
              </List>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <List dense disablePadding className={classes.listroot} key={value}>
            {positions?.map((value) => {
                  const labelId = `checkbox-list-positions-label-${value}`;
                  return (
                    <ListItem key={value} button onClick={handleToggle(value, 'position')}>
                      <ListItemText
                        id={labelId}
                        primary={<Typography variant="body2" style={{ fontWeight: "bold", fontSize: "14px" }}>{value.includes("All") ? value : `${value.charAt(0).toUpperCase() + value.slice(1)}`} </Typography>}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(value,'position')}
                          checked={searchFilter.includes(`${value} position`)}
                          inputProps={{ "aria-labelledby": labelId }}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          color="primary"
                          key={value}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <List dense disablePadding className={classes.listroot} key={value}>
            {shyftTimes?.map((value) => {
                  const labelId = `checkbox-list-shyft-times-label-${value}`;
                  const isDefault = defaultValues.includes(`${value} times`);

                  return (
                    <>
                    <ListItem key={value} button onClick={handleToggle(value, 'times')}>
                      <ListItemText
                        id={labelId}
                        primary={<Typography variant="body2" style={{ fontWeight: "bold", fontSize: "14px" }}>{value}</Typography>}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(value,'times')}
                          checked={searchFilter.includes(`${value} times`)}
                          inputProps={{ "aria-labelledby": labelId }}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          color="primary"
                          key={value}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                     {isDefault && 
                     <Typography className={classes.subTitle}>
                     Shyft Start Time
                   </Typography>
                     }
                    </>
                  );
                })}
              </List>
            </TabPanel>
            <TabPanel value={value} index={3}>
            <List dense disablePadding className={classes.listroot} key={value}>
                {shyftAmount?.map((value) => {
                  const labelId = `checkbox-list-shyft-amount-label-${value}`;
                  const isDefault = defaultValues.includes(`${value} amount`);
                  const splitValue = value.split('-');
                  const formattedValue = `$${splitValue[0]} - $${splitValue[1]}`

                  return (
                    <>
                    <ListItem key={value} button onClick={handleToggle(value, 'amount')}>
                      <ListItemText
                        id={labelId}
                        primary={<Typography variant="body2" style={{ fontWeight: "bold", fontSize: "14px" }}>{value.includes("All") ? value : formattedValue}</Typography>}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(value,'amount')}
                          checked={searchFilter.includes(`${value} amount`)}
                          inputProps={{ "aria-labelledby": labelId }}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          color="primary"
                          key={value}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    {isDefault && 
                     <Typography className={`${classes.subTitle} ${classes.timeSubTitle}`}>
                     Per Hour
                   </Typography>
                     }
                    </>
                  );
                })}
              </List>
            </TabPanel>
          </div>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="10px"
          marginTop="10px"
        >
          <Button
            className={classes.Button}
            variant="outlined"
            size="large"
            color="primary"
            data-test-id="clearFilter"
            onClick={() => {
              setSearchFilter(defaultValues)
              if(handleSearchFilter)
              handleSearchFilter(defaultValues,"reset");
            }}
          >
            Clear All
          </Button>
          <Button
            className={`${classes.Button} ${classes.ApplyButton}`}
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              if (handleSearchFilter && !!searchFilter.length) {
                handleSearchFilter(searchFilter);
                handleClose();
              }
            }}
            data-test-id="applyFilter"
          >
            Apply
          </Button>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};
export default SearchWithFilterandLocation;
