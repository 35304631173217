import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    },
    input: {
      display: "none"
    },
    icon:{
      color:"#fff"
    }

  })
);

export default function UploadPicButtons({handleFileChange}:any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        onChange={(e: any) => handleFileChange(e)}
        accept="image/*"
        className={classes.input}
        id="icon-button-file"
        type="file"
        data-test-id="updateProfilePic"
      />
      <label htmlFor="icon-button-file">
        <IconButton
        className={classes.icon}
          aria-label="upload picture"
          component="span"
        >
          <PhotoCamera />
        </IconButton>
      </label>
    </div>
  );
}
