import React from "react";
import { Container, Box, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import EmployerBusinessProfileContoller, {
  Props,
} from "./EmployerBusinessProfileController";

import {
  TOfficialDocs,
  TProfileBlock,
  TRatingsData,
} from "./ShyfterBusinessProfileController";

import { AllReview } from "../../../components/src/AllReview";
import BusinessProfile from "../../../components/src/BusinessProfile";
import FormDialogBox from "../../../components/src/FormDialogBox";
import ProfileBlock from "../../../components/src/ProfileBlock";
import { ProfileQuickLink } from "../../../components/src/ProfileQuickLink";
import { UserOfficialDocs } from "../../user-profile-basic/src/UserOfficialDocs.web";
import OtpPopUp from "../../../components/src/OtpPopUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});

const styles = {
  label: {
    fontSize: "16px",
    color: "#000",
    fontWieght: 700,
    lineHeight: "18px",
  },
};

const profileBreadCrumbsPath = [
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];

const renderEmployerTabsData = (
  activeTabId: number,
  employerBlock: TProfileBlock[],
  managementBlock: TProfileBlock[],
  officialDocuments: TOfficialDocs | null,
  ratingsData: TRatingsData | null
) => {
  if (activeTabId === 1) {
    return (
      <>
        <ProfileBlock blockTitle="Basic Details" blockData={employerBlock} />
        <ProfileBlock
          blockTitle="Management Person Details"
          blockData={managementBlock}
        />
      </>
    );
  }

  if (activeTabId === 2) {
    return (
      <UserOfficialDocs
        formLabel="Certificate of Organisation"
        certificateLabel="W-9 Form"
        officialDocs={officialDocuments}
      />
    );
  }

  return ratingsData ? <AllReview ratingsData={ratingsData} /> : null;
};

const isVerifyOTPDisabled = (otp: string, seconds: number) => {
  return otp.length < 5 || seconds === 0;
};

export default class EmployerBusinessProfile extends EmployerBusinessProfileContoller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      employerProfile,
      sideTabs,
      employerBlock,
      managementBlock,
      ratingsData,
      officialDocuments,
      isEmailEditPopUpOpen,
      isEmailOtpPopUpOpen,
      isPhoneEditPopUpOpen,
      isPhoneOtpPopUpOpen,
      phoneNumber,
      phoneNumberOTP,
      seconds,
      email,
      emailOTP,
    } = this.state;
    const activeTab = sideTabs.find((tab) => tab.isActive);
    const activeTabId = activeTab ? activeTab.id : 0;

    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Your Account"
          breadCrumsData={profileBreadCrumbsPath}
        />
        <Box marginTop="10%">
          <Container maxWidth="lg">
            <Box width="100%">
              {employerProfile && (
                <BusinessProfile
                  {...employerProfile}
                  isEditable
                  ctaButtonText="Manage Account"
                  onEditEmail={this.openEditEmailModal}
                  onEditNumber={this.openEditPhoneNumberModal}
                  onCtaBtnClick={this.goToManageAccount}
                />
              )}
            </Box>
            <Box marginY="30px">
              <Grid spacing={3} container>
                <Grid xs={12} md={3} item>
                  <Box marginBottom="10px">
                    <Typography style={styles.label}>Quick Links</Typography>
                  </Box>
                  <Box borderRadius="20px" bgcolor={"#fff"}>
                    <ProfileQuickLink
                      getActiveTab={this.changeTabHandler}
                      linkData={sideTabs}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} md={9} item>
                  <Box>
                    {renderEmployerTabsData(
                      activeTabId,
                      employerBlock,
                      managementBlock,
                      officialDocuments,
                      ratingsData
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>

          <FormDialogBox
            type="email"
            name="email"
            title="Update Your Email"
            isOpen={isEmailEditPopUpOpen}
            value={email}
            handleChange={this.onChangeEmail}
            handleClose={this.closeEditEmailModal}
            handleSubmit={this.getEmailOtp}
          />

          <FormDialogBox
            name="phone"
            type="phone"
            title="Update Your Phone Number"
            isOpen={isPhoneEditPopUpOpen}
            value={phoneNumber}
            handleChange={this.onChangePhoneNumber}
            handleClose={this.closeEditPhoneNumberModal}
            handleSubmit={this.getPhoneNumberOtp}
          />

          <OtpPopUp
            isOpen={isEmailOtpPopUpOpen}
            otp={emailOTP}
            phone={email}
            setOtp={this.onChangeEmailOTP}
            seconds={seconds}
            handleClose={this.closeEmailOTPModal}
            resend={this.getEmailOtp}
            handleChange={this.closeEmailOTPModal}
            handleSubmit={this.updateUserEmail}
            disabled={isVerifyOTPDisabled(emailOTP, seconds)}
          />

          <OtpPopUp
            isOpen={isPhoneOtpPopUpOpen}
            phone={phoneNumber}
            otp={phoneNumberOTP}
            setOtp={this.onChangePhoneNumberOTP}
            seconds={seconds}
            handleClose={this.closePhoneOTPModal}
            resend={this.getPhoneNumberOtp}
            handleChange={this.closePhoneOTPModal}
            handleSubmit={this.updateUserPhoneNumber}
            disabled={isVerifyOTPDisabled(phoneNumberOTP, seconds)}
          />
        </Box>
      </ThemeProvider>
    );
  }
}
