import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
import {
  docsIcon,
  fadeDocsIcon,
  fileCircle,
  fileCircleIconFade,
  startComment,
  startCommentfade,
  globeIcon,
  phoneIcon,
  marker,
  envelopeIcon,
  userIcon,
  buildingIcon,
} from "./assets";
import { formatReviewDate, getUserId } from "../../../components/src/Utilities";

const tabs = [
  {
    id: 1,
    tab: "Overview",
    tabIcon: fileCircleIconFade,
    activeTabIcon: fileCircle,
    isActive: true,
  },
  {
    id: 2,
    tab: "Officail Docs",
    tabIcon: fadeDocsIcon,
    activeTabIcon: docsIcon,
    isActive: false,
  },
  {
    id: 3,
    tab: "Ratings",
    tabIcon: startComment,
    activeTabIcon: startCommentfade,
    isActive: false,
  },
];

import {
  TProfileBlock,
  TSideTab,
  TBusinessProfile,
  TRatingsData,
  TOfficialDocs,
} from "./ShyfterBusinessProfileController";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  employerProfile: TBusinessProfile | null;
  sideTabs: TSideTab[];
  employerBlock: TProfileBlock[];
  managementBlock: TProfileBlock[];
  ratingsData: TRatingsData | null;
  officialDocuments: TOfficialDocs | null;
  isEmailEditPopUpOpen: boolean;
  isEmailOtpPopUpOpen: boolean;
  isPhoneEditPopUpOpen: boolean;
  isPhoneOtpPopUpOpen: boolean;
  phoneNumber: string;
  email: string;
  phoneNumberOTP: string;
  emailOTP: string;
  seconds: number;
}

interface SS {
  id: any;
}

export default class EmployerBusinessProfileContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getEmployerProfileId: string = "";
  getEmailOTPId: string = "";
  getPhoneOTPId: string = "";
  patchUserProfleId: string = "";
  otpTimer?: number;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationViewBusinessProfileMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      employerProfile: null,
      sideTabs: tabs,
      employerBlock: [],
      managementBlock: [],
      ratingsData: null,
      officialDocuments: null,
      isEmailEditPopUpOpen: false,
      isEmailOtpPopUpOpen: false,
      isPhoneEditPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      phoneNumber: "",
      email: "",
      phoneNumberOTP: "",
      emailOTP: "",
      seconds: 120,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getBusinessProfileHandler(userId: number) {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEmployerProfileId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShyfteUserProfileEndPoint}/${userId}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleBusinessProfileData = (response: any) => {
    const isHotel = (response.type as string).toLowerCase() === "hotel";

    const employerProfile: TBusinessProfile = {
      id: response.id,
      name: response.full_name,
      address: response.business_details.address,
      imageURL: response.profile_picture,
      tag: response.business_details[
        isHotel ? "hotel_type" : "restaurant_type"
      ],
      phoneNumber: response.phone_number,
      websiteURL: response.email,
    };

    const employerBlock = [
      {
        title: "DBA Name",
        icon: buildingIcon,
        value: response.business_details.dba_name,
      },
      {
        title: `${isHotel ? "Hotel" : "Restaurant"} Address`,
        icon: marker,
        value: response.business_details.address,
      },
      {
        title: "Business Website ",
        icon: globeIcon,
        value: response.business_details.website,
      },
    ];

    const managementBlock = [
      {
        title: "Contact Name",
        icon: userIcon,
        value: response.full_name,
      },
      {
        title: "Contact Number",
        icon: phoneIcon,
        value: response.business_details.telephone,
      },
      {
        title: "Contact Email ID",
        icon: envelopeIcon,
        value: response.email,
      },
    ];

    const ratingsData: TRatingsData = {
      reviewsCount: response.ratings.reviews_count,
      ratingsCount: response.ratings.ratings_count,
      reviews: response.ratings.ratings_data.map((review: any) => ({
        id: review.id,
        comment: review.comment,
        score: review.score,
        date: formatReviewDate(review.created_at),
        userImg: review.worker_profile_picture,
        userName: review.worker_name,
        shiftType: review.shift_type,
      })),
      overallRating: response.ratings.overall_rating,
    };

    const officialDocuments = {
      certificate: response.official_docs.certificate,
      form: response.official_docs.form,
    };

    this.setState({
      employerProfile,
      employerBlock,
      ratingsData,
      managementBlock,
      officialDocuments,
    });
  };

  changeTabHandler = (tabId: any) => {
    const updatedTabs = this.state.sideTabs.map((tab: any) => {
      if (tab.id === tabId) {
        return { ...tab, isActive: true };
      }

      return { ...tab, isActive: false };
    });

    this.setState({ sideTabs: updatedTabs });
  };

  openEditPhoneNumberModal = () => {
    this.setState({ isPhoneEditPopUpOpen: true, phoneNumber: "" });
  };

  closeEditPhoneNumberModal = () => {
    this.setState({ isPhoneEditPopUpOpen: false });
  };

  openEditEmailModal = () => {
    this.setState({ isEmailEditPopUpOpen: true, email: "" });
  };

  closeEditEmailModal = () => {
    this.setState({ isEmailEditPopUpOpen: false });
  };

  startOtpTimer = () => {
    this.clearOTPTimer();
    this.otpTimer = window.setInterval(() => {
      this.setState((prevState) => {
        if (prevState.seconds > 0) {
          return { seconds: prevState.seconds - 1 };
        }

        this.clearOTPTimer();
        return { seconds: prevState.seconds };
      });
    }, 1000);
  };

  clearOTPTimer = () => {
    if (this.otpTimer) clearInterval(this.otpTimer);
  };

  openPhoneOTPModal = () => {
    this.setState({ isPhoneOtpPopUpOpen: true, seconds: 120 });
    this.startOtpTimer();
  };

  closePhoneOTPModal = () => {
    this.setState({ isPhoneOtpPopUpOpen: false });
    this.clearOTPTimer();
  };

  openEmailOTPModal = () => {
    this.setState({ isEmailOtpPopUpOpen: true, seconds: 120 });
    this.startOtpTimer();
  };

  closeEmailOTPModal = () => {
    this.setState({ isEmailOtpPopUpOpen: false });
    this.clearOTPTimer();
  };

  onChangePhoneNumber = (phoneNumber: string) => {
    this.setState({ phoneNumber });
  };

  onChangePhoneNumberOTP = (phoneNumberOTP: string) => {
    this.setState({ phoneNumberOTP });
  };

  onChangeEmail = (email: string) => {
    this.setState({ email });
  };

  onChangeEmailOTP = (emailOTP: string) => {
    this.setState({ emailOTP });
  };

  getPhoneNumberOtp = () => {
    const { phoneNumber } = this.state;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPhoneOTPEndpoint}?phone_number=${phoneNumber}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getPhoneOTPId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  getEmailOtp = () => {
    const { email } = this.state;
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postEmailOTPEndpoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      email,
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationPostMethod
    );

    this.getEmailOTPId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  updateUserPhoneNumber = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putUpdatePhoneEndpoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      phone_number: `91${this.state.phoneNumber}`,
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    this.patchUserProfleId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  updateUserEmail = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      user: {
        email: this.state.email,
      },
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );

    this.patchUserProfleId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleUpdateSuccess = () => {
    this.setState({
      email: "",
      phoneNumber: "",
      isEmailEditPopUpOpen: false,
      isPhoneEditPopUpOpen: false,
      isEmailOtpPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      emailOTP: "",
      phoneNumberOTP: "",
    });

    this.clearOTPTimer();

    const userId = getUserId();
    if (userId) {
      this.getBusinessProfileHandler(userId);
    }
  };

  goToManageAccount = () => {};

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getEmployerProfileId) {
        this.handleBusinessProfileData(responseJson.user.data.attributes);
      }

      if (apiRequestCallId === this.getEmailOTPId) {
        this.openEmailOTPModal();
      }

      if (apiRequestCallId === this.getPhoneOTPId) {
        this.openPhoneOTPModal();
      }

      if (apiRequestCallId === this.patchUserProfleId) {
        this.handleUpdateSuccess();
      }
    }
  }

  async componentDidMount() {
    const userId = getUserId();
    if (userId) {
      this.getBusinessProfileHandler(userId);
    }
  }

  async componentWillUnmount() {
    this.clearOTPTimer();
  }
}
