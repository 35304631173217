Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.phoneNumberOptVarifyUrl = "account_block/verify_now?phone_number=";
exports.verifyOTP = "account_block/verify_otp";
exports.phoneNumberOptVarifyContentType = "application/json";
exports.phoneNumberOptVarifyMethodType = "GET";

exports.signUpUrl = "account_block/users";
exports.signUpContentType = "multipart/form-data";
exports.signUpMethodType = "POST";
exports.hotelList = [
  { name: "Luxury", value: "luxury"},
  { name: "Economy", value: "economy"},
  { name: "Mid Scale", value: "midscale"},
  { name: "Resort", value: "resort"},
];
exports.restaurantList = [
  { name: "Quick Serve", value: "quick_serve"},
  { name: "Casual", value: "casual"},
  { name: "Up Scale", value: "upscale"},
  { name: "Fine Dining", value: "fine_dining"},
];
exports.dlStateList = [
  { name: "Texas", value: "texas"},
  { name: "Florida", value: "florida"},
  { name: "Alaska", value: "alaska"},
];
exports.experienceList = [
  { name: "1 Year", value: "1"},
  { name: "2 Years", value: "2"},
  { name: "3 Years", value: "3"},
  { name: "4 Years", value: "4"},
  { name: "5 Years", value: "5"},
];
exports.restaurantSkills = [
  "Server",
  "Host",
  "Bartender",
  "Busser/Runner",
  "Cook",
  "Dishwasher",
];
exports.hotelSkills = [
  "Front Desk",
  "Concierge",
  "Bellperson",
  "Doorman",
  "Room Attendant",
  "House Person",
  "Laundry Attendant",
  "Shuttle Driver",
  "Engineer (Maintenance)"
];
exports.minMaxCharLimitRegex = /^.{4,30}$/;
exports.minMaxCharLimitForAddressRegex = /^.{4,100}$/;
exports.phoneNumberRegex = /^\d{10}$/;
exports.emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
exports.einNumberRegex = /^\d{9,11}$/;
exports.fullNameRegex = /^[A-Za-z ]+$/;
exports.backToLogin = "Back to Login";
exports.enterPersonalDetails = "Enter Your Personal Details";
exports.enterProfessionalDetails = "Enter Your Professional Details";
exports.fullName = "Full Name";
exports.mobileNumber = "Mobile Number";
exports.verifyNow = "Verify Now";
exports.emailAddress = "Email Address";
exports.address = "Address";
exports.dateOfBirth = "Date Of Birth";
exports.enterSSN = "Enter SSN";
exports.selectDLState = "Select DL State";
exports.dlNumber = "DL-Number";
exports.next = "Next";
exports.yourExperience = "Your Experience";
exports.prev = "Prev";
exports.signUpNow = "Sign Up Now";
exports.languageSkills = "Language Skills";



// Customizable Area End
