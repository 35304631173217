import React from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchWithFilterandLocation from "./SearchWithFilterandLocation";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const location = [
  {
    id: 1,
    country: "US",
    city: "New York",
    state: "Loss Engelish",
  },
  {
    id: 2,
    country: "Germany",
    city: "New York",
    state: "Anhd",
  },
];
const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      paddingTop: "24px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
  },
}));

interface ISuccessPopupDialogProps {
  open: boolean;
  isShyfter?: boolean;
  closeModal: (e: any) => void;
  onSearch: (value: string) => void;
  removeQueryFromSearch?: (query: string) => void;
  recentSearches?: string[];
}

const SearchShyftsModal: React.FC<ISuccessPopupDialogProps> = ({
  open,
  closeModal,
  onSearch,
  recentSearches = [],
  removeQueryFromSearch,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="xs"
      scroll="body"
      fullWidth
    >
      <IconButton
        className="closeIcon"
        onClick={closeModal}
        data-test-id="closeModal"
      >
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className="successDialogTitle">Search Your Shyfts</Box>
      <Box marginTop={"-20px"}>
        <SearchWithFilterandLocation modal={true} handleSerchModal={onSearch} />
      </Box>
      {recentSearches.length !== 0 && (
        <Box
          width={"100%"}
          maxWidth={"100%"}
          justifyContent="space-between"
          display="flex"
          alignItems={"center"}
        >
          <Divider style={{ width: "25%" }} />
          <Box margin={"10px 0"} textAlign={"center"}>
            Recent Search
          </Box>
          <Divider style={{ width: "25%" }} />
        </Box>
      )}
      <Box>
        <List>
          {recentSearches.map((search) => {
            return (
              <>
                <ListItem key={search}>
                  <ListItemText primary={search} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      data-test-id="remove-search"
                      onClick={() =>
                        removeQueryFromSearch && removeQueryFromSearch(search)
                      }
                    >
                      <CloseIcon style={{ height: "14px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Divider style={{ width: "25%" }} />
        <Box margin={"10px 0"} textAlign={"center"}>
          Top Hotels & Restaurants{" "}
        </Box>
        <Divider style={{ width: "25%" }} />
      </Box>
      <Box>
        <List>
          {location.map((elm) => {
            return (
              <>
                <ListItem key={elm.id}>
                  <ListItemText primary={elm.country} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <ArrowRightAltIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Box>
    </Dialog>
  );
};

export default SearchShyftsModal;
