import React from "react";
import { Container, Box, Typography, Grid, Divider } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ProfileCard from "../../../components/src/ProfileCard";
import ShyfterProfilePageContoller, {
  Props,
} from "./ShyfterProfilePageController.web";
import { AllReview } from "../../../components/src/AllReview";
import { ProfileQuickLink } from "../../../components/src/ProfileQuickLink";
import CustomButton from "../../../components/src/CustomButton.web";
import {UserProfileBasicBlock} from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import {UserOfficialDocs} from "../../user-profile-basic/src/UserOfficialDocs.web";
import { breadCrumSubsPath } from "../../../components/src/Utilities";

export default class ShyfterProfilePage extends ShyfterProfilePageContoller {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader {...this.props} isBreadcrumbs={true} currentPage="Your Account" breadCrumsData={breadCrumSubsPath}/>
        <Box marginTop={"10%"}>
          <Container maxWidth={"lg"}>
            <Box width={"100%"}>
              <ProfileCard userData={this.state.user} isProfilePage={true} navigateManageProfile={()=>this.navigateManageProfile()}/>
            </Box>
            <Box marginY={"15px"}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Box marginBottom={"20px"}>
                  <Typography style={webStyle.lable}>Quick Link</Typography>
                </Box>
                <Box bgcolor={"#fff"} borderRadius={"20px"}>
                  <ProfileQuickLink
                    linkData={this.state.sideNavTab}
                    getActiveTab={this.handleActiveTab}
                  />
                </Box>
                <Box width={"100%"} marginY={"15px"}>
                  <Divider />
                </Box>
                <CustomButton label="Logout" islogOutBtn={true} isDanger={true} isOutlined={true}/>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box>
                  {this.state.sideNavTab.map((tab: any) => {
                    if (tab.id == 1 && tab.isActive) {
                      return <UserProfileBasicBlock userData={this.state.user} key={tab.id}/>;
                    } else if (tab.id == 2 && tab.isActive) {
                      return <UserOfficialDocs key={tab.id} officialDocs={this.state.user?.official_docs}/>;
                    } else if (tab.id == 3 && tab.isActive) {
                      return <AllReview key={tab.id}/>;
                    }
                  })}
                </Box>
              </Grid>
            </Grid>
            </Box>
           
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  boxContainer: {
    marginY: "10px",
    bgcolor: "#fff",
    borderRadius: "20px",
    border: "1px solid #e1e6ec",
  },
  lable: {
    fontSize: "16px",
    color: "#000",
    fontWieght: 700,
    lineHeight: "18px",
  },
  fadeText: {
    fontSize: "16px",
    color: "#6f7c8e",
    fontWieght: 500,
    lineHeight: "18px",
  },
  contentText: {
    fontSize: "16px",
    color: "#000",
    fontWieght: 500,
    lineHeight: "18px",
  },
  activeText: {
    fontSize: "16px",
    color: "#390879",
    fontWieght: 500,
    lineHeight: "18px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
