// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { BreadCrumType, breadCrumPathData } from "../../../components/src/Utilities";
import React from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isSuccess: boolean,
  formData: {
    fullName: string;
    reason: string;
    message: string;
  };
  formDataErrors: {
    fullName: string;
    reason: string;
    message: string;
  };
  helpCenterBreadCrumPath: BreadCrumType[];
  currentPage: string;
  helpApiError: boolean;
  openPopup: boolean;
}

interface SS {
  id: any;
}

export default class HelpCenterWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  helpUsApiCallId: string = "";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      isSuccess: false,
      formData: {
        fullName: '',
        reason: '',
        message: '',
      },
      formDataErrors: {
        fullName: '',
        reason: '',
        message: '',
      },
      helpCenterBreadCrumPath: breadCrumPathData,
      currentPage: 'Help Center',
      helpApiError: false,
      openPopup: false,
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo(0,0);
  }

  initialFormErrorObject = () => {
    const errObject = {
            fullName: "",
            reason: "",
            message: '',
    }
    return errObject
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
         
        if (!apiRequestCallId || !responseJson) {
            console.log("Error in API Message Recived- HelpCenter");
            return;
        }

        if (apiRequestCallId === this.helpUsApiCallId) {
          if(responseJson && !responseJson.error) {
            this.setState({
              formDataErrors: this.initialFormErrorObject(),
              formData: {
                fullName: '',
                reason: '',
                message: '',
              },
              helpApiError: false,
              isSuccess: true,
            });
          } 
          if(responseJson.error) {
            this.setState({ helpApiError: true, isSuccess: false })
          }
          this.setState({ openPopup: true })
        }
    }
  } 

  handleModalClose = () => {
    this.setState({ openPopup: false, isSuccess: false, helpApiError: false })
  }

  handleFormInputChange = (event: any, name: any) => {
    const { value } = event.target;
    let newValue = value;
    switch (name) {
    case "fullName": 
        const tempBusinessValue = newValue.replace(/[^a-zA-Z\s]/g, '');
        newValue = tempBusinessValue.slice(0, 40);
        break;
    case "message": 
        const tempMesssageValue = newValue.slice(0, 200);
        newValue = tempMesssageValue;
        break;
    }

    this.setState((prevState) => (
        {
            formData: {
                ...prevState.formData,
                [name]: newValue,
            },
            formDataErrors: {
                ...prevState.formDataErrors,
                [name]: "",
            },
        })
    )

  };

  handleFormErrors = (value: any) => {
      this.setState((prevState) => 
          ({ 
              formDataErrors: {
                  ...prevState.formDataErrors, 
                  [value]: 'This field is required' 
              },
          })
      );
  }

  isFormValid = () => {
    let isValid = false;
      isValid = Object.values(this.state.formDataErrors).every((value: any)=> {
        if (value === "") {
          return true;
        }
        return false;
      });

    if(isValid) {
        this.setState({ formDataErrors: this.initialFormErrorObject() })
        this.contactFormApiCall();
    } else {
        this.onFormSubmitHandler()
    }
  }

  onFormSubmitHandler = () => { 
    const { fullName, reason, message } = this.state.formData;

    if(fullName === "" || !configJSON.minMaxFullNameLimitCheck.test(fullName)) {
      this.handleFormErrors("fullName");
    }
    if(reason === "" ) {
        this.handleFormErrors("reason");
    }
    if(message === ""  || !configJSON.minMaxMessageLimitCheck.test(message)) {
        this.handleFormErrors("message");
    }

    if(fullName !== "" && reason !== "" && message !== "") {
        this.setState({
            formDataErrors : this.initialFormErrorObject()
        })
        this.isFormValid()
    }
  }

  contactFormApiCall = () => {
    const { formData } = this.state;
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: localStorage.getItem('authToken'),
        };

        let body = {
            help_us: {
                select_reason: formData.reason,
                message: formData.message 
            }
        };
    
         
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.helpUsApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.helpusApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
  }

}

// Customizable Area End