import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Typography,
  Divider,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";
import Rating from "./Rating";
import CustomInputTextBox from "./CustomInputTextBoxField.web";
const Briefcase = require("./assets/images/briefcase-blank.png");
const defaultProfile = require("./assets/images/ProfileImg.png");
const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      // overflowY: "visible",
      padding: 30,
    },
    "& div[role='dialog']": {
      padding: '10px 15px',
      borderRadius: '20px',
      overflowY: "visible",

    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-46px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      paddingTop: "20px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
      paddingBottom: "18px",
    },
  },
  errorMessage: {
    fontSize: "14px",
    color: "red",
  },
}));

interface ISuccessPopupDialogProps {
  open: boolean;
  isShyfter?: boolean;
  handleCloseModal: any;
  handleSubmitReview: (rating: number, comment: string) => void;
  title?: string;
  subtitle?: string;
  profile?: string | null;
  isFromEmployerEnd?: boolean;
}

const RatingPopUp: React.FC<ISuccessPopupDialogProps> = ({
  open,
  title,
  subtitle,
  isShyfter,
  handleSubmitReview,
  handleCloseModal,
  profile,
  isFromEmployerEnd
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmitReview = () => {
    setIsSubmitted(true);

    if (comment && rating > 0) {
      handleSubmitReview(rating, comment);
      setIsSubmitted(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setIsSubmitted(false);
      setRating(0);
      setComment("");
    }
  }, [open]);

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" onClick={handleCloseModal}>
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className="successDialogTitle">
        {isFromEmployerEnd ? "Rate & Review Shyfter" : "Rate & Review Bussiness"}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Box
          height={"24px"}
          width={isFromEmployerEnd ? "40px" :"24px"}
          bgcolor={isFromEmployerEnd ? "#fff" : "rgba(57, 8, 121, 0.05)"}
          borderRadius="10px"
          padding="10px"
          margin="10px"
        >
          {isFromEmployerEnd ?
            <img 
              src={profile && profile !== null ? profile : defaultProfile} 
              alt="user_profile" height="56px" width="60px" 
              style={{ borderRadius: "12px", marginTop: "-14px", marginLeft: "-10px" }}
            />
            :
            <img src={Briefcase} alt="" height="24px" width="24px" />
          }
        </Box>
        <Box textAlign="center" padding={"10px"}>
          <Typography style={{ fontWeight: 700, fontSize: "24px", marginBottom: "2px" }}>{title}</Typography>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            { isFromEmployerEnd &&
              <img src={Briefcase} alt="" height="20px" width="22px" style={{ marginRight: "5px" }} />
            }
            <Typography style={{ color: "#390879", fontSize: "18px" }}>{subtitle}</Typography>
          </Box>
        </Box>
        <Box textAlign="center" padding={"10px"}>
          <Rating rating={rating} handleRatingVal={(newValue:any)=> setRating(newValue)}/>

          {isSubmitted && rating === 0 && (
            <Typography className={classes.errorMessage}>
              Please give a rating!
            </Typography>
          )}
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={"10px 0"}
          width={"100%"}
        >
          <Divider style={{ width: "31%" }} />
          <Box margin={"3px 0"}>
            <Typography style={{ fontSize: "18px", fontWeight: 600, margin: "0px 6px" }}>Add Review</Typography>
          </Box>
          <Divider style={{ width: "31%" }} />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          maxWidth={"100%"}
          width={"85%"}
        >
          {/* <TextField
            id="outlined-basic"
            label="Your Comment..."
            variant="outlined"
            value={comment}
            minRows={3}
            data-test-id="add-comment"
            onChange={(e) => setComment(e.target.value)}
          /> */}

          <CustomInputTextBox
            data-test-id="add-comment"
            value={comment}
            handleChange={(event: any) => setComment(event.target.value)}
            label={"Your Comment..."}
            name="comment"
            maxLength={200}
            error={false}
          />
          {isSubmitted && !comment && (
            <Typography className={classes.errorMessage}>
              Please enter a comment!
            </Typography>
          )}
        </Box>
        <Box className="actionWrapper" width={"200px"}>
          <CustomButton label="Rate Now" handleClick={onSubmitReview} />
        </Box>
      </Box>
    </Dialog>
  );
};

export default RatingPopUp;
