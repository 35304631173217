Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions3";
exports.labelBodyText = "TermsAndConditions3 Body";

exports.btnExampleTitle = "CLICK ME";

exports.termsAndConditionEndPoint = "bx_block_termsandconditions3/terms_conditions_privacy_policy?type=terms_and_conditions&role="
exports.privacyPolicyEndPoint = "bx_block_termsandconditions3/terms_conditions_privacy_policy?type=privacy_policy&role="

exports.termsAndConditionType = "terms_and_conditions";
exports.privacyPolicyType = "privacy_policy";

exports.shyfterRole = "shyfter";
exports.businessRole = "business";

exports.businessTileText = "Businesses";
exports.snagShyftTitleText = "Snagashyft";

// Customizable Area End