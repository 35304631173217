import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  docsIcon,
  fadeDocsIcon,
  fileCircle,
  fileCircleIconFade,
  startComment,
  startCommentfade,
} from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";


export const configJSON = require("./config");

const sideNavTab = [
  {
    id: 1,
    tab: "Overview",
    tabIcon: fileCircleIconFade,
    activeTabIcon: fileCircle,
    isActive: true,
  },
  {
    id: 2,
    tab: "Officail Docs",
    tabIcon: fadeDocsIcon,
    activeTabIcon: docsIcon,
    isActive: false,
  },
  {
    id: 3,
    tab: "Ratings",
    tabIcon: startComment,
    activeTabIcon: startCommentfade,
    isActive: false,
  },
];


export interface Props {
  navigation: any;
  id: string;
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}

interface S {
  isPastShyft?: boolean;
  sideNavTabs?: any;
  user:any;
  isAccepted: boolean;
  isIgnored: boolean;
}

interface SS {
  id: any;
}

export default class ApplicantProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserApiCallId: string="";
  ignoreRequestShyftApiCallId:string = "";
  acceptRequestShyftApiCallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationApplicantProfileMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.state = {
      isPastShyft: false,
      sideNavTabs: sideNavTab,
      user:"",
      isAccepted: false,
      isIgnored: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    const applicantId= await getStorageData("applicantId");
    const requestId= await getStorageData("requestId");
  
    !!applicantId && this.getApplicantProfile(applicantId);

    Number(requestId) === 0 && this.setState({
      isAccepted: true
  })
  
  Number(requestId) === -1 &&  this.setState({
    isIgnored: true
})

  };


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponseData(message);
    }

  }
  handleApiResponseData(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    (apiRequestCallId === this.getUserApiCallId) && this.getApplicantUserProfileRes(responseJson);
    (apiRequestCallId === this.acceptRequestShyftApiCallId) && setStorageData("requestId", JSON.stringify(0));
    (apiRequestCallId === this.acceptRequestShyftApiCallId) && this.setState({
      isAccepted: true
  })


    if (apiRequestCallId === this.ignoreRequestShyftApiCallId) {
      this.setState({
        isIgnored: true
    })
    setStorageData("requestId", JSON.stringify(-1));
    }
  }


  async getApplicantProfile(userId:any) {
    const getToken = await getStorageData('authToken')
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token: getToken
    };
    const requestMessageProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserApiCallId = requestMessageProfile.messageId;
    requestMessageProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShyfteUserProfileEndPoint+`/${userId}`
    );
    requestMessageProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessageProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageProfile.id, requestMessageProfile);
  }

  getApplicantUserProfileRes(responseJson: any) {
    this.setState({
        user:responseJson.user.data.attributes
    })
  }

  handleActiveTabs = (tabId:any) => {
    const updatedTabs = this.state.sideNavTabs.map((aplicantTab:any) => {
      if (aplicantTab.id === tabId) {
        return { ...aplicantTab, isActive: true };
      } else {
        return { ...aplicantTab, isActive: false };
      }
    });

    this.setState({ sideNavTabs: updatedTabs });
  };

  apiCallApplicant = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessageForAplicant = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForAplicant.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
      token: await getStorageData('authToken')
    };
    requestMessageForAplicant.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageForAplicant.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageForAplicant.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageForAplicant.id, requestMessageForAplicant);
    return requestMessageForAplicant.messageId;
  };

  ignoreRequestShyft = async () => {
    const requestId =  await getStorageData("requestId");
    const shiftId =  await getStorageData("shiftId");
    let apiData = {
      shift_id: shiftId,
      request_id: requestId
                  }
        this.ignoreRequestShyftApiCallId = await this.apiCallApplicant({
          contentType: configJSON.postignoreRequestApiContentType,
          method: configJSON.postignoreRequestApiMethodType,
          endPoint: configJSON.postignoreRequestEndPoint,
          body:apiData
    });
  }
  
    acceptRequestShyft = async () => {
      const requestId = await getStorageData("requestId");
      const shiftId =  await getStorageData("shiftId");
      let apiDataObject = {
        shift_id: shiftId,
        request_id: requestId
      }
      this.acceptRequestShyftApiCallId = await this.apiCallApplicant({
          contentType: configJSON.postaceeptRequestApiContentType,
          method: configJSON.postaceeptRequestApiMethodType,
          endPoint: configJSON.postaceeptRequestEndPoint,
          body:apiDataObject
        });
    }

}
