import React from 'react';
import TimerWithCircularProgressBar from './TimerWithCircularProgressBar';
import { Avatar, Badge, Box } from '@material-ui/core';
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { ProfileImg } from '../../blocks/landingpage/src/assets';
import UploadButton from './UploadButton';
import UploadPicButtons from './UploadPicButton';

interface Props{
    progress:number;
    handleFileChange:any;
    profilePic?:any;
}

export const UpdateProfileCard=(props:Props)=>{
    return(
        <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        border={"1px solid #e1e6ec"}
        bgcolor={"#fff"}
        padding={"20px"}
        borderRadius={"10px"}
      >
        <Box>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"30px"}
                width={"30px"}
                borderRadius={"50px"}
                bgcolor={"#390879"}
                padding={"2px"}
                color={"#fff"}
              >
                <UploadPicButtons handleFileChange={(e:any)=>props.handleFileChange(e)}/>
              </Box>
            }
          >
            <Avatar
              alt="Travis Howard"
              src={!!props.profilePic?props.profilePic:ProfileImg}
              style={{ height: "80px", width: "80px" }}
            />
          </Badge>
        </Box>
        <Box>
          <TimerWithCircularProgressBar
            percent={props.progress}
            size={50}
            isShyftPage={false}
          />
        </Box>
      </Box>
    )
}