import React from "react";

import LandingPageHeaderController, {
  Props,
} from "./LandingPageHeaderController.web";
import {
  Container,
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Divider,
  Paper,
  Breadcrumbs,
  Link,
  Modal,
  Popover,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Logo,
  HomeIcon,
  MessageIcon,
  BookmarkBlank,
  Notification,
  Arrow,
  PastShyft,
  JobIcon,
  ProfileImg,
  VerifiedIcon,
  Edit,
  RightArrowIcon,
  Help,
  SignoutIcon,
  PlaceIcon,
  BookmarkBlankfilled,
  JobIconDis,
  CrownIcon,
  dashboardUnSelectedIcon,
  dashboardSelectedIcon,
  settings,
  document,
  pastShyftActive,
  commentsBlue,
} from "./assets";
import MenuIcon from "@material-ui/icons/Menu";
import SideNave from "../../../components/src/SideNav";
import NotificationList from "../../notifications/src/NotificationList.web";
const theme = createTheme({
  typography: {
    h6: {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#6f7c8e",
      fontWeight: 500,
      fontFamily: "Gotham",
      textAlign: "center",
    },
  },
});

const setDashboardTabIcon = (activeTab: string) => {
  return activeTab === "/DashboardStats" ? dashboardSelectedIcon : dashboardUnSelectedIcon;
}

const setSavedShyftTabIcon = (activeTab: string) => {
  return activeTab == "/SavedShyft" ? BookmarkBlankfilled : BookmarkBlank;
}

const setMyShyftTabIcon = (activeTab: string) => {
  return activeTab == "/MyShyfts" ? JobIcon : JobIconDis;
}

const setPastShyftTabIcon = (activeTab: string) => {
  return activeTab == "/PastShyft" ? pastShyftActive : PastShyft;
}

const setMessagesTabIcon = (activeTab: string) => {
  return activeTab == "/Messages" ? commentsBlue : MessageIcon;
}

const setActiveTabStyle = (tab: string, activeTab: string, isEmployer: boolean) => {
  if (isEmployer && activeTab === "/EmployerHomePage" && tab === "/ShyfterDashboard") {
    return styles.activeTab;
  }
  return tab === activeTab ? styles.activeTab : styles.tabText;
}

export default class LandingPageHeader extends LandingPageHeaderController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box
          width="100%"
          display={{
            xs: "none",
            sm: "block",
            md: "block",
            lg: "block",
            xl: "block",
          }}
        >
          <AppBar style={styles.mainHeaderContainer}>
            <Toolbar>
              <Container maxWidth="lg">
                <Grid container style={styles.container}>
                  <Grid item sm={3}>
                    <Box
                      style={styles.tab}
                      onClick={() => this.goToHome()}
                      data-test-id={"logo"}
                    >
                      <img src={Logo} alt="logo" height="40px" />
                    </Box>
                  </Grid>
                  <Grid item sm={5}>
                    <Container style={styles.tabsContainer}>
                      <Box
                        style={styles.tab}
                        onClick={() => this.goToHome()}
                        data-test-id={"goToHome"}
                      >
                        <img src={HomeIcon} alt="logo" style={styles.tabIcon} />
                        <Typography style={setActiveTabStyle("/ShyfterDashboard", this.state.activeTab, this.state.isEmployer)}>Home</Typography>
                      </Box>

                      {!this.state.isEmployer && (
                        <Box
                          style={styles.tab}
                          onClick={() => this.goToMyShyft()}
                          data-test-id={"goToMyShyfts"}
                        >
                          <img
                            src={setMyShyftTabIcon(this.state.activeTab)}
                            alt="logo"
                            style={styles.tabIcon}
                          />
                            <Typography style={setActiveTabStyle("/MyShyfts", this.state.activeTab, this.state.isEmployer)}>
                              My Shyfts
                            </Typography>
                        </Box>
                      )}
                      <Box style={styles.tab} data-test-id={"goToMessage"} onClick={() => this.goToPage("Messages")}>
                        <img
                          src={setMessagesTabIcon(this.state.activeTab)}
                          alt="logo"
                          style={styles.tabIcon}
                        />
                          <Typography style={setActiveTabStyle("/Messages", this.state.activeTab, this.state.isEmployer)}>
                            Messages
                          </Typography>
                      </Box>
                      {this.state.isEmployer && (
                        <Box style={styles.tab} onClick={() => this.goToDashboardStats()}
                        data-test-id="dashboardStats">
                          <img
                            src={setDashboardTabIcon(this.state.activeTab)}
                            alt="logo"
                            style={styles.tabIcon}
                          />
                            <Typography style={setActiveTabStyle("/DashboardStats", this.state.activeTab, this.state.isEmployer)}>
                              Dashboard
                            </Typography>
                        </Box>
                      )}
                      {!this.state.isEmployer && (
                        <>
                          <Box
                            style={styles.tab}
                            onClick={() => this.goToPastShyft()}
                            data-test-id={"goToPastShyft"}
                          >
                            <img
                              src={setPastShyftTabIcon(this.state.activeTab)}
                              alt="logo"
                              style={styles.tabIcon}
                            />
                              <Typography style={setActiveTabStyle("/PastShyft", this.state.activeTab, this.state.isEmployer)}>
                                Past Shyfts
                              </Typography>
                          </Box>
                        </>
                      )}
                    </Container>
                  </Grid>
                  <Grid item sm={4} style={styles.tabsContainer}>
                    {this.state.isEmployer ? (
                      <Box
                        style={styles.iconBox}
                        borderRadius={"50%"}
                        onClick={()=>this.navigateToYourSubscription()}
                        data-test-id={"yoursubscriptionTab"}
                      >
                        <img src={CrownIcon} alt="logo" height="18px" />
                      </Box>
                    ) : (
                      <Box
                        style={styles.iconBox}
                        borderRadius={"50%"}
                        onClick={() => this.goToSavedShyft()}
                        data-test-id={"goSavedShyft"}
                      >
                        <img
                          src={setSavedShyftTabIcon(this.state.activeTab)}
                          alt="logo"
                          height="18px"
                        />
                      </Box>
                    )}
                    <Box
                      style={styles.iconBox}
                      borderRadius={"50%"}
                      onClick={(e: any) => this.showNotification(e)}
                      data-test-id={"goToNotification"}
                    >
                      <img src={Notification} alt="logo" height="18px" />
                    </Box>
                    <Box
                      style={styles.profileTab}
                      onClick={() => this.openProfileMenu()}
                      data-test-id={"goToProfileMenu"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <img
                          src={ProfileImg}
                          alt="logo"
                          style={styles.prodileImg}
                        />
                        <Typography style={styles.tabText}>
                          {this.props.userName?.slice(0, 7)}...
                        </Typography>
                      </Box>
                      <Box
                        padding={"5px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <img src={Arrow} alt="logo" height="18px" />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Toolbar>
            {this.props.isBreadcrumbs && (
              <>
                <Divider />
                <Container maxWidth="lg">
                  <Box
                    data-test-id={"breadCrumBoxWrapperId"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    paddingY={"5px"}
                    marginY={"5px"}
                  >
                    <Box display={"flex"} alignItems={"center"} color={"#000"}>
                      <Box 
                        data-test-id={"breadCrumBackArrowId"}
                        bgcolor={"#e1e6ec"} borderRadius={"50px"} width={"28px"} 
                        height={"28px"} display="flex" justifyContent={"center"} 
                        alignItems={"center"}
                        onClick={() => this.goToHome()}
                      >
                        <ArrowBackIcon />
                      </Box>
                      <Typography style={{ marginLeft: '10px', fontSize: "15px", color: 'black', fontWeight: 'bold' }}>

                      {this.props.pageTitle?this.props.pageTitle:this.props.currentPage}
                      </Typography>
                      </Box>
                    <Breadcrumbs
                      separator={<NavigateBeforeIcon fontSize="small" />}
                      aria-label="breadcrumb"
                      style={{ fontSize: "14px" }}
                    >
                      <Typography
                        color="textPrimary"
                        style={{ fontSize: "14px", color: '#7c8084', fontWeight: 500 }}
                      >
                        {this.props.currentPage}
                      </Typography>

                      { this.props.breadCrumsData &&
                        this.props.breadCrumsData.map((item:any, index: number) => {
                          return (
                            <Link
                              key={index}
                              color="inherit"
                              style={{ color: '#390879', cursor: 'pointer' }}
                              onClick={() => this.handleBreadCrumNavigation(item.link)}
                              data-test-id={`breadCrumOptionsId-${index}`}
                            >
                              {item.title}
                            </Link>
                          );
                        })
                      }
                    </Breadcrumbs>
                  </Box>
                </Container>
              </>
            )}
          </AppBar>
        </Box>
        <Box
          width={"100%"}
          display={{
            xs: "block",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          }}
        >
          <AppBar style={mobileStyle.mobileMainHeader}>
            <Toolbar>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <IconButton
                  edge="start"
                  style={mobileStyle.menuBtn}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => this.openTabsMenu()}
                  data-test-id="sideNav"
                >
                  <MenuIcon />
                </IconButton>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img src={ProfileImg} alt="logo" style={styles.prodileImg} />
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="left"
            open={this.state.isOpenTabsMenu}
            onClose={() => this.openTabsMenu}
            style={{ width: "50%" }}
            data-test-id="sideNaveOpenBtn"
          >
            <SideNave isMobileView={true} />
          </Drawer>
        </Box>

        <Modal
          open={this.state.isProfileMenu}
          onClose={() => this.setState({ isProfileMenu: false })}
        >
          <Paper elevation={3}>
            <Box style={styles.modal} right={"10%"}>
              <Box style={styles.modalContent}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  onClick={this.props.gotToProfile}
                >
                  <img
                    src={ProfileImg}
                    alt=" userImg"
                    height="40px"
                    width="40px"
                  />
                  <Box margin={"0 10px"}>
                    <Typography style={styles.userName}>{this.props.userName}</Typography>
                    <Box>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <img
                          src={PlaceIcon}
                          alt=""
                          height={"14px"}
                          width={"14px"}
                          style={{marginRight: "5px"}}
                        />
                        <Typography style={styles.tabText}>
                          {this.props.userAddress}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={VerifiedIcon}
                    alt=""
                    height={"18px"}
                    width={"18px"}
                  />
                </Box>
              </Box>
              <Box margin={"5px 0px"}>
                <Divider variant="middle" />
              </Box>
              <Box padding={"10px"}>
                <Typography style={styles.tabText}>
                  {" "}
                  General Settings
                </Typography>
              </Box>
              <Box style={styles.modalContent}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Box style={styles.iconBox} borderRadius={"10px"}>
                    <img src={Edit} alt="" height={"18px"} width={"18px"} />
                  </Box>
                  <Typography style={styles.menuItem}>
                    Edit & Update Profile
                  </Typography>
                </Box>
                <Box
                  style={styles.iconBox}
                  maxHeight={"24px"}
                  maxWidth={"24px"}
                  borderRadius={"49px"}
                >
                  <img
                    src={RightArrowIcon}
                    alt=""
                    height={"10px"}
                    width={"8px"}
                  />
                </Box>
              </Box>
              <Box style={styles.modalContent}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Box style={styles.iconBox} borderRadius={"10px"}>
                    {" "}
                    <img src={settings} alt="" height={"18px"} width={"18px"} />
                  </Box>
                  <Typography style={styles.menuItem}>Settings</Typography>
                </Box>
                <Box
                  style={styles.iconBox}
                  maxHeight={"24px"}
                  maxWidth={"24px"}
                  borderRadius={"50px"}
                >
                  <img
                    src={RightArrowIcon}
                    alt=""
                    height={"10px"}
                    width={"8px"}
                  />
                </Box>
              </Box>
              <Box margin={"5px 0px"}>
                <Divider variant="middle" />
              </Box>
              <Box padding={"10px"}>
                <Typography style={styles.tabText}>
                  Other
                </Typography>
              </Box>
              <Box style={styles.modalContent}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Box style={styles.iconBox} borderRadius={"10px"}>
                    {" "}
                    <img src={Help} alt="" height={"18px"} width={"18px"} />
                  </Box>
                  <Typography style={styles.menuItem}>Help Center</Typography>
                </Box>
                <Box
                  data-test-id="goToPageHelpCenterId"
                  style={styles.iconBox}
                  maxWidth="24px"
                  borderRadius="50px"
                  maxHeight="24px"
                  onClick={() => this.goToPage("HelpCentre")}
                >
                  <img src={RightArrowIcon} alt="" width="8px" height="10px" />
                </Box>
              </Box>
              <Box style={styles.modalContent}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Box style={styles.iconBox} borderRadius={"10px"}>
                    <img src={document} alt="Term & Conditions" width="18px" height="18px" />
                  </Box>
                  <Typography style={styles.menuItem}>
                    Term & Conditions
                  </Typography>
                </Box>
                <Box
                  data-test-id="goToPageTermsAndConditionsId"
                  style={styles.iconBox}
                  maxWidth="24px"
                  maxHeight="24px"
                  borderRadius="50px"
                  onClick={() => this.goToPage("TermsAndConditions")}
                >
                  <img src={RightArrowIcon} alt="" width="8px" height="10px" />
                </Box>
              </Box>
              <Box margin="5px 0px">
                <Divider variant="middle" />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                padding={"10px"}
              >
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  padding={"0px 10px"}
                  border={"1px solid #390879"}
                  borderRadius={"50px"}
                  width={"100%"}
                >
                  <Typography style={{color: "#390879"}}>Logout Account</Typography>
                  <Box margin={"10px"}>
                    <img
                      src={SignoutIcon}
                      alt=""
                      height={"18px"}
                      width={"18px"}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Modal>

        <Popover
        open={Boolean(this.state.isShowNotification)}
        anchorEl={this.state.isShowNotification}
        onClose={this.closeNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            borderRadius: "14px"
          }
        }}
      >
        <NotificationList navigation={this.props.navigation} id="" />
      </Popover>
      </ThemeProvider>
    );
  }
}

const styles: Record<string, CSSProperties> = {
  activeTab: {
    color: "#390879",
    fontSize: "14px",
    fontFamily: '"Gotham",sans-serif',
    fontWeight: 400,
    lineHeight: "18px",
  },
  mainHeaderContainer: {
    backgroundColor: "#fff",
    padding: "5px 0px",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  tabIcon: {
    height: "18px",
    marginRight: "5px",
  },
  tabText: {
    fontSize: "14px",
    fontFamily: '"Gotham",sans-serif',
    fontWeight: 400,
    lineHeight: "18px",
    color: "#6f7c8e",
    textTransform: "capitalize",
  },
  profileTab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #6f7c8e",
    width: "50%",
    padding: "5px",
    borderRadius: "30px",
  },
  prodileImg: {
    borderRadius: "50px",
    height: "40px",
    marginRight: "5px",
  },
  userName: {
    fontSize: "20px",
    color: "#000",
    fontFamily: `"Gotham",sans-serif`,
    fontWeight: 700,
    textTransform: "capitalize",
  },
  menuItem: {
    fontSize: "14px",
    color: "#000",
    fontFamily: `"Gotham",sans-serif`,
    fontWeight: 500,
  },
  outlineBtn: {
    margin: "10px",
    borderRadius: "50px",
    padding: " 5px 15px",
  },
  modal: {
    maxWidth: "320px",
    width: "320px",
    height: "auto",
    padding: "20px",
    backgroundColor: "#ffff",
    position: "absolute",
    top: "10%",
    borderRadius: "20px",
    zIndex: 999999,
  },
  modalContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
  },
  iconBox: {
    backgroundColor: "rgba(225, 195, 247, 0.3)",
    height: "40px",
    width: "40px",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  notificationTitle: {
    color: "#390879",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: `"Gotham",sans-serif`,
  },
  notificationDes: {
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: `"Gotham",sans-serif`,
  }
};
const mobileStyle: Record<string, CSSProperties> = {
  mobileMainHeader: {
    backgroundColor: "linear-gradient(180deg, #4d139a 0%, #390879 100%)",
    padding: "10px",
  },
};
