import React from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import SearchWithFilterandLocation from "./SearchWithFilterandLocation";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const location = [
  {
    id: 1,
    country: "US",
    city: "New York",
    state: "Loss Engelish",
  },
  {
    id: 2,
    country: "Germany",
    city: "New York",
    state: "Anhd",
  },
];
const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      paddingTop: "24px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
  },
}));

interface ISuccessPopupDialogProps {
  open: boolean;
  isShyfter?: boolean;
  closeModal: (e: any) => void;
}

const GetLocationModal: React.FC<ISuccessPopupDialogProps> = ({
  open,
  closeModal,
}) => {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="xs"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" onClick={closeModal} data-test-id="closeModal">
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className="successDialogTitle">Select your Location</Box>
      <Box
        justifyContent="center"
        display="flex"
        alignItems={"center"}
        padding={"10px"}
        borderRadius={"50px"}
        bgcolor={"#f7faff"}
        border={"1px solid #390879"}
        color="#390879"
      >
        <MyLocationIcon /> Use your current location
      </Box>
      <Box
        width={"100%"}
        maxWidth={"100%"}
        justifyContent="space-between"
        display="flex"
        alignItems={"center"}
      >
        <Divider style={{ width: "45%" }} />
        <Box margin={"10px 0"}>OR</Box>
        <Divider style={{ width: "45%" }} />
      </Box>
      <Box marginTop={"-20px"}>
        <SearchWithFilterandLocation modal={true} />
      </Box>
      <Box display="flex" justifyContent={"center"} alignItems={"center"} marginTop={"10px"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit">
            All State
          </Link>
        </Breadcrumbs>
      </Box>
      <Box>
        <List dense={dense}>
          {location?.map((elm) => {
            return (
              <>
                <ListItem key={elm.id}>
                  <ListItemText primary={elm.country} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <ArrowRightAltIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Box>
    </Dialog>
  );
};

export default GetLocationModal;
