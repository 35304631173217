import React from "react";

import {
  Box,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area Start
import '../../../components/src/style.css'
import ContactusController, { Props } from "./ContactusController";
import GenericHeader from "../../../components/src/GenericHeader.web";
import GenericFooter from "../../../components/src/GenericFooter.web";
import ReachUs from "./ReachUs.web";
import "./style.css";
import { callCenterImg, mailImg, pinImg } from "./assets";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ContactusWeb extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        {/* Customizable Area Start */}
        <GenericHeader navigation={this.props.navigation}/>

          <Box className="commonheader">
            <Box className="firstsection-heading commonheader-heading">
              <Typography variant="h5" className="heading-main" data-test-id="test-heading">Shyft Into the  <span>Conversation</span></Typography>
              <label className="heading-mainsub">
                <span className="header-home" onClick={() => window.location.href = "/"}>Home</span> &nbsp;|&nbsp; <span className="header-home" onClick={() => window.location.href = "/ContactUS"}>Contact Us</span>
              </label>
            </Box>
          </Box>

          <Box 
            className="imageSection contact-page" 
            sx={webStyle.contactUsWrapper}
          >
            <Box style={webStyle.leftWrapper}>
              <div style={webStyle.leftSubWrapper} >
                <img src={callCenterImg} alt="phone_img" style={webStyle.leftImgStyle} />
                
                <div style={webStyle.leftTextWrapper}>
                  <Typography  variant="h6" style={webStyle.leftMainText}> Call Us </Typography>
                  
                  <div> 
                    <span>
                      <label style={webStyle.leftSubText}>+1-5563 00321</label>
                      <br/>
                      <label style={webStyle.leftSubText}>+1-5563 00322</label>
                    </span>
                  </div>

                </div>
              </div>

              <hr style={webStyle.horizontalLine} />

              <div style={webStyle.leftSubWrapper} >
                <img src={mailImg} alt="email_img" style={webStyle.leftImgStyle} />

                <div style={webStyle.leftTextWrapper}>
                  <Typography  variant="h6" style={webStyle.leftMainText}> Email Us </Typography>

                  <div> 
                    <span>
                      <label style={webStyle.leftSubText}>support@snagashyft.com</label>
                      <br/>
                      <label style={webStyle.leftSubText}>info@snagashyft.com</label>
                    </span>
                  </div>

                </div>
              </div>

              <hr style={webStyle.horizontalLine} />

              <div style={webStyle.leftSubWrapper} >
                <img src={pinImg} alt="location_img" style={webStyle.leftImgStyle} />
                
                <div style={webStyle.leftTextWrapper}>
                  <Typography  variant="h6" style={webStyle.leftMainText}> Headquarter Address </Typography>
                  
                  <div> 
                    <span>
                      <label style={webStyle.leftSubText}>733 Hickory Lane, Washinton</label>
                      <br/>
                      <label style={webStyle.leftSubText}>United States</label>
                    </span>
                  </div>

                </div>
              </div>
            </Box>

            <Box style={webStyle.rightWrapper}>
              <ReachUs navigation={undefined} id={""}/>
            </Box>
          </Box>

         <GenericFooter  navigation={this.props.navigation} />
           {/* Customizable End Start */}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const webStyle = {
  contactUsWrapper: {
    flexDirection: "row", 
    width: '100%', 
    justifyContent: 'center',
    paddingLeft: '0px',
    marginTop: '10px'
  },
  leftWrapper: { 
    width: '40%', 
    height: '760px',  
    display: 'flex', 
    flexDirection: 'column' as 'column', 
    gap: '10px' 
  },
  leftSubWrapper:{
    color: '#390879', 
    fontWeight: 'bold' as 'bold', 
    display: 'flex', 
    flexDirection: 'row' as 'row', 
    justifyContent: 'start', 
    alignItems: 'center'
  },
  leftImgStyle: {
    width: '60px', 
    height: '60px',
    marginRight: '25px' 
  },
  leftTextWrapper: {
    flexDirection: 'column' as 'column' 
  },
  leftMainText: {
    fontWeight: 'bold' as 'bold', 
    fontSize: '24px'
  },
  leftSubText: {
    color: 'black', 
    fontSize: '16px', 
    fontWeight: 'bold' as 'bold'
  },
  horizontalLine: {
    border: 'none', 
    width: '100%', 
    borderTop: '2px dotted grey', 
    height: '1px', 
    paddingTop: '10px', 
    marginTop: '25px'
  },
  rightWrapper: {
    width: "40%", 
    height: "800px" 
  }
};

// Customizable Area End
