import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { convertTimeStringIntoDateObject } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isShowNoShyfterPage: boolean;
  isShowNoChatsPage: boolean;
  isEmployer: boolean;
  isOpenNewMessageDialog: boolean;
  isOpenAddShyftDialog: boolean;
  isOpenSuccessDialog: boolean;
}

interface SS {}

export default class MessagesController extends BlockComponent<Props, S, SS> {
  employerShyftsCountApiId: string = "";
  shyfterMyShyftsApiId: string = "";
  addShyftApiId: string = "";
  isHotelUser: boolean = false;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isShowNoShyfterPage: true,
      isShowNoChatsPage: true,
      isEmployer: false,
      isOpenNewMessageDialog: false,
      isOpenAddShyftDialog: false,
      isOpenSuccessDialog: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const userRole = localStorage.getItem("userRole");
    this.setState(
      {
        ...this.state,
        isEmployer: !!(userRole && userRole === "1"),
      },
      () => {
        if (this.state.isEmployer) {
          this.getEmployerShyftsCount();
        } else {
					this.getMyShyfts();
				}
      }
    );
    const userObject = localStorage.getItem("userData");

    if (userObject && JSON.parse(userObject)?.hotel?.length > 0) {
      this.isHotelUser = true;
    }
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.employerShyftsCountApiId && responseJson?.ongoing_shifts) {
			if (responseJson.ongoing_shifts > 0) {
				this.setState({...this.state, isShowNoShyfterPage: false });
			}
    }
    if (apiRequestCallId ===  this.shyfterMyShyftsApiId && responseJson?.meta?.pagination_details) {
			if (responseJson?.meta?.pagination_details?.total_records > 0) {
				this.setState({...this.state, isShowNoShyfterPage: false });
			}
    }
    if (apiRequestCallId === this.addShyftApiId) {
      this.setNewShyftResponse(responseJson);
    }
  }

  handleActionBtnClick = () => {
    if (this.state.isShowNoChatsPage && !this.state.isShowNoShyfterPage) {
      this.setState({ ...this.state, isOpenNewMessageDialog: true });
    }
    if (this.state.isEmployer && this.state.isShowNoShyfterPage) {
      this.setState({ ...this.state, isOpenAddShyftDialog: true, isOpenSuccessDialog: false });
    }
    if (!this.state.isEmployer && this.state.isShowNoShyfterPage) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "ShyfterLandingPage"
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  handleCloseNewMessageDialog = () => {
    this.setState({ ...this.state, isOpenNewMessageDialog: false });
  };

  closeAddShyftDialog = () => {
    this.setState({ ...this.state, isOpenAddShyftDialog: false });
  }

  closeSuccessDialogDialog = () => {
    this.setState({ ...this.state, isOpenSuccessDialog: false });
    const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmployerHomePage"
      );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setNewShyftResponse = (responseJson: any) => {
    if (responseJson && responseJson.shift) {
      this.setState({ ...this.state, 
        isOpenSuccessDialog: true, 
        isOpenAddShyftDialog: false
      });
    }
  };

  saveNewShyftData = (shyftData: any) => {
    const newShyftObj = new FormData();
    newShyftObj.append("[shift]shift_type", shyftData.selectedShyft);
    newShyftObj.append(
      "[shift]requirements",
      shyftData.shyftRequirements.join(",")
    );
    newShyftObj.append("[shift]description", shyftData.shyftDescription);
    newShyftObj.append("[shift]expirence", shyftData.experience);
    newShyftObj.append("[shift]amount_per", shyftData.rateFrequency);
    newShyftObj.append("[shift]amount", shyftData.amount);
    newShyftObj.append(
      "[shift]shift_from",
      moment(convertTimeStringIntoDateObject(shyftData.fromTimeHour, shyftData.fromTimeMinute, shyftData.fromTimeFormat)).format('hh:mm A')
    );
    newShyftObj.append(
      "[shift]shift_to",
      moment(convertTimeStringIntoDateObject(shyftData.toTimeHour, shyftData.toTimeMinute, shyftData.toTimeFormat)).format('hh:mm A')
    );

    const headers = {
      token: localStorage.getItem("authToken"),
    };

    const newShyftAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addShyftApiId = newShyftAPIMsg.messageId;

    newShyftAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addShyftApiEndPoint
    );

    newShyftAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    newShyftAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    newShyftAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      newShyftObj
    );
    runEngine.sendMessage(newShyftAPIMsg.id, newShyftAPIMsg);
  }

  getEmployerShyftsCount() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const employerShyftsCountMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employerShyftsCountApiId = employerShyftsCountMsg.messageId;

    employerShyftsCountMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shyftsCountApiEndPoint
    );

    employerShyftsCountMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    employerShyftsCountMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(employerShyftsCountMsg.id, employerShyftsCountMsg);
  }

	getMyShyfts() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const myShyftsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shyfterMyShyftsApiId = myShyftsApiMsg.messageId;

    myShyftsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myShyftsApiEndPoint
    );

    myShyftsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    myShyftsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(myShyftsApiMsg.id, myShyftsApiMsg);
  }
}
