// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStartAndEndDateValidation } from "./FilterRecords.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  isOpenFilterDrawer: boolean;
  onCloseFilter: () => void;
  applyFilter: () => void;
}

interface S {
  activeFilterTab: number;
  byStatusFilter: string;
  dateFrom: string;
  dateTo: string;
  dateToError: string;
  dateFromError: string;
}

interface SS {
  id: any;
}

export default class FilterRecordsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeFilterTab: 0,
      byStatusFilter: "default",
      dateFrom: "",
      dateTo: "",
      dateToError: "",
      dateFromError: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }

  onChangeFilterTab = (tab: number) => {
    this.setState({ ...this.state, activeFilterTab: tab });
  };

  onChangeStatusFilter = (tab: string) => {
    this.setState({ ...this.state, byStatusFilter: tab });
  };

  onChangeDate = (e: any, name: string) => {
    this.setState({ ...this.state, [name]: e.target.value }, () => {
      this.setState({
        dateToError:
          this.state.dateFrom &&
          this.state.dateTo &&
          getStartAndEndDateValidation(this.state.dateFrom, this.state.dateTo)
            ? ""
            : this.state.dateFromError,
        dateFromError: this.state.dateFrom ? "" : this.state.dateFromError
      });
    });
  };

  clearFilter = () => {
    if (this.state.activeFilterTab === 0) {
      this.setState({ ...this.state, byStatusFilter: "default" });
    } else {
      this.setState({ ...this.state, dateFrom: "", dateTo: "" });
    }
  };

  applyFilter = () => {
    if (this.state.activeFilterTab === 1) {
      this.setState({
        dateFromError: !this.state.dateFrom ? "From date is required" : ""
      });
      if (
        !this.state.dateTo ||
        !getStartAndEndDateValidation(this.state.dateFrom, this.state.dateTo)
      ) {
        this.setState({ dateToError: "To date is required" });
      } else {
        this.setState({ dateToError: "" });
      }
      if (
        this.state.dateTo &&
        this.state.dateFrom &&
        getStartAndEndDateValidation(this.state.dateFrom, this.state.dateTo)
      ) {
        this.props.applyFilter();
      }
    } else {
      this.setState({
        ...this.state,
        byStatusFilter: "default",
        dateFrom: "",
        dateTo: ""
      });
      this.props.applyFilter();
    }
  };

  closeFilterRecords = () => {
    this.setState({
      ...this.state,
      byStatusFilter: "default",
      dateFrom: "",
      dateTo: ""
    });
    this.props.onCloseFilter();
  };
}
// Customizable Area End
