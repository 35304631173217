import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  InputLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";
import CustomSelect from "./CustomSelectField.web";
import MultiValueInput from "./MultiValueInput.web";
import CustomInput from "./CustomInputField.web";
import CustomTextArea from "./CustomTextArea.web";
import CustomInputWithDropdown from "./CustomInputWithDropdown.web";
import CustomTimePickerDropdown from "./CustomTimePickerDropdown.web";
import { getTimeHoursFromDateString } from "./Utilities";

const useStyles = makeStyles((theme) => ({
  addShyftDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .addShyftTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "20px",
      color: "#0a0412",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      " & button": {
        width: 250,
      },
    },
    " & .shyft-label": {
      fontSize: 13,
      color: "#6f7c8e",
      fontWeight: 500,
      paddingBottom: 5,
    },
    " & .errorTo": {
      fontSize: 10,
      color: "red",
      fontFamily: '"Gotham", sans-serif',
      marginTop: "-10px"
    }
  },
}));

interface IAddShyftDialogProps {
  open: boolean;
  isEdit?: boolean;
  editShyftData?: any;
  title: string;
  isHotelType?: boolean;
  onCloseDialog: () => void;
  saveShyft: (data: any) => void;
}

interface IShyftData {
  selectedShyft: string;
  shyftRequirements: string[];
  shyftDescription: string;
  experience: string;
  amount: string;
  rateFrequency: string;
  fromTimeMinute: string;
  fromTimeHour: string;
  fromTimeFormat: string;
  toTimeMinute: string;
  toTimeHour: string;
  toTimeFormat: string;
  id?: string;
}

interface IShyftDataErrors {
  selectedShyft: string;
  shyftRequirements: string;
  shyftDescription: string;
  experience: string;
  amount: string;
  fromTime: string;
  toTime: string;
  amountPer: string;
}

const shyftOptions = (isHotelType: any) => {
  return isHotelType ? [
    { name: "Front Desk", value: "Front Desk" },
    { name: "Concierge", value: "Concierge" },
    { name: "Bellperson", value: "Bellperson" },
    { name: "Doorman", value: "Doorman" },
    { name: "Room Attendant", value: "Room Attendant" },
    { name: "House person", value: "House person" },
    { name: "Laundry attendant", value: "Laundry attendant" },
    { name: "Shuttle driver", value: "Shuttle driver" },
    { name: "Engineer (Maintenance)", value: "Engineer (Maintenance)" },
  ]: [
    { name: "Server", value: "Server" },
    { name: "Host", value: "Host" },
    { name: "Bartender", value: "Bartender" },
    { name: "Busser/Runner", value: "Busser/Runner" },
    { name: "Cook", value: "Cook" },
    { name: "Dishwasher", value: "Dishwasher" },

  ]
}

const AddShyftDialog: React.FC<IAddShyftDialogProps> = ({
  open,
  editShyftData,
  isEdit,
  title,
  isHotelType,
  onCloseDialog,
  saveShyft,
}) => {
  const classes = useStyles();
  const initialErrorsOb: IShyftDataErrors = {
    selectedShyft: "",
    shyftRequirements: "",
    shyftDescription: "",
    experience: "",
    amount: "",
    fromTime: "",
    toTime: "",
    amountPer: ""
  };
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [shyftData, setShyftData] = useState<IShyftData>({
    selectedShyft: "",
    shyftRequirements: [],
    shyftDescription: "",
    experience: "",
    amount: "",
    rateFrequency: "hour",
    fromTimeHour: "00",
    fromTimeMinute: "00",
    fromTimeFormat: "AM",
    toTimeHour: "00",
    toTimeMinute: "00",
    toTimeFormat: "AM",
  });

  useEffect(() => {
    if (isSaveClicked) {
      checkValidation(false);
    }
  }, [isSaveClicked, shyftData]);

  useEffect(() => {
    if (isEdit && editShyftData?.id) {
      setShyftData({
        selectedShyft: editShyftData?.shift_type,
        shyftRequirements: editShyftData?.requirements,
        shyftDescription: editShyftData?.description,
        experience: editShyftData?.expirence,
        amount: editShyftData?.amount,
        rateFrequency: "hour",
        fromTimeHour: getTimeHoursFromDateString(editShyftData?.shift_from).hours12h,
        fromTimeMinute: getTimeHoursFromDateString(editShyftData?.shift_from).minutes,
        fromTimeFormat: getTimeHoursFromDateString(editShyftData?.shift_from).amPm,
        toTimeHour: getTimeHoursFromDateString(editShyftData?.shift_to).hours12h,
        toTimeMinute: getTimeHoursFromDateString(editShyftData?.shift_to).minutes,
        toTimeFormat: getTimeHoursFromDateString(editShyftData?.shift_to).amPm,
        id: editShyftData?.id
      })
    }
  }, [isEdit, editShyftData]);

  const [shyftDataErrors, setShyftDataErrors] = useState<IShyftDataErrors>(
    initialErrorsOb
  );

  const handleShyftDataChange = (e: any, name: string) => {
    let value = e.target.value;
    if (name === "experience" || name === "amount") {
      const numberValue = value.toString();
      const sanitizedValue = numberValue
        .replace(/[+e-]/g, "")
        .replace(/\D/g, "");
      value = sanitizedValue.slice(0, name === "amount" ? 4 : 2);
    }
    setShyftData({ ...shyftData, [name]: value });
  };

  const handleShyftRequirements = (data: any) => {
    setShyftData({ ...shyftData, shyftRequirements: data });
  };

  const handleSave = () => {
    setIsSaveClicked(true);
    checkValidation(true);
  };

  const checkValidation = (isNext: boolean) => {
    const {
      selectedShyft,
      shyftDescription,
      shyftRequirements,
      fromTimeFormat,
      fromTimeHour,
      fromTimeMinute,
      toTimeFormat,
      toTimeHour,
      toTimeMinute,
      amount,
      experience,
    } = shyftData;

    let initialErrors = initialErrorsOb;
    if (!selectedShyft) {
      initialErrors.selectedShyft = "Shyft is required";
    }
    if (!shyftRequirements.length || shyftRequirements.length > 10) {
      initialErrors.shyftRequirements = "Shyft requirements are invalid";
    }
    if (!shyftDescription) {
      initialErrors.shyftDescription = "Shyft description is required";
    }
    if (!experience) {
      initialErrors.experience = "Experience is required";
    }
    if (
      !amount
    ) {
      initialErrors.amount = "Amount is required";
    }
    if (
      amount &&
      (Number(amount) < 15)
    ) {
      initialErrors.amountPer = "Amount per hour should be greater than $15";
    }
    if (
      isSameTime(
        Number(fromTimeHour),
        Number(fromTimeMinute),
        fromTimeFormat,
        Number(toTimeHour),
        Number(toTimeMinute),
        toTimeFormat
      )
    ) {
      initialErrors.toTime = "Shyft From and Shyft To can't be same";
    }
    setValidation(initialErrors, isNext);
  };

  const setValidation = (initialErrors: IShyftDataErrors, isNext: boolean) => {
    const isValid = Object.values(initialErrors).every((value: any) => {
      if (value === "") {
        return true;
      }
      return false;
    });
    if (isValid) {
      setShyftDataErrors(initialErrorsOb);
      if (isNext) {
        saveShyft(shyftData);
      }
    } else {
      setShyftDataErrors(initialErrors);
    }
  }

  const isSameTime = (
    fromHour: number,
    fromMinute: number,
    fromAMPM: string,
    toHour: number,
    toMinute: number,
    toAMPM: string
  ) => {

    const startDate = `${fromHour}${fromMinute}${fromAMPM}`;
    const endDate = `${toHour}${toMinute}${toAMPM}`;
    return startDate === endDate;
  };

  return (
    <Dialog
      className={classes.addShyftDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton
        className="closeIcon"
        onClick={onCloseDialog}
        data-test-id="close-shyft-btn"
      >
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className="addShyftTitle">{title}</Box>
      <Box>
        <CustomSelect
          value={shyftData.selectedShyft}
          handleChange={(e) => handleShyftDataChange(e, "selectedShyft")}
          label="Select Shyft For"
          name="selectedShyft"
          error={!!shyftDataErrors.selectedShyft}
          options={shyftOptions(isHotelType)}
        />
        <MultiValueInput
          values={shyftData.shyftRequirements}
          handleChange={(data) => handleShyftRequirements(data)}
          name="shyftRequirements"
          label="Shyfter requirements"
          error={!!shyftDataErrors.shyftRequirements}
          notRounded={true}
        />
        <CustomTextArea
          value={shyftData.shyftDescription}
          handleChange={(e) => handleShyftDataChange(e, "shyftDescription")}
          label="Shyft Description"
          name="shyftDescription"
          maxLength={200}
          error={!!shyftDataErrors.shyftDescription}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width="100%" paddingRight="8px">
            <CustomInput
              value={shyftData.experience}
              handleChange={(e) => handleShyftDataChange(e, "experience")}
              label="Exp. Required"
              name="experience"
              maxLength={2}
              error={!!shyftDataErrors.experience}
            />
          </Box>
          <Box width="100%" paddingLeft="8px">
            <CustomInputWithDropdown
              value={shyftData.amount}
              handleChange={(e) => handleShyftDataChange(e, "amount")}
              label="Amount"
              name="amount"
              maxLength={4}
              error={!!shyftDataErrors.amount}
            />
            <div className="errorTo">{shyftDataErrors.amountPer}</div>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingTop="10px">
          <Box width="100%" paddingRight="8px">
            <InputLabel className="shyft-label" error={false}>
              Shyft From
            </InputLabel>
            <CustomTimePickerDropdown
              error={!!shyftDataErrors.toTime}
              selectedValue={shyftData.fromTimeFormat}
              options={[
                { name: "AM", value: "AM" },
                { name: "PM", value: "PM" },
              ]}
              handleDropdownChange={(e) =>
                handleShyftDataChange(e, "fromTimeFormat")
              }
              handleHourChange={(e) => handleShyftDataChange(e, "fromTimeHour")}
              handleMinuteChange={(e) =>
                handleShyftDataChange(e, "fromTimeMinute")
              }
              minute={shyftData.fromTimeMinute}
              hour={shyftData.fromTimeHour}
            />
          </Box>
          <Box width="100%" paddingLeft="8px">
            <InputLabel className="shyft-label" error={false}>
              Shyft To
            </InputLabel>
            <CustomTimePickerDropdown
              error={!!shyftDataErrors.toTime}
              selectedValue={shyftData.toTimeFormat}
              options={[
                { name: "AM", value: "AM" },
                { name: "PM", value: "PM" },
              ]}
              handleDropdownChange={(e) =>
                handleShyftDataChange(e, "toTimeFormat")
              }
              handleHourChange={(e) => handleShyftDataChange(e, "toTimeHour")}
              handleMinuteChange={(e) =>
                handleShyftDataChange(e, "toTimeMinute")
              }
              minute={shyftData.toTimeMinute}
              hour={shyftData.toTimeHour}
            />
          </Box>
        </Box>
        <div className="errorTo">{shyftDataErrors.toTime}</div>
      </Box>
      <Box className="actionWrapper">
        <CustomButton
          notFullWidth={true}
          label="Save Shyft"
          handleClick={handleSave}
          data-test-id="save-shyft-btn"
        />
      </Box>
    </Dialog>
  );
};

export default AddShyftDialog;
