import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";

const userImage = require("./assets/images/ProfileImg.png");
const gemIcon = require("./gem.png");
const settingsImg = require("./settings.png");
const marker = require("./assets/images/marker.png");
const editIcon = require("./assets/images/editIcon.png");
const verifyIcon = require("./assets/images/verified.png");
const contact = require("./contact.png");
const email = require("./email.png");

import {
  ThemeProvider,
  createTheme,
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import { Star } from "@material-ui/icons";
import { Grid } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyle = makeStyles({
  card: {
    border: "1px solid #e1e6ec",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    padding: "10px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  IconMargin: {
    marginRight: "10px",
  },
  margin: {
    margin: theme.spacing(2),
    borderRadius: "50px",
    padding: " 5px 25px",
  },
  cardTitleText: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#0a0412",
  },
  cardSubtitleText: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#6f7c8e",
  },
  cardTag: {
    background: "rgba(98, 54, 255, 0.05)",
    boxShadow: "0px 3px 14px rgba(10,4,18,0.04)",
    borderRadius: "18px",
  },
  tagText: {
    fontSize: "11px",
    color: "#6236ff",
    textTransform: "capitalize",
  },
  cardbodyText: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#000",
  },
  ctaText: {
    fontSize: "13px",
    color: "#390879",
    textDecoration: "underline",
  },
  link: {
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#390879",
    cursor: "pointer",
  },
  primaryText: {
    color: "#390879",
  },
  actionIcon: {
    height: "18px",
    cursor: "pointer",
    marginLeft: "15px",
  },
  usertype: {
    color: "390879",
    backgroundColor: "rgba(225, 195, 247, 0.3)",
    padding: "5px 10px",
    borderRadius: "20px",
    marginBottom: "10px",
  },
});

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 20,
      height: 22,
    },
  })
)(Avatar);

interface Props {
  name: string;
  address: string;
  imageURL: string;
  tag: string;
  phoneNumber: string;
  websiteURL: string;
  ctaButtonText?: string;
  isEditable?: boolean;
  onEditNumber?: () => void;
  onEditEmail?: () => void;
  onCtaBtnClick?: () => void;
}

export default function BusinessProfile(props: Readonly<Props>) {
  const classes = useStyle();
  const {
    name,
    address,
    imageURL,
    phoneNumber,
    tag,
    websiteURL,
    ctaButtonText,
    onCtaBtnClick,
    onEditNumber,
    onEditEmail,
    isEditable = false,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box padding="10px" display="flex" alignItems="center" width="100%">
              <Box marginRight="20px">
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={<SmallAvatar alt={name} src={verifyIcon} />}
                >
                  <Avatar
                    alt={name}
                    src={imageURL || userImage}
                    style={{ height: "100px", width: "100px" }}
                  />
                </Badge>
              </Box>
              <Box width="100%">
                <Box display="flex" alignItems="center">
                  <Typography className={classes.cardTitleText}>
                    {name}
                  </Typography>
                  <Box
                    padding="5px 10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginX="10px"
                    borderRadius="50px"
                    bgcolor="#f7b500"
                  >
                    <Star
                      style={{ color: "#fff", height: "12px", width: "12px" }}
                    />
                    <Typography style={{ color: "#fff", fontSize: "11px" }}>
                      4
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" paddingY="5px">
                  <img
                    src={marker}
                    alt=""
                    height="18px"
                    style={{ marginRight: "5px" }}
                  />
                  <Typography className={classes.cardSubtitleText}>
                    {address}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Box
                    display="flex"
                    alignItems="center"
                    paddingY="5px"
                    paddingX="10px"
                    className={classes.cardTag}
                  >
                    <img
                      src={gemIcon}
                      style={{
                        height: "14px",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className={classes.tagText}>{tag}</Typography>
                  </Box>
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
              padding="10px"
            >
              <Box>
                <Box display="flex" alignItems="center" marginBottom="10px">
                  <Box display="flex" alignItems="center">
                    <img
                      src={contact}
                      alt=""
                      className={classes.IconMargin}
                      width={28}
                    />
                    <Typography
                      data-test-id="phone-number"
                      className={classes.cardbodyText}
                    >
                      {phoneNumber}
                    </Typography>
                  </Box>
                  {isEditable && (
                    <img
                      src={editIcon}
                      alt="edit"
                      className={classes.actionIcon}
                      onClick={onEditNumber}
                      data-test-id="editPhonePopup"
                    />
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <img
                      src={email}
                      alt=""
                      className={classes.IconMargin}
                      width={28}
                    />
                    <Typography
                      data-test-id="email"
                      className={classes.cardbodyText}
                    >
                      {websiteURL || "NA"}
                    </Typography>
                  </Box>
                  {isEditable && (
                    <img
                      src={editIcon}
                      alt="edit"
                      className={classes.actionIcon}
                      onClick={onEditEmail}
                      data-test-id="emailPopUp"
                    />
                  )}
                </Box>
              </Box>
              {ctaButtonText && (
                <Box
                  style={{ cursor: "pointer" }}
                  borderRadius="50px"
                  bgcolor="rgba(57, 8, 121, 0.05)"
                  padding="12px 24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  data-test-id="viewProfile"
                  onClick={onCtaBtnClick}
                >
                  <img
                    src={settingsImg}
                    alt=""
                    className={classes.IconMargin}
                    height="18px"
                  />
                  <Typography className={classes.ctaText}>
                    {ctaButtonText}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
