import React from "react";
import { Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import NotificationListController, {
  Props,
} from "./NotificationListController";
import { briefcase, notification } from "./assets";
import moment from "moment";

interface INotificationCard {
  title?: string;
  message?: string;
  isRead: boolean;
  type?: string;
  time?: string;
  handleClick: () => void;
}

const formatDateString = (dateString: string) => {
  const date = moment(dateString, 'YYYY-MM-DD');

  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');

  if (date.isSame(today, 'd')) {
    return 'Today';
  } else if (date.isSame(yesterday, 'd')) {
    return 'Yesterday';
  } else {
    return date.format('DD MMM, YYYY');
  }
}

const getTimeAgo = (inpDate: string) => {
  const currentDate = moment();
  const inputMoment = moment(inpDate);

  const minutesDiff = currentDate.diff(inputMoment, 'minutes');
  const hoursDiff = currentDate.diff(inputMoment, 'hours');
  const daysDiff = currentDate.diff(inputMoment, 'days');

  if (minutesDiff < 1) {
    return 'Just now';
  } else if (minutesDiff < 60) {
      return `${minutesDiff} min${minutesDiff !== 1 ? 's' : ''}`;
  } else if (hoursDiff < 1) {
      return `${hoursDiff} hr${hoursDiff !== -1 ? 's' : ''}`;
  } else if (hoursDiff >= 1 && daysDiff < 1) {
      return `${hoursDiff} hr${hoursDiff !== 1 ? 's' : ''}`;
  } else if (daysDiff === 1) {
      return '1 day';
  } else {
      return `${daysDiff} days`;
  }
}

const getLogo = (type?: string) => {
  let logo = notification;
  if (type === "New shift request") {
    logo = briefcase;
  }
  return logo;
}

export const NotificationCard = (props: INotificationCard) => {
  const { title, message, isRead, type, time, handleClick } = props;
  return (
    <Box
      display="flex"
      border="1px solid #e1e6ec"
      borderRadius="14px"
      padding="10px 5px 10px 10px"
      onClick={handleClick}
      style={{ cursor: isRead ? "default" : "pointer" }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="40px"
        height="40px"
        borderRadius="12px"
        bgcolor="#e1c3f74d"
        marginRight="10px"
      >
        <img src={getLogo(type)} height={20} alt="notification" />
      </Box>
      <Box>
        <Box color={isRead ? "#0a0412" : "#390879"} style={webStyle.notificationTitle}>{title}</Box>
        <Box color={isRead ? "#6f7c8e" : "#0a0412"} style={webStyle.notificationDescription}>{message}</Box>
      </Box>
      <Box style={isRead ? webStyle.lastUpdateWithoutAction : webStyle.actionPart }>
        {!isRead && <Box style={webStyle.dot} />}
        <Box style={webStyle.lastUpdate}>{time}</Box>
      </Box>
    </Box>
  );
};

export default class NotificationList extends NotificationListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box width={330} padding="14px">
          <Box style={webStyle.title}>All Notifications</Box>
          <div style={webStyle.notificationContainer} ref={this.notificationRef} onScroll={this.handleScroll}>
            {this.state.notificationList.map((item): any => (
              <span key={item?.date}>
                <Box style={webStyle.date}>{formatDateString(item?.date)}</Box>
                {item.data.map((notification: any) => (
                  <Box key={notification.id} padding="5px 0px">
                    <NotificationCard
                      isRead={notification?.attributes?.is_read}
                      title={notification?.attributes?.title}
                      message={notification?.attributes?.remarks}
                      time={getTimeAgo(notification?.attributes?.updated_at)}
                      type={notification?.attributes?.notify_type}
                      handleClick={() => this.readNotification(notification?.id, notification?.attributes?.is_read)}
                    />
                  </Box>
                ))
                }
              </span>
            ))}
          </div>
        </Box>
      </ThemeProvider>
    );
  }
}

const theme = createTheme({});

const webStyle = {
  title: {
    fontSize: "15px",
    color: "#0a0412",
    fontWeight: 600,
    fontFamily: '"Gotham", sans-serif',
    padding: "10px 0px",
    borderBottom: "1px solid #e1e6ec",
  },
  notificationTitle: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    paddingTop: "2px"
  },
  notificationDescription: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    width: "200px",
    paddingTop: "2px"
  },
  lastUpdate: {
    fontSize: "10px",
    color: "#6f7c8e",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
  },
  notificationContainer: {
    height: "400px",
    overflowY: "auto" as any,
  },
  dot: {
    width: "7px",
    height: "7px",
    backgroundColor: "#390879",
    borderRadius: "50%",
    border: "2px solid #fff",
    boxShadow: "0px 0px 0px 1px #390879",
    margin: "0px 10px 20px 10px",
  },
  date: {
    fontSize: '12px',
    lineHeight: '19px',
    color: '#6f7c8e',
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    padding: "10px 0px 5px"
  },
  lastUpdateWithoutAction: {
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: "5px" 
  },
  actionPart: {
    paddingLeft: "5px" 
  },
  notificationCard: {
    cursor: "pointer"
  }
};
