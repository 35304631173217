import React from "react";
import { Box, Divider, Radio, Typography, makeStyles } from "@material-ui/core";
import {
  arrowRightBlueIcon,
  arrowRightPinkIcon,
  arrowRightYellowIcon,
  calendar,
  radioCheckedIcon,
  radioUnCheckedIcon,
  timerIcon,
  crownIcon,
} from "./assets";
import moment from "moment";
import { extractListItems } from "../../../components/src/Utilities";
import EventIcon from "@material-ui/icons/Event";

interface ISubscriptionTileProps {
  isChecked: boolean;
  title: string;
  month: string;
  price: string;
  type: string;
  id: string;
  benefits: any;
  minHeight?: any;
  isModaluse?: boolean;
  isCurrentSub?: boolean;
  validtill?: any;
  handleChecked?: (e: any) => void;
  isFromUpgradePlan?: boolean;
  isCrownLogo?: boolean;
  isFromCheckout?: boolean;
  isUpgradePlan?: boolean;
  isCurrentPlan?: boolean;
}

export const SubscriptionCard = (props: ISubscriptionTileProps) => {
  const classes = useStyles();
  const arrowRightIcon = () => {
    let arrowIcon = arrowRightBlueIcon;
    if (props.type == "standard") {
      arrowIcon = arrowRightPinkIcon;
    } else if (props.type === "gold") {
      arrowIcon = arrowRightYellowIcon;
    }
    return arrowIcon;
  };

  return (
    <Box className={classes.subscriptionDialogWrapper} width={"100%"}>
      <Box
        minHeight={props.minHeight}
        className={`plan-tile ${
          props.isChecked ? `plan-tile-${props.type}` : ""
        }`}
      >
        {!!props.isModaluse && (
          <div className={`plan-header plan-header-${props.type}`}>
            {props.title}
          </div>
        )}
        <div className={`plan-top-section ${props.isCrownLogo ? "plan-top-with-crown" : ""}`}>
          <div className={"plan-price-section"}>
            { props.isCrownLogo &&  
              <div className={`crown-icon  crown-back-color-${props.type}`}>
                <img src={crownIcon} alt="crown" height={20}/>
              </div>
            }
            {/* <div
              className={`plan-month-${
                props.isChecked ? "active" : "disabled"
              }`}
            >
              {props.month} Days
            </div> */}
            
            <div
              className={`plan-price ${
                props.isChecked ? `plan-price-${props.type}` : ""
              }`}
            >
              ${props.price}/month
            </div>
          </div>
          
          {!!props.isModaluse ? (
            <div className="plan-action">
              <Radio
                checked={props.isChecked}
                onChange={props.handleChecked}
                value="plan"
                color="primary"
                checkedIcon={
                  <img src={radioCheckedIcon} height={20} alt="checked" />
                }
                icon={
                  <img src={radioUnCheckedIcon} height={20} alt="unchecked" />
                }
              />
            </div>
          ) : (
            <div className="plan-action">
              <div className={`plan-header1 plan-header-${props.type}`}>
                {props.title}
              </div>
            </div>
          )}
        </div>
        <div className={`
            line-with-text 
            ${props.isCurrentPlan && "upgradePlan-line-text-current"}  
            ${props.isUpgradePlan && "upgradePlan-line-text-upgradeto"}
          `}
        >
          <span 
            className={`
              text 
              ${props.isFromCheckout && "text-chekout-bold"} 
              ${props.isUpgradePlan && "upgradePlan-benefits-text"}
              ${props.isFromUpgradePlan && "upgradePlan-basic"}
            `}
          >
            Benefits of Plan
          </span>
        </div>
        <div className="benefit-list">
          {extractListItems(props.benefits)?.slice(0,10).map((item: any) => (
            <div className="benefit-text" key={item + "benefit-item"}>
              <span className="benefit-text-span">
                <img src={arrowRightIcon()} alt="" height={13} />
              </span>
              {item}
            </div>
          ))}
        </div>
        {props.isCurrentSub && (
          <>
            <Box width={"100%"} marginY={"10px"}>
              <Divider />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Box>
                  <EventIcon />
                </Box>
                <Box marginX={"10px"}>
                  <Typography style={{ color: "#6f7c8e", fontSize: "14px" }}>
                    Plan End Date
                  </Typography>
                  <Typography>
                    {moment(props.validtill).format("D MMMM, YYYY")}
                  </Typography>
                </Box>
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {moment(props.validtill).diff(moment(), "days") > 0 ? (
                  <>
                    <Typography
                      style={{
                        color: "rgba(251, 192, 0, 0.99)",
                        fontSize: "15px",
                      }}
                    >
                      {moment(props.validtill).diff(moment(), "days")} Days Left
                    </Typography>
                    <Box marginLeft={"10px"}>
                      <img src={timerIcon} alt="" height={"18px"} />
                    </Box>
                  </>
                ) : (
                  <Box className={classes.expired}>
                    <Typography>Expired!</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export const useStyles = makeStyles((theme) => ({
  expired: {
    backgroundColor: "#edcad5",
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    padding: "6px 30px",
  },
  subscriptionDialogWrapper: {
    " & .MuiDialog-paperWidthMd": {
      maxWidth: 900,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 20,
    },
    " & .close-icon": {
      border: "1px solid rgba(255, 255, 255, 0.3)",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0",
      borderRadius: "50%",
      backgroundColor: "rgba(0, 0, 0, 0.55)",
      color: "#fff",
    },
    " & .subscribe-now-dialog-content": {
      padding: "10px",
    },
    " & .subscribe-now-title": {
      fontSize: "22px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "5px 0px 30px",
      lineHeight: "26px",
      color: "#0a0412",
      fontWeight: "bold",
      textAlign: "center",
    },
    " & .plan-tile": {
      borderRadius: "14px",
      filter: "drop-shadow(0px 4px 7px rgba(57, 8, 121, 0.09))",
      backgroundColor: "#ffffff",
      border: "1px solid #e1e6ec",
      padding: "16px",
      position: "relative",
      maxHeight: "300px",
    },
    " & .plan-tile-standard": {
      border: "1px solid rgba(205, 34, 255, 1)",
    },
    " & .plan-tile-basic": {
      border: "1px solid #2675ff",
    },
    " & .plan-tile-gold": {
      border: "1px solid rgba(251, 192, 0, 1)",
    },
    " & .subscription-tile-grid": {
      display: "flex",
    },
    "& .plan-header1": {
      fontSize: "12px",
      color: "#fff",
      top: "-14px",
      left: "14px",
      padding: "6px 14px",
      borderRadius: "15px",
      lineHeight: "18px",
      fontWeight: 500,
    },
    " & .plan-header": {
      position: "absolute",
      fontSize: "12px",
      color: "#fff",
      top: "-14px",
      left: "14px",
      padding: "6px 14px",
      borderRadius: "15px",
      lineHeight: "18px",
      fontWeight: 500,
    },
    " & .plan-header-basic": {
      backgroundImage:
        "linear-gradient(225deg, rgba(34, 137, 255, 0.9999999999999999) 0%, rgba(39, 93, 255, 0.99) 100%)",
    },
    " & .plan-header-standard": {
      backgroundImage:
        "linear-gradient(225deg,rgba(217, 87, 255, 0.9999999999999999) 0%,rgba(205, 34, 255, 0.99) 100%)",
    },
    " & .plan-header-gold": {
      backgroundImage:
        "linear-gradient(225deg,rgba(255, 215, 0, 0.9999999999999999) 0%,rgba(251, 192, 0, 0.99) 100%)",
    },
    " & .line-with-text": {
      textAlign: "center",
      border: "1px solid #e1e6ec",
      lineHeight: "0em",
      margin: "10px 0 20px",
    },
    " & .upgradePlan-line-text-current": {
        margin: "5px 0 20px",
    },
    " & .upgradePlan-line-text-upgradeto": {
        margin: "0px 0 30px",
    },
    " & .text": {
      background: "#fff",
      padding: "0 10px",
      fontSize: "12px",
      color: "#0a0412",
      fontWeight: "500",
    },
    " & .text-chekout-bold": {
      fontWeight: "600",
      fontSize: "14px",
    },
    " & .upgradePlan-basic": {
        fontWeight: "600",
        fontSize: "14px",
    },
    " & .upgradePlan-benefits-text": {
        float: "left",
        marginTop: "5px",
        paddingTop: "10px",
        paddingLeft: "5px",
    },
    " & .subscribe-image": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    " & .crown-icon": {
      padding: "14px",
      borderRadius: "24px",
      marginRight: "5px",
    },
    " & .plan-top-section": {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 0px",
      alignItems: "center",
    },
    " & .plan-top-with-crown": {
      padding: "0px 0px",
    },
    " & .plan-price-section": {
        display: "flex",
        flexDirection: "row",
        padding: "5px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    " & .crown-back-color-basic": {
        backgroundColor: "rgba(57, 8, 121, 0.04499999)",
    },
    " & .crown-back-color-standard": {
        backgroundColor: "rgba(205, 34, 255, 0.05589)",
    },
    " & .crown-back-color-gold": {
        backgroundColor: "rgba(251, 192, 0, 0.17555)",
    },
    " & .plan-price": {
      fontSize: "20px",
      letterSpacing: "0px",
      lineHeight: "18px",
      fontWeight: "500",
      color: "#0a0412",
    },
    " & .plan-price-standard": {
      color: "rgba(205, 34, 255, 1)",
    },
    " & .plan-price-basic": {
      color: "#2675ff",
    },
    " & .plan-price-gold": {
      color: "rgba(251, 192, 0, 1)",
    },
    " & .plan-month-active": {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "18px",
      textTransform: "uppercase",
      color: "#0a0412",
      fontWeight: "500",
      paddingBottom: "5px",
    },
    " & .plan-month-disabled": {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "18px",
      textTransform: "uppercase",
      fontWeight: "500",
      paddingBottom: "5px",
      color: "#6f7c8e",
    },
    " & .plan-action": {
      marginTop: "-10px",
    },
    " & .benefit-list": {
      overflowY: "auto",
      height: "150px"
    },
    " & .benefit-text": {
      fontSize: "13px",
      letterSpacing: "0px",
      lineHeight: "17px",
      color: "#0a0412",
      paddingBottom: "5px",
      display: "flex",
    },
    " & .benefit-text-span": {
      padding: "4px 6px",
    },
    " & .subscribe-now-action-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "30px",
    },
    " & .subscribe-btn": {
      width: "250px",
      height: "48px",
      borderRadius: "24px",
      backgroundColor: "#390879",
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: "bold",
      textAlign: "center",
      textTransform: "none",
    },
  },
}));
