import React from "react";

import RecommendedShyftsController, {
  Props,
} from "./RecommendedShyftsController.web";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import { SuccessIcon } from "./assets";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import { breadCrumSubsPath } from "../../../components/src/Utilities";
import GetLocationModal from "../../../components/src/GetLocationModal.web";
import ShyfterFeedbackModal from "../../../components/src/ShyfterFeedbackModal.web";

export default class RecomendedShyftsPage extends RecommendedShyftsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      recommendedList,
      selectedShyft,
      shyftDetails,
      request,
      isShyftApplied,
      isRegisterationPending,
      isLocationModalOpen,
      totalShyfts,
    } = this.state;

    return (
      <>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Recommended Shyfts"
          breadCrumsData={breadCrumSubsPath}
        />
        <Box width={"100%"} marginTop="8%">
          <Container maxWidth="md">
            <SearchWithFilterandLocation
              showFilterText={true}
              handleSerchModal={this.onSearchShifts}
              islocation={true}
              handleLoctionModal={this.openLocationModal}
              handleSearchFilter={this.handleRecomendedFilterShifts}
            />
            {totalShyfts !== 0 && (
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >{`Total ${totalShyfts} Shyfts`}</Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <Box>
                  {recommendedList.map((item) => {
                    return (
                      <ShyftCard
                        key={item.id}
                        {...item}
                        onCardClick={() => this.onSelectShift(item.id)}
                        onSaveShyft={() =>
                          this.handleSaveShyft(item.id, item.saved)
                        }
                        isActiveShyft={item.id === selectedShyft}
                        showBookMark={true}
                      />
                    );
                  })}
                  <div ref={this.elementRef}></div>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box
                  margin={"15px 0px"}
                  style={{ position: "sticky", top: "24%" }}
                >
                  {shyftDetails && (
                    <ShyfterDetailsCard
                      key={shyftDetails.id}
                      {...shyftDetails}
                      shyftStatus={request ? request.status : undefined}
                      isAppliedShyft={this.checkIfAppliedeForShyft(request)}
                      onWithdrawShift={() =>
                        this.withdrawFromRecommendedShyft(shyftDetails.id)
                      }
                      onApplyShift={() =>
                        this.applyForRecommendedShyft(shyftDetails.id)
                      }
                      showOverviewSubtext={false}
                      onViewProfile={() => {
                        this.viewBusinessProfile(shyftDetails.businessId);
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            <GetLocationModal
              open={isLocationModalOpen}
              closeModal={this.closeLocationModal}
            />

            <ShyfterFeedbackModal
              title="Registration Pending"
              isOpen={isRegisterationPending}
              onClose={this.closePendingRegistrationModal}
              primaryButtonText="Complete Now"
              primaryButtonOnClick={this.closePendingRegistrationModal}
              description="Your registeration is pending. Please complete your registration to apply for a shyft in a hotel and restaurant."
              imageSrc={SuccessIcon}
            />

            <ShyfterFeedbackModal
              isOpen={isShyftApplied}
              title="Applied Successfully"
              imageSrc={SuccessIcon}
              description="This shift has been applied successfully. Explore more shyfts or you can view status of this shyft"
              primaryButtonText="Explore More"
              secondaryButtonText="View Status"
              primaryButtonOnClick={this.closeApplyShiftModal}
              secondaryButtonOnClick={this.closeApplyShiftModal}
              onClose={this.closeApplyShiftModal}
            />
          </Container>
        </Box>
      </>
    );
  }
}
