// Customizable Area Start
import React from "react";
import EmployerHomeAfterSubscriptionController, {
  Props,
  configJSON,
} from "./EmployerHomeAfterSubscriptionController";
import { ThemeProvider, createTheme, styled } from "@material-ui/core/styles";
import { Grid, Container, Box, Typography } from "@material-ui/core";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileCard from "../../../components/src/ProfileCard";
import SideNav from "../../../components/src/SideNav";
import GreenButton from "../../../components/src/GreenButton.web";
import CommonLandingPage from "../../../components/src/CommonLandingPage.web";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
  crown,
  NoShyftImg,
  NoSubscriptionImg,
  ProfileImg,
} from "./assets";
import AddShyftDialog from "../../../components/src/AddShyftDialog.web";
import CustomSuccessDialog from "../../../components/src/CustomSuccessDialog";
import {
  convertDateIntoFormattedString,
  convertDateIntoTwelveHourFormat,
} from "../../../components/src/Utilities";
import ShyftDetailCardWithoutTabs from "../../../components/src/ShyftDetailCardWithoutTabs.web";
import FormDialogBox from "../../../components/src/FormDialogBox";
import { DownloadAppCard } from "../../../components/src/DownloadApp";
import RequestsDialog from "../../../components/src/RequestsDialog";
import EmployerShyftCard from "../../../components/src/EmployerShyftCard.web";
import ShyftMenu from "../../../components/src/ShyftMenu.web";
import SubscribeNowModal from "../../customisableusersubscriptions/src/SubscribeNowModal.web";
import moment from "moment";
import FilterRecords from "../../filteritems/src/FilterRecords.web";
import PastShyftActivity from "../../../components/src/PastShyftActivity.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#0a0412",
    },
  },
});
const styles: Record<string, CSSProperties> = {
  tabsContainer: {
    border: "1px solid #e1e6ec",
    borderRadius: "14px",
    height: "fit-content",
    backgroundColor: "#FFF",
    padding: "0px 10px",
  },
  inActiveTab: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#6f7c8e",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    textAlign: "center",
    padding: "20px",
    borderBottom: "3px solid transparent",
    cursor: "pointer",
  },
  activeTab: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#390879",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    textAlign: "center",
    borderBottom: "3px solid #390879",
    padding: "20px",
    cursor: "pointer",
  },

  ongoingShyftContainer: {
    position: "relative",
    padding: "20px 5px 0px 10px",
    border: "1px solid #e1e6ec",
    borderTopRightRadius: "14px",
    borderTopLeftRadius: "14px",
    height: "auto",
    marginRight: "15px",
    marginLeft: "15px",
    borderRadius: "14px",
    width: "416px",
    backgroundColor: "#FFF",
  },

  closeButton: {
    position: "absolute",
    right: "60px",
    bottom: "135px",
    backgroundColor: "#390879",
    borderColor: "#fff",
    borderWidth: "thin",
    color: "#fff",
    borderRadius: "50% ",
    zIndex: 4,
    height: "47px",
    width: "47px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  mainModalHeaderOne: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "10px",
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },

  ongoingShyft: {
    height: "90%",
    width: "100%",
    overflowY: "scroll",
    padding: "15px",
    boxSizing: "border-box",
    backgroundColor: "#FFF",
    position: "relative",
  },

  ModalHeader: {
    height: "800px",
    width: "100%",
    borderRadius: "10px",
    position: "relative",
    border: "1px solid #DDD",
    overflow: "hidden",
  },

  noActivityRecord: {
    width: "100%",
    height: "150px",
    padding: "10px 0px",
    margin: "40px 0px",
  }
};

const setActiveTabStyle = (tab: number, selectedTab: number) => {
  return tab === selectedTab ? styles.activeTab : styles.inActiveTab;
};

const showEmptyShyftComponent = (
  selectedTab: number,
  createShyftList: any[],
  ongoingShyftList: any[]
) => {
  return (
    (selectedTab === 0 && !createShyftList.length) ||
    (selectedTab === 1 && !ongoingShyftList.length)
  );
};

const showShyftList = (selectedTab: number, tab: number, list: any[]) => {
  return selectedTab === tab && list.length > 0;
};

export const formatAmountPer = (per: string) => {
  return per === "hour" ? "Hr" : "Day";
};

export const getHotelOrRestaurantName = (data: any) => {
  let name = "";
  if (data.hotel?.hotel_name) {
    name = data.hotel.hotel_name;
  } else if (data.restaurant?.restuarant_name) {
    name = data.restaurant.restuarant_name;
  }
  return name;
};

const setActiveShyftData = (type: string, data: any) => {
  return type === "edit" ? data?.shift : {};
};

const shyftDialogTitle = (type: string) => {
  return type === "edit" ? "Edit Shyft" : "Add Shyft";
};

export const showUserImage = (userImage: string) => {
  return userImage || ProfileImg;
};

export const getRecentActivityType = (loginTime: any, logoutTime: any) => {
  let activityType: "Login" | "Logout" = "Login";
  let activityTime = "";
  if (!loginTime && logoutTime) {
    activityType = "Logout";
    activityTime = convertDateIntoFormattedString(logoutTime);
  } else if (loginTime && !logoutTime) {
    activityTime = convertDateIntoFormattedString(loginTime);
  }
  if (loginTime && logoutTime) {
    const loginMoment = moment(loginTime);
    const logoutMoment = moment(logoutTime);
    if (loginMoment.isSameOrBefore(logoutMoment)) {
      activityType = "Login";
      activityTime = convertDateIntoFormattedString(loginTime);
    } else {
      activityType = "Logout";
      activityTime = convertDateIntoFormattedString(logoutTime);
    }
  }
  return { activityType, activityTime };
};

export const showAddress = (data: any) => {
  let address = "";
  if (data.hotel?.address) {
    address = data.hotel.address;
  } else if (data.restaurant?.address) {
    address = data.restaurant.address;
  }
  return address;
}

export default class EmployerHomeAfterSubscription extends EmployerHomeAfterSubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      selectedTab,
      isOpenAddShyftDialog,
      isOpenSuccessDialog,
      createdShyftList,
      ongoingShyftList,
      activeShyftDetails,
      userData,
      menuAnchor,
      successDialogDescription,
      successDialogTitle,
      isOpenFilterDrawer,
      isActivityRecordVisible,
      ongoingActivityRecordList,
      ongoingShyftSummaryData,
      ongoingActivityRequestData,
      ongoingRemoveReason,
      isEndRemoveSuccessModal,
      endRemoveSuccessModalDesc,
      endRemoveSuccessModalTitle,
      rateAndReviewModalData,
      openOngoingReviewAndRatingModal,
      openRatingSucessModal,
      isOpenUpdateStatusModal,
      singleItemActivityRecordData
    } = this.state;

    return (
      <>
        <ThemeProvider theme={theme}>
          <LandingPageHeader
            {...this.props}
            userName={this.state.userData?.full_name}
            userAddress={this.state.userData?.address}
          />
          {!!isOpenAddShyftDialog && (
            <AddShyftDialog
              open={!!isOpenAddShyftDialog}
              saveShyft={this.saveShyft}
              title={shyftDialogTitle(isOpenAddShyftDialog)}
              onCloseDialog={this.closeAddShyftDialog}
              isEdit={isOpenAddShyftDialog === "edit"}
              editShyftData={setActiveShyftData(
                isOpenAddShyftDialog,
                activeShyftDetails
              )}
              isHotelType={this.isHotelUser}
            />
          )}
          {isOpenSuccessDialog && (
            <CustomSuccessDialog
              open={isOpenSuccessDialog}
              title={successDialogTitle}
              description={successDialogDescription}
              positiveAction={() => this.toggleSuccessDialog(false)}
              positiveActionText="Okay"
              negativeAction={() => this.openAddShyftDialog(false)}
              negativeActionText="+ Add More"
              closeDialog={() => this.toggleSuccessDialog(false)}
            />
          )}
          <Container>
            <Box marginTop={"10%"} marginBottom={"10%"}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={3}>
                  <ProfileCard
                    userName="Skyline Hotels"
                    contactMail="info@gmail.com"
                    contactNumber="+91 5241578523"
                    employerType="Luxury"
                    userData={this.state.userData}
                    showEditEmailPopUp={this.showUpdateEmailPopUp}
                    showEditPhonePopUp={this.showUpdatePhonePopUp}
                    goToProfile={this.viewUserProfile}
                  />
                  <Typography>
                    <Box padding={"10px 0px"}>More Options</Box>
                  </Typography>
                  <SideNav
                    isEmployer={true}
                    pastShyft={() => {}}
                    myShyft={() => {}}
                    otherPageNavigation={(path: string) =>
                      this.employeerMoreOptionsNavigation(path)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  {!!userData.subscritions ? (
                    <>
                      <Box
                        style={styles.tabsContainer}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          data-test-id="shift-wrapper"
                        >
                          <Box
                            onClick={() => this.handleTabChange(0)}
                            style={setActiveTabStyle(0, selectedTab)}
                            data-test-id="created-shyft"
                          >
                            {configJSON.createdShyftLabel}
                          </Box>
                          <Box
                            onClick={() => this.handleTabChange(1)}
                            style={setActiveTabStyle(1, selectedTab)}
                            data-test-id="ongoing-shyft"
                          >
                            {configJSON.ongoingShyftLabel}
                          </Box>
                        </Box>
                        <Box>
                          <GreenButton
                            label="+ Add Shyft"
                            handleClick={() => this.openAddShyftDialog(false)}
                          />
                        </Box>
                      </Box>
                      <Box marginTop="14px">
                        {showEmptyShyftComponent(
                          selectedTab,
                          createdShyftList,
                          ongoingShyftList
                        ) && (
                          <CommonLandingPage
                            heading={configJSON.emptyCreatedShyftTitle}
                            paragraph={""}
                            imgUrl={NoShyftImg}
                            btnText={configJSON.addFirstShyftBtnLabel}
                            handleClick={() => this.openAddShyftDialog(false)}
                            data-test-id="no-shyft-data-card"
                          />
                        )}
                        {showShyftList(selectedTab, 0, createdShyftList) && (
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              {createdShyftList.map((item: any) => (
                                <EmployerShyftCard
                                  key={item.shift.id}
                                  userRole={item.shift.shift_type}
                                  amount={item.shift.amount}
                                  toTime={convertDateIntoTwelveHourFormat(
                                    item.shift.shift_to
                                  )}
                                  fromTime={convertDateIntoTwelveHourFormat(
                                    item.shift.shift_from
                                  )}
                                  shyftCreationTime={convertDateIntoFormattedString(
                                    item.shift.created_at
                                  )}
                                  showTimingAndAmountLabel={false}
                                  isActive={
                                    item.shift.id ===
                                    activeShyftDetails?.shift?.id
                                  }
                                  handleClick={() =>
                                    this.getShyftDetails(item.shift.id)
                                  }
                                  handleMenuClick={(e: any) => this.openShyftMenu(e, item?.shift?.id)}
                                  type="create"
                                  amountType={formatAmountPer(
                                    item.shift.amount_per
                                  )}
                                />
                              ))}
                            </Grid>
                            <Grid item xs={6}
                            >
                              {activeShyftDetails?.shift?.id && (
                                <ShyftDetailCardWithoutTabs
                                data-test-id="ViewRequestTestID"
                                  handleActionButtonClick={
                                    this.handleViewRequest
                                  }
                                  shyftType={
                                    getHotelOrRestaurantName(activeShyftDetails)
                                  }
                                  shyftTitle={
                                    activeShyftDetails?.shift?.shift_type
                                  }
                                  address={showAddress(activeShyftDetails)}
                                  amountPer={formatAmountPer(
                                    activeShyftDetails?.shift?.amount_per
                                    )}
                                  amount={activeShyftDetails?.shift?.amount}
                                  shyftTimeFrom={convertDateIntoTwelveHourFormat(
                                    activeShyftDetails?.shift?.shift_from
                                  )}
                                  shyftTimeTo={convertDateIntoTwelveHourFormat(
                                    activeShyftDetails?.shift?.shift_to
                                  )}
                                  experience={
                                    activeShyftDetails?.shift?.expirence
                                  }
                                  shyftRequirements={
                                    activeShyftDetails?.shift?.requirements
                                  }
                                  shyftDescription={
                                    activeShyftDetails?.shift?.description
                                  }
                                  isActionDisabled={!activeShyftDetails?.request_count}
                                  actionBtnTitle={`View Requests (${activeShyftDetails?.request_count})`}
                                />
                              )}
                            </Grid>
                          </Grid>
                        )}
                        {showShyftList(selectedTab, 1, ongoingShyftList) && (
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              {ongoingShyftList.map((item: any) => (
                                <EmployerShyftCard
                                  key={item.shift.id}
                                  userRole={item.shift?.shift_type}
                                  amount={item.shift?.amount}
                                  userName={item?.worker?.full_name}
                                  toTime={convertDateIntoTwelveHourFormat(
                                    item.shift?.shift_to
                                  )}
                                  fromTime={convertDateIntoTwelveHourFormat(
                                    item.shift?.shift_from
                                  )}
                                  shyftCreationTime={convertDateIntoFormattedString(
                                    item.shift?.created_at
                                  )}
                                  showTimingAndAmountLabel={true}
                                  isActive={item.shift.id === this.state.ongoingActivityShyftId}
                                  handleClick={() => this.handleOngoingActivityRecord(item.shift?.id, item.worker?.id, item)}
                                  handleMenuClick={(e: any) => this.openShyftMenu(e, item?.shift?.id)}
                                  type="ongoing"
                                  showUsername={true}
                                  amountType={formatAmountPer(
                                    item.shift?.amount_per
                                  )}
                                  userImage={showUserImage(
                                    item?.worker_profile_picture
                                  )}
                                  recentActivityType={
                                    getRecentActivityType(
                                      item?.worker?.recent_login,
                                      item?.worker?.recent_logout
                                    ).activityType
                                  }
                                  shyftActivityTime={
                                    getRecentActivityType(
                                      item?.worker?.recent_login,
                                      item?.worker?.recent_logout
                                    ).activityTime
                                  }
                                />
                              ))}
                            </Grid>
                            <Grid item xs={6}>
                              { isActivityRecordVisible && 
                                <PastShyftActivity 
                                  isOngoingShyftActivity={true}
                                  activityData={ongoingActivityRecordList} 
                                  summaryData={ongoingShyftSummaryData}
                                  removeText={ongoingRemoveReason}
                                  removeTextError={this.state.removeInputErr}
                                  handleRemoveReason={this.handleChangeRemoveReason}
                                  isRemoveModalOpen={this.state.isRemoveModalOpen}
                                  isEndShyftModalOpen={this.state.isEndShyft}
                                  closeRemoveModal={this.handleCloseRemoveModal}
                                  closeEndShyftModal={this.handleisCloseEndShyft}
                                  handleFilterRecords={this.openFilterDrawer}
                                  handleRemoveShyft={this.handleOpenRemoveModal}
                                  handleEndShyft={this.handleisEndShyft}
                                  handleEndShyftSubmit={this.handleEndShyftApiCall}
                                  handleRemoveSubmit={this.handleRemovePerson}
                                  recentStatusData={this.state.recentActivityStatus}
                                  isReviewAndRate={openOngoingReviewAndRatingModal}
                                  isRatingSuccess={openRatingSucessModal}
                                  onSubmitRating={this.handleReviewAndRatingSubmit}
                                  ratingsData={rateAndReviewModalData}
                                  closeRatingSuccessModal={this.closeRateSuccessModal}
                                  closeReviewAndRateModal={this.closeReviewAndRatingModal}
                                  handleUpdateStatusCard={this.handleHomeUpdateActivityStatusCard}
                                  isStatusModalOpen={isOpenUpdateStatusModal}
                                  activityUpdateStatusData={singleItemActivityRecordData}
                                  updateStatusModalSubmitButton={this.handleHomeUpdateStatusModalSubmit}
                                  closeUpdateStatusModal={this.handleCloseUpdateModalHome}
                                />
                              }
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                          <Box>
                            <CommonLandingPage
                              btnIcon={<img src={crown} alt="" />}
                              imgUrl={NoSubscriptionImg}
                              btnText="Subscribe Now"
                              heading="No Subscription Found!"
                              paragraph="Please choose a monthly plan to maximize the benefits of
                the app."
                              handleClick={this.handleSubscribeNowBtn}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <DownloadAppCard />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            <FormDialogBox
              title={configJSON.updateEmail}
              type={"email"}
              value={this.state.newEmail}
              name={"email"}
              isOpen={this.state.updateEmailPopUp}
              handleClose={this.showUpdateEmailPopUp}
            />

            <FormDialogBox
              title={configJSON.updatePhone}
              type={"phone"}
              value={this.state.newPhone}
              name={"phone"}
              isOpen={this.state.updatePhonePopUp}
              handleClose={this.showUpdatePhonePopUp}
            />
            <SubscribeNowModal
              isOpen={this.state.subscribeNowDialog}
              handleClose={this.handleSubscribeNowBtn}
              allSubscriptionsList={this.state.allSubscriptionList}
              handleSave={(selectedPlan: any) =>this.navigateToCheckout(selectedPlan)}
            />
          </Container>
          <Footer />
          <ShyftMenu
            anchorEl={menuAnchor}
            handleMenuItemClick={this.handleMenuItemClick}
            handleClose={this.closeShyftMenu}
            selectedTab={selectedTab}
          />
        </ThemeProvider>
        <Box data-test-id="requestDialogID">
          <RequestsDialog
            openDialog={this.state.viewRequestDialog}
            handleCloseDialog={this.handleViewRequestCloseDialog}
            handleOpenDialog={this.handleViewRequest}
            handleignoreRequest={this.ignoreRequestShyft}
            shyfDrawerData={this.state.shyftDrawerData}
            handleacceptRequest={this.acceptRequestShyft}
            closeModal={() => this.closeShyftModal(false)}
            isModalOpen={this.state.isModalOpen}
            shyftName={activeShyftDetails?.shift?.shift_type}
            acceptedShyfter={this.state.acceptedShyfterName}
            viewOngoingShyfts={() => this.closeShyftModal(true)}
            navigateApplicantProfile={this.navigateApplicantProfile}
          />
        </Box>
        {isOpenFilterDrawer && (
          <FilterRecords
            id=""
            navigation={this.props.navigation}
            isOpenFilterDrawer={isOpenFilterDrawer}
            onCloseFilter={this.closeFilter}
            applyFilter={this.applyFilter}
          />
        )}
        {isEndRemoveSuccessModal && (
            <CustomSuccessDialog
              open={isEndRemoveSuccessModal}
              title={endRemoveSuccessModalTitle}
              description={endRemoveSuccessModalDesc}
              positiveActionText="Rate Shyfter"
              closeDialog={() => this.handleEndOrRemoveSuccessModalClose()}
              negativeActionText="Done"
              positiveAction={() => this.handleRateShyftEndOrRemoveSuccessModal()}
              negativeAction={() => this.handleEndOrRemoveSuccessModalClose()}
              data-test-id="HomeEndOrRemoveSuccessModalId"
            />
          )}
      </>
    );
  }
}

// Customizable Area End
