import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { ProfileImg } from "./UserProfileCard.web";
import StarIcon from "@material-ui/icons/Star";
import { TReviewItem } from "../../blocks/CustomisableUserProfiles/src/ShyfterBusinessProfileController";

const useStyle = makeStyles({
  card: {
    border: "1px solid #e1e6ec",
    borderRadius: "10px",
    padding: "5px",
    marginTop: "10px",
    backgroundColor: "#fff",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
  },
  cardTitleText: {
    fontSize: "14px",
    fontWeight: 400,
  },
  cardSubTitleText: {
    fontSize: "11px",
    fontWeight: 400,
    color: "#6f7c8e",
  },
  cardBodyText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000",
  },
  ShyftName: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#390879",
  },
});

interface Props extends TReviewItem {}

export const ReviewCard = (props: Readonly<Props>) => {
  const classes = useStyle();
  const { score, comment, date, shiftType, userImg, userName } = props;

  return (
    <Box className={classes.card}>
      <Box className={classes.cardContent}>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Box marginRight={"10px"}>
            <img src={userImg || ProfileImg} height={"42px"} width={"42px"} />
          </Box>
          <Box padding={"0px 10px"}>
            <Typography className={classes.cardTitleText}>
              {userName}
            </Typography>
            <Typography className={classes.cardSubTitleText}>
              {shiftType} | {date}
            </Typography>
          </Box>
        </Box>
        <Box
          padding={"3px 7px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius="30px"
          bgcolor={"#f5c42c"}
          color={"#fff"}
          fontSize="14px"
          fontWeight="600"
        >
          <StarIcon style={{ color: "#fff", height: "12px", width: "12px" }} />
          {score}
        </Box>
      </Box>
      <Box padding={"0px 10px"}>
        <Typography className={classes.cardBodyText}>{comment}</Typography>
      </Box>
    </Box>
  );
};
