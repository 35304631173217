import React from "react";
import { Container, Box, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyfterBusinessProfileContoller, {
  Props,
  TOfficialDocs,
  TProfileBlock,
  TRatingsData,
} from "./ShyfterBusinessProfileController";
import BusinessProfile from "../../../components/src/BusinessProfile";
import { ProfileQuickLink } from "../../../components/src/ProfileQuickLink";
import ProfileBlock from "../../../components/src/ProfileBlock";
import { AllReview } from "../../../components/src/AllReview";
import { UserOfficialDocs } from "../../user-profile-basic/src/UserOfficialDocs.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});

const styles = {
  label: {
    fontSize: "16px",
    color: "#000",
    fontWieght: 700,
    lineHeight: "18px",
  },
};

const profileBreadCrumbsPath = [
  {
    title: "Explore Shyfts",
    link: "ExploreShyftsPage",
    active: false,
  },
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];

const renderTabsData = (
  activeTabId: number,
  basicBlock: TProfileBlock[],
  managementBlock: TProfileBlock[],
  officalDocs: TOfficialDocs | null,
  ratingsData: TRatingsData | null
) => {
  if (activeTabId === 1) {
    return (
      <>
        <ProfileBlock blockTitle="Basic Details" blockData={basicBlock} />
        <ProfileBlock
          blockTitle="Management Person Details"
          blockData={managementBlock}
        />
      </>
    );
  }

  if (activeTabId === 2) {
    return (
      <UserOfficialDocs
        formLabel="Certificate of Organisation"
        certificateLabel="W-9 Form"
        officialDocs={officalDocs}
        isBusinessProfile
      />
    );
  }

  return ratingsData ? <AllReview ratingsData={ratingsData} /> : null;
};

export default class ShyfterBusinessProfile extends ShyfterBusinessProfileContoller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      businessProfile,
      sideTabs,
      basicBlock,
      managementBlock,
      ratingsData,
      officalDocs,
    } = this.state;
    const activeTab = sideTabs.find((tab) => tab.isActive);
    const activeTabId = activeTab ? activeTab.id : 0;

    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Your Account"
          breadCrumsData={profileBreadCrumbsPath}
        />
        <Box marginTop="10%">
          <Container maxWidth="lg">
            <Box width="100%">
              {businessProfile && <BusinessProfile {...businessProfile} />}
            </Box>
            <Box marginY="30px">
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Box marginBottom="10px">
                    <Typography style={styles.label}>Quick Links</Typography>
                  </Box>
                  <Box bgcolor={"#fff"} borderRadius="20px">
                    <ProfileQuickLink
                      linkData={sideTabs}
                      getActiveTab={this.changeTabHandler}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box>
                    {renderTabsData(
                      activeTabId,
                      basicBlock,
                      managementBlock,
                      officalDocs,
                      ratingsData
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
}
